import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import { findInList, getImageUrl, selectStyles } from "../../../Utils/Common";
import swal from "sweetalert";
import SubscriptionService from "../../../services/subscription";
import FeaturesService from "../../../services/subscription/features";
import Switch from "react-switch";
import NumberFormat from "react-number-format";

const SubscriptionForm = (props) => {
  const { id } = useParams();
  const actionName = props.action;
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionDescription, setSubscriptionDescription] = useState("");
  const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
  const [subscriptionImage, setSubscriptionImage] = useState("");
  const [subscriptionPrice, setSubscriptionPrice] = useState("");
  const [typeList, setTypeList] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);
  const [features, setFeatures] = useState([]);
  const [type, setType] = useState("");
  const [isHT, setIsHT] = useState(false);
  const [vat, setVat] = useState(0);
  const [stripePrice, setStripePrice] = useState('');
  const [stripeProduct, setStripeProduct] = useState('');

  const handleChangeImage = () => {
    const pics = document.getElementById("id-subscription-pics");
    pics.click();
  };

  const getSubscription = async () => {
    if (id) {
      const result = await SubscriptionService.getSubscriptionById(id);

      if (!result.error) {
        const data = result.data;
        setPhotoUrl(getImageUrl(data.photo)); 
        setSubscriptionName(data.name);
        setSubscriptionPrice(data.price);
        setType(data.type);
        setStripePrice(data.stripePrice);
        setStripeProduct(data.stripeProduct);
        const loadedFeatures = data.PackFeatures;
        let packFeatures = [];
        loadedFeatures.forEach((el) => {
          if (el.feature) {
            packFeatures.push({ label: el.feature.name, value: el.feature.id });
          }
        });

        setFeatures(packFeatures);
        setSubscriptionDescription(data.description);
      }
    }
  };

  const getTypeList = async () => {
    const types =
      await SubscriptionService.getSubscriptionTypeAsKeyValueService();
    setTypeList(types);
  };

  const getAllFeatures = async () => {
    const features = await FeaturesService.getAllAsKeyValue();
    setAllFeatures(features);
  };

  const createSubscriptionHandler = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("photo", subscriptionImage);
    data.append("subscriptionName", subscriptionName);
    data.append("subscriptionDescription", subscriptionDescription);
    data.append("subscriptionPrice", subscriptionPrice);
    data.append("type", type);
    data.append("tva", vat);
    data.append("isHT", isHT);
    data.append("stripePrice", stripePrice);
    data.append("stripeProduct", stripeProduct);
    
    let featuresIds = [];
    features.forEach((elt) => {
      featuresIds.push(elt.value);
    });

    data.append("features", JSON.stringify(featuresIds));

    let result;

    if (id) {
      result = await SubscriptionService.updateSubscriptionService(id, data);
    } else {
      result = await SubscriptionService.createSubscriptionService(data);
    }

    if (result.error) {
      swal(result.response.data.message, {
        icon: "warning",
        buttons: {
          confirm: "OK",
        },
      }).then((value) => {
        event.preventDefault();
      });
    } else {
      swal(result.data.message, {
        icon: "success",
        buttons: {
          confirm: "OK",
        },
      }).then((value) => {
        if (value) {
          event.preventDefault();
        }
      });
    }
  };

  useEffect(() => {
    getTypeList();
    getSubscription();
    getAllFeatures();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{actionName} Subscription</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Subscription</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">{actionName} Subscription</h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/subscription/"
                      type="button"
                      className="btn btn-tool bg-info"
                      
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => createSubscriptionHandler(e)}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mdg-col-sm-12 text-center">
                              <input
                                className="mdg-d-none"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="id-subscription-pics"
                                onChange={(event) => {
                                  setSubscriptionImage(event.target.files[0]);
                                  setPhotoUrl(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                }}
                              />
                              <img
                                onClick={handleChangeImage}
                                src={photoUrl}
                                className="mdg-upload-img"
                              ></img>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-subscription-name"
                                    type="text"
                                    value={subscriptionName}
                                    onChange={(event) => {
                                      setSubscriptionName(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-simulator-name">
                                    Name *
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-subscription-description"
                                    type="text"
                                    value={subscriptionDescription}
                                    onChange={(event) => {
                                      setSubscriptionDescription(
                                        event.target.value
                                      );
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-simulator-description">
                                    Description *
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="id-subscription-price"
                                    type="text"
                                    min="0"
                                    step="0.01"
                                    style={{ color: "#fff" }}
                                    value={stripeProduct}
                                    onChange={(event) => {
                                      setStripeProduct(event.target.value);
                                    }}
                                    
                                  />
                                  <label htmlFor="id-item-price">Stripe Product</label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="id-subscription-price"
                                    type="text"
                                    min="0"
                                    step="0.01"
                                    style={{ color: "#fff" }}
                                    value={stripePrice}
                                    onChange={(event) => {
                                      setStripePrice(event.target.value);
                                    }}
                                    
                                  />
                                  <label htmlFor="id-item-price">Stripe Product price</label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="id-subscription-price"
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    style={{ color: "#fff" }}
                                    value={subscriptionPrice}
                                    onChange={(event) => {
                                      setSubscriptionPrice(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="id-item-price">Price *</label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mdg-d-flex mt-1">
                                  <Switch
                                    onColor="#117a8b"
                                    id="pack-vat"
                                    onChange={(checked) => {
                                      setIsHT(checked);
                                    }}
                                    checked={isHT}
                                  />{" "}
                                  <label
                                    className="ml-2 mdg-normal-label"
                                    htmlFor="pack-vat"
                                    style={{ color: "#92969a" }}
                                  >
                                    excluding VAT
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined mt-1">
                                  <NumberFormat
                                    suffix={"%"}
                                    decimalScale={vat >= 100 ? 0 : 2}
                                    decimalSeparator=","
                                    style={{ color: "#fff" }}
                                    className={`text-right`}
                                    value={vat}
                                    id="input-vat"
                                    maxLength={vat >= 100 ? 3 : null}
                                    required
                                    onValueChange={(values) => {
                                      if (values.floatValue <= 100) {
                                        setVat(values.floatValue);
                                      } else {
                                        setVat(100);
                                      }
                                    }}
                                  />
                                  <label htmlFor="input-vat">VAT</label>
                                </div>
                              </div>
                              {typeList && (
                                <div className="col-lg-12">
                                  <div className="mdg-textfield-out-lined">
                                    <Select
                                      placeholder="Choose a type *"
                                      options={typeList}
                                      styles={selectStyles}
                                      isClearable={true}
                                      style={{ color: "#fff" }}
                                      onChange={(newValue, actionMeta) => {
                                        newValue != null
                                          ? setType(newValue.value)
                                          : setType(null);
                                      }}
                                      value={findInList(typeList, type)}
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                              {allFeatures && (
                                <div className="col-lg-12">
                                  <div className="mdg-textfield-out-lined">
                                    <Select
                                      placeholder="Choose features"
                                      style={{ color: "#fff" }}
                                      closeMenuOnSelect={false}
                                      isMulti
                                      isClearable={true}
                                      onChange={async (
                                        newValue,
                                        actionMeta
                                      ) => {
                                        newValue && setFeatures(newValue);
                                      }}
                                      options={allFeatures}
                                      styles={selectStyles}
                                      value={features}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-12 text-right mt-2">
                            <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubscriptionForm;
