import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SettingService from "../../../../services/setting";
import SettingNavHeader from "../NavHeader";
import NumberFormat from "react-number-format";

const BotRepatriation = () => {
    const [MPA_percentage, setMPA_percentage] = useState("");
    const [MPA_numberOfFlight, setMPA_numberOfFlight] = useState("");
    const [MPC_percentage, setMPC_percentage] = useState("");

    const getInitialSetting = async () => {
        await SettingService.getSettingsByName("BOT_REPATRIATION").then((res) => {
            if (!res.error) {
                setMPA_percentage(res.data.MPA_percentage);
                setMPA_numberOfFlight(res.data.MPA_numberOfFlight);
                setMPC_percentage(res.data.MPC_percentage);
            }
        });
    };

    const handlePassengerRepatriation = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        const data = {
            MPA_percentage: MPA_percentage,
            MPA_numberOfFlight: MPA_numberOfFlight,
            MPC_percentage: MPC_percentage,
        };
        formData.append("name", "BOT_REPATRIATION");
        formData.append("data", JSON.stringify(data));

        let result = await SettingService.create(formData);

        if (result.error) {
            Swal.fire({
                title: result.response.data.message
                    ? result.response.data.message
                    : result.response.data.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: result.data.message,
                icon: result.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => { });
        }
    };

    useEffect(() => {
        getInitialSetting();
    }, []);

    return (
        <div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"> Settings</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#">Admin</a>
                                </li>
                                <li className="breadcrumb-item active">Settings</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <SettingNavHeader />

            <section className="content mt-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        Repatriation of passengers for the bot
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={(e) => handlePassengerRepatriation(e)}>
                                        <div className="row">
                                            <div className="row col-lg-12 mt-2">
                                                <div className="col-lg-12">
                                                    <h5 className="card-title">
                                                        Repatriation of passengers from the most populated airports on the same continent
                                                    </h5>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mdg-textfield-out-lined">
                                                        <NumberFormat
                                                            suffix={" %"}
                                                            decimalSeparator=","
                                                            style={{ color: "#fff" }}
                                                            className={`text-right`}
                                                            value={MPA_percentage}
                                                            id="input-mpa-percentage"
                                                            required
                                                            onValueChange={(values) =>
                                                                setMPA_percentage(values.floatValue)
                                                            }
                                                        />
                                                        <label htmlFor="input-mpa-percentage">
                                                            Percentage  *
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mdg-textfield-out-lined">
                                                        <NumberFormat
                                                            suffix={" airports"}
                                                            decimalSeparator=","
                                                            style={{ color: "#fff" }}
                                                            className={`text-right`}
                                                            value={MPA_numberOfFlight}
                                                            id="input-mpa-number-of-flight"
                                                            required
                                                            onValueChange={(values) =>
                                                                setMPA_numberOfFlight(values.floatValue)
                                                            }
                                                        />
                                                        <label htmlFor="input-mpa-number-of-flight">
                                                            Number of airports  *
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-lg-12 mt-2">
                                                <div className="col-lg-12">
                                                    <h5 className="card-title">
                                                        Repatriation of passengers from the most populous continent
                                                    </h5>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mdg-textfield-out-lined">
                                                        <NumberFormat
                                                            suffix={" %"}
                                                            decimalSeparator=","
                                                            style={{ color: "#fff" }}
                                                            className={`text-right`}
                                                            value={MPC_percentage}
                                                            id="input-flight-duration"
                                                            required
                                                            onValueChange={(values) =>
                                                                setMPC_percentage(values.floatValue)
                                                            }
                                                        />
                                                        <label htmlFor="input-flight-duration">
                                                            Percentage  *
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 text-right mt-2">
                                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BotRepatriation;
