import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ItemService from "../../../../../../services/Item";
import { useSelector } from "react-redux";
import Language from "../../../../../extra/language/language";

const MyItemDetailNav = (props) => {
    const auth = useSelector((state) => state.auth)
    const { user } = auth;
    const { itemId } = props;
    const [used, setUsed] = useState(0);
    const [rented, setRented] = useState(0);
    const [exchanged, setExchanged] = useState(0);
    const [finished, setFinished] = useState(0);

    useEffect(() => {
        ItemService.countAllRelatedItems(itemId, user.id).then((result) => {
            if (!result.error) {
                setFinished(result.data.finished);
                setUsed(result.data.sell);
                setRented(result.data.rent);
                setExchanged(result.data.exchange);
            }
        });
    }, [props.itemId])

    return (
        <>
            <div className="row">
                <div className="col-lg-8 mb-3">
                    <div className="row justify-content-md-center">
                        <ul
                            className="nav nav-pills mt-3 text-center"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item" role="presentation">
                                <NavLink
                                    className={(navDatas) =>
                                        navDatas.isActive ? "nav-link mdg-active" : "nav-link"
                                    }
                                    to={`/account/items/${props.itemId}/details/sell`}

                                >
                                    <Language word={"Seller.For_Sale"}></Language>
                                    <span className="ml-2 badge">
                                        {used}
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav-item" role="presentation">
                                <NavLink
                                    className={(navDatas) =>
                                        navDatas.isActive ? "nav-link mdg-active" : "nav-link"
                                    }
                                    to={`/account/items/${props.itemId}/details/rent`}
                                >
                                    <Language word={"Seller.Rented_out"}></Language>
                                    <span className="ml-2 badge">
                                        {rented}
                                    </span>
                                </NavLink>
                            </li>
                            <li className="nav-item" role="presentation">
                                <NavLink
                                    className={(navDatas) =>
                                        navDatas.isActive ? "nav-link mdg-active" : "nav-link"
                                    }
                                    to={`/account/items/${props.itemId}/details/exchange`}
                                >
                                    <Language word={"Seller.Exchanged"}></Language>
                                    <span className="ml-2 badge">
                                        {exchanged}
                                    </span>
                                </NavLink>
                            </li>

                            <li className="nav-item" role="presentation">
                                <NavLink
                                    className={(navDatas) =>
                                        navDatas.isActive ? "nav-link mdg-active" : "nav-link"
                                    }
                                    to={`/account/items/${props.itemId}/details/finished`}
                                >
                                    <Language word={"Seller.Deal_Finished"}></Language>
                                    <span className="ml-2 badge">
                                        {finished}
                                    </span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 mt-3">
                   
                </div>
            </div>
        </>
    )
}

export default MyItemDetailNav;