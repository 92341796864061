import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import Settings from './Settings';

const SettingsPages = () => {
    return (
        <div>     
             <BaseBO page={<Settings />}></BaseBO>
        </div>
    );
};

export default SettingsPages;