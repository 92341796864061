import { useState } from "react";
import { useSelector } from "react-redux";

const translation = require("../../../translation/translation.json");

const Language = ({word}) => {
    const lang = useSelector((state) => state.language);
    const t = translation[lang];
   
    const getWord = (word) =>{
        const words = word.split(".");
        let wordRes = t;
        for(let i = 0;i < words.length; i ++){
            wordRes = wordRes[words[i]]
            if(!wordRes){
                wordRes = word
            }
        }
        
        return wordRes;
    }
    
    return <>{getWord(word)}</>
}

export default Language;