import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Language from "../../../extra/language/language";
import PackEltContainer from "./PackBoosterCarreerLevelEltContainer";
import { useSelector } from "react-redux";
import PackBoosterCarreerLevelService from "../../../../services/pack/packBoosterCarreerLevel";

const PackBoosterCarreerLevel = ({ version }) => {
  const history = useNavigate();
  const [packBoosterList, setPackBoosterList] = useState([]);
  const lang = useSelector((state) => state.language);

  const getPackList = async () => {
    await PackBoosterCarreerLevelService.getAll().then((res) => {
      setPackBoosterList(res.data);
    });
  };

  const classWidth = version === "v2" ? "col-lg-12 mt-3" : "col-lg-12 mt-3"
  const classHidden = version === "v2" ? "mdg-top-0 mdg-bg-content-v2" : "mdg-top-container";
  const hidden = version === "v2" ? "mdg-d-none" : "";
  const classBg = version === "v2" ? "mdg-width-card-body" : "mdg-width-card-body";


  useEffect(() => {
    getPackList();
  }, []);

  return (
    <div className={`${classHidden} container`}>
      <div className={`${hidden} mdg-title-ingame row`}>
        <div className="col-sm">
          <span className="mdg-h2"><Language word={"Packs.All_Packs_Carreer_Level_Booster"}></Language></span>
        </div>
      </div>
      <div className="cards-1">
        <div className="row">
          {packBoosterList &&
            packBoosterList.map((pack, i) => {
              return (
                <PackEltContainer
                  packClass={classWidth}
                  packBg={classBg}
                  pack={pack}
                  key={i}
                  history={history}
                  lang={lang}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PackBoosterCarreerLevel;
