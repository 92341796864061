import DataTable from "react-data-table-component";
import { getImageUrl } from "../../../../Utils/Common";
import Thumbnail from "../../../extra/img/thumbnail";

const ItemHistoryTable = (props) => {
  const rowStyle = [
    {
      when: (row) => row.id > 0,
      style: {
        backgroundColor: "transparent",
        color: "#fff",
        userSelect: "none",
      },
    },
  ];

  const columns = [
    {
        name: "Item",
        selector: (row) => row.media,
        sortable: false,
        reorder: false,
        cell: (row) => (
            <><Thumbnail src={getImageUrl(row.media) } ></Thumbnail><span className="ml-2">{row.itemName}</span></>
        )
    },
    {
      name: "description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
      sortable: true,
      left: true,
    },
  ];
  return (
    <>
      <DataTable
        className="mdg-card-profil-flight"
        style={{ background: "transparent", color: "#fff" }}
        // title="Item History"
        columns={columns}
        data={props.data}
        defaultSortFieldID={1}
        progressPending={props.progressPending}
        paginationDefaultPage={props.paginationDefaultPage}
        paginationServer
        paginationTotalRows={props.paginationTotalRows}
        pagination
        selectableRows
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        conditionalRowStyles={rowStyle}
      />
    </>
  );
};

export default ItemHistoryTable;
