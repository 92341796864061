import React, { useEffect, useState } from 'react';
import ItemService from '../../../services/Item';
import WishListService from '../../../services/item/wishList';
import OrderService from '../../../services/order';
import UserService from '../../../services/user';
import UserSubscriptionService from '../../../services/userSubscription';
import Language from '../../extra/language/language';
import WishListTable from './WishListTable';

const TopWishlistComponent = (props) => {
    const [topWishlist, setTopWishlist] = useState([]);

    const getTopWishlist = async () => {
        const result = await WishListService.getTopWishlist();
        if (result.status == 200) {
            setTopWishlist(result.data);
        }
    }

    useEffect(() => {
        getTopWishlist();
    }, []);
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header border-transparent">
                        <h3 className="card-title"><Language word={"Admin.Dashboard.Top_Wishlist"}></Language></h3>

                        <div className="card-tools">
                            {/* <button type="button" className="btn btn-tool" >
                                <i className="fas fa-minus"></i>
                            </button>
                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                <i className="fas fa-times"></i>
                            </button> */}
                        </div>
                    </div>
                    <div className="card-body p-0">
                        <div className="table-responsive">
                            <table className="table m-0">
                                <thead>
                                    <tr>
                                        <th><Language word={"Admin.Dashboard.Item"}></Language></th>
                                        <th><Language word={"Admin.Dashboard.Name"}></Language></th>
                                        <th><Language word={"Admin.Dashboard.Description"}></Language></th>
                                        <th><Language word={"Admin.Dashboard.Number"}></Language></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td><a href="pages/examples/invoice.html">OR9842</a></td>
                                        <td>Call of Duty IV</td>
                                        <td><span className="badge badge-success">Shipped</span></td>
                                        <td>
                                            <div className="sparkbar" data-color="#00a65a" data-height="20">90,80,90,-70,61,-83,63</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="pages/examples/invoice.html">OR1848</a></td>
                                        <td>Samsung Smart TV</td>
                                        <td><span className="badge badge-warning">Pending</span></td>
                                        <td>
                                            <div className="sparkbar" data-color="#f39c12" data-height="20">90,80,-90,70,61,-83,68</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="pages/examples/invoice.html">OR7429</a></td>
                                        <td>iPhone 6 Plus</td>
                                        <td><span className="badge badge-danger">Delivered</span></td>
                                        <td>
                                            <div className="sparkbar" data-color="#f56954" data-height="20">90,-80,90,70,-61,83,63</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="pages/examples/invoice.html">OR7429</a></td>
                                        <td>Samsung Smart TV</td>
                                        <td><span className="badge badge-info">Processing</span></td>
                                        <td>
                                            <div className="sparkbar" data-color="#00c0ef" data-height="20">90,80,-90,70,-61,83,63</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="pages/examples/invoice.html">OR1848</a></td>
                                        <td>Samsung Smart TV</td>
                                        <td><span className="badge badge-warning">Pending</span></td>
                                        <td>
                                            <div className="sparkbar" data-color="#f39c12" data-height="20">90,80,-90,70,61,-83,68</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="pages/examples/invoice.html">OR7429</a></td>
                                        <td>iPhone 6 Plus</td>
                                        <td><span className="badge badge-danger">Delivered</span></td>
                                        <td>
                                            <div className="sparkbar" data-color="#f56954" data-height="20">90,-80,90,70,-61,83,63</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><a href="pages/examples/invoice.html">OR9842</a></td>
                                        <td>Call of Duty IV</td>
                                        <td><span className="badge badge-success">Shipped</span></td>
                                        <td>
                                            <div className="sparkbar" data-color="#00a65a" data-height="20">90,80,90,-70,61,-83,63</div>
                                        </td>
                                    </tr> */}
                                    {topWishlist.map((elt, i) => (
                                        <WishListTable
                                            wish={elt}
                                            key={i}
                                        ></WishListTable>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer clearfix">
                        {/* <a href="javascript:void(0)" className="btn btn-sm btn-info float-left">Place New Order</a>
                    <a href="javascript:void(0)" className="btn btn-sm btn-secondary float-right">View All Orders</a> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopWishlistComponent;