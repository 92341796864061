/* eslint-disable jsx-a11y/alt-text */
import { getJSON } from "jquery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "../../../../services/base/location";
import { getSimulators } from "../../../../services/base/simulator";
import { getUserAvailableRoles } from "../../../../services/role";
import UserService, {
  updateUserInfoService,
  updateUserPaymentDataInfoService,
} from "../../../../services/user";
import {
  selectStylesFo,
  getFormatedDate,
  getLocalFormatedDate,
  getFrenchFormatedDate,
} from "../../../../Utils/Common";
import ChangePassword from "./ChangePassword";
import swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import { verifyTokenAsync } from "../../../../asyncActions/authAsyncActions";

import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import ProfilePaymentCard from "./paymentCard/ProfilePaymentCard";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Radio, Space } from 'antd';
import PContentProfil from "./PContentProfil";
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY
    : "pk_test_HORCTPcuoNEPkD4KnGOcgE2u"
);

const Profile = ({ getClass }) => {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [roleList, setRoleList] = useState([]);

  const [locationList, setLocationList] = useState([]);
  const [simulatorList, setSimulatorList] = useState([]);
  const [updateInfoError, setUpdateInfoError] = useState([]);
  const [updateInfoSuccess, setUpdateInfoSuccess] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState("");
  const [userPics, setUserPics] = useState(null);

  const [selectedDate, setselectedDate] = useState(null);

  const { user } = authObj;

  const [show, setShow] = useState(false);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [affiliateLink, setAffiliateLink] = useState(user.mfId);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [role, setRole] = useState("");
  const [location, setLocation] = useState(user.metadata.location);
  const [simulator, setSimulator] = useState("");
  const [city, setCity] = useState(user.metadata.city);
  const [activeProfil, setActiveProfil] = useState("PROFIL");
  const [value, setValue] = useState(1);

  const [dateOfBirth, setdateOfBirth] = useState(user.metadata.dateOfBirth);

  const onChangeValue = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  /******************************** paymentData */
  const [dataCountry, setDataCountry] = useState(
    user.payment.country ? user.payment.country : ""
  );
  const [city2, setCity2] = useState(
    user.payment.city ? user.payment.city : ""
  );
  const [adr1, setAdr1] = useState(
    user.payment.address1 ? user.payment.address1 : ""
  );
  const [adr2, setAdr2] = useState(
    user.payment.address2 ? user.payment.address2 : ""
  );
  const [postalCode, setPostalCode] = useState(
    user.payment.postalcode ? user.payment.postalcode : ""
  );

  const lang = useSelector((state) => state.language);

  const myDataCountry = () => {
    //setCountry(user.metadata.country);

    let val = countryList.filter((countryElt, i) =>
      user.payment.country
        ? countryElt.value === user.payment.country.toUpperCase()
        : null
    );

    return val[0] ? val[0] : countryList[0];
  };

  const handleUpdatePaymentData = async (e) => {
    e.preventDefault();

    const paymentFormData = new FormData();
    paymentFormData.append("city", city2);
    paymentFormData.append("country", dataCountry);
    paymentFormData.append("address1", adr1);
    paymentFormData.append("address2", adr2);
    paymentFormData.append("postalcode", postalCode);

    // const res = Object.fromEntries(
    //   [...paymentFormData].map(([key, value]) => [key, value])
    // );
    //console.log(res);

    const paymentDataResult = await updateUserPaymentDataInfoService(
      paymentFormData
    );

    // console.log(paymentDataResult);

    if (paymentDataResult.error) {
      swal
        .fire({
          text: paymentDataResult.response.data.data.message,
          icon: "warning",
        })
        .then((value) => { });
    } else {
      swal
        .fire({
          text: paymentDataResult.data.message,
          icon: "success",
        })
        .then((value) => {
          dispatch(verifyTokenAsync(true));
        });
    }
  };

  //******************************************  */

  const getRoleList = async () => {
    await getUserAvailableRoles().then((res) => {
      const roles = [];
      for (let i = 0; i < res.data.length; ++i) {
        const rl = res.data[i];
        roles.push({ label: rl.roleName, value: rl.id });
      }
      setRoleList(roles);
    });
  };

  const getUserPaymentMethodList = async () => {
    const result = await UserService.getUserPaymentMethodListService();
    if (!result.error) {
      setPaymentMethodList(result.data.user.data);
    }
  };

  const getSimulatorList = async () => {
    await getSimulators().then((res) => {
      const simulators = [];
      for (let i = 0; i < res.data.length; ++i) {
        const simulator = res.data[i];
        simulators.push({
          label: simulator.simulatorName,
          value: simulator.id,
        });
      }
      setSimulatorList(simulators);
    });
  };

  const getCountryList = async () => {
    getJSON("https://trial.mobiscroll.com/content/countries.json", (resp) => {
      const countries = [];
      for (let i = 0; i < resp.length; ++i) {
        const country = resp[i];
        let elt = {
          label: country.text,
          value: country.value,
        };
        countries.push(elt);
      }
      setCountryList(countries);
    });
  };

  const myCountry = () => {
    //setCountry(user.metadata.country);
    let val = countryList.filter((countryElt, i) =>
      user.metadata.country
        ? countryElt.value === user.metadata.country.toUpperCase()
        : null
    );

    return val[0] ? val[0] : countryList[0];
  };

  const mySimulator = () => {
    //setSimulator(user.metadata.simulator);
    let val = simulatorList.filter(
      (elt, i) => elt.value === user.metadata.simulator
    );
    return val[0];
  };

  const myRole = () => {
    //setRole(user.roleId);
    let val = roleList.filter((elt, i) => elt.value === user.roleId);
    return val[0];
  };

  const myLocation = () => {
    //setLocation(user.metadata.location);
    let val = locationList.filter((elt, i) => i === user.metadata.location);
    let val2 = locationList.filter((elt, i) => i === user.metadata.location);
    return val[0] ? val[0] : val2[0] ? val2[0] : null;
  };

  const getLocationList = async () => {
    setLocation(user.metadata.location);
    setRole(user.roleId);
    setSimulator(user.metadata.simulator);
    setCountry(user.metadata.country);

    await getLocations("").then((res) => {
      const locations = [];
      if (!res.error) {
        for (let i = 0; i < res.data.length; ++i) {
          const location = res.data[i];
          locations.push({
            label: `${location.label} (${location.iata_code})`,
            value: location.id,
          });
        }
      }
      setLocationList(locations);
    });
  };

  const handleChangeImage = () => {
    const pics = document.getElementById("id-profile-pics");
    pics.click();
  };

  const filterLocation = (inputValue) => {
    return locationList.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadLocationListOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterLocation(inputValue));
    }, 1000);
  };

  const createOptions = (fontSize, padding) => {
    return {
      hidePostalCode: true,
      style: {
        base: {
          fontSize: fontSize,
          color: "#fff",
          letterSpacing: "0.025em",
          "::placeholder": {
            color: "#92969a",
          },
          padding: padding,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };

  const navigate = useNavigate();
  const stripe = useStripe();

  const [typePayment, setTypePayment] = useState("STRIPE");

  const [stripeError, setStripeError] = useState(null);

  const elements = useElements();
  const [inProcess, setInProcess] = useState(false);
  const [inDetachProcess, setDetachInProcess] = useState(false);

  const handleAddPaymentMethodSubmit = async () => {
    const cardElement = elements.getElement(CardElement);

    setInProcess(true);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      Swal.fire({
        icon: "error",
        text: "Stripe error",
      });
    } else {
      const createUserPaymentMethodResult =
        await UserService.createUserPaymentMethodService(token.id);

      if (!createUserPaymentMethodResult.error) {
        Swal.fire({
          icon: "success",
          text: createUserPaymentMethodResult.data.message,
        });

        getUserPaymentMethodList();
      } else {
        Swal.fire({
          icon: "error",
          text: createUserPaymentMethodResult.response.data.message,
        });
      }
    }
    setInProcess(false);
  };

  const handleDetachPaymentMethodSubmit = async (customerId, id) => {
    setDetachInProcess(true);

    Swal.fire({
      title: "Confirmation !",
      text: "Are you sure to delete this card",
      confirmButtonText: "Yes, i'm sure !",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const detachUserPaymentMethodResult = await UserService.detachUserPaymentMethodService(customerId, id);
        if (detachUserPaymentMethodResult.error) {
          Swal.fire({
            icon: "error",
            text: detachUserPaymentMethodResult.response.data.message,
          });
        } else {
          getUserPaymentMethodList();
          Swal.fire({
            icon: "success",
            text: detachUserPaymentMethodResult.data.message,
          });
        }
      }
      setDetachInProcess(false);
    });
  };

  useEffect(() => {
    getCountryList();
    getUserPaymentMethodList();
    getRoleList();
    getLocationList();
    getSimulatorList();
    setUpdateInfoError(null);
  }, []);

  const handleLocationInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");

    return inputValue;
  };

  const handleUpdateInfo = async (e) => {
    e.preventDefault();

    setUpdateInfoError(null);
    setUpdateInfoSuccess(null);

    const metadata = user.isAdmin
      ? null
      : {
        role: role,
        simulator: simulator,
        location: location,
        country: country,
        city: city,
        dateOfBirth: dateOfBirth,
      };

    /**
     * check all condition here
     */
    if (true) {
      const formData = new FormData();
      formData.append("photo", userPics);
      formData.append("lastName", lastName);
      formData.append("firstName", firstName);

      if (!user.isAdmin) {
        formData.append("metadata", JSON.stringify(metadata));
      }

      const result = await updateUserInfoService(formData);
      if (result.error) {
        // setUpdateInfoError(result.response.data.message);
        swal
          .fire({
            text: result.response.data.message,
            icon: "warning",
          })
          .then((value) => { });
      } else {
        // setUpdateInfoSuccess(result.data.message);
        swal
          .fire({
            text: result.data.message,
            icon: "success",
          })
          .then((value) => {
            dispatch(verifyTokenAsync(true));
          });
      }
    }
  };

  const changePayment = (payment) => {
    setTypePayment(payment);
  };

  const changeActiveProfil = (type) => {
    setActiveProfil(type);
  };

  const LinkSwall = () => {
    const link = window.location.origin + "/register/" + user.userName;
    navigator.clipboard.writeText(link);
    swal.fire({
      text: translate("Profil.Link_copied_successfuly", lang),
      icon: "success",
    });
  };

  return (
    <div className="container mdg-top-container">
      <div className="mdg-title-ingame">
        <div className="row justify-content-between">
          <div className="col-4">
            <span className="mdg-h2">
              <Language word={"Profil.Profile"}></Language>
            </span>
          </div>
          <div className="col-4">
            <div className="btn-group mdg-bg-btn-group float-right">
              <button
                className={
                  activeProfil === "DASHBOARD"
                    ? "btn mdg-btn-meta btn-sm active mdg-btn-round mdg-d-box-shadow mr-2"
                    : "btn mdg-btn-meta btn-sm mdg-btn-round mdg-d-box-shadow mr-2"
                }
                onClick={(e) => {
                  navigate("/user-dashboard");
                }}
              >
                <Language word={"User_Dashboard.User_Dashboard"}></Language>
              </button>
              <button
                className={
                  activeProfil === "PROFIL"
                    ? "btn mdg-btn-meta btn-sm active mdg-btn-round mdg-d-box-shadow"
                    : "btn mdg-btn-meta btn-sm mdg-btn-round mdg-d-box-shadow"
                }
                onClick={(e) => {
                  changeActiveProfil("PROFIL");
                }}
              >
                <Language word={"Profil.Profile"}></Language>
              </button>
              {/* <button
                className={
                  activeProfil === "ACHIEVEMENT"
                    ? "btn mdg-btn-meta btn-sm active ml-2 mdg-btn-round mdg-d-box-shadow"
                    : "btn mdg-btn-meta btn-sm ml-2 mdg-btn-round mdg-d-box-shadow"
                }
                onClick={(e) => {
                  changeActiveProfil("ACHIEVEMENT");
                }}
              >
                <Language word={"Profil.Payment"}></Language>
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className={
            activeProfil === "PROFIL"
              ? "mdg-history-content mdg-blur"
              : "mdg-d-none"
          }
        >
          <PContentProfil />
        </div>
        <div
          className={
            activeProfil === "ACHIEVEMENT"
              ? "mdg-history-content mdg-blur"
              : "mdg-d-none"
          }
        >
          <div className="col-lg-12 row">
            <div className="col-lg-12 mdg-width-card-profil-information mb-2">
              <h5 className="card-title mdg-nav-span-profil ">
                <Language word={"Profil.Information"}></Language>
              </h5>
            </div>
            <div className="col-lg-6 text-center">
              <div className="mdg-card-profil-flight  text-center">
                <div className="mdg-col-sm-12 mdg-text-white">
                  <h5 className="card-title">
                    <Language word={"Profil.Billing_address"}></Language>
                  </h5>
                </div>
                <div className="card-body mt-3">
                  <form onSubmit={(e) => handleUpdatePaymentData(e)}>
                    <div className="mdg-col-sm-12 mt-3">
                      <div className="mdg-textfield-out-lined">
                        <input
                          id="input-city2"
                          autoComplete="false"
                          type="text"
                          placeholder=" "
                          onChange={(e) => {
                            setCity2(e.target.value);
                          }}
                          value={city2}
                          required
                          style={{ color: "#fff" }}
                        />
                        <label htmlFor="input-city2">
                          <Language word={"Profil.City"}></Language>
                        </label>
                      </div>
                      {myCountry() && (
                        <div className="mdg-textfield-out-lined">
                          {/* <label>Country</label> */}
                          <Select
                            isClearable={true}
                            onChange={(newValue, actionMeta) => {
                              newValue && setDataCountry(newValue.value);
                            }}
                            defaultValue={myDataCountry()}
                            placeholder={
                              <div>
                                <Language
                                  word={"Profil.Choose_a_country"}
                                ></Language>
                              </div>
                            }
                            options={countryList}
                            className="mdg-height-select-profil"
                            styles={selectStylesFo}
                            required
                          />
                        </div>
                      )}
                      <div className="mdg-textfield-out-lined">
                        <input
                          id="input-line1"
                          autoComplete="false"
                          type="text"
                          onChange={(e) => {
                            setAdr1(e.target.value);
                          }}
                          value={adr1}
                          placeholder=" "
                          style={{ color: "#fff" }}
                          required
                        />
                        <label htmlFor="input-line1">
                          <Language word={"Profil.Adress_Line_1"}></Language>
                        </label>
                      </div>
                      <div className="mdg-textfield-out-lined">
                        <input
                          id="input-line2"
                          autoComplete="false"
                          type="text"
                          onChange={(e) => {
                            setAdr2(e.target.value);
                          }}
                          value={adr2}
                          placeholder=" "
                          style={{ color: "#fff" }}
                          required
                        />
                        <label htmlFor="input-line2">
                          <Language word={"Profil.Adress_Line_2"}></Language>
                        </label>
                      </div>
                      <div className="mdg-textfield-out-lined">
                        <input
                          id="input-postal-code"
                          autoComplete="false"
                          type="text"
                          onChange={(e) => {
                            setPostalCode(e.target.value);
                          }}
                          value={postalCode}
                          placeholder=" "
                          style={{ color: "#fff" }}
                          required
                        />
                        <label htmlFor="input-postal-code">
                          <Language word={"Profil.Postal_Code"}></Language>
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="btn mdg-btn-common btn-sm float-right mt-3 mdg-btn-12"
                      >
                        <Language word={"Profil.Save"}></Language>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="mdg-col-sm-12 mt-2 ml-2">
                <h5 className="card-title mdg-text-white">
                  <Language word={"Profil.Card"}></Language> Stripe
                </h5>
              </div>
              <div className="mdg-card-profil-flight  text-center mt-4">
                <div className="card-body mt-2">
                  <div className="mdg-col-sm-12">
                    <div className="row justify-content-md-center">
                      <div className="col-lg-10 mdg-border-checkout pt-1 mt-3">
                        <div className="mdg-textfield-out-lined">
                          <CardElement options={createOptions()} />
                        </div>
                      </div>
                      <div className="col-lg-2 mt-2">
                        <button
                          type="submit"
                          disabled={inProcess}
                          className="btn mdg-btn-common btn-sm float-right mt-3 mdg-btn-12"
                          onClick={() =>
                            handleAddPaymentMethodSubmit(stripe, elements)
                          }
                        >
                          {inProcess ? (
                            "...."
                          ) : (
                            <Language word={"Profil.Add"}></Language>
                          )}
                        </button>
                      </div>
                    </div>
                    {stripeError && (
                      <span className="text-danger">{stripeError}</span>
                    )}
                  </div>
                </div>
                <>
                  {paymentMethodList && (
                    <div className="row">
                      <div className="col-lg-10">
                        {paymentMethodList.map(
                          (elt, i) =>
                            elt.card && (
                              <div className="mt-2" key={i}>
                                <div className="">
                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-lg-10 mdg-card-l-stripe">
                                        <div className="row justify-content-between">
                                          <div className="col-lg-10">
                                            <h5 className="mdg-show-number-card">
                                              **** **** **** {elt.card.last4}
                                            </h5>
                                          </div>
                                          {paymentMethodList[0] !== elt &&
                                            <div className="col-lg-2">
                                              <button
                                                type="submit"
                                                disabled={inDetachProcess}
                                                className="btn mdg-btn-transparent btn-sm"
                                                onClick={() =>
                                                  handleDetachPaymentMethodSubmit(elt.customer, elt.id)
                                                }
                                                style={{ position: 'absolute', top: '-8px' }}
                                              >
                                                {inDetachProcess ? (
                                                  "...."
                                                ) : (
                                                  <i class="fa fa-close" aria-hidden="true" style={{ color: 'red' }}></i>
                                                )}
                                              </button>
                                            </div>
                                          }
                                          <div className="col-lg-3 text-left">
                                            {elt.card.exp_month > 10
                                              ? elt.card.exp_month
                                              : "0" + elt.card.exp_month}
                                            /{elt.card.exp_year}
                                          </div>
                                          <div className="col-lg-3 text-right">
                                            <span className="mdg-text-white">{elt.card.brand}</span>
                                          </div>
                                        </div>
                                      </div>
                                      {paymentMethodList[0] === elt && <div className="col-lg-2">
                                        <Radio.Group onChange={onChangeValue} value={value}>
                                          <Space direction="vertical">
                                            <Radio value={1}> <small>Default Payement</small></Radio>
                                          </Space>
                                        </Radio.Group>
                                      </div>}
                                    </div>
                                  </div>


                                </div>
                              </div>
                            )
                        )}
                      </div>
                      {/* <div className="col-lg-2">
                        <Radio.Group onChange={onChangeValue} value={value}>
                          <Space direction="vertical">
                            <Radio value={1}>Default Payement</Radio>
                          </Space>
                        </Radio.Group>
                      </div> */}
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Profil = ({ visibility, setIsVisilble, version }) => {
  return (
    <Elements stripe={stripePromise}>
      <Profile visibility={visibility} setIsVisilble={setIsVisilble}>
        {" "}
      </Profile>
    </Elements>
  )
};

export default Profil;
