export const VERIFY_TOKEN_STARTED = 'VERIFY_TOKEN_STARTED';
export const VERIFY_TOKEN_END = 'VERIFY_TOKEN_END';
 
export const USER_LOGIN_STARTED = 'USER_LOGIN_STARTED';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
 
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const LOGGED_WITH_SUCCESS = 'LOGGED_WITH_SUCCESS';
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
export const USER_LOGOUT = 'USER_LOGOUT';

export const REFRESH_USER_WALLET = 'REFRESH_USER_WALLET';
export const REFRESH_USER_WALLET_START = 'REFRESH_USER_WALLET_START';
export const REFRESH_USER_WALLET_END = 'REFRESH_USER_WALLET_END';