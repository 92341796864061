import axios  from "../AxiosInstance"

const createLevelService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/level`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAllLevelService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/level`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const getLevelById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/level/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const updateLevelService = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/level/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteByIdService = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/level/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getCareerLevelAsKeyValueService = async () => {
    const res = await getAllLevelService();
    if (res.error) {
        return [];
    } else {
        const levels = [];
        for (let i = 0; i < res.data.length; ++i) {
            const elt = res.data[i];
            
            levels.push({ label: elt.level, value: elt.id });
        }
        return levels;
    }
};

const LevelService = {
    createLevelService,
    getAllLevelService,
    getLevelById,
    updateLevelService,
    deleteByIdService,
    getCareerLevelAsKeyValueService
}

export default LevelService;