import React, { useState } from "react";

const QuantityPickerInput = (props) => {
  const [value, setValue] = useState(props.inputValue);
  const { min, max, isDisabled} = props;
  const step = props.step ? props.step : 1;

  const onChange = (e) => {
    let newValue = Number(e.target.value)
    if (newValue && newValue >= min ) {
      if(!max ||  newValue <= max){
        props.onChange(parseInt(e.target.value))
        setValue(parseInt(e.target.value))
      }
    }
  }
  
  return (
    <div>
      <div className="row justify-content-md-center">
        <div className="col-lg-12">
          <div className="form-group form-spinner">
            <div className="spinner-control">
              <button type="button" className="btn btn-info btn-decrement" disabled={value == min || isDisabled} onClick={(event) => {
                if (value > 0) {
                  props.onChange(value - step)
                  setValue(value => value - step);
                }
              }}>
                <i className="fa fa-minus"></i>
              </button>
              <input
                type="text"
                data-type="number"
                step="1"
                min={min}
                max={max}
                value={value}
                readOnly={false}
                onChange={onChange}
                disabled={isDisabled}
              />
              <button type="button" className="btn btn-info btn-increment" disabled={value >= max || isDisabled} onClick={(e) => {
                props.onChange(value + step)
                setValue(value => value + step);
              }}>
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuantityPickerInput;
