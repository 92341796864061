import React, { useEffect, useState } from "react";
import { deleteRoles, getAllAvailableRoles } from "../../../services/role";
import { useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { userLogout, verifyTokenEnd } from "../../../actions/authActions";
import DescriptionComponent from "../description/DescriptionComponent";


const RoleList = () => {
    const dispatch = useDispatch();

    const [roleList, setRoleList] = useState([]);
    const [initialRoleList, setInitialRoleList] = useState([]);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const columns = [
        {
            id: 1,
            name: "Id",
            selector: (row) => row.id,
            sortable: true,
            reorder: true,
        },
        {
            id: 2,
            name: "Name",
            selector: (row) => row.displayName,
            sortable: true,
            reorder: true,
        },
        {
            id: 3,
            name: "Client",
            cell: (row) => <input type="checkbox" checked={row.isClient} readOnly />,
            sortable: true,
            center: true,
            reorder: true,
        },
        {
            id: 4,
            name: "Active",
            cell: (row) => (
                <input type="checkbox" checked={row.isActive} readOnly={true} />
            ),
            sortable: true,
            center: true,
            reorder: true,
        },
        {
            id: 5,
            button: true,
            left: true,
            cell: (row) =>
                row.displayName !== "Admin" ? (
                    <NavLink
                        className="mdg-btn mdg-btn-outline-meta mr-2"
                        to={"/admin/role/edit/" + row.id}
                    >
                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </NavLink>
                ) : (
                    ""
                ),
        },
        {
            id: 6,
            button: true,
            left: true,
            cell: (row) =>
                row.displayName !== "Admin" ? (
                    <button
                        type="button"
                        className="mdg-btn mdg-btn-danger mr-2"
                        onClick={async () => {
                            var proceed = window.confirm(
                                `Are you sure to delete the role : ${row.displayName}`
                            );
                            if (proceed) {
                                await deleteRoles(row.id).then((res) => {
                                    getRoleList();
                                });
                            } else {
                            }
                        }}
                    >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                    </button>
                ) : (
                    ""
                ),
        },
    ];

    const getRoleList = async () => {
        const res = await getAllAvailableRoles();
        if (res.error) {
            dispatch(verifyTokenEnd());
            if (res.response && [401, 403].includes(res.response.status))
                dispatch(userLogout());
            return;
        }

        const roles = [];
        for (let i = 0; i < res.data.length; ++i) {
            const rl = res.data[i];
            roles.push({
                id: rl.id,
                roleName: rl.roleName,
                displayName: rl.displayName,
                isClient: rl.isClient,
                isActive: rl.isActive,
            });
        }
        setInitialRoleList(roles);
        filterRole(roles, "");
    };

    useEffect(() => {
        getRoleList();
    }, []);

    const filterRole = (list, txt) => {
        let res = list.filter((elt, i) =>
            elt.displayName.toLowerCase().includes(txt.toLowerCase())
        );
        setRoleList(res);
    };


    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Role</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Role</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Roles</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/role/new" type="button" className="btn btn-tool bg-info" data-card-widget="collapse">
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 mdg-historique-table">
                                            {roleList && (
                                                <DataTable
                                                    columns={columns}
                                                    data={roleList}
                                                    defaultSortFieldId={1}
                                                    pagination
                                                    selectableRows
                                                    conditionalRowStyles={rowStyle}
                                                />
                                            )}
                                        </div>


                                    </div>

                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default RoleList;
