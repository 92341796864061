import React, { Component } from "react";
import Language from "../../../extra/language/language";
import HeaderPage from "../header/HeaderPage";
import SideNav from "./Side";


class SellerBasePage extends Component {
    render() {
        document.body.style.backgroundColor = "#081217";
    
        return (
            <div className="mdg-body-ingame">
                <div className="mdg-header-ingame">
                    <HeaderPage />
                        <div className="container mdg-top-container">
                            <div className="mdg-title-ingame row">
                                <div className="col-sm">
                                    <span className="mdg-h2"><Language word={"Seller.Seller_mode"}></Language></span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3">
                                    <SideNav />
                                </div>
                                <div className="col-lg-9">
                                    {this.props.page}
                                </div>
                            </div>
                        </div>
                 
                </div>
            </div>
        );
    }
}

export default SellerBasePage;
