import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { getFormatedAmount, getFullDate, tableRowStyle } from "../../../Utils/Common";
import swal from 'sweetalert'
import OrderService from "../../../services/order";
import Select from "react-select";
import { findInList, selectStyles } from "../../../Utils/Common";

const OrderTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [statusList, setStatusList] = useState([]);

    const getStatusList = async () => {
        await OrderService.getPackOrdersStatusAsKeyValueService().then((res) => {
            setStatusList(res);
        });
    }

    const getList = async () => {
        setLoading(true);

        const filter = {
            page: currentPage - 1,
            size: perPage,
            search: search
        }

        const response = await OrderService.getList(filter);
        if (!response.error) {
            setData(response.data.items);
            setTotalRows(response.data.totalItems);
        }

        setLoading(false);
    };

    useEffect(() => {
        getStatusList();
        getList();
    }, [currentPage, perPage]);

    const updateOrderStatus = (status, id) => {
        const data = new FormData();
        data.append('status', status);
        swal(`Are you sure to update the status to : ${status}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await OrderService.updateStatus(id, data);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }
                getList();
            }

        });

    }

    const columns = useMemo(
        () => [
            {
                name: "Order n°",
                selector: (row) => "Order n° "+ row.id,
                sortable: true
            }, {
                width: '250px',
                name: "User",
                selector: (row) => row.user.firstName + ' ' + row.user.lastName + ' ( ' + row.user.userName + ')',
                sortable: true
            }, {
                name: "FC Amount",
                selector: (row) =>  getFormatedAmount(row.fcAmount) ,
                sortable: true
            }, {
                name: "Price",
                selector: (row) => getFormatedAmount(row.price)  ,
                sortable: true
            }, {
                name: "Quantity",
                selector: (row) => row.quantity  ,
                sortable: true
            }
            // , {
            //     name: "Quantity",
            //     selector: (row) => row.quantity,
            //     sortable: true
            // }
            , {
                name: "Date",
                selector: (row) => getFullDate(row.createdAt),
                sortable: true
            }, {
                width: '180px',
                name: "Status",
                selector: (row) => getFullDate(row.createdAt),
                sortable: true,
                cell: (row) => (
                    <div className="status">
                        {(row.status == "COMPLETED") ? <div className="d-flex align-items-center"><i className="text-success fa fa-2x fa-check"></i><span className="ml-1">COMPLETED</span></div> : (
                            <Select
                                options={statusList}
                                styles={selectStyles}
                                isClearable={true}
                                style={{ color: "#fff" }}
                                onChange={(newValue, actionMeta) => {
                                    updateOrderStatus(newValue.value, row.id);
                                }}
                                defaultValue={findInList(statusList, row.status)}
                                required
                            />)
                        }
                    </div>
                )
            },
        ]
    );

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    return (
        <DataTable
            title=""
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) => { console.log(selectedRows) }}
            conditionalRowStyles={tableRowStyle}
        />
    );
};


export default OrderTable;

