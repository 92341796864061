import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getImageUrl } from "../../../../Utils/Common";

const SideBo = () => {
  const authObj = useSelector((state) => state.auth);
  const { user } = authObj;
  return (
    <aside
      className="main-sidebar sidebar-dark-primary elevation-4"
      style={{ marginTop: 5 + "px", position: "fixed" }}
    >
      <NavLink to="/" className="brand-link">
        <img
          src="/assets/img/logo-meta-white-blue.png"
          alt="Metaflight Logo"
          className="brand-image "
        />
        <span className="brand-text font-weight-light">Metaflight</span>
      </NavLink>

      <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-transition os-host-scrollbar-horizontal-hidden">
        <div className="os-resize-observer-host observed">
          <div className="os-resize-observer"></div>
        </div>
        <div className="os-size-auto-observer observed">
          <div className="os-resize-observer"></div>
        </div>
        <div className="os-content-glue"></div>
        <div className="os-padding">
          <div className="os-viewport os-viewport-native-scrollbars-invisible">
            <div className="os-content">
              <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                <div className="image">
                  <img
                    src={
                      user.photo
                        ? getImageUrl(user.photo)
                        : "/assets/img/default-profile-picture.png"
                    }
                    className="img-circle elevation-2"
                    alt="User Image"
                  />
                </div>
                <div className="info">
                  <a href="#" className="d-block">
                    {user?.lastName}
                  </a>
                </div>
              </div>
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-item ">
                    <NavLink to="/admin/dashboard" className="nav-link ">
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>Dashboard</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Items</li>
                  <li className="nav-item">
                    <NavLink to="/admin/category" className="nav-link ">
                      <i
                        className="nav-icon fa fa-snowflake-o"
                        aria-hidden="true"
                      ></i>
                      <p>Categories</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/type" className="nav-link ">
                      <i className="nav-icon fa fa-columns"></i>
                      <p>Types</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/item" className="nav-link ">
                      <i className="nav-icon fa fa-th"></i>
                      <p>Items</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Packs</li>
                  <li className="nav-item">
                    <NavLink to="/admin/pack" className="nav-link ">
                      <i className="nav-icon fa fa-shopping-basket"></i>
                      <p>Packs</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/up-to-sell" className="nav-link ">
                      <i className="nav-icon fa fa-shopping-basket"></i>
                      <p>Up sell</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/order" className="nav-link ">
                      <i className="nav-icon fa fa-shopping-cart"></i>
                      <p>Orders</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/promocode" className="nav-link ">
                      <i className="nav-icon fa fa-code"></i>
                      <p>Promocode</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Packs booster</li>
                  <li className="nav-item">
                    <NavLink
                      to="/admin/pack-booster-carreer-level"
                      className="nav-link "
                    >
                      <i className="nav-icon fa fa-rocket"></i>
                      <p>Pack booster level</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/admin/pack-booster-carreer-order"
                      className="nav-link "
                    >
                      <i className="nav-icon fa fa-rocket"></i>
                      <p>Pack booster order</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Subscriptions</li>
                  <li className="nav-item">
                    <NavLink to="/admin/subscription" className="nav-link ">
                      <i className="nav-icon fa fa-pencil"></i>
                      <p>Pack</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/admin/user-subscription"
                      className="nav-link "
                    >
                      <i className="nav-icon fa fa-pencil-square"></i>
                      <p>Subscriptions</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/features" className="nav-link ">
                      <i className="nav-icon fa fa-hashtag"></i>
                      <p>Features</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Notifications</li>
                  <li className="nav-item">
                    <NavLink to="/admin/notice" className="nav-link ">
                      <i className="nav-icon fas fa-bell"></i>
                      <p>Notices</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Cargo</li>
                  <li className="nav-item">
                    <NavLink to="/admin/cargo-type" className="nav-link ">
                      <i className="nav-icon fas fa-cubes"></i>
                      <p>Type</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/admin/cargo-category" className="nav-link ">
                      <i className="nav-icon fa fa-ticket"></i>
                      <p>Category</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/admin/wares" className="nav-link ">
                      <i className="nav-icon fa fa-truck"></i>
                      <p>Wares</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">PaxNCargo</li>
                  <li className="nav-item">
                    <NavLink
                      to="/admin/paxncargo/food-category"
                      className="nav-link "
                    >
                      <i className="nav-icon fa fa fa-cutlery"></i>
                      <p>Food Category</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/admin/paxncargo/food" className="nav-link ">
                      <i className="nav-icon fa fa fa-spoon"></i>
                      <p>Food</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/admin/paxncargo/flight-incident"
                      className="nav-link "
                    >
                      <i className="nav-icon fa fa fa-exclamation"></i>
                      <p>Flight Incident</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Missions</li>
                  <li className="nav-item">
                    <NavLink to="/admin/level" className="nav-link ">
                      <i className="nav-icon fas fa-level-up-alt"></i>
                      <p>Levels</p>
                    </NavLink>
                  </li>
                  {/*<li className="nav-item">
              <NavLink to="/admin/carreer-level-scenarios" className="nav-link ">
                <i className="nav-icon fas fa-angle-double-up"></i>
                <p>
                  Scenario Levels
                </p>
              </NavLink>

            </li>*/}
                  <li className="nav-item">
                    <NavLink to="/admin/mission-type" className="nav-link ">
                      <i className="nav-icon fas fa-outdent"></i>
                      <p>Types</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/mission" className="nav-link ">
                      <i className="nav-icon fas fa-bullseye"></i>
                      <p>Missions</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/admin/aviation-type" className="nav-link ">
                      <i className="nav-icon fas fa-plane"></i>
                      <p>Aviation Type</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Reward/Award</li>
                  <li className="nav-item">
                    <NavLink to="/admin/scenario" className="nav-link ">
                      <i className="nav-icon fa fa-steam"></i>
                      <p>Scenario</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/award" className="nav-link ">
                      <i className="nav-icon fas fa-trophy"></i>
                      <p>Award</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">MF Store</li>
                  <li className="nav-item">
                    <NavLink to="/admin/store-category" className="nav-link ">
                      <i
                        className="nav-icon fa fa-snowflake-o"
                        aria-hidden="true"
                      ></i>
                      <p>Categories</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/store-product" className="nav-link ">
                      <i
                        className="nav-icon fa fa-archive"
                        aria-hidden="true"
                      ></i>
                      <p>Product</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/store-order" className="nav-link ">
                      <i
                        className="nav-icon fa fa-cart-arrow-down"
                        aria-hidden="true"
                      ></i>
                      <p>Order</p>
                    </NavLink>
                  </li>
                  <li className="nav-header">Users</li>
                  <li className="nav-item">
                    <NavLink to="/admin/user" className="nav-link ">
                      <i className="nav-icon fas fa-users"></i>
                      <p>Users</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/role" className="nav-link ">
                      <i className="nav-icon fa fa-sitemap"></i>
                      <p>Roles</p>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/admin/simulator" className="nav-link ">
                      <i className="nav-icon fa fa-object-group"></i>
                      <p>Simulator</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/location" className="nav-link ">
                      <i className="nav-icon fa fa-map-marker"></i>
                      <p>Location</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/admin/manage-profile" className="nav-link ">
                      <i className="nav-icon fa fa-list"></i>
                      <p>Type</p>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
          <div className="os-scrollbar-track">
            <div className="os-scrollbar-handle"></div>
          </div>
        </div>
        <div className="os-scrollbar os-scrollbar-vertical os-scrollbar-auto-hidden">
          <div className="os-scrollbar-track">
            <div className="os-scrollbar-handle"></div>
          </div>
        </div>
        <div className="os-scrollbar-corner"></div>
      </div>
    </aside>
  );
};

export default SideBo;
