import axios  from "../AxiosInstance"

const getAllScenarioCondition = async (id) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario-condition`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getById = async (id) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario-condition/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const create = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario-condition`,
      data: data
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario-condition/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id, data) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario-condition/${id}`,
      data: data
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllAsKeyValue = async () => {
  const res = await getAllScenarioCondition();
  if (res.error) {
    return [];
  } else {
    const output = [];
    const data = res.data;

    data.forEach((elt, i) => {
      output.push({ label: elt.name, value: elt.id });
    })

    return output;
  }
}

const getScenarioConditionTypeService = async () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario-condition/condition-type-list`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getScenarioConditionTypeAsKeyValueService = async () => {
  const res = await getScenarioConditionTypeService();

  if (res.error) {
    return [];
  } else {
    const status = [];
    const { data } = res;
    const dataArray = Object.keys(data);

    for (let i = 0; i < dataArray.length; ++i) {
      const elt = dataArray[i];

      status.push({ label: elt.split("_").join(" "), value: elt });
    }

    return status;
  }
}

const ScenarioConditionService = {
  create,
  getAllScenarioCondition,
  getById,
  deleteById,
  update,
  getAllAsKeyValue,
  getScenarioConditionTypeAsKeyValueService,
}

export default ScenarioConditionService;