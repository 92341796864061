import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import SubscriptionService from "../../../../services/subscription";
import ListFeature from "./ListFeature";
import SubscriptionList from "./SubscriptionList";
import Swal from "sweetalert2";
import { swalResult } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";
import { useDispatch, useSelector } from "react-redux";
import translate from "../../../extra/language/translate";
import { updateUserInfo } from "../../../../asyncActions/authAsyncActions";
import Panier from "../checkout/panier/Panier";
import MetaCart from "../../../../actions/cartActions";

const Subscription = ({ version }) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [refresh, setRefresh] = useState(1);
    const [subscriptionList, setSubscriptionList] = useState([]);


    const lang = useSelector((state) => state.language);
    const cart = useSelector((state) => state.cart);
    const authObj = useSelector((state) => state.auth);
    const { user } = authObj;
    const [mySubscription, setMySubcription] = useState(user.subscriptions);
    const [portalUrl,setPortalUrl] = useState(null); 
    const [activeType, setActiveType] = useState((user.subscriptions && user.subscriptions.subscription) ? user.subscriptions.subscription.type : 'MONTHLY');

    const getAllSubscriptionList = async (searchText) => {
        await SubscriptionService.getAllSubscriptionService().then((res) => {
            if (!res.error) {
                if (res.data.subscriptionList) {
                    setSubscriptionList(res.data.subscriptionList);
                }
            }
        });
    };

    const changeActiveType = (type) => {
        setActiveType(type);
    }

    const handleRefreshState = () => {
        setRefresh((refresh) => refresh + 1)
    }

    const unSubscribe = () => {
        Swal.fire({
            title: translate('Seller.Confirmation', lang),
            icon: "question",
            text: translate('Seller.Are_you_sure_you_want_to_unsubscribe_from_this_offer', lang),
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
                const result = await SubscriptionService.cancelMySubscription();
                swalResult(result);
                setRefresh((refresh) => refresh + 1)
                // window.location.reload()
               dispatch(updateUserInfo ());
                
            }
        })
    }

    const refreshUserInformation = () => {
        dispatch(updateUserInfo ());
    }

    const getPortalUrl = async () => {
        const result = await SubscriptionService.getMySubscriptionPortal();
        if(!result.error){
            setPortalUrl(result.data.url);
        }
    }

    useEffect(() => {
        getAllSubscriptionList();
    }, [refresh, mySubscription]);

    useEffect(() => {
        setMySubcription(user.subscriptions)

        if(user.subscriptions){
           
            if(user.subscriptions.clientPortal){
                setPortalUrl(user.subscriptions.clientPortal)
            }else{
                getPortalUrl();
            }
        }else{
            setPortalUrl(null);
        }
    }, [user.subscriptions])

    useEffect(() => {
        if(cart.error){
            Swal.fire({
                'icon': 'error',
                'text': translate(cart.message,lang)
            })
            dispatch(MetaCart.HideError())
        }
    },[cart.error])

    const classHidden = version === "v2" ? "mdg-top-0 mdg-bg-content-v2" : "mdg-top-container";
    const classParentWidth = version === "v2" ? "col-lg-12" : "col-lg-9";
    const classBg = version === "v2" ? "mdg-card-dahsboard-flight" : "";
    const hidden = version === "v2" ? "mdg-d-none" : "";

    return (
        <>
        
        <div className={`${classHidden} container`}>
            <div className={`${hidden} mdg-title-ingame row`}>
                <div className="col-sm">
                    <span className="mdg-h2"><Language word={"Subscription.My_Subscription"}></Language></span>
                </div>
            </div>
            <div className="row justify-content-md-center text-center mt-3">
                <div className="col-lg-4">
                    <div className="btn-group mdg-bg-btn-group">
                        <button
                            className={activeType === 'MONTHLY' ? "btn mdg-btn-meta btn-sm active mdg-btn-round mdg-d-box-shadow" : "btn mdg-btn-meta btn-sm mdg-btn-round mdg-d-box-shadow"}
                            onClick={(e) => {
                                changeActiveType('MONTHLY');
                            }}

                        ><Language word={"Subscription.Billed_Monthly"}></Language></button>
                        <button
                            className={activeType === 'ANNUAL' ? "btn mdg-btn-meta btn-sm ml-2 active mdg-btn-round mdg-d-box-shadow" : "btn mdg-btn-meta btn-sm ml-2 mdg-btn-round mdg-d-box-shadow"}
                            onClick={(e) => {
                                changeActiveType('ANNUAL');
                            }}
                        ><Language word={"Subscription.Billed_Yearly"}></Language></button>
                    </div>
                </div>
                <div className={`${classParentWidth} mt-3`}>
                    <div className="row justify-content-md-center">
                        {subscriptionList.map((elt, i) => elt.type === activeType && (
                            <div className="col-lg-4 mt-4 d-flex" key={i}>
                                <SubscriptionList
                                    subscriptionClass={classBg}
                                    subscription={elt}
                                    key={i}
                                    history={history}
                                    activeSubscription={mySubscription}
                                    unSubscribe={unSubscribe}
                                    lang={lang}
                                    handleRefreshState={handleRefreshState}
                                    user={user}
                                    refreshUserInformation={refreshUserInformation}
                                    portalUrl={portalUrl}
                                ></SubscriptionList>
                            </div>
                        ))}
                    </div>
                    <ListFeature subscriptions={subscriptionList} activeType={activeType} activeSub={mySubscription} />
                </div>
            </div>
        </div>
        </>
    );
};

export default Subscription;
