import { useState } from "react";
import { useSelector } from "react-redux";
import swal from 'sweetalert2';
import { updatePassword } from "../../../../services/user";
import { useFormInput } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";

const ChangePassword = (props) => {
  const oldPassword = useFormInput("");
  const newPassword = useFormInput("");
  const confirmNewPassword = useFormInput("");

  const [changePasswordError, setChangePasswordError] = useState([]);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState([]);

  const lang = useSelector((state) => state.language);
  
  const handleChangePassword = async (e) => {
    e.preventDefault();
    setChangePasswordError(null);
    setChangePasswordSuccess(null);
    const new_password = newPassword.value;
    const confirm_new_password = confirmNewPassword.value;
    const old_password = oldPassword.value;
    if (!new_password || !confirm_new_password || !old_password) {
      setChangePasswordError(translate('Password.All_fields_is_required',lang));

      return;
    }
    if (new_password !== confirm_new_password) {
      setChangePasswordError(
        translate('Password.New_Password_and_Confirm_New_Password_does_not_match',lang)
      );

      return;
    }

    const result = await updatePassword(old_password, new_password);

    if (result.error) {
      // setChangePasswordError(result.response.data.message);
      swal.fire( {
        text: result.response.data.message,
        icon: "warning",
        buttons: {
          confirm: "OK"
        },
      }).then(value => {
      })
    } else {
      // setChangePasswordSuccess(result.data.message);
      swal.fire( {
        text: result.data.message,
        icon: "success",
        buttons: {
          confirm: "OK"
        },
      }).then(value => {
        if (value) {
          
        }
      })
    }
  };

  return (    
      <div className="col-lg-12 mt-4">
        <form onSubmit={(e) => handleChangePassword(e)}>
          <div className="mdg-row">
            <div className="mdg-col-lg-4">
              <div className="mdg-textfield-out-lined">
                <input id="input-oldpassword" autoComplete="false" type="password" placeholder=" " {...oldPassword} style={{ color: "#fff" }}/>
                <label htmlFor="input-oldpassword"><Language word={"Password.Old_Password"}></Language></label>
              </div>
            </div>
            <div className="mdg-col-lg-4">
              <div className="mdg-textfield-out-lined">
                <input id="input-last-newpassword" autoComplete="false" type="password" placeholder=" "  {...newPassword}  style={{ color: "#fff" }}/>
                <label htmlFor="input-last-newpassword"><Language word={"Password.New_Password"}></Language></label>
              </div>
            </div>
            <div className="mdg-col-lg-4">
              <div className="mdg-textfield-out-lined">
                <input id="input-confirm" autoComplete="false" type="password" placeholder=" " {...confirmNewPassword} style={{ color: "#fff" }} />
                <label htmlFor="input-confirm"><Language word={"Password.Confirm_Password"}></Language></label>
              </div>
            </div>
            <div className="mdg-col-sm-12 text-center">
              {changePasswordError && (
                <>
                  <small className="text-danger"> {changePasswordError} </small>{" "}
                </>
              )}
              {changePasswordSuccess && (
                <>
                  <small className="text-success">{changePasswordSuccess} </small>{" "}
                </>
              )}
            </div>

          </div>
            <div className="row justify-content-end">
              <div className="col">
                <button
                  type="submit"
                  className="btn mdg-btn-common btn-sm float-right mt-3 mdg-btn-12"
                >
                  <Language word={"Password.Save"}></Language>
                </button>
              </div>
            </div>


        </form>
      </div>
  );
};

export default ChangePassword;
