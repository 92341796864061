import React, { useState } from "react";
import { useSelector } from "react-redux";
import Cart from "../Cart";
import Coupon from "../Coupon";
import PayPalPayment from "../gateway/paypal/payment";
import Language from "../../../../extra/language/language";
import StripePayment from "../gateway/stripe/StripePayment";

const Panier = (props) => {
  const cart = useSelector((state) => state.cart);
  const [typePayment, setTypePayment] = useState("STRIPE");
  const changePayment = (payment) => {
    setTypePayment(payment)
  }

  return (

    <div>
      <div className={props.visibility == null ? "mdg-d-none" : (props.visibility ? "mdg-width-panier-all visible-in-right" : "mdg-width-panier-all visible-out-right")} >
        <div className="mdg-panier-header">
          <div className="">
            <button className="btn mdg-btn-transparent" onClick={(e) => props.setIsVisilble()} >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div className="mdg-panier-body">
          <div className="order-md-2 mb-4 p-2">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span style={{ color: "#fff" }}><Language word={"Panier.Your_cart"}></Language></span>
            </h4>
            <div className="mdg-height-panier-cart-parent">
              {cart.type ==='pack' && <Coupon></Coupon>}
              <Cart cart={cart}></Cart>
            </div>
            <div className="mdg-panier-bottom" style={{ marginBottom :"50px"}}>
              <div className="row justify-content-md-center mdg-height-moyen-payment">
                <div className="col-lg-12">
                  <div className="row justify-content-md-centers">
                    <div className="col">
                      <button
                        className={typePayment === "STRIPE" ? " btn-sm mdg-btn mdg-btn-transparent active" : "btn-sm mdg-btn mdg-btn-transparent"}
                        onClick={(e) => {
                          changePayment("STRIPE")
                        }}
                      >
                        <Language word={"Payment.card"}></Language>
                      </button>
                    </div>
                    {
                      !cart.hasSubsrciption && (
                        <div className="col">
                          <button
                            className={typePayment === "PAYPAL" ? "btn-sm mdg-btn mdg-btn-transparent active" : "btn-sm mdg-btn mdg-btn-transparent"}
                            onClick={(e) => {
                              changePayment("PAYPAL")
                            }}
                          >
                            <img
                              src={"/assets/image/logo-paypal-white.png"}
                              className="mdg-payment-logo"
                              alt=""
                            />
                          </button>
                        </div>
                      )
                    }
                  </div>
                </div>
                {typePayment === "STRIPE" &&
                  <>
                    <StripePayment></StripePayment>
                  </>
                }
                {(typePayment === "PAYPAL" && !cart.hasSubsrciption) &&
                  
                    (<PayPalPayment></PayPalPayment>)
                  
                }
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Checkout = ({ visibility, setIsVisilble }) => (
    <Panier visibility={visibility} setIsVisilble={setIsVisilble}> </Panier>
);

export default Checkout;


