import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Thumbnail from "../../../../extra/img/thumbnail";
import { useSelector } from "react-redux";
import { getFormatedAmount, getImageUrl } from "../../../../../Utils/Common";
import Rent from "../../seller/MarketPlace/Rent";
import Exchange from "../../seller/MarketPlace/Exchange";
import Sell from "../../seller/MarketPlace/Sell";
import Language from "../../../../extra/language/language";

const ItemDetail2 = (props) => {
    const item = props.item;
    const [action, setAction] = useState("sell");

    let isBenefits = false;
    if (item) {
        isBenefits = item.conditionXMilesFixedBonus || item.conditionXMilesPerDayForXDays || item.conditionXMilesPerHourOfFlightForXDays;
    }

    let ariane = 'Sell';
    const getForm = () => {
        let form;
        switch (action) {
            case 'rent': ariane = 'Rent'; form = <Rent itemId={props.id} item={item} ></Rent>; break;
            case 'exchange': ariane = 'Exchange'; form = <Exchange itemId={props.id} item={item} ></Exchange>; break;
            default: form = <Sell itemId={props.id} item={item} ></Sell>; break;
        }

        return form;
    }

    const wallet = useSelector((state) => state.wallet);

    // 

    return (
        <>
            {
                item && (
                    <>
                        <div className="row justify-content-end mb-2">
                            <div className="col-lg-4">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col">
                                            <button
                                                state={{
                                                    item: item,
                                                }}
                                                className={action === "sell" ? "mdg-btn mdg-btn-common active" : "mdg-btn mdg-btn-common"}
                                                onClick={(e) => setAction("sell")}
                                            >
                                                <Language word={"Seller.Sell"}></Language>
                                            </button>
                                        </div>
                                        <div className="col">
                                            <button
                                                state={{
                                                    item: item,
                                                }}
                                                className={action === "rent" ? "mdg-btn mdg-btn-common active" : "mdg-btn mdg-btn-common"}
                                                onClick={(e) => setAction("rent")}
                                            >
                                                <Language word={"Seller.Rent"}></Language>
                                            </button>
                                        </div>
                                        <div className="col">
                                            <button
                                                state={{
                                                    item: item,
                                                }}
                                                className={action === "exchange" ? "mdg-btn mdg-btn-common active" : "mdg-btn mdg-btn-common"}
                                                onClick={(e) => setAction("exchange")}
                                            >
                                                <Language word={"Seller.Exchange"}></Language>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mdg-min-details ">
                            <div className="col-lg-8">
                                {item && (
                                    <Thumbnail
                                        src={getImageUrl(item.media) }
                                        alt="item image"
                                        className="mdg-image-body-sale"
                                    ></Thumbnail>
                                )}
                            </div>
                            {/*TODO_MILES*/}
                            <div className="col-lg-3 d-none">
                                <div>
                                    {isBenefits && <h2><Language word={"Seller.Benefits"}></Language></h2>}
                                    {item && (
                                        <>
                                            {item.conditionXMilesFixedBonus && (
                                                <p>
                                                    <img
                                                        src="/assets/image/miles-coin.png"
                                                        width="30"
                                                        alt=""
                                                        height="30"
                                                        className="rounded-circle-sm mr-1"
                                                    />
                                                    <small className="mdg-text-white">
                                                        {item.xMilesFixedBonus} <Language word={"Seller.miles_fixed_bonus"}></Language>
                                                    </small>
                                                </p>
                                            )}
                                            {item.conditionXMilesPerDayForXDays && (
                                                <p>
                                                    <img
                                                        src="/assets/image/miles-coin.png"
                                                        width="30"
                                                        alt=""
                                                        height="30"
                                                        className="rounded-circle-sm mr-1"
                                                    />
                                                    <small className="mdg-text-white">
                                                        {item.xMilesPerDayForXDaysMiles} <Language word={"Seller.miles_per_day"}></Language>
                                                        {item.xMilesPerDayForXDaysDays > 0 && (
                                                            <> <Language word={"Seller.for"}></Language> {item.xMilesPerDayForXDaysDays} <Language word={"Seller.days"}></Language></>
                                                        )}
                                                        .
                                                    </small>
                                                </p>
                                            )}
                                            {item.conditionXMilesPerHourOfFlightForXDays && (
                                                <p>
                                                    <img
                                                        src="/assets/image/miles-coin.png"
                                                        width="30"
                                                        alt=""
                                                        height="30"
                                                        className="rounded-circle-sm mr-1"
                                                    />
                                                    <small className="mdg-text-white">
                                                        {item.xMilesPerHourOfFlightForXDaysMiles} <Language word={"Seller.miles_per_hour_of_flight"}></Language>
                                                        {item.xMilesPerHourOfFlightForXDaysDays > 0 && (
                                                            <> <Language word={"Seller.for"}></Language> {item.xMilesPerHourOfFlightForXDaysDays} <Language word={"Seller.days"}></Language></>
                                                        )}
                                                        .
                                                    </small>
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {!props.hide && (
                                        <>
                                            <p>
                                                <img
                                                    src="/assets/image/coin.png"
                                                    width="30"
                                                    height="30"
                                                    alt=""
                                                    className="rounded-circle-sm mr-1"
                                                />
                                                <small className="mdg-text-white">
                                                    <Language word={"Seller.Remaining_FC_to_purchase_this_item"}></Language> : {getFormatedAmount(wallet.fc)} FC
                                                </small>
                                                <NavLink
                                                    className="btn mdg-btn-common btn-sm mdg-btn-12 mt-2"
                                                    to={`/packs`}
                                                >
                                                    <Language word={"Seller.Purchase_FC"}></Language>
                                                </NavLink>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row">
                                    <div className="col-lg-12">
                                        {getForm()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

        </>
    );
};

export default ItemDetail2;
