import React from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import SettingNavHeader from "../NavHeader";

const Achat = () => {
  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <SettingNavHeader />

      <section className="content mt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Remuneration for eTickets</h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/type/new"
                      type="button"
                      className="btn btn-tool bg-info"
                      data-card-widget="collapse"
                    >
                      New
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 mdg-historique-table">
                      <DataTable pagination selectableRows />
                    </div>
                  </div>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Achat;
