import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import SubscriptionForm from './SubscriptionForm';
import SubscriptionList from './SubscriptionList';

const SubscriptionComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <SubscriptionForm action="Create"></SubscriptionForm> ;break;
        case "edit": page = <SubscriptionForm action="Edit"></SubscriptionForm> ; break;
        default: page = <SubscriptionList></SubscriptionList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default SubscriptionComponent;