import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import CargoCategoryService from "../../../services/missions/cargoCategory";
import CargoTypeService from "../../../services/missions/cargoType";
import WaresService from "../../../services/missions/wares";
import Select from "react-select";
import { findInList, getImageUrl, selectStyles } from "../../../Utils/Common";
import CountryService from "../../../services/missions/country";

const WaresForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [name, setName] = useState("");
    const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
    const [image, setImage] = useState("");
    const [cargoTypeList, setCargoTypeList] = useState([]);
    const [cargoType, setCargoType] = useState([]);
    const [cargoCategoryList, setCargoCategoryList] = useState([]);
    const [cargoCategory, setCargoCategory] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [countryListWithAll, setCountryListWithAll] = useState([]);
    const [importingCountry, setImportingCountry] = useState([]);
    const [exportingCountry, setExportingCountry] = useState([]);
    const [isMissionOnly, setIsMissionOnly] = useState(false);
    const [allowLocalExport, setAllowLocalExport] = useState(false);
    const [defaultLargeQty, setDefaultLargeQty] = useState(200);
    const [defaultMediumQty, setDefaultMediumQty] = useState(100);
    
    const handleCreateWares = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("isMissionOnly", isMissionOnly);
        formData.append("allowLocalExport", allowLocalExport);
        formData.append("defaultLargeQty", defaultLargeQty);
        formData.append("defaultMediumQty", defaultMediumQty);
        formData.append("image", image);
        formData.append("name", name);
        formData.append("cargoType", cargoType);
        let cargoCategoryIds = [];
        cargoCategory.forEach((elt) => {
            cargoCategoryIds.push(elt.value);
        });
        formData.append("cargoCategory", JSON.stringify(cargoCategoryIds));
        let importingCountryIds = [];
        importingCountry.forEach((elt) => {
            importingCountryIds.push(elt.value);
        });
        formData.append("importingCountry", JSON.stringify(importingCountryIds));
        let exportingCountryIds = [];
        exportingCountry.forEach((elt) => {
            exportingCountryIds.push(elt.value);
        });
        formData.append("exportingCountry", JSON.stringify(exportingCountryIds));

        let result;
        if (id) {
            result = await WaresService.update(id, formData);
        } else {
            result = await WaresService.create(formData);
        }

        if (result.error) {
            swal(result.response.data.data.message, {
                icon: "error",
            });
        } else {
            swal(result.data.message, {
                icon: "success",
            });
        }
    };

    const getWares = async () => {
        if (id) {
            const result = await WaresService.getById(id);
            if (result.status === 200) {
                const {data} = result.data;
                setName(data.name);
                setIsMissionOnly(data.missionOnly);
                setDefaultLargeQty(data.defaultLargeQty);
                setAllowLocalExport(data.allowLocalExport);
                setDefaultMediumQty(data.defaultMediumQty);
                setCargoType(data.cargoType);
                if (data.image) {
                    setPhotoUrl(getImageUrl(data.image));
                }
                const loadedWaresCategories = data.WaresCategories;
                let cargoCategories = [];
                loadedWaresCategories.forEach((el) => {
                    if (el.cargoCategory) {
                        cargoCategories.push({ label: el.cargoCategory.name, value: el.cargoCategory.id });
                    }
                });
                setCargoCategory(cargoCategories);
                const loadedWaresImporter = data.WaresImporters;
                let waresImporter = [];
                loadedWaresImporter.forEach((el) => {
                    if (el.countryImporter) {
                        waresImporter.push({ label: el.countryImporter.name, value: el.countryImporter.iso });
                    }
                });
                setImportingCountry(waresImporter);
                const loadedWaresExporter = data.WaresExporters;
                let waresExporter = [];
                loadedWaresExporter.forEach((el) => {
                    if (el.countryExporter) {
                        waresExporter.push({ label: el.countryExporter.name, value: el.countryExporter.iso });
                    }
                });
                setExportingCountry(waresExporter);
            }
        }
    };

    const handleChangeImage = () => {
        const pics = document.getElementById("id-wares-pics");
        pics.click();
    };

    const getCargoTypeList = async () => {
        const result = await CargoTypeService.getMissionTypeAsKeyValueService();

        setCargoTypeList(result);
    }

    const getCargoCategoryList = async () => {
        const result = await CargoCategoryService.getMissionTypeAsKeyValueService();

        setCargoCategoryList(result);
    }

    const getCountryList = async () => {
        const result = await CountryService.getMissionTypeAsKeyValueService();

        setCountryList(result);
    }

    const getCountryListWithAll = async () => {
        const result = await CountryService.getMissionTypeAsKeyValueService(true);

        setCountryListWithAll(result);
    }

    useEffect(() => {
        getWares();
        getCargoTypeList();
        getCargoCategoryList();
        getCountryListWithAll();
        getCountryList();

    }, []);

    return (
        <div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">
                                {id ? "Edit Wares" : "Create new Wares"}
                            </h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#">Admin</a>
                                </li>
                                <li className="breadcrumb-item active">Wares</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">
                                        {id ? "Edit Wares Information" : "New Wares"}
                                    </h5>

                                    <div className="card-tools">
                                        <NavLink
                                            to="/admin/wares/"
                                            type="button"
                                            className="btn btn-tool bg-info"
                                        >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => handleCreateWares(e)}>
                                                <div className="row">
                                                    {/* <div className="col-lg-6"></div> */}
                                                    <div className="col-lg-12">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="mdg-textfield-out-lined">
                                                                    <input
                                                                        className="mdg-d-none"
                                                                        type="file"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        id="id-wares-pics"
                                                                        onChange={(event) => {
                                                                            setImage(event.target.files[0]);
                                                                            setPhotoUrl(
                                                                                URL.createObjectURL(event.target.files[0])
                                                                            );
                                                                        }}
                                                                    />

                                                                    <img
                                                                        onClick={handleChangeImage}
                                                                        className="mdg-upload-img"
                                                                        src={photoUrl && photoUrl}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <input
                                                                                id="input-category-parent-id"
                                                                                type="text"
                                                                                placeholder=" "
                                                                                value={name}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={(event) => {
                                                                                    setName(event.target.value);
                                                                                }}
                                                                                required
                                                                            />
                                                                            <label htmlFor="input-category-parent-id">
                                                                                Wares name *
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <label className="mdg-normal-label">
                                                                                Cargo Type
                                                                            </label>
                                                                            <Select
                                                                                placeholder="Choose Cargo Type *"
                                                                                options={cargoTypeList}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={(newValue, actionMeta) => {
                                                                                    if (newValue != null) {
                                                                                        setCargoType(newValue.value);
                                                                                    } else {
                                                                                        setCargoType(null);
                                                                                    }

                                                                                }}
                                                                                value={findInList(cargoTypeList, cargoType)}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <label className="mdg-normal-label">
                                                                                Cargo Category
                                                                            </label>
                                                                            <Select
                                                                                placeholder="Choose Cargo Category *"
                                                                                options={cargoCategoryList}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                isMulti={true}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={async (newValue, actionMeta) => {
                                                                                    newValue && setCargoCategory(newValue);
                                                                                    
                                                                                }}
                                                                                value={cargoCategory}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <label className="mdg-normal-label">
                                                                                Importing country
                                                                            </label>
                                                                            <Select
                                                                                placeholder="Choose Importing Country *"
                                                                                options={countryListWithAll}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                isMulti={true}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={async (newValue, actionMeta) => {
                                                                                    if(findInList(newValue, "all")){
                                                                                        setImportingCountry(countryList);
                                                                                    }else{
                                                                                        newValue && setImportingCountry(newValue);
                                                                                    }
                                                                                }}
                                                                                value={importingCountry}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <label className="mdg-normal-label">
                                                                                Exporting country
                                                                            </label>
                                                                            <Select
                                                                                placeholder="Choose Exporting Country *"
                                                                                options={countryListWithAll}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                isMulti={true}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={async (newValue, actionMeta) => {
                                                                                    if(findInList(newValue, "all")){
                                                                                        setExportingCountry(countryList);
                                                                                    }else{
                                                                                        newValue && setExportingCountry(newValue);
                                                                                    }
                                                                                }}
                                                                                value={exportingCountry}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <input
                                                                                id="default-large-quantity"
                                                                                type="number"
                                                                                placeholder=" "
                                                                                value={defaultLargeQty}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={(event) => {
                                                                                    setDefaultLargeQty(event.target.value);
                                                                                }}
                                                                                required
                                                                            />
                                                                            <label htmlFor="default-large-quantity">
                                                                                Default Large quantity
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <input
                                                                                id="default-medium-quantity"
                                                                                type="number"
                                                                                placeholder=" "
                                                                                value={defaultMediumQty}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={(event) => {
                                                                                    setDefaultMediumQty(event.target.value);
                                                                                }}
                                                                                required
                                                                            />
                                                                            <label htmlFor="default-medium-quantity">
                                                                                Default Medium quantity
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mdg-col-sm-6">
                                                                        <input
                                                                            id="id-item-can-sell"
                                                                            className=""
                                                                            type="checkbox"
                                                                            onChange={(e) => setIsMissionOnly(!isMissionOnly)}
                                                                            checked={isMissionOnly ? true : false}
                                                                        />
                                                                        <label className="ml-1 mdg-normal-label" htmlFor="id-item-can-sell" style={{ color: "#92969a" }}>Mission Only</label>
                                                                    </div>
                                                                    <div className="mdg-col-sm-6">
                                                                        <input
                                                                            id="id-allow-local-export"
                                                                                
                                                                            type="checkbox"
                                                                            onChange={(e) => setAllowLocalExport(!allowLocalExport)}
                                                                            checked={allowLocalExport ? true : false}
                                                                        />
                                                                        <label className="ml-1 mdg-normal-label" htmlFor="id-allow-local-export" style={{ color: "#92969a" }}>Allow Local export</label>
                                                                    </div>
                                                                    <div className="col-lg-12 text-right mt-2">
                                                                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                                            Save
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="offset-lg-11 mt-1"></div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WaresForm;
