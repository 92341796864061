import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import SettingNavHeader from "../NavHeader";
import SettingService from "../../../../services/setting";
import Swal from "sweetalert2";
import WalletService from "../../../../services/wallet/wallet";
import CommissionDatatable from "./CommissionTable";

const Commission = () => {
    const [commissionRent, setCommissionRent] = useState(0);
    const [commissionReSell, setCommissionReSell] = useState(0);
    const [commissionExchange, setCommissionExchange] = useState(0);
    const [walletHistoryList, setWalletHistoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [totalCommission, setTotalCommission] = useState(0);
    const [rentCommission, setRentCommission] = useState(0);
    const [sellCommission, setSellCommission] = useState(0);
    const [exchangeCommission, setExchangeCommission] = useState(0);

    const getHistory = async () => {
        setLoading(true);
        const filter = {
            page: currentPage - 1,
            size: perPage,
        };
        const res = await WalletService.getWalletAllCommissionHistoryAsAdminService(filter);
        if (!res.error) {
            //setWalletHistoryList(res.data);
            const commission = res.data.items;
            setWalletHistoryList(res.data.items);

            setTotalRows(res.data.totalItems);

            let total = 0;
            let rent = 0;
            let sell = 0;
            let exchange = 0;
            commission.map((value, i) => {
                total += value.amount;
                switch (value.transactionType) {
                    case "RENT COMMISSION":
                        rent += value.amount;
                        break;
                    case "SELL COMMISSION":
                        sell += value.amount;
                        break;
                    case "EXCHANGE COMMISSION":
                        exchange += value.amount;
                        break;
                }
            })
            setTotalCommission(total);
            setRentCommission(rent);
            setSellCommission(sell);
            setExchangeCommission(exchange);
        }
        setLoading(false);
    };

    const handleCommission = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        const data = {
            Commission_Rent: commissionRent,
            Commission_ReSell: commissionReSell,
            Commission_Exchange: commissionExchange,
        };
        formData.append("name", "Commission");
        formData.append("data", JSON.stringify(data));

        let result = await SettingService.create(formData);

        if (result.error) {
            Swal.fire({
                title: result.response.data.message
                    ? result.response.data.message
                    : result.response.data.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: result.data.message,
                icon: result.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => { });
        }
    };

    const getSetting = async () => {
        const result = await SettingService.getAllSettings();
        if (!result.error) {
            const { data } = result;
            data.map((value, i) => {
                let configuration = JSON.parse(value.configuration);
                const name = value.name;
                switch (name) {
                    case "Commission":
                        setCommissionRent(configuration.Commission_Rent ? configuration.Commission_Rent : 0);
                        setCommissionReSell(configuration.Commission_ReSell ? configuration.Commission_ReSell : 0);
                        setCommissionExchange(configuration.Commission_Exchange ? configuration.Commission_Exchange : 0);
                        break;
                }
            });
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    useEffect(() => {
        getHistory();
        getSetting();
    }, [currentPage, perPage]);
    return (
        <div>
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"> Settings</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#">Admin</a>
                                </li>
                                <li className="breadcrumb-item active">Settings</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <SettingNavHeader />

            <section className="content mt-2">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Amount in percentage of MetaFlight Commission for</h5>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={(e) => handleCommission(e)}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="mdg-textfield-out-lined">
                                                                    <input
                                                                        id="input-mfc-miles"
                                                                        type="number"
                                                                        value={commissionRent}
                                                                        onChange={(event) => {
                                                                            setCommissionRent(event.target.value);
                                                                        }}
                                                                        style={{ color: "#fff" }}
                                                                        min={0}
                                                                        required
                                                                    />
                                                                    <label htmlFor="input-mfc-miles">Rent *</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="mdg-textfield-out-lined">
                                                                    <input
                                                                        id="input-mfc-miles-min"
                                                                        type="number"
                                                                        value={commissionReSell}
                                                                        onChange={(event) => {
                                                                            setCommissionReSell(event.target.value);
                                                                        }}
                                                                        style={{ color: "#fff" }}
                                                                        min={0}
                                                                        required
                                                                    />
                                                                    <label htmlFor="input-mfc-miles-min">
                                                                        Resell *
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="mdg-textfield-out-lined">
                                                                    <input
                                                                        id="input-mfc-miles-min"
                                                                        type="number"
                                                                        value={commissionExchange}
                                                                        onChange={(event) => {
                                                                            setCommissionExchange(event.target.value);
                                                                        }}
                                                                        style={{ color: "#fff" }}
                                                                        min={0}
                                                                        required
                                                                    />
                                                                    <label htmlFor="input-mfc-miles-min">
                                                                        Exchange *
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 text-right mt-2">
                                                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="row card p-4">
                                                            <div className="col-lg-12">
                                                                <p>Total Commission Amount: {totalCommission} FC</p>
                                                                <p>Commission collected for rent: {rentCommission} FC</p>
                                                                <p>Commission collected for resell: {sellCommission} FC</p>
                                                                <p>Commission collected for exchange: {exchangeCommission} FC</p> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer"></div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">History of transaction Commissions</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 mdg-historique-table">
                                            {walletHistoryList && (
                                                <CommissionDatatable
                                                    data={walletHistoryList}
                                                    progressPending={loading}
                                                    paginationDefaultPage={currentPage}
                                                    paginationTotalRows={totalRows}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
};

export default Commission;
