import { verifyTokenAsync } from "../asyncActions/authAsyncActions";
import PromocodeService from "../services/promocode";

export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const GET_NUMBER_CART = 'GET_NUMBER_CART';
export const ADD_CART = 'ADD_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const DELETE_CART = 'DELETE_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const ADD_COUPON = 'ADD_COUPON';
export const DELETE_COUPON = 'DELETE_COUPON';
export const TOGGLE_SHOW_CART = 'TOGGLE_SHOW_CART';
export const HIDE_ERROR = 'HIDE_ERROR';

const toggleShowCart = () => {
    return {
        type: 'TOGGLE_SHOW_CART'
    }
}


const GetNumberCart = () => {
    return {
        type: 'GET_NUMBER_CART'
    }
}

const AddCart = (payload) => {
    return {
        type: 'ADD_CART',
        payload
    }
}
const UpdateCart = (payload) => {
    return {
        type: 'UPDATE_CART',
        payload
    }
}
const DeleteCart = (payload) => {
    return {
        type: 'DELETE_CART',
        payload
    }
}

const IncreaseQuantity = (payload) => {
    return {
        type: 'INCREASE_QUANTITY',
        payload
    }
}

const DecreaseQuantity = (payload) => {
    return {
        type: 'DECREASE_QUANTITY',
        payload
    }
}

const EmptyCart = () => {
    return {
        type: 'EMPTY_CART'
    }
}

const mapState = (state) => {
    const { cart } = state;

    return { myCart: cart }
}

const mapDispach = (dispatch) => {
    return {
        addToCart: (item) => dispatch(MetaCart.AddCart(item)),
        refreshUser: () => dispatch(verifyTokenAsync(true))
    }
}


const getCartSubTotal = (cart) => {
    let total = 0;
    cart.Carts.forEach(element => {
        total += (element.price * element.quantity);
    });
   

    return total < 0 ? 0 : total;
}


const getCartTotal = (cart) => {
    let total = 0;
    cart.Carts.forEach(element => {
        total += (element.price * element.quantity);
    });

    cart.coupons.forEach(element => {
        if(element.type == "FIXED"){
            total -= element.value;
        }else{
            total -= (total * element.value) / 100;
        }
    })

    

    return total < 0 ? 0 : total;
}

const AddCoupon = (payload) => {
    return {
        type: ADD_COUPON,
        payload
    }
}

const RemoveCoupon = (payload) => {
    
    return {
        type: DELETE_COUPON,
        payload
    }
}

const addCouponAsync = (coupon) => async dispatch => {
    const result = await PromocodeService.checkCode(coupon);
    
    if (result.error) {
      return;
    }
   
    if (result.status == 200){
        let code = {
            code : coupon,
            type: result.data.type,
            value: result.data.value,
            data : result.data,
            packs : result.data.packs,
            subscriptions : result.data.subscriptions,
        }

        dispatch(AddCoupon(code));
    }
    else{
        
        return;
    }
}

const HideError = () => {
    return {
        type: HIDE_ERROR,
    }
}

const MetaCart = {
    GetNumberCart,
    AddCart,
    UpdateCart,
    DeleteCart,
    IncreaseQuantity,
    DecreaseQuantity,
    EmptyCart,
    mapState,
    mapDispach,
    getCartTotal,
    addCouponAsync,
    RemoveCoupon,
    getCartSubTotal,
    toggleShowCart,
    HideError
}


export default MetaCart;

