import React, { useEffect, useState } from "react";
import Select from "react-select";
import CargoTypeService from "../../../services/missions/wares";
import WaresService from "../../../services/missions/wares";
import { findInList, selectStyles } from "../../../Utils/Common";

const CargoForm = (props) => {
    // console.log(props.arrayCargoLength);
    const elt = props.i;
    const cargoKey = props.i + 1;
    const arrayCargoLength = props.arrayCargoLength;
    const cargo = props.cargo;

    // const appendCargo = props.appendCargo; 

    const [wares, setWares] = useState(cargo[0]);
    const [quantity, setQuantity] = useState(cargo[1]);
    const [waresList, setWaresList] = useState([]);

    const getWaresList = async () => {
        const result = await WaresService.getWaresAsKeyValueService()

        setWaresList(result);
    }

    useEffect(() => {
        getWaresList();
    }, []);

    useEffect(() => {
        setWares(cargo[0]);
        setQuantity(cargo[1]);
    }, [cargo]);

    return (
        <div className="row">
            <div className="col-lg-6">
                <div className="mdg-textfield-out-lined">
                    <label className="mdg-normal-label">
                        Wares
                    </label>
                    <Select
                        placeholder="Choose Wares *"
                        options={waresList}
                        styles={selectStyles}
                        isClearable={true}
                        style={{ color: "#fff" }}
                        onChange={(newValue, actionMeta) => {
                            if (newValue != null) {
                                setWares(newValue.value);
                                props.setCargo(elt, newValue.value);
                            } else {
                                setWares(null);
                            }

                        }}
                        value={findInList(waresList, wares)}
                        required
                    />
                </div>
            </div>
            <div className="col-lg-3">
                <label className="mdg-normal-label">
                    Quantity
                </label>
                <input
                    type="number"
                    placeholder=""
                    style={{ color: "#fff" }}
                    className="form-control mt-1"
                    min={0}
                    onChange={(e) => { props.setQuantity(elt, e.target.value); setQuantity(e.target.value) }}
                    value={quantity}
                />
            </div>
            <div className="col-lg-3 pt-4 d-flex pb-1">
                <button type="button" className="btn btn-danger mt-2" onClick={(e) => props.deleteCargo(elt)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                {arrayCargoLength == cargoKey && <button type="button" className="btn btn-warning ml-1 mt-2" onClick={(e) => props.appendCargo()}><i className="fa fa-plus" aria-hidden="true"></i></button>}
            </div>
        </div>
    )
}

export default CargoForm;