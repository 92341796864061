import axios  from "../AxiosInstance"

const getAll = async () => {
    try {
      return axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-level`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const getAllByUserLevel = async (userLevel) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-level/my-level/${userLevel}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getById = async (id) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-level/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const create = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-level`,
      data: data,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-level/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id, data) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-level/${id}`,
      data: data,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllAsKeyValue = async () => {
  const res = await getAll();
  if (res.error) {
    return [];
  } else {
    const output = [];
    const data = res.data;

    data.forEach((elt, i) => {
      output.push({ label: elt.title, value: elt.id });
    });

    return output;
  }
};

const order = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-order`,
      data: data,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllOrder = async () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-order`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllByUserId = async (userId) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-order/${userId}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const updateOrder = async (id, data) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-order/${id}`,
      data: data,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getPackBoosterOrdersStatusService = async () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/pack-booster-carreer-order/status`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getPackBoosterOrdersStatusAsKeyValueService = async () => {
  const res = await getPackBoosterOrdersStatusService();
  if (res.error) {
    return [];
  } else {
    const status = [];
    const { data } = res;
    const dataArray = Object.keys(data);

    for (let i = 0; i < dataArray.length; ++i) {
      const elt = dataArray[i];

      status.push({ label: elt, value: elt });
    }
    return status;
  }
};

const PackBoosterCarreerLevelService = {
  getAll,
  getAllByUserLevel,
  getById,
  deleteById,
  update,
  create,
  getAllAsKeyValue,
  order,
  getAllOrder,
  getAllByUserId,
  updateOrder,
  getPackBoosterOrdersStatusAsKeyValueService,
};

export default PackBoosterCarreerLevelService;