import LiveMap from "../extra/livemap/map";
import HeaderPage from "../pages/fo/header/HeaderPage";
import React from "react";
import {Outlet} from "react-router-dom";
import Panier from "../pages/fo/checkout/panier/Panier";

const InGameStoreLayout = () => {
    document.body.style.backgroundColor = "#081217";
    return (
        <div className="mdg-body-ingame">
            <LiveMap />
            <div className="mdg-header-ingame">
                <HeaderPage />
                <Outlet />
                <Panier />
            </div>
        </div>
    );
}

export default InGameStoreLayout;
