import React from "react";
import Thumbnail from "../../../extra/img/thumbnail";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import BidProposal from "./porposal/bid";
import ExchangeProposal from "./porposal/exchange";
import Language from "../../../extra/language/language";
import { useSelector } from "react-redux";
import translate from "../../../extra/language/translate";
import { getImageUrl } from "../../../../Utils/Common";

class MyStoreList extends React.Component {
    getProposalBid = (id) => {
        const MySwal = withReactContent(Swal);
        const lang = this.props.lang;
        MySwal.fire({
            title: translate('Seller.List_of_bid_s_proposals',lang),
            html:(<><BidProposal itemId={id}></BidProposal></> ),
            showConfirmButton: false,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: "Close",
            width: 800,
        }).then((res) => {
            if(res.isConfirmed){
                
            }
        }); 
    }

    getProposalExchange = (id) => {
        const MySwal = withReactContent(Swal);
        const lang = this.props.lang;
        MySwal.fire({
            title: translate('Seller.List_of_exchange_s_proposals',lang),
            html:(<><ExchangeProposal itemId={id}></ExchangeProposal></> ),
            showConfirmButton: false,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: "Close",
            width: 800,
        }).then((res) => {
            if(res.isConfirmed){
                
            }
        }); 
    }

    render() {
        const item = this.props.item;
        const media = item.media;
        const type = item.type?item.type.name:'';
        const quantity = item.stock;
        const itemName = item.name;
        const itemDescription = item.description;
        const itemProprioPhoto = item.proprio.photo;
        const price = item.price;
        const id = item.id;
        const isProposalBid = item.storeType === "BID";
        const isProposalExchange = item.storeType === "EXCHANGE";
        return (
            <>
                <div className="col-sm-4 mt-3">
                    <div className="mdg-width-card-body">
                        <div className="mdg-ribbon"><span>{item.storeType}</span></div>
                        <div className="mdg-card-header-items mdg-icon-heart" onClick={(e) =>{
                            this.props.deleteMyStore(id);
                        }}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </div>
                        <div className="mdg-card-body-items">
                            <div>
                                <Thumbnail
                                    src={media?getImageUrl(media):"/assets/image/hotesse-noire.png"}
                                    alt="logo"
                                    className="mdg-image-body"
                                ></Thumbnail>
                                <div className="mdg-body-description mt-2 pl-2" style={{ position: "relative" }}>
                                    <div className="row justify-content-between">
                                        <div className="col-4">
                                            <span className="mdg-bg-common">{type?type:<Language word={"Seller.COMMON"}></Language>} </span>
                                        </div>
                                        <div className="col-4">
                                            <small><Language word={"Seller.Quantity"}></Language></small><span className="ml-2 badge">{quantity?quantity:0}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-body-footer-description mt-3">
                                    <div className="col-lg-12">
                                        <img
                                            src={itemProprioPhoto? getImageUrl(itemProprioPhoto) : "/assets/img/default-profile-picture.png"}
                                            alt="logo"
                                            className="mdg-img-description mr-1"
                                        />

                                        <br />
                                        <span className="mdg-bg-text-items mdg-text-marque">
                                            {itemName}
                                        </span>
                                        <br />
                                        <span className="mdg-bg-text-items">
                                            <small>{itemDescription}</small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer mdg-card-footer-items">
                            <span className="mdg-bg-text-items">
                                <Thumbnail
                                    src={"/assets/image/coin.png"}
                                    width="30"
                                    height="30"
                                    className="rounded-circle-sm mr-1"
                                ></Thumbnail>
                                {price} FC
                            </span>
                            {isProposalBid && (
                                <button className="btn mdg-btn-common btn-sm float-right" onClick={(e) => {
                                    this.getProposalBid(id);
                                }}>
                                    <span><Language word={"Seller.Proposal"}></Language></span>
                                </button>
                            )}
                            {isProposalExchange && (
                                <button className="btn mdg-btn-common btn-sm float-right" onClick={(e) => {
                                    this.getProposalExchange(id);
                                }}>
                                    <span><Language word={"Seller.Proposal"}></Language></span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default MyStoreList;