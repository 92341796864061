import axios  from "../AxiosInstance"

export const getDashboardData = async() => {
    try {
        return await axios.get(
            `/dashboard/data`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
}

const DashboardService = {
    getDashboardData
}

export default DashboardService;