import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import ItemTypeForm from './ItemTypeForm';
import ItemTypeList from './ItemTypeList';

const ItemTypeComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <ItemTypeForm action="Create"></ItemTypeForm> ;break;
        case "edit": page = <ItemTypeForm action="Edit"></ItemTypeForm> ; break;
        default: page = <ItemTypeList></ItemTypeList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default ItemTypeComponent;