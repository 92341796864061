import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import ScenarioService from "../../../services/scenario";
import ScenarioConditionForm from "./ScenarioConditionForm";
import Select from "react-select";
import { findInList, getImageUrl, selectStyles } from "../../../Utils/Common";
import AwardService from "../../../services/award";
import NumberFormat from "react-number-format";

const ListCondition = ({ arrayCondition, appendCondition, setCondition, setQuantity, deleteCondition, setAward }) => {
    return (
        <>
            {arrayCondition.map((elt, i) => (
                <ScenarioConditionForm
                    condition={elt}
                    key={i}
                    i={i}
                    arrayConditionLength={arrayCondition.length}
                    appendCondition={appendCondition}
                    arrayCondition={arrayCondition}
                    setCondition={setCondition}
                    setQuantity={setQuantity}
                    deleteCondition={deleteCondition}
                    setAward={setAward}
                >
                </ScenarioConditionForm>
            ))}
        </>
    )
}

const ScenarioForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
    const [image, setImage] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [awardFC, setAwardFC] = useState("");
    const [listCondition, setListCondition] = useState("");
    const [arrayCondition, setArrayCondition] = useState([["", 0, ""]]);
    const [changeCondition, setChangeCondition] = useState(false);
    const [awardList, setAwardList] = useState([]);
    const [selectedAward, setSelectedAward] = useState([]);
    // const [scenarioList, setScenarioList] = useState([]);
    const [scenarioList, setScenarioList] = useState([]);    
    const [requiredScenario, setRequiredScenario] = useState([]);    
    

    const getScenarioList = async () => {
        
        await ScenarioService.getAllScenario().then((res) => {
            const scenarioList = [];
            for (let i = 0; i < res.data.length; ++i) {
                const elt = res.data[i];
                if(elt.id != id){
                    scenarioList.push({
                        value: elt.id,
                        label: elt.title,
                    });
                }
                
            }
       
            setScenarioList(scenarioList);
        });
    };

    useEffect(() => {
        getScenarioList();
    }, []);

    const appendCondition = () => {
        let temp = arrayCondition;
        temp.push(["", 0, ""]);

        setArrayCondition(temp);
        setChangeCondition(!changeCondition);
    }

    const setCondition = (i, value) => {
        let temp = arrayCondition;
        temp[i][0] = value;
        setArrayCondition(temp);
    }

    const setQuantity = (i, value) => {
        let temp = arrayCondition;
        temp[i][2] = value;
        setArrayCondition(temp);
    }

    const setAward = (i, value) => {
        let temp = arrayCondition;
        temp[i][1] = value;
        setArrayCondition(temp);
    }

    const deleteCondition = (index) => {
        let temp = [];
        for (let i = 0; i < arrayCondition.length; i++) {
            if (i !== index) {
                temp.push(arrayCondition[i])
            }
        }
        setArrayCondition(temp);
        setChangeCondition(!changeCondition);
    }

    const getScenario = async () => {
        if (id) {
            
            const result = await ScenarioService.getById(id);
            if (!result.error) {
                const data = result.data.data;
                if (data.image) setPhotoUrl(getImageUrl(data.image));
                setTitle(data.title);
                setDescription(data.description);
                setRequiredScenario(data.requiredScenario);
                const dataCondition = JSON.parse(data.ScenarioConditions[0].data);
                setSelectedAward(dataCondition.awards)
                setAwardFC(dataCondition.fc)
                /*const loadedConditionType = data.ScenarioConditions;
                let arrayConditionType = [];
                if (loadedConditionType.length > 0) {
                    loadedConditionType.forEach((el) => {
                        if (el.scenarioConditionType) {
                            arrayConditionType.push([el.scenarioConditionType, el.award, el.data]);
                        }
                    });
                    setArrayCondition(arrayConditionType);
                    setChangeCondition(!changeCondition);
                }*/
            }
        }
    };

    const handleChangeImage = () => {
        const pics = document.getElementById("id-scenario-pics");
        pics.click();
    };

    const getAwardList = async () => {        
        await AwardService.getAllAward().then((res) => {
            var _awards = [];
            for(let i in res.data){
                _awards.push({label: res.data[i].name, value: res.data[i].id});
            }
            setAwardList(_awards)
        });
    };

    useEffect(() => {
        getScenario();
        getAwardList()
    }, []);

    useEffect(() => {
        setListCondition(<ListCondition arrayCondition={arrayCondition} appendCondition={appendCondition} setCondition={setCondition} setAward={setAward} setQuantity={setQuantity} deleteCondition={deleteCondition} ></ListCondition>)
    }, [changeCondition])

    const createScenarioHandler = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('photo', image);
        formData.append('title', title);
        formData.append('description', description);
        if(Number(requiredScenario) > 0){

            formData.append('requiredScenario', requiredScenario);
        }
        formData.append('arrayCondition', JSON.stringify({awards: selectedAward, fc: awardFC}));
        // formData.append("arrayCondition", JSON.stringify(arrayCondition));

        let result;

        if (id) {
            result = await ScenarioService.update(id, formData);
        } else {
            result = await ScenarioService.create(formData);
        }

        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Scenario</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Scenario</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">New Scenario</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/scenario/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createScenarioHandler(e)}>
                                                <div className="row">
                                                    <div className="mdg-card-body mdg-row">
                                                        <div className="col-lg-3">
                                                            <div className="mdg-col-sm-12 text-center">
                                                                <input
                                                                    className="mdg-d-none"
                                                                    type="file"
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    id="id-scenario-pics"
                                                                    onChange={(event) => {
                                                                        setImage(event.target.files[0]);
                                                                        setPhotoUrl(
                                                                            URL.createObjectURL(event.target.files[0])
                                                                        );
                                                                    }}
                                                                />
                                                                <img
                                                                    onClick={handleChangeImage}
                                                                    className="mdg-upload-img"
                                                                    src={photoUrl && photoUrl}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-9">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-scenario-title" className="mdg-normal-label">
                                                                        Title *
                                                                    </label>
                                                                    <input
                                                                        id="input-scenario-title"
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={title}
                                                                        onChange={(event) => {
                                                                            setTitle(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-scenario-description" className="mdg-normal-label">
                                                                        Description *
                                                                    </label>
                                                                    <input
                                                                        id="input-scenario-description"
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={description}
                                                                        onChange={(event) => {
                                                                            setDescription(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-scenario-description" className="mdg-normal-label">
                                                                        Required Scenario
                                                                    </label>
                                                                    <Select
                                                                        isClearable={true}
                                                                        onChange={(newValue, actionMeta) => {
                                                                            newValue != null ? setRequiredScenario(newValue.value): setRequiredScenario(null);
                                                                        }}
                                                                        placeholder="Choose a Scenario"
                                                                        options={scenarioList}
                                                                        styles={selectStyles}
                                                                        value={findInList(scenarioList,requiredScenario)}
                                                                        style={{ color: "#fff" }}
                                                                    />
                                                                </div>
                                                                {/*<div className="col-lg-12">
                                                                    {listCondition}
                                                                </div>*/}
                                                                <div className="row col-lg-12">
                                                                    <div className="col-lg-8">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <label htmlFor="input-data" className="mdg-normal-label">Award *</label>
                                                                            <Select
                                                                                placeholder="Please select an award"
                                                                                options={awardList}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                style={{ color: "#fff" }}
                                                                                isMulti
                                                                                onChange={(newValue, actionMeta) => {
                                                                                    if (newValue != null) {
                                                                                        setSelectedAward(newValue)
                                                                                    } else {
                                                                                        setSelectedAward(null)
                                                                                    }
                                                                                }}
                                                                                value={selectedAward}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-4">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <label className="mdg-normal-label">
                                                                                FC award
                                                                            </label>
                                                                            <NumberFormat
                                                                                suffix={" FC"}
                                                                                decimalSeparator=","
                                                                                style={{ color: "#fff" }}
                                                                                className={`text-right`}
                                                                                value={awardFC}
                                                                                required
                                                                                onValueChange={(values) => {
                                                                                    setAwardFC(values.floatValue);
                                                                                }
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 text-right mt-2">
                                                            <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default ScenarioForm;
