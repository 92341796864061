import React from "react";
import { getFullDate, getImageUrl } from "../../../../../Utils/Common";
import Thumbnail from "../../../../extra/img/thumbnail";
import Language from "../../../../extra/language/language";

class MyRentedItemsList extends React.Component {

    render() {
        const item = this.props.item;
        const media = item.media;
        const type = item.type ? item.type.name : '';
        const itemTypeTextColor = item.type ? item.type.textColor : '';
        const itemTypeColor = item.type ? item.type.color : '';
        const itemName = item.name;
        const itemDescription = item.description;
        const itemProprioPhoto = item.proprio.photo ? item.proprio.photo : null;
        const itemProprioName = item.proprio.userName ? item.proprio.userName : '';
        const price = item.price;
        const rentItems = item.rentItem ? item.rentItem : [];
        const expirationDate = rentItems.length > 0 ? getFullDate(rentItems[0].expirationDate) : '';
        const nbDays = rentItems.length > 0 ? rentItems[0].nbDays : '';
        const createdAt = rentItems.length > 0 ? getFullDate(rentItems[0].createdAt) : '';
        return (
            <>
                <div className="row justify-content-between mdg-bg-row-3 mt-4">
                    <div className="col-lg-1 mt-2">
                        <span
                            className="mdg-bg-common"
                            style={{
                                color: itemTypeTextColor ? itemTypeTextColor : "",
                                backgroundColor: itemTypeColor ? itemTypeColor : "",
                            }}
                        >
                            {type ? type : <Language word={"Seller.COMMON"}></Language>}
                        </span>
                    </div>
                    <div className="col-lg-4">
                        <div className="p-2">
                            <Thumbnail
                                src={getImageUrl(media)}
                                alt="logo"
                                className="mdg-image-body"
                            ></Thumbnail>
                        </div>
                    </div>
                    <div className="col-lg-7 mdg-relative">
                        <div className="p-3">
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-12">
                                            <img
                                                src={itemProprioPhoto ? getImageUrl(itemProprioPhoto)  : "/assets/img/default-profile-picture.png"}
                                                alt="logo"
                                                className="mdg-img-description mr-1"
                                            />
                                            <span className="mdg-bg-text-items mdg-text-marque m-1">
                                                {itemProprioName}
                                            </span>
                                        </div>
                                        <div className="col-lg-12 mt-4">
                                            <span className="mdg-bg-text-items mdg-text-marque">
                                                {itemName}
                                            </span>
                                            <br />
                                            <span className="mdg-bg-text-items">
                                                <small>{itemDescription}</small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row justify-content-between">
                                        <div className="col-lg-12">
                                            <h6><Language word={"Seller.Rent_Details"}></Language></h6>
                                        </div>
                                        <div className="col-lg-12 m-2">
                                            <Thumbnail
                                                src={"/assets/image/coin.png"}
                                                width="30"
                                                height="30"
                                                className="rounded-circle-sm mr-1"
                                            ></Thumbnail>
                                            <small>{price} FC <Language word={"Seller.per_Days"}></Language></small>
                                        </div><div className="col-lg-12 m-2">
                                            <span className=" badge">{nbDays}</span>
                                            <small> <Language word={"Seller.Days"}></Language></small>
                                        </div>
                                        <div className="col-lg-12 m-2">
                                            <small> <Language word={"Seller.Rental_date"}></Language> : {createdAt} </small>
                                        </div>
                                        <div className="col-lg-12 m-2">
                                            <small> <Language word={"Seller.Expiration_date"}></Language> : {expirationDate} </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default MyRentedItemsList;