import axios  from "../AxiosInstance"

const createStoreOrderService = async (data) => {
  try {
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-order`,
        data: data
    }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


const getPendingOrderService = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-order/pending-order`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getList = async (filter) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-order/list`, 
    { params: filter });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


const StoreOrderService = {
  createStoreOrderService,
  getPendingOrderService,
  getList
}

export default StoreOrderService;