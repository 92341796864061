import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import swal from 'sweetalert';
import Breadcrumb from "../templates/Breadcrumb";
import { deleteItemsTypes, getItemsTypesListService } from "../../../services/ItemTypes";
import DescriptionComponent from "../description/DescriptionComponent";

const ItemTypeList = () => {
    const [itemTypesList, setItemTypesList] = useState([]);
    const [initialItemTypesList, setInitialItemTypesList] = useState([]);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const columns = [
        {
            id: 1,
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Text Color",
            center:true,
            cell: (row) => (
                row.textColor && <input
                    type="color"
                    value={row.textColor}
                    disabled
                    />
            )
        },
        {
            id: 3,
            name: "Background Color",
            center:true,
            cell: (row) => (
                row.color && <input
                    type="color"
                    value={row.color}
                    disabled
                    />
            )
        },
        {
            id: 4,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/type/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 5,
            button: true,
            left: true,
            cell: (row) => (

                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => {
                    swal(`Are you sure to delete the item : ${row.name}`, {
                        icon: "warning",
                        buttons: {
                            cancel: "Cancel",
                            confirm: "Yes"
                        },
                    }).then(value => {
                        if (value) {
                            deleteItemsTypes(row.id).then((res) => {
                                if (res.error) {
                                    swal("Deleted!", "An error was occurred!", "error");
                                }
                                else {
                                    swal("Deleted!", "Item deleted with success!", "success");
                                    getItemsTypesList();
                                }
                            });
                        }
                    })

                }}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const getItemsTypesList = async () => {

        await getItemsTypesListService().then((res) => {
            const itemTypes = [];
            for (let i = 0; i < res.data.length; ++i) {
                const elt = res.data[i];
                itemTypes.push({
                    id: elt.id,
                    name: elt.name,
                    color: elt.color,
                    textColor: elt.textColor
                });
            }
            setInitialItemTypesList(itemTypes);
            filterItemsTypes(itemTypes, "");
        });
    };

    useEffect(() => {
        getItemsTypesList();
    }, []);

    const filterItemsTypes = (list, txt) => {
        let res = list.filter(
            (elt, i) =>
                elt.name.toLowerCase().includes(txt.toLowerCase())
        );
        setItemTypesList(res)
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Types</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Types</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Types</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/type/new" type="button" className="btn btn-tool bg-info" data-card-widget="collapse">
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 mdg-historique-table">
                                            {itemTypesList && (<DataTable
                                                columns={columns}
                                                data={itemTypesList}
                                                defaultSortFieldId={1}
                                                pagination
                                                selectableRows
                                                conditionalRowStyles={rowStyle}
                                            />)}
                                        </div>


                                    </div>

                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default ItemTypeList;
