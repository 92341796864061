import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SellerModeService from "../../../../../services/sellerMode";
import Thumbnail from "../../../../extra/img/thumbnail";
import Language from "../../../../extra/language/language";
import { useSelector } from "react-redux";
import "./style.css"
import translate from "../../../../extra/language/translate";
import { getImageUrl } from "../../../../../Utils/Common";

const ExchangeProposal2 = ({ itemId }) => {
    const [proposals, setProposals] = useState([]);
    const [loading, setLoading] = useState(true);
    const lang = useSelector((state) => state.language);

    const getExchangeProposals = async () => {
        const result = await SellerModeService.getExchangeProposalByItemId(itemId);
        if (!result.error) {
            setProposals(result.data)
        }
        setLoading(false);
    }


    const refuseExchangeProposal = async (id) => {
        Swal.fire({
            title: translate('Seller.Confirmation',lang),
            text: translate('Seller.Do_you_realy_want_to_decline_this_offer',lang),
            icon: "question",
            confirmButtonText: "Ok",
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
                const data = new FormData();
                data.append("exchangeProposalStatus", "DENIED");
                const res = await SellerModeService.updateExchangeProposalStatus(id, data);
                if (res.error) {
                    Swal.fire({
                        title: res.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: res.data.message,
                        icon: res.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        getExchangeProposals();
                    });
                }
            }
        });
    }

    const validProposal = async (id) => {
        Swal.fire({
            title: translate('Seller.Confirmation',lang),
            text: translate('Seller.Are_you_sure_that_you_want_to_accept_this_offer',lang),
            icon: "question",
            confirmButtonText: "Ok",
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
                const data = new FormData();
                data.append("exchangeProposalStatus", "ACCEPTED");
                const res = await SellerModeService.updateExchangeProposalStatus(id, data);
                if (res.error) {
                    Swal.fire({
                        title: res.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: res.data.message,
                        icon: res.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        getExchangeProposals();
                    });
                }
            }
        });

    }

    useEffect(() => {
        getExchangeProposals();
    }, []);


    return (
        <>
            {
                loading && (
                    <div className="row justify-content-md-center mt-2" >
                        <div className="col-lg-12">
                            <h3 className="text-center"><Language word={"Seller.loading"}></Language></h3>
                        </div>
                    </div>
                )
            }
            {
                (!loading && proposals.length == 0 ) && (
                    <div className="row justify-content-md-center mt-2">
                        <div className="col-lg-12">
                            <h3 className="text-center"><Language word={"Seller.No_proposal_found"}></Language></h3>
                        </div>
                    </div>
                )
            }
            {
                !loading && proposals.map((proposal, i) => (
                 
                        <div className="row justify-content-md-center mt-2" key={i}>
                            <div className="col-lg-12">
                                <div className="row mdg-bg-row-proposals p-4">

                                    <div className="col-lg d-inline-flex mdg-border-md">
                                        <div className=" mdg-text-right">
                                            <img
                                                src={getImageUrl(proposal.user.photo)}
                                                className="mdg-upload-img-detail"
                                            ></img>
                                        </div>
                                        <div className=" mt-2 ml-2 text-center">
                                            <h6></h6>
                                            <span> {proposal.user.userName}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg text-center mdg-border-md">
                                        <h6><Language word={"Seller.Exchange_with"}></Language></h6>
                                        <div>{proposal.exchangeItem.name}</div>
                                        <div>{proposal.exchangeItem.description}</div>
                                    </div>
                                    <div className="col-lg text-center mdg-border-md">
                                        
                                        <Thumbnail
                                            src={getImageUrl(proposal.exchangeItem.media) }
                                            alt="logo"
                                            className="mdg-upload-img-detail"
                                        ></Thumbnail>
                                    </div>
                                    <div className="col-lg text-center mdg-border-md">
                                        <h6><Language word={"Seller.Details"}></Language></h6>
                                        <div>
                                            <span><Language word={"Seller.Price"}></Language> :  {proposal.exchangeItem.price} FC</span>
                                        </div>
                                        <div>
                                            <span><Language word={"Seller.Name"}></Language> :  {proposal.exchangeItem.name} </span>
                                        </div>
                                    </div>
                                    <div className="col-lg text-center mdg-border-md mdg-align-center">
                                        {proposal.status == "PENDING" ? (
                                            <>
                                                <button className="btn  btn-sm btn-danger mr-2" onClick={(e) => { refuseExchangeProposal(proposal.id) }}><Language word={"Seller.Decline"}></Language></button>
                                                <button className="btn mdg-btn-common btn-sm mdg-btn-12" onClick={(e) => { validProposal(proposal.id) }}><Language word={"Seller.Accept"}></Language></button>
                                            </>
                                        ) : (<>{proposal.status == "DENIED" ? "DECLINED" : proposal.status}</>)}
                                    </div>
                                </div>
                            </div>
                        </div>

                   

                ))
            }
            
        </>)
}

export default ExchangeProposal2;