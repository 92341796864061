import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import ManageProfileForm from './ManageProfileForm';
import ManageProfileList from './ManageProfileList';


const ManageProfileComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <ManageProfileForm action="Create"></ManageProfileForm> ;break;
        case "edit": page = <ManageProfileForm action="Edit"></ManageProfileForm> ; break;
        default: page = <ManageProfileList></ManageProfileList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default ManageProfileComponent;