import React from "react";
import { getFullDate, getImageUrl } from "../../../../Utils/Common";
import Thumbnail from "../../../extra/img/thumbnail";

const NoticeList = (props) => {
    const photo = props.notice.photo;
    const name = props.notice.name;
    const startDate = getFullDate(props.notice.startDate);
    const endDate = getFullDate(props.notice.endDate);
    const content = props.notice.content;

    return (

        <div className="col-lg-4 mt-3" onClick={(e) => props.handleModal(content, name, photo ? getImageUrl(photo) : "/assets/img/upload-img.png")}>
            <div className="mdg-card-box-notice">
                <div className="">
                    <Thumbnail
                        src={photo ? getImageUrl(photo) : "/assets/img/upload-img.png"}
                        alt="logo"
                        className="mdg-notice-image-sm"
                    ></Thumbnail>
                </div>
                <div className="mdg-content-notice">
                    <div className="mdg-name-notice">
                        <span>{name}</span>
                    </div>
                    <div className="mdb-bottom-notice">
                        <div className="row justify-content-between">
                            <div className="col-lg-5">
                                <span><i className="fa fa-calendar-o mr-1" aria-hidden="true"></i>{startDate}</span>
                            </div>
                            <div className="col-lg-5">
                                <span className="float-right"><i className="fa fa-calendar-times-o mr-1" aria-hidden="true"></i>{endDate}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoticeList;
