import React from "react";
import Thumbnail from "../../../extra/img/thumbnail";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SellForm from "./SellForm";
import SellerModeService from "../../../../services/sellerMode";
import RentForm from "./RentForm";
import ExchangeForm from "./ExchangeForm";
import { useSelector } from "react-redux";
import translate from "../../../extra/language/translate";
import { getImageUrl } from "../../../../Utils/Common";

class SellerItemClass extends React.Component {
    constructor(props) {
        super(props);
        this.setItem = this.setItem.bind(this);
        this.handleSellItem = this.handleSellItem.bind(this);
        this.state = { 
            sellType: "SELL",
            price: 0,
            dateLimit: "",
            user: "",
            pricePerDay: 0,
            minDays: 1,
            exchangeData : {
                categoryId: null,
                itemId: null,
                priceMin: 0,
                priceMax: 1000
            }
        }

        this.formatDate = (date) =>{
            const e_dateLimit = date.split(".");
            const year = e_dateLimit[2];
            const month = e_dateLimit[1];
            const day = e_dateLimit[0];
            return [year, month, day].join('-');
        }
    }

    setItem = (i) => {
        this.props.setItem(i)
    }

    setPrice = (price) => {
        this.setState({ price: price });
    }

    setSellType = (type) => {
        this.setState({ sellType: type });
    }

    setDateLimit = (dateLimit) => {
        this.setState({ dateLimit: dateLimit });
    }

    setUser = (user) => {
        this.setState({ user: user });
    }

    setPricePerDay = (price) => {
        this.setState({ pricePerDay: price });
    }

    setMinDays = (price) => {
        this.setState({ minDays: price });
    }

    setExchange = (data) => {
        this.setState({ exchangeData : data})
    }

    handleMakeSellItem = async (e) => {
        const formData = new FormData();
        // const { itemId , fcAmount , dateLimit , cible , type, qty } = req.body
        formData.append("itemId", this.props.item.item.id);
        formData.append("fcAmount", this.state.price);
        formData.append("type", this.state.sellType);

        
        if(this.state.dateLimit){
            
            formData.append("dateLimit", this.formatDate(this.state.dateLimit));
        }
        if(this.state.user){
            formData.append("cible", this.state.user);
        }
        
        const res = await SellerModeService.sellMyItem(formData);

        if (res.error) {
            Swal.fire({
                title: res.response.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: res.data.message,
                icon: res.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
                // this.getItems();
            });
        }
    }

    rentMyItem = async (e) => {
        const formData = new FormData();

        formData.append("itemId", this.props.item.item.id);
        formData.append("pricePerDay", this.state.pricePerDay);
        formData.append("minDays", this.state.minDays);
        
        const res = await SellerModeService.rentMyItem(formData);
        if (res.error) {
            Swal.fire({
                title: res.response.data.message ? res.response.data.message : res.response.data.data.message ,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: res.data.message,
                icon: res.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
                // this.getItems();
            });
        }
    }

    exchangeMyItem = async (e) => {
        const formData = new FormData();

        formData.append("itemId", this.props.item.item.id);
        formData.append("minPrice", this.state.exchangeData.priceMin);
        formData.append("maxPrice", this.state.exchangeData.priceMax);
        formData.append("category", this.state.exchangeData.categoryId);
        formData.append("exchangeItem", this.state.exchangeData.itemId);
        
        const res = await SellerModeService.exchangeMyItem(formData);
        if (res.error) {
            Swal.fire({
                title: res.response.data.message ? res.response.data.message : res.response.data.data.message ,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: res.data.message,
                icon: res.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
                // this.getItems();
            });
        }
    }

    handleSellItem = async () => {
        this.setState({ sellType: "SELL", price: 0, dateLimit: "", user: "" })
        const MySwal = withReactContent(Swal);
        const lang = this.props.lang;
        MySwal.fire({
            title: translate('Seller.Sell_my_Item',lang),
            html: (
                <><SellForm users={this.props.users} state={this.state} setPrice={this.setPrice} setSellType={this.setSellType} setDateLimit={this.setDateLimit} setUser={this.setUser} /></>
            ),
            showConfirmButton: true,
            showCloseButton: true,
            showCancelButton: true,
            showCloseButton: false,
            preConfirm: (data) => {
                if (this.state.price <= 0) {
                    return false;
                }
                if (this.state.sellType == "BID") {
                    if (!this.state.dateLimit) {
                        return false;
                    }
                }
                if (this.state.sellType == "PRIVATE") {
                    if (!this.state.user) {
                        return false;
                    }
                }

                return true;
            }
            // width: 800,
        }).then((res) => {
            if(res.isConfirmed){
                this.handleMakeSellItem();
            }
        });
    }

    handleRentItem = () => {
        this.setState({ pricePerDay: 0, minDays : 1 })
        const MySwal = withReactContent(Swal);
        const lang = this.props.lang;
        MySwal.fire({
            title: translate('Seller.Rent_my_item',lang),
            html: (
                <><RentForm  setPricePerDay={this.setPricePerDay} setMinDays={this.setMinDays} state={this.state} /></>
            ),
            showConfirmButton: true,
            showCloseButton: true,
            showCancelButton: true,
            showCloseButton: false,
            preConfirm: (data) => {
                if (this.state.minDays <= 0) {
                    return false;
                }
                if (this.state.pricePerDay <= 0) {
                    return false;
                }
                
                return true;
            }
            // width: 800,
        }).then((res) => {
            if(res.isConfirmed){
                this.rentMyItem();
            }
        });
    }

    handleExchangeItem = () => {
        this.setState({ exchangeData: {categoryId: undefined,itemId: undefined,priceMin: 0,priceMax: 1000}})
        const MySwal = withReactContent(Swal);
        const rent = <ExchangeForm setExchange={this.setExchange}/>;
        const lang = this.props.lang;
        MySwal.fire({
            title: translate('Seller.Exchange_my_item',lang),
            html: (
                rent
            ),
            showConfirmButton: true,
            showCloseButton: true,
            showCancelButton: true,
            showCloseButton: false,
            preConfirm: (data) => {
                if (this.state.exchangeData.priceMax <= this.state.exchangeData.priceMin) {
                    Swal.fire({
                        text : translate('Seller.Please_fill_valid_value_for_price_range',lang)
                    })
                    return false;
                }

                return true;
            }
            // width: 800,
        }).then((res) => {
            if(res.isConfirmed){
                this.exchangeMyItem();
            }
        });
    }

    render() {
        const allData = this.props.item;
        const item = allData.item;

        const media = item.media;
        const name = item.name;
        const description = item.description;
        const stock = allData.quantity;

        /* item type */
        const itemTypeName = item.type ? item.type.name : null;
        const itemTypeTextColor = item.type ? item.type.textColor ? item.type.textColor : null : null;
        const itemTypeColor = item.type ? item.type.color ? item.type.color : null : null;
        /* item type */

        return (
            <div className="col-sm-4 mt-3">
                <div className="mdg-width-card-body">
                    <div className="mdg-card-header-items">
                    </div>
                    <div className="mdg-card-body-items">
                        <div>
                            <Thumbnail
                                src={getImageUrl(media)}
                                alt="logo"
                                className="mdg-image-body"
                            ></Thumbnail>
                            <div className="mdg-body-description mt-2 pl-2" style={{ position: "relative" }}>
                                <div className="row justify-content-between">
                                    <div className="col-4">
                                        <span className="mdg-bg-common" style={{ color: itemTypeTextColor ? itemTypeTextColor : '', backgroundColor: itemTypeColor ? itemTypeColor : '' }}>{itemTypeName ? itemTypeName : "COMMON"}</span>
                                    </div>
                                    <div className="col-5">
                                        <small>Quantity</small><span className="ml-2 badge"> {stock}</span>
                                    </div>
                                </div>                                
                            </div>
                            <div className="mdg-body-description mt-2 pl-2" style={{ position: "relative" }}>
                                <div className="row">

                                </div>
                            </div>
                            <div className="mdg-body-footer-description mt-3">
                                <div className="col-lg-12">
                                    <span className="mdg-bg-text-items mdg-text-marque">
                                        {name}
                                    </span>
                                    <br />
                                    <span className="mdg-bg-text-items">
                                        <small>{description}</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer mdg-card-footer-items">
                        <div className="btn-group float-right">
                            <button className="btn mdg-btn-common btn-sm btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                Action button
                            </button>
                            <div className="dropdown-menu mdg-dropdwon-menu-action mdg-width-dropdwon">
                                <button className="dropdown-item mdg-color-menu-item" onClick={(e) => this.handleSellItem()} >Sell</button>
                                <button className="dropdown-item mdg-color-menu-item" onClick={(e) => this.handleRentItem()} >Rent</button>
                                <button className="dropdown-item mdg-color-menu-item" onClick={(e) => this.handleExchangeItem()}>Exchange</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const SellerItem = () => {
    const lang = useSelector((state) => state.language);
    return <SellerItemClass lang={lang}></SellerItemClass>
}
export default SellerItem;
