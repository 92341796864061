import axios  from "./AxiosInstance"

export const getUserAvailableRoles = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/roles/`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getUserAvailableRolesAsKeyValueService = async () => {
  const res = await getUserAvailableRoles();
  if (res.error) {
    return [];
  } else {
    const roles = [];
    for (let i = 0; i < res.data.length; ++i) {
        const rl = res.data[i];
        roles.push({ label: rl.roleName, value: rl.id });
    }
    return roles;
  }
};

export const getAllAvailableRoles = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/roles/all`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const deleteRoles = async (id) => {
  try {
    return await axios.delete(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/roles/${id}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getRoleById = async (id) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/roles/${id}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const createRoleService = async (data) => {
  try {
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/roles`,
        data: data
    }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const updateRoleService = async (id, data) => {
  try{
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/roles/${id}`,
      data: data
    }) ;
  }catch(err){
    return {
      error: true,
      response: err.response,
    };
  }
};

const RoleService = {
  getUserAvailableRoles,
  getAllAvailableRoles,
  deleteRoles,
  getRoleById,
  createRoleService,
  updateRoleService,
  getUserAvailableRolesAsKeyValueService
}

export default RoleService;
