import React from "react";
import ItemService from "../../../../services/Item";
import SellerItem from "./SellerItem";
import Select from "react-select";
import { selectStyles } from "../../../../Utils/Common";
import SideNav from "./Side";
import { getUserListService } from "../../../../services/user";
import { NavLink } from "react-router-dom";
class Seller extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentItem: null,
      fixed: true,
      bid: false,
      selectOption: [{ label: "Sell", value: "SELL" }, { label: "Bid", value: "BID" }],
      selectedSellType: { label: "Sell", value: "SELL" },
      sellType: "SELL",
      fcAmount: 0,
      limiteDate: "",
      user: "",
      users : []
    }

    this.setCurrentItem = this.setCurrentItem.bind(this);
    this.handleMakeSellItem = this.handleMakeSellItem.bind(this);
  }

  setCurrentItem = (newItem) => {
    this.setState({ currentItem: newItem });
   
  }

  getAllUser = async () => {
    await getUserListService().then((res) => {
      const users = [];
      if (res.statusText === "OK") {
        for (let i = 0; i < res.data.length; ++i) {
          let elt = res.data[i];
          let data = {
            name: elt.firstName + " " + elt.lastName,
            value: elt.id,
            label: elt.firstName + " " + elt.lastName,
          };
          users.push(data);
        }
      }
      this.setState({users : users});
    });
  };


  getItems = async () => {
    await ItemService.getMyItems().then((res) => {
      this.setState({ items: res.data });
    });
  }

  refreshPage = async () => {
    this.getItems();
  }

  handleMakeSellItem = async (e) => {
  
  }

  componentDidMount() {
    this.getItems();
    this.getAllUser();
  }

  render() {
    return (
      <>
        <div className="container mdg-top-container">
          <div className="mdg-title-ingame row">
            <div className="col-sm">
              <span className="mdg-h2">Seller Mode</span>
            </div>
          </div>
          <div className="col-sm">
            <NavLink
              type="submit"
              to={"/Ingame"}
              className="btn btn-info float-right"
            >
              Ingam
            </NavLink>
            <span> / </span>
            <NavLink
              type="submit"
              to={"/account/items"}
              className="btn btn-info float-right"
            >
              Seller Mode
            </NavLink>
          </div>
          <div className="row">
            <div className="col-lg-3">
            <SideNav/>
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="lands"
                  role="tabpanel"
                  aria-labelledby="lands-tab"
                >
                  <div className="row justify-content-between">
                <div className="col-lg-8">
                  {/* <div className="row">
                    <div className="col-lg-2 md-sm-3">
                      <div className="select-search">
                        <select className="mdg-select-search">
                          <option>Price</option>
                          <option value="1">20 €</option>
                          <option value="2">50 €</option>
                          <option value="3">100 €</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 md-sm-3">
                      <div className="select-search">
                        <select className="mdg-select-search">
                          <option>FC generated</option>
                          <option value="1">option 1</option>
                          <option value="2">option 2</option>
                          <option value="3">option 3</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 md-sm-3">
                      <div className="select-search">
                        <select className="mdg-select-search">
                          <option>Deal</option>
                          <option value="1">New</option>
                          <option value="2">Used</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="col-lg-2 md-sm-3 ">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mdg-search-place">
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Search "
                          style={{ color: "#fff" }}
                          className="mdg-search-input"
                        />
                        <i
                          className="fa fa-search mdg-fa"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                  <div className="mdg-width-card-item">
                    <div
                      className="tab-pane fade show active"
                      id="lands"
                      role="tabpanel"
                      aria-labelledby="lands-tab"
                    >
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="all-flight"
                          role="tabpanel"
                          aria-labelledby="all-flight-tab"
                        >
                          <div className="mdg-width-card-item p-3">
                            <div className="row">
                              {this.state.items.map((item, i) => (<SellerItem item={item} setItem={this.setCurrentItem} key={i} users={this.state.users}/>))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* start modal */}

          <div className="mdg-modal">
            <div className="mdg-modal-overlay modal-toggle"></div>
            <div className="mdg-modal-wrapper-large mdg-modal-transition">

              <div className="mdg-modal-header mdg-modal-sell mdg-modal-metaflight">
                <button className="mdg-modal-close modal-toggle ">
                  <i className="fa fa-times"></i>
                </button>
                <h2 className="mdg-modal-heading">Do you want to sell this ?</h2>
              </div>
              <div className="mdg-modal-body">
                <div className="mdg-modal-content">
                  <div className="mdg-row">

                    <div className="mdg-col-sm-12">

                      <div className="row">
                        <div className="col-lg-6">
                          <Select
                            isClearable={false}
                            onChange={(newValue, actionMeta) => {
                              this.setState({ selectedSellType: newValue })
                              this.setState({ sellType: newValue.value })
                              console.log(this.state.sellType);
                            }}
                            isSearchable={false}
                            placeholder={<div>Sell Type</div>}
                            options={this.state.selectOption}
                            styles={selectStyles}
                            value={this.state.selectedSellType}

                          />

                        </div>
                        <div className="col-lg-6">
                          <div className="mdg-textfield-out-lined">
                            <input id="input-price-fixed" type="text" name="sellType" placeholder=" " value={this.state.fcAmount} onChange={(e) => this.setState({ fcAmount: e.target.value })} />
                            <label htmlFor="input-price-fixed"> Price</label>
                          </div>
                        </div>
                        {/*
                        <div className="col-lg-6">
                          <div className="mdg-textfield-out-lined">
                            <input id="input-user-fixed" type="text" placeholder=" " />
                            <label htmlFor="input-user-fixed">User</label>
                          </div>
                        </div>*/
                        }
                        {
                          this.state.sellType == "BID" &&
                          (<div className="col-lg-6">
                            <div className="mdg-textfield-out-lined">
                              <input id="input-user-fixed" type="date" placeholder=" " value={this.state.limiteDate} onChange={(e) => this.setState({ limiteDate: e.target.value })} />
                              <label htmlFor="input-user-fixed">Limit Date</label>
                            </div>
                          </div>)
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mdg-modal-footer">
                <div className="mdg-col-sm-4">
                  <button
                    onClick={(e) => this.handleMakeSellItem(e)}
                    type="submit"
                    className="btn mdg-btn-common float-right"
                  >
                    Save
                  </button>
                </div>
              </div>

            </div>
          </div>
          {/* end modal */}




        </div>

      </>
    );
  }
}

export default Seller;
