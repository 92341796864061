import React, { useState, useEffect } from "react";
import SellerModeService from "../../../../../services/sellerMode";
import MyStoreList from "../MyStoreList";
import Swal from "sweetalert2";
import Language from "../../../../extra/language/language";
import { useSelector } from "react-redux";
import translate from "../../../../extra/language/translate";

const MyStorePage2 = (props) => {
  const [items, setItems] = useState([]);
  const lang = useSelector((state) => state.language);

  const getItems = async () => {
    const result = await SellerModeService.getAllMyStoreService(props.item);
    if (!result.error) {
      setItems(result.data);
    }
  };

  const deleteMyStore = async (id) => {
    Swal.fire({
      title: translate('Seller.Confirmation',lang),
      text: translate('Seller.Are_you_sure_to_remove_this_item_from_the_store',lang),
      confirmButtonText: "Yes, i'm sure !",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await SellerModeService.deleteMyStore(id);
        if (res.error) {
          Swal.fire({
            title: res.response.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: res.data.message,
            icon: res.data.error ? "warning" : "success",
            confirmButtonText: "Ok",
          }).then(() => {
            getItems();
          });
        }
      }
    });
  };

  useEffect(() => {
    getItems();
  }, [props.item]);

  return (
    <>
      <div className="row justify-content-end">
        <div className="col-lg-4">
          <div className="mdg-search-place">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search "
              style={{ color: "#fff" }}
              className="mdg-search-input"
            />
            <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <div className="mdg-width-card-item mdg-mt-6">
        <div
          className="tab-pane fade show active"
          id="lands"
          role="tabpanel"
          aria-labelledby="lands-tab"
        >
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="all-flight"
              role="tabpanel"
              aria-labelledby="all-flight-tab"
            >
              <div className="mdg-width-card-item p-3">
                {items.length > 0 ? (
                  <div className="row">
                    {items.map((elt, i) => (
                      <MyStoreList
                        item={elt}
                        key={i}
                        deleteMyStore={deleteMyStore}
                      ></MyStoreList>
                    ))}
                  </div>
                ) : (
                  <p><Language word={"Seller.No_item_found_in_your_store"}></Language></p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyStorePage2;
