import axios  from "../AxiosInstance"

const getAllSimulatorService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/simulators`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteById = async (id) => {
    try {
        return await axios.delete(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/simulators/${id}`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const createSimulatorService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/simulators`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const updateSimulatorService = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/simulators/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

export const getSimulatorById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/simulators/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const SimulatorService = {
    getAllSimulatorService,
    deleteById,
    createSimulatorService,
    updateSimulatorService,
    getSimulatorById
}

export default SimulatorService;