import axios  from "../AxiosInstance"


const getAllUserSubscriptionService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-subscriptions`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const createUserSubscriptionService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-subscriptions`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const createFreeTrialUserSubscriptionService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-subscriptions/free-trial`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getList = async (filter) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-subscriptions/list`,
            { params: filter });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getActive = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-subscriptions/active`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const UserSubscriptionService = {
    getAllUserSubscriptionService,
    createUserSubscriptionService,
    getList,
    getActive,
    createFreeTrialUserSubscriptionService
}

export default UserSubscriptionService;