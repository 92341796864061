import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LocationService from "../../../services/base/location";
import SimulatorService from "../../../services/base/simulator";
import RoleService from "../../../services/role";
import { getUserInfo, updateUserInfo } from "../../../services/user";
import { findInList, getFullDate, getImageUrl, selectStyles } from "../../../Utils/Common";
import Select from "react-select";
import { verifyTokenAsync } from "../../../asyncActions/authAsyncActions";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import CountryService from "../../../services/base/country";
import WalletService from "../../../services/wallet/wallet";
import WalletDatatable from "../../pages/fo/wallet/WalletDatatable";
import ItemService from "../../../services/Item";
import ItemHistoryTable from "../../pages/fo/items/itemHistoryTable";
import Switch from "react-switch";

const UserEditProfilForm = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    //Wallet history
    const [walletHistoryList, setWalletHistoryList] = useState([]);
    const [userWalletItemHistoryList, setUserWalletItemHistoryList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isApproved, setIsApproved] = useState(false);

    const [itemHistoryLoading, setItemHistoryLoading] = useState(false);
    const [itemTotalRows, setItemTotalRows] = useState(0);
    const [itemHistoryPerPage, setItemHistoryPerPage] = useState(10);
    const [itemHistoryCurrentPage, setItemHistoryCurrentPage] = useState(1);
    const [navLink, setNavLink] = useState({
        editUser: true,
        history: false,
        itemHistory: false,
    });

    const handleChangeNav = title => {
        let temp = {
            editUser: false,
            history: false,
            itemHistory: false,
        }
        temp[title] = true;
        setNavLink(temp);
    }

    const getHistory = async () => {
        setLoading(true);
        const filter = {
            page: currentPage - 1,
            size: perPage,
            userId: id,
        };
        const res = await WalletService.getWalletAllHistoryAsAdminService(filter);
        if (!res.error) {
            //setWalletHistoryList(res.data);
            setWalletHistoryList(res.data.items);

            setTotalRows(res.data.totalItems);
        }
        setLoading(false);
    };

    const getItemHistory = async () => {
        setItemHistoryLoading(true);
        const filter = {
            page: itemHistoryCurrentPage - 1,
            size: itemHistoryPerPage,
            userId: id,
        };
        const res = await ItemService.getUserWalletItemHistory(filter);
        if (!res.error) {
            const lists = [];
            await res.data.items.map((value, i) => {
                let data = JSON.parse(value.data);
                lists.push({
                    id: value.id,
                    description: value.description,
                    type: value.type,
                    date: getFullDate(value.createdAt),
                    media: data.media,
                    itemName: data.name,
                });
            });
            setUserWalletItemHistoryList(lists);

            setItemTotalRows(res.data.totalItems);
        }
        setItemHistoryLoading(false);
    };

    const [roleList, setRoleList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [simulatorList, setSimulatorList] = useState([]);
    const [updateInfoError, setUpdateInfoError] = useState([]);
    const [updateInfoSuccess, setUpdateInfoSuccess] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState("");
    const [userPics, setUserPics] = useState(null);

    const [user, setUser] = useState(null);

    const [role, setRole] = useState("");
    const [location, setLocation] = useState("");
    const [simulator, setSimulator] = useState("");

    const [city, setCity] = useState("");
    const [username, setUserName] = useState("");
    const [dob, setDob] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const getUser = async () => {
        if (id) {
            const result = await getUserInfo(id);
            if (!result.error) {
                let usr = result.data.user;
                setUser(usr);
                setFirstName(usr.firstName);
                setUserName(usr.userName);
                setLastName(usr.lastName);
                setEmail(usr.email);
                setIsApproved(usr.approvedAdmin);
                setLocation(usr.metadata.location);
                setSimulator(usr.metadata.simulator);
                setCity(usr.metadata.city);
                setCountry(usr.metadata.country);
                setRole(usr.roleId);
                setDob(usr.metadata.dateOfBirth);
            }
        }
    };

    const getRoleList = async () => {
        let roles_list = await RoleService.getUserAvailableRolesAsKeyValueService();
        setRoleList(roles_list);
    };

    const getSimulatorList = async () => {
        let simulator_list =
            await SimulatorService.getSimulatorsAsKeyValueService();
        setSimulatorList(simulator_list);
    };

    const getCountryList = async () => {
        let countries_list = await CountryService.getCountryListService();

        setCountryList(countries_list);
    };

    const getLocationList = async () => {
        let locations_list = await LocationService.getLocationsAsKeyValueService();
        setLocationList(locations_list);
    };

    const handleChangeImage = () => {
        const pics = document.getElementById("id-profile-pics");
        pics.click();
    };

    useEffect(() => {
        getUser();
        getCountryList();
        getRoleList();
        getLocationList();
        getSimulatorList();
        getHistory();
        getItemHistory();
        setUpdateInfoError(null);
    }, [currentPage, perPage, itemHistoryCurrentPage, itemHistoryPerPage]);

    const handleUpdateInfo = async (e) => {
        e.preventDefault();
        setUpdateInfoError(null);
        setUpdateInfoSuccess(null);

        const metadata = user.isAdmin
            ? null
            : {
                role: role,
                simulator: simulator,
                location: location,
                country: country,
                city: city,
                dateOfBirth: dob,
            };

        /**
         * check all condition here
         */
        const formData = new FormData();
        formData.append("photo", userPics);
        formData.append("userName", username);
        formData.append("email", email);
        formData.append("lastName", lastName);
        formData.append("firstName", firstName);
        formData.append("isApproved", isApproved);
        formData.append("metadata", JSON.stringify(metadata));

        const result = await updateUserInfo(id, formData);
        if (result.error) {
            // setUpdateInfoError(result.response.data.message);
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => { });
        } else {
            // setUpdateInfoSuccess(result.data.message);
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => { });
            dispatch(verifyTokenAsync(true));
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const handleItemHistoryPageChange = (page) => {
        setItemHistoryCurrentPage(page);
    };

    const handleItemHistoryPerRowsChange = async (newPerPage, page) => {
        setItemHistoryPerPage(newPerPage);
    };

    return (
        <div>
            <section className="content">
                <div className="container-fluid pt-2">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item">
                            <div className={navLink.editUser ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={(e) => handleChangeNav('editUser')}>
                                User Profile
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={navLink.history ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={(e) => handleChangeNav('history')}>
                                History
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className={navLink.itemHistory ? "nav-link active" : "nav-link"} style={{ cursor: "pointer" }} onClick={(e) => handleChangeNav('itemHistory')}>
                                Item History
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="content mt-2">
                <div className="container-fluid">
                    <div className="row">
                        {navLink.editUser && (
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="card-title">
                                                    Change User personnal information
                                                </h5>

                                                <div className="card-tools">
                                                    <NavLink
                                                        to="/admin/user"
                                                        type="button"
                                                        className="btn btn-tool bg-info"
                                                        
                                                    >
                                                        List
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        {user && (
                                                            <form onSubmit={(e) => handleUpdateInfo(e)}>
                                                                <div className="row">
                                                                    <div className="mdg-col-sm-6 text-center">
                                                                        <input
                                                                            className="mdg-d-none"
                                                                            type="file"
                                                                            accept="image/png, image/gif, image/jpeg"
                                                                            id="id-profile-pics"
                                                                            onChange={(event) => {
                                                                                setUserPics(event.target.files[0]);
                                                                            }}
                                                                        />
                                                                        {userPics ? (
                                                                            <img
                                                                                onClick={handleChangeImage}
                                                                                className="mdg-upload-img-profil"
                                                                                src={URL.createObjectURL(userPics)}
                                                                            ></img>
                                                                        ) : (
                                                                            <img
                                                                                onClick={handleChangeImage}
                                                                                src={
                                                                                    user.photo
                                                                                        ?getImageUrl(user.photo)
                                                                                        : "/assets/img/up-profil.png"
                                                                                }
                                                                                className="mdg-upload-img-profil"
                                                                            ></img>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="row ">
                                                                    <div className="col-lg-6">
                                                                        <div className="row">
                                                                            <div className="mdg-card-body">
                                                                                <div className="mdg-col-sm-12">
                                                                                    <label htmlFor="input-last-name">
                                                                                        Username
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        id="input-last-name"
                                                                                        type="text"
                                                                                        placeholder=" "
                                                                                        value={username}
                                                                                        onChange={(e) => {
                                                                                            setUserName(e.target.value);
                                                                                        }}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="mdg-col-sm-12">
                                                                                    <label htmlFor="input-last-name">
                                                                                        Last Name
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        id="input-last-name"
                                                                                        type="text"
                                                                                        placeholder=" "
                                                                                        value={lastName}
                                                                                        onChange={(e) => {
                                                                                            setLastName(e.target.value);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className="mdg-col-sm-12">
                                                                                    <label htmlFor="input-first-name">
                                                                                        First Name
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        id="input-first-name"
                                                                                        type="text"
                                                                                        placeholder=" "
                                                                                        value={firstName}
                                                                                        onChange={(e) => {
                                                                                            setFirstName(e.target.value);
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div className="mdg-col-sm-12">
                                                                                    <label htmlFor="input-first-name">
                                                                                        Email
                                                                                    </label>
                                                                                    <input
                                                                                        className="form-control"
                                                                                        id="input-surname"
                                                                                        type="email"
                                                                                        required
                                                                                        placeholder=" "
                                                                                        value={email}
                                                                                        onChange={(e) => {
                                                                                            setEmail(e.target.value);
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        {!user.isAdmin && (
                                                                            <div className="row">
                                                                                <div className="mdg-card-body">
                                                                                    <div className="mdg-col-sm-12">
                                                                                        <label>Date of Birth</label>

                                                                                        <input
                                                                                            type="date"
                                                                                            className="form-control"
                                                                                            value={dob}
                                                                                            onChange={(e) => {
                                                                                                setDob(e.target.value);
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="mdg-col-sm-12">
                                                                                        <label>Role</label>
                                                                                        {roleList && (
                                                                                            <Select
                                                                                                isClearable={false}
                                                                                                onChange={(newValue, actionMeta) => {
                                                                                                    setRole(newValue.value);
                                                                                                }}
                                                                                                value={findInList(roleList, role)}
                                                                                                isSearchable={false}
                                                                                                placeholder={
                                                                                                    <div>You will be join as</div>
                                                                                                }
                                                                                                options={roleList}
                                                                                                styles={selectStyles}
                                                                                            />
                                                                                        )}
                                                                                    </div>

                                                                                    <div className="mdg-col-sm-12">
                                                                                        <label>Simulator</label>
                                                                                        {simulatorList && (
                                                                                            <Select
                                                                                                isClearable={false}
                                                                                                onChange={(newValue, actionMeta) => {
                                                                                                    setSimulator(newValue.value);
                                                                                                }}
                                                                                                value={findInList(
                                                                                                    simulatorList,
                                                                                                    simulator
                                                                                                )}
                                                                                                isSearchable={false}
                                                                                                placeholder={
                                                                                                    <div>Which sim are you using?</div>
                                                                                                }
                                                                                                options={simulatorList}
                                                                                                styles={selectStyles}
                                                                                            />
                                                                                        )}
                                                                                    </div>

                                                                                    <div className="mdg-col-sm-12">
                                                                                        <label>Location</label>
                                                                                        {locationList && (
                                                                                            <Select
                                                                                                isClearable={false}
                                                                                                onChange={(newValue, actionMeta) => {
                                                                                                    setLocation(newValue.value);
                                                                                                }}
                                                                                                value={findInList(
                                                                                                    locationList,
                                                                                                    location
                                                                                                )}
                                                                                                isSearchable={false}
                                                                                                placeholder={
                                                                                                    <div>Choose your location</div>
                                                                                                }
                                                                                                options={locationList}
                                                                                                styles={selectStyles}
                                                                                            />
                                                                                        )}
                                                                                    </div>

                                                                                    <div className="mdg-col-sm-12">
                                                                                        <label>Country</label>
                                                                                        {countryList && (
                                                                                            <Select
                                                                                                isClearable={true}
                                                                                                onChange={(newValue, actionMeta) => {
                                                                                                    newValue &&
                                                                                                        setCountry(newValue.value);
                                                                                                }}
                                                                                                value={findInList(
                                                                                                    countryList,
                                                                                                    country
                                                                                                )}
                                                                                                placeholder={
                                                                                                    <div>Choose a country</div>
                                                                                                }
                                                                                                options={countryList}
                                                                                                styles={selectStyles}
                                                                                            />
                                                                                        )}
                                                                                    </div>

                                                                                    <div className="mdg-col-sm-12">
                                                                                        <label>City</label>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            id="input-city"
                                                                                            type="text"
                                                                                            placeholder=" "
                                                                                            value={city}
                                                                                            onChange={(e) => {
                                                                                                setCity(e.target.value);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="mdg-col-sm-12">
                                                                                        <div className="row mt-2 ml-1">
                                                                                            <label className="mr-4" >Is approved</label>
                                                                                            <Switch 
                                                                                                onColor="#117a8b" checked={isApproved} 
                                                                                                onChange={(checked) => {
                                                                                                    setIsApproved(checked);
                                                                                                }} />
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="mdg-modal-footer">
                                                                    <div className="mdg-col-sm-10 text-center">
                                                                        {updateInfoError && (
                                                                            <>
                                                                                <small className="text-danger">
                                                                                    {" "}
                                                                                    {updateInfoError}{" "}
                                                                                </small>{" "}
                                                                            </>
                                                                        )}
                                                                        {updateInfoSuccess && (
                                                                            <>
                                                                                <small className="text-success">
                                                                                    {updateInfoSuccess}{" "}
                                                                                </small>{" "}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    <div className="mdg-col-sm-2">
                                                                        <button className="mdg-btn mdg-btn-outline-meta mdg-btn-round">
                                                                            Enregistrer
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        {navLink.history && (
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="card-title">
                                                    History
                                                </h5>

                                                <div className="card-tools">
                                                    <NavLink
                                                        to="/admin/user"
                                                        type="button"
                                                        className="btn btn-tool bg-info"
                                                        
                                                    >
                                                        List
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row mt-5">
                                                    <div className="col-lg-12 mdg-historique-table">
                                                        {walletHistoryList && (
                                                            <WalletDatatable
                                                                data={walletHistoryList}
                                                                progressPending={loading}
                                                                paginationDefaultPage={currentPage}
                                                                paginationTotalRows={totalRows}
                                                                onChangeRowsPerPage={handlePerRowsChange}
                                                                onChangePage={handlePageChange}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        {navLink.itemHistory && (
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h5 className="card-title">
                                                    Item History
                                                </h5>

                                                <div className="card-tools">
                                                    <NavLink
                                                        to="/admin/user"
                                                        type="button"
                                                        className="btn btn-tool bg-info"
                                                        
                                                    >
                                                        List
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row mt-5">
                                                    <div className="col-lg-12 mdg-historique-table">
                                                        {userWalletItemHistoryList && (
                                                            <ItemHistoryTable
                                                                data={userWalletItemHistoryList}
                                                                progressPending={itemHistoryLoading}
                                                                paginationDefaultPage={itemHistoryCurrentPage}
                                                                paginationTotalRows={itemTotalRows}
                                                                onChangeRowsPerPage={handleItemHistoryPerRowsChange}
                                                                onChangePage={handleItemHistoryPageChange}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                    </div>
                </div>
            </section>
            {/* <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">User Profile</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#">Admin</a>
                                </li>
                                <li className="breadcrumb-item">User</li>
                                <li className="breadcrumb-item active">Profile</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">



                </div>
            </section> */}
        </div>
    );
};

export default UserEditProfilForm;
