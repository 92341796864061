import React, { useEffect } from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import PublicRoute from "./Utils/PublicRoute";
import ProtectedRoute from "./Utils/ProtectedRoute";
import { useDispatch, useSelector } from "react-redux";
import { verifyTokenAsync,updateUserInfo } from "./asyncActions/authAsyncActions";
import AdminRoute from "./Utils/AdminRoute";
import moment from 'moment';
import ProfilPageFO from "./components/pages/fo/profil/ProfilPageFO";
import WalletPage from "./components/pages/fo/wallet/WalletPage";
import SellerPage from "./components/pages/fo/seller/SellerPage";
import WishlistPage from "./components/pages/fo/wishlist/WishlistPage";
import NotFound from "./components/notfound/NotFound";
import CategoryComponent from "./components/BO/category/CategoryComponent";
import ItemTypeComponent from "./components/BO/itemTypes/ItemTypeComponent";
import PackComponent from "./components/BO/pack/PackComponent";
import RoleComponent from "./components/BO/role/RoleComponent";
import UserComponent from "./components/BO/user/UserComponent";
import DashboardComponent from "./components/BO/dashboard/DashboardComponent";
import PackPageFo from "./components/pages/fo/pack/PackPageFo";
import ItemComponent from "./components/BO/item/ItemComponent";
import OrderComponent from "./components/BO/order/OrderComponent";
import MyStorePage from "./components/pages/fo/seller/MyStorePage";
import SimulatorComponent from "./components/BO/simulator/SimulatorComponent";
import SellerBasePage from "./components/pages/fo/seller/SellerBasePage";
import MyItemsPage from "./components/pages/fo/seller/MyItems/ItemsPage";
import MyStorePage2 from "./components/pages/fo/seller/MyStore/MyStorePage";
import RecommendationPage from "./components/pages/fo/seller/MyRecommendations/RecommendationPage";
import MarketPlacePage from "./components/pages/fo/seller/MarketPlace/MarketPlace";
import SubscriptionComponent from "./components/BO/subscription/SubscriptionComponent";
import UserSubscriptionComponent from "./components/BO/userSubscription/UserSubscriptionComponent";
import SubscriptionPage from "./components/pages/fo/subscription/SubscriptionPage";
import MyItemDetailPage from "./components/pages/fo/seller/MyItems/Detail/MyItemDetailPage";
import SettingsPages from "./components/BO/settings/SettingsPages";
import AchatPages from "./components/BO/settings/achat_ebillet/AchatPages";
import CommissionPages from "./components/BO/settings/commission_ebillet/CommissionPages";
import FeaturesComponent from "./components/BO/subscription/features/FeaturesComponent";
import PromocodeComponent from "./components/BO/promocode/PromocodeComponent";
import MyRentedItems from "./components/pages/fo/seller/MyRentedItems/MyRentedItems";
import LocationComponent from "./components/BO/location/LocationComponent";
import PackUpToSellComponent from "./components/BO/packUpTosell/PackUpToSellComponent";
import NoticesComponent from "./components/BO/notification/NoticesComponents";
import NoticePageFO from "./components/pages/fo/notice/NoticePageFO";
import LevelComponent from "./components/BO/missions/LevelComponents";
import TypeComponent from "./components/BO/missions/TypeComponent";
import MissionComponent from "./components/BO/missions/MissionComponents";
import AffiliateBonusPages from "./components/BO/settings/affiliate-bonus/AffiliateBonusPages";
import CargoTypeComponent from "./components/BO/cargoType/CargoTypeComponent";
import AviationTypeComponent from "./components/BO/aviationType/AviationTypeComponent";
import DetailsMission from "./components/pages/fo/mission/DetailsMission";
import PiloteRemunerationPages from "./components/BO/settings/pilote_remuneration/PiloteRemunerationPages";
import MissionPage from "./components/pages/fo/mission/mission";
import BotThresholdPage from "./components/BO/settings/botThreshold/BotThresholdPage";
import ScenarioComponent from "./components/BO/scenario/ScenarioComponent";
import BotRepatriationPages from "./components/BO/settings/bot-repatriation/BotRepatriationPages";
import AwardComponent from "./components/BO/award/AwardComponent";
import CargoCategoryComponent from "./components/BO/cargoCategory/CargoCategoryComponent";
import WaresComponent from "./components/BO/wares/WaresComponent";
import DetailsFlightPages from "./components/pages/fo/flight/DetailsFlightPages";
import FoodCategoryComponent from "./components/BO/wfm/foodCategory/FoodCategoryComponent";
import FoodComponent from "./components/BO/wfm/food/FoodComponent";
import FlightIncidentComponent from "./components/BO/wfm/flight-incident/FlightIncidentComponent";
import PackBoosterCarreerLevelComponent from "./components/BO/packBoosterCarreerLevel/PackBoosterCarreerLevelComponent";
import PackBoosterCarreerLevelFo from "./components/pages/fo/packBoosterCarreerLevel/PackBoosterCarreerLevelFo";
import StoreCategoryComponent from "./components/BO/storeCategory/StoreCategoryComponent";
import StoreProductComponent from "./components/BO/storeProduct/StoreProductComponent";
import StorePage from "./components/pages/fo/store/storePage";
import PackageJson from "../package.json";
import StoreOrderComponent from "./components/BO/storeOrder/StoreOrderComponent";
import GeneralSettingsPages from "./components/BO/settings/GeneralSettingsPages";
import ManageProfileComponent from "./components/BO/manageProfile/ManageProfileComponent";
import MalusLastPositionPages from "./components/BO/settings/malus_last_position/MalusLastPositionPages";
import MyItemPage from "./components/pages/fo/items/ItemsPage";
import ItemsPage from "./components/pages/fo/items/ItemsPage";
import FlightPage from "./components/pages/fo/flight/FlightPage";
import MainLayout from "./components/layout/MainLayout";
import InGameStoreLayout from "./components/layout/InGameStoreLayout";
import { AnimatePresence } from "framer-motion";
import loadable from "@loadable/component";
import UserDashboardLayout from "./components/layout/UserDashboardLayout";

/**
 * dasboard v2
 */
import DashboardUser from "./components/pages/fo/userDashboard/DashboardUser";
const Notice = loadable(() => import("./components/pages/fo/notice/Notice")) ;
const PContentProfil = loadable(() => import("./components/pages/fo/profil/PContentProfil"));
const Items = loadable(() => import( "./components/pages/fo/items/Items"));
const Flight = loadable(() => import( "./components/pages/fo/flight/Flight"));
const Pack = loadable(() => import( "./components/pages/fo/pack/Pack"));
const Wallet = loadable(() => import( "./components/pages/fo/wallet/Wallet"));
const PaymentTable = loadable(() => import( "./components/pages/fo/payment/PaymentTable"));
const Subscription = loadable(() => import( "./components/pages/fo/subscription/Subscription"));
const Wishlist = loadable(() => import( "./components/pages/fo/wishlist/Wishlist"));


/**
 * Authentification Routes
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const NewLogin = loadable(() => import("./components/pages/login/NewLogin"));
const RegisterPage = loadable(() => import("./components/pages/register/RegisterPage"));
const StepRegister = loadable(() => import("./components/pages/register/StepRegister"));
const ForgotPasswordPage = loadable(() => import("./components/pages/forgotPassword/ForgotPasswordPage"));
const ResetPasswordPage = loadable(() => import("./components/pages/forgotPassword/resetPassword/ResetPasswordPage"));

/**
 * Home page
 * @return {JSX.Element}
 * @constructor
 */
const Home = loadable(() => import("./components/pages/home/Home"));

/**
 * In game store layout
 * @return {JSX.Element}
 * @constructor
 */
const Ingame = loadable(() => import("./components/pages/fo/ingame/Ingame"));
const IngameDetailsPages = loadable(() => import("./components/pages/fo/ingame/Details/IngameDetailsPages"));
const ExchangeProposalPage = loadable(() => import("./components/pages/fo/ingame/Details/exchange/ExchangeProposalPage"));
const Info = loadable(() => import("./components/pages/fo/ingame/Info/Info"));
const DetailSell = loadable(() => import("./components/pages/fo/ingame/Info/detail/detail_sell/DetailSell"));
const DetailExchange = loadable(() => import("./components/pages/fo/ingame/Info/detail/detail_exchange/DetailExchange"))

/**
 * Protected Layout
 * @return {JSX.Element}
 * @constructor
 */
const Logout = loadable(() => import("./components/extra/logout/Logout"));
const Userdashboardv2 = loadable(() => import("./components/pages/fo/userDashboard/Userdashboardv2"));

/**
 * stripe payment
 */
const  PaymentSuccess = loadable(() => import( "./components/pages/payment/PaymentSuccess"));
const PaymentError = loadable(() => import( "./components/pages/payment/PaymentError"));

const AnimatedRoutes = () => {
    const authObj = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();
    const { isAuthenticated, user } = authObj;

    useEffect(() => {
        if(!user){
            dispatch(updateUserInfo());
        }
    }, [])

    useEffect(() => {
        console.log("App version : " + PackageJson.version);
    },[]);

    return (
        <AnimatePresence mode="wait">
            <Routes key={location.pathname} location={location}>
                {/** Not Authenticated Routes */}
                <Route element={<PublicRoute isAuthenticated={isAuthenticated} redirectPath="/" />}>
                    <Route exact path="/login" element={<NewLogin />} />
                    <Route exact path="/register" element={<RegisterPage />} />
                    <Route exact path="/register/:parrain" element={<RegisterPage />} component={RegisterPage} />
                    <Route exact path="/step-register" element={<StepRegister />} />
                    <Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
                    <Route exact path="/reset-password" element={<ResetPasswordPage />} />
                </Route>
                {/** End Not Authenticated Routes */}
                
                {/** in game store */}
                <Route element={<MainLayout /> }>
                    <Route index exact path="/" element={<Home />} />
                    <Route exact path="/home" element={<Home />} />
                    <Route exact path="/verify-email" element={<Home page={"verify-email"} />} />
                    <Route exact path="/thank-you" element={<PaymentSuccess />} />
                    <Route exact path="/payment-error" element={<PaymentError />} />
                </Route>
                <Route element={<InGameStoreLayout />} path="ingame-store">
                    <Route index path="" element={<Ingame />} />
                    <Route path=":id/:type" element={<Ingame />} />
                    <Route path="details">
                        <Route path="" element={<IngameDetailsPages />} />
                        <Route path=":id/:type" element={<IngameDetailsPages />} />
                        <Route path=":id/exchange/do-proposal/:deal" element={<ExchangeProposalPage />} />
                    </Route>
                    <Route path="item-details" element={<Info />} />
                    <Route path="sell-details" element={<DetailSell />} />
                    <Route path="rent-details" element={<DetailSell />} />
                    <Route path="exchange-details" element={<DetailExchange />} />
                </Route>
                {/** end in game store */}
                <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} redirectPath="/login" />}>
                    <Route exact path="/logout" element={<Logout />} />
                    <Route path="*" element={<NotFound />} />
                    <Route element={<UserDashboardLayout />}>
                        <Route exact path="/user-dashboard" element={<DashboardUser />} />
                        <Route exact path="/user-profile" element={<PContentProfil version={"v2"} />} />
                        <Route exact path="/user-items" element={<Items version={"v2"} />} />
                        <Route exact path="/user-flights" element={<Flight version={"v2"} />} />
                        <Route exact path="/pack-booster" element={<Pack version={"v2"} />} />
                        <Route exact path="/user-wallet" element={<Wallet version={"v2"} />} />
                        <Route exact path="/user-payment" element={<PaymentTable version={"v2"} />} />
                        <Route exact path="/subscriptions" element={<Subscription version={"v2"} />} />
                        <Route exact path="/wishlists" element={<Wishlist version={"v2"} />} />
                        <Route exact path="/notices" element={ <Notice version={"v2"} />} />
                    </Route>
                    {/* <Route exact path="/user-dashboard" element={<Userdashboardv2 />} /> */}

                    {/** account */}
                    <Route path="account">
                        <Route path="store" element={<SellerBasePage page={<MyStorePage2></MyStorePage2>} />} />
                        <Route path="recommendations" element={<SellerBasePage page={<RecommendationPage></RecommendationPage>} />} />
                        <Route path="my-rented-items" element={<SellerBasePage page={<MyRentedItems></MyRentedItems>} />} />
                        <Route path='items'>
                            <Route path="" element={<SellerBasePage page={<MyItemsPage></MyItemsPage>} />} />
                            <Route path=":id">
                                <Route path='marketplace'>
                                    <Route path="sell" element={<MarketPlacePage action="sell" />} />
                                    <Route path="rent" element={<MarketPlacePage action="rent" />} />
                                    <Route path="exchange" element={<MarketPlacePage action="exchange" />} />
                                </Route>
                                <Route path="details">
                                    <Route path=":type" element={<MyItemDetailPage />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    {/** end account */}

                    <Route exact path="/my-items/:id" element={<MyItemPage />} />
                    <Route exact path="/seller-mode" element={<SellerPage />} />
                    <Route exact path="/my-store" element={<MyStorePage page="store" />} />
                    <Route exact path="/recommendation" element={<MyStorePage page="recommendation" />} />
                    <Route exact path="/packs" element={<PackPageFo />} />
                    <Route exact path="/pack-booster-carreer-level" element={<PackBoosterCarreerLevelFo />} />
                    <Route exact path="/wishlist" element={<WishlistPage />} />
                    <Route exact path="/profil-user" element={<ProfilPageFO />} />
                    <Route exact path="/notice" element={<NoticePageFO />} />
                    <Route exact path="/wallet" element={<WalletPage />} />
                    <Route exact path="/my-items" element={<ItemsPage />} />
                    
                    
                    <Route path="/mission">
                        <Route path="" element={<MissionPage />} />
                        <Route path="detail" element={<DetailsMission page="detail" />} />
                    </Route>

                    <Route path="mf-store">
                        <Route path="" element={<StorePage />} />
                        <Route path=":id/:type" element={<StorePage />} />
                    </Route>
                    <Route path="/my-flight-list">
                        <Route path="" element={<FlightPage />} />
                        <Route path="detail/:id" element={<DetailsFlightPages page="detail" />} />
                    </Route>

                    <Route exact path="/subscription" element={<SubscriptionPage />} />
                    <Route path="admin" element={<AdminRoute isAdmin={user?.isAdmin} redirectPath="/login" ></AdminRoute>}>
                        <Route path="dashboard" element={<DashboardComponent />} />
                        <Route path="user">
                            <Route path="" element={<UserComponent page="list" />}></Route>
                            <Route exact path="profil/:id" element={<UserComponent page="edit" />} />
                            <Route path="wallet">
                                <Route exact path="credit/:id" element={<UserComponent page="credit" />} />
                                <Route exact path="debit/:id" element={<UserComponent page="debit" />} />
                            </Route>
                        </Route>
                        <Route path="category">
                            <Route path="" element={<CategoryComponent page="list" />}></Route>
                            <Route path="new" element={<CategoryComponent page="new" />} />
                            <Route path="edit/:id" element={<CategoryComponent page="edit" />} />
                        </Route>
                        <Route path="role" >
                            <Route path="" element={<RoleComponent page="list" />}></Route>
                            <Route path="new" element={<RoleComponent page="new" />} />
                            <Route path="edit/:id" element={<RoleComponent page="edit" />} />
                        </Route>
                        <Route path="item">
                            <Route path="" element={<ItemComponent page="list" />}></Route>
                            <Route path="new" element={<ItemComponent page="new" />} />
                            <Route path="edit/:id" element={<ItemComponent page="edit" />} />
                        </Route>
                        <Route path="type">
                            <Route path="" element={<ItemTypeComponent page="list" />}></Route>
                            <Route path="new" element={<ItemTypeComponent page="new" />} />
                            <Route path="edit/:id" element={<ItemTypeComponent page="edit" />} />
                        </Route>
                        <Route path="pack">
                            <Route path="" element={<PackComponent page="list" />}></Route>
                            <Route path="new" element={<PackComponent page="new" />} />
                            <Route path="edit/:id" element={<PackComponent page="edit" />} />
                        </Route>
                        <Route path="pack-booster-carreer-level">
                            <Route path="" element={<PackBoosterCarreerLevelComponent page="list" />}></Route>
                            <Route path="new" element={<PackBoosterCarreerLevelComponent page="new" />} />
                            <Route path="edit/:id" element={<PackBoosterCarreerLevelComponent page="edit" />} />
                        </Route>
                        <Route path="pack-booster-carreer-order">
                            <Route path="" element={<PackBoosterCarreerLevelComponent page="order"/>} />
                        </Route>
                        <Route path="paxncargo">
                            <Route path="food-category">
                                <Route path="" element={<FoodCategoryComponent page="list" />}></Route>
                                <Route path="new" element={<FoodCategoryComponent page="new" />} />
                                <Route path="edit/:id" element={<FoodCategoryComponent page="edit" />} />
                            </Route>
                            <Route path="food">
                                <Route path="" element={<FoodComponent page="list" />}></Route>
                                <Route path="new" element={<FoodComponent page="new" />} />
                                <Route path="edit/:id" element={<FoodComponent page="edit" />} />
                            </Route>
                            <Route path="flight-incident">
                                <Route path="" element={<FlightIncidentComponent page="list" />}></Route>
                                <Route path="new" element={<FlightIncidentComponent page="new" />} />
                                <Route path="edit/:id" element={<FlightIncidentComponent page="edit" />} />
                            </Route>
                        </Route>
                        <Route path="up-to-sell">
                            <Route path="" element={<PackUpToSellComponent page="list" />}></Route>
                            <Route path="new" element={<PackUpToSellComponent page="new" />} />
                            <Route path="edit/:id" element={<PackUpToSellComponent page="edit" />} />
                        </Route>
                        <Route path="promocode">
                            <Route path="" element={<PromocodeComponent page="list" />}></Route>
                            <Route path="new" element={<PromocodeComponent page="new" />} />
                            <Route path="edit/:id" element={<PromocodeComponent page="edit" />} />
                        </Route>
                        <Route path="cargo-type">
                            <Route path="" element={<CargoTypeComponent page="list" />}></Route>
                            <Route path="new" element={<CargoTypeComponent page="new" />} />
                            <Route path="edit/:id" element={<CargoTypeComponent page="edit" />} />
                        </Route>
                        <Route path="aviation-type">
                            <Route path="" element={<AviationTypeComponent page="list" />}></Route>
                            <Route path="new" element={<AviationTypeComponent page="new" />} />
                            <Route path="edit/:id" element={<AviationTypeComponent page="edit" />} />
                        </Route>
                        <Route path="order">
                            <Route path="" element={<OrderComponent page="list" />}></Route>
                        </Route>
                        <Route path="simulator">
                            <Route path="" element={<SimulatorComponent page="list" />}></Route>
                            <Route path="new" element={<SimulatorComponent page="new" />} />
                            <Route path="edit/:id" element={<SimulatorComponent page="edit" />} />
                        </Route>
                        <Route path="location">
                            <Route path="" element={<LocationComponent page="list" />}></Route>
                            <Route path="flight" element={<LocationComponent page="flight" />} />
                            <Route path="edit/:id" element={<LocationComponent page="edit" />} />
                        </Route>
                        <Route path="subscription">
                            <Route path="" element={<SubscriptionComponent page="list" />}></Route>
                            <Route path="new" element={<SubscriptionComponent page="new" />} />
                            <Route path="edit/:id" element={<SubscriptionComponent page="edit" />} />
                        </Route>
                        <Route path="user-subscription">
                            <Route path="" element={<UserSubscriptionComponent page="list" />}></Route>
                        </Route>
                        <Route path="features">
                            <Route path="" element={<FeaturesComponent page="list" />}></Route>
                            <Route path="new" element={<FeaturesComponent page="new" />} />
                            <Route path="edit/:id" element={<FeaturesComponent page="edit" />} />
                        </Route>
                        <Route path="setting">
                            <Route path="general" element={<GeneralSettingsPages page="general" />}></Route>
                            <Route path="flight-coin-conversion" element={<SettingsPages page="compensation" />}></Route>
                            <Route path="affiliate-bonus" element={<AffiliateBonusPages page="affiliate-bonus" />}></Route>
                            <Route path="bot-threshold" element={<BotThresholdPage page="bot-threshold" />}></Route>
                            <Route path="pilote-remuneration" element={<PiloteRemunerationPages page="pilote-remuneration" />}></Route>
                            <Route path="remuneration-eTicket" element={<AchatPages page="achat" />}></Route>
                            <Route path="ingame-store-virtual-properties-commission" element={<CommissionPages page="commission" />}></Route>
                            <Route path="bot-repatriation" element={<BotRepatriationPages page="bot-repatriation" />}></Route>
                            <Route path="malus-last-position" element={<MalusLastPositionPages page="malus-last-position" />}></Route>
                        </Route>
                        <Route path="notice">
                            <Route path="" element={<NoticesComponent page="list" />}></Route>
                            <Route path="new" element={<NoticesComponent page="new" />} />
                            <Route path="edit/:id" element={<NoticesComponent page="edit" />} />
                        </Route>
                        <Route path="level">
                            <Route path="" element={<LevelComponent page="list" />}></Route>
                            <Route path="new" element={<LevelComponent page="new" />} />
                            <Route path="edit/:id" element={<LevelComponent page="edit" />} />
                        </Route>
                        <Route path="mission-type">
                            <Route path="" element={<TypeComponent page="list" />}></Route>
                            <Route path="new" element={<TypeComponent page="new" />} />
                            <Route path="edit/:id" element={<TypeComponent page="edit" />} />
                        </Route>
                        <Route path="mission">
                            <Route path="" element={<MissionComponent page="list" />}></Route>
                            <Route path="new" element={<MissionComponent page="new" />} />
                            <Route path="edit/:id" element={<MissionComponent page="edit" />} />
                        </Route>
                        <Route path="scenario">
                            <Route path="" element={<ScenarioComponent page="list" />}></Route>
                            <Route path="new" element={<ScenarioComponent page="new" />} />
                            <Route path="edit/:id" element={<ScenarioComponent page="edit" />} />
                        </Route>
                        <Route path="award">
                            <Route path="" element={<AwardComponent page="list" />}></Route>
                            <Route path="new" element={<AwardComponent page="new" />} />
                            <Route path="edit/:id" element={<AwardComponent page="edit" />} />
                        </Route>
                        <Route path="cargo-category">
                            <Route path="" element={<CargoCategoryComponent page="list" />}></Route>
                            <Route path="new" element={<CargoCategoryComponent page="new" />} />
                            <Route path="edit/:id" element={<CargoCategoryComponent page="edit" />} />
                        </Route>
                        <Route path="wares">
                            <Route path="" element={<WaresComponent page="list" />}></Route>
                            <Route path="new" element={<WaresComponent page="new" />} />
                            <Route path="edit/:id" element={<WaresComponent page="edit" />} />
                        </Route>
                        <Route path="store-category">
                            <Route path="" element={<StoreCategoryComponent page="list" />}></Route>
                            <Route path="new" element={<StoreCategoryComponent page="new" />} />
                            <Route path="edit/:id" element={<StoreCategoryComponent page="edit" />} />
                        </Route>
                        <Route path="store-product">
                            <Route path="" element={<StoreProductComponent page="list" />}></Route>
                            <Route path="new" element={<StoreProductComponent page="new" />} />
                            <Route path="edit/:id" element={<StoreProductComponent page="edit" />} />
                        </Route>
                        <Route path="store-order">
                            <Route path="" element={<StoreOrderComponent page="list" />}></Route>
                        </Route>
                        <Route path="manage-profile">
                            <Route path="" element={<ManageProfileComponent page="list" />}></Route>
                            <Route path="new" element={<ManageProfileComponent page="new" />} />
                            <Route path="edit/:id" element={<ManageProfileComponent page="edit" />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes;
