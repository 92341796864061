import axios  from "../AxiosInstance"

const orderPack = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/checkout/order`,
      data: data,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const subscribeUser = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/checkout/subscribe`,
      data: data,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getClientToken = async (data) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/checkout/token`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const createOrder = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/checkout/create-order`,
      data: data,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const captureOrder = async (order_id) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/checkout/capture-order`,
      data: {
        order_id : order_id
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const checkPayment = async (paymentId) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/webhook/check-payment`,
      data: {
        paymentId : paymentId
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const CheckoutService = {
  orderPack,
  subscribeUser,
  getClientToken,
  createOrder,
  captureOrder,
  checkPayment
};

export default CheckoutService;
