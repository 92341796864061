import React, { useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import MapAction from '../../../../../actions/map/actions';
import { Switch } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import Language from '../../../../extra/language/language';
import 'antd/dist/antd.css';

const Right = () => {
    const [isVisible, setIsVisilble] = useState(false);

    const mode = useSelector((store) => store.map.mode);
    const user = useSelector((store) => store.auth.user);


    const cloud = useSelector((store) => store.map.weather.cloud.checked);
    const light = useSelector((store) => store.map.weather.light.checked);
    const temperature = useSelector((store) => store.map.weather.temperature.checked);
    const precipitation = useSelector((store) => store.map.weather.precipitation.checked);
    const wind = useSelector((store) => store.map.weather.wind.checked);
    const snow = useSelector((store) => store.map.weather.snow.checked);
    const pressure = useSelector((store) => store.map.weather.pressure.checked);

    const ivao = useSelector((store) => store.map.networks.ivao);
    const afrv = useSelector((store) => store.map.networks.afrv);
    const metaflight = useSelector((store) => store.map.networks.metaflight);
    const airport = useSelector((store) => store.map.networks.airport);
    const vatsim = useSelector((store) => store.map.networks.vatsim);
    const home = useSelector((store) => store.map.networks.home);
    const last_position = useSelector((store) => store.map.networks.last_position);
    const wfm = useSelector((store) => store.map.networks.wfm);

    const dispacth = useDispatch();

    const handleMode = (value) => {
        dispacth(MapAction.ChangeMode(value));
    };

    return (

        <div className="mdg-pos-relative">
            <div className="mdg-pos-absolute">
                <div style={{ display: 'flex' }}>
                    <div
                        style={{ width: '50px' }}
                        className={
                            isVisible
                                ? 'mdg-card-setting visible-in-right-menu'
                                : 'mdg-card-setting visible-out-right-menu'
                        }
                    >
                        <div className="">
                            <div className="mdg-label-content mdg-text-white">
                                <Button
                                    type=""
                                    className="mdg-btn-info-home mdg-icon-menu-right mdg-fix-btn"
                                    onClick={() => setIsVisilble(!isVisible)}
                                >
                                    <Language word={"Setting_Option.Setting_card"}></Language>
                                </Button>
                            </div>
                        </div>

                        <div className="mdg-content-text mdg-30-top">
                            <div className="mdg-list-item">
                                <h5 className="mdg-text-white mdg-setting-title">Mode</h5>
                                <div
                                    className={
                                        mode === 'DARK' ? 'mdg-on-active' : 'mdg-off-active'
                                    }
                                >

                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">

                                                <Button
                                                    type="primary"
                                                    className="mdg-btn-transparent text-left"
                                                    onClick={(e) => {
                                                        handleMode('DARK');
                                                    }}
                                                >
                                                    <Language word={"Setting_Option.Dark"}></Language>
                                                </Button>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/night.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-small mr-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        mode === 'LIGHT' ? 'mdg-on-active' : 'mdg-off-active'
                                    }
                                >

                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Button
                                                    type="primary"
                                                    className="mdg-btn-transparent text-left"
                                                    onClick={(e) => {
                                                        handleMode('LIGHT');
                                                    }}
                                                >
                                                    <Language word={"Setting_Option.Light"}></Language>
                                                </Button>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/contrast.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-small mr-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={
                                        mode === 'SATELLITE' ? 'mdg-on-active' : 'mdg-off-active'
                                    }
                                >
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">

                                                <Button
                                                    type="primary"
                                                    className="mdg-btn-transparent text-left"
                                                    onClick={(e) => {
                                                        handleMode('SATELLITE');
                                                    }}
                                                >
                                                    <Language word={"Setting_Option.Satellite"}></Language>
                                                </Button>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/satellite.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-small mr-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                user && (
                                    <div className="mdg-list-item">
                                        <h5 className="mdg-text-white mdg-setting-title">
                                            <Language word={"Setting_Option.Location"}></Language>
                                        </h5>
                                        <div className="mdg-off-active d-none">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-10">
                                                        <Switch
                                                            size="small"
                                                            checked={home}
                                                            onChange={() => {
                                                                dispacth(MapAction.ToogleNetwork('home'))
                                                            }}
                                                        />
                                                        <span className="ml-2 mdg-text-white mdg-12-text"><Language word={"Setting_Option.ShowHome"}></Language></span>
                                                    </div>
                                                    <div className="col-2">
                                                        <img
                                                            src="/assets/image/home.png"

                                                            alt=""

                                                            style={{
                                                                width: "24px"
                                                            }}
                                                            className="rounded-card-small mr-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mdg-off-active">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-10">
                                                        <Switch
                                                            size="small"
                                                            checked={last_position}
                                                            onChange={() => {
                                                                dispacth(MapAction.ToogleNetwork('last_position'))
                                                            }}
                                                        />
                                                        <span className="ml-2 mdg-text-white mdg-12-text">
                                                            <Language word={"Setting_Option.Derniere_Position"}></Language>
                                                        </span>
                                                    </div>
                                                    <div className="col-2">
                                                        <img
                                                            src="/assets/image/lastp.png"

                                                            alt=""
                                                            style={{
                                                                width: "20px"
                                                            }}
                                                            className="rounded-card-small mr-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mdg-off-active d-none">
                                            <div className="col-lg-12 ">
                                                <div className="row">
                                                    <div className="col-10 ">
                                                        <Switch
                                                            size="small"
                                                            checked={wfm}
                                                            onChange={() => {
                                                                dispacth(MapAction.ToogleNetwork('wfm'))
                                                            }}
                                                        />
                                                        <span className="ml-2 mdg-text-white mdg-12-text">
                                                            WFM
                                                        </span>
                                                    </div>
                                                    <div className="col-2">
                                                        <img
                                                            src="/assets/image/localisateur.png"
                                                            width="10"
                                                            alt=""
                                                            height="10"
                                                            className="rounded-card-small mr-1"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="mdg-list-item">
                                <h5 className="mdg-text-white mdg-setting-title">
                                    <Language word={"Setting_Option.VirtualAirline"}></Language>
                                </h5>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={metaflight}
                                                    onChange={() => {
                                                        dispacth(MapAction.ToogleNetwork('metaflight'))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">Metaflight</span>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/6.blue.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-small mr-1 mdg-incline-image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={afrv}
                                                    onChange={() => {
                                                        dispacth(MapAction.ToogleNetwork('afrv'))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">AFRV</span>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/6.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-small mr-1 mdg-incline-image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="mdg-list-item">
                                <h5 className="mdg-text-white mdg-setting-title">
                                    <Language word={"Setting_Option.Network"}></Language>
                                </h5>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={ivao}
                                                    onChange={() => {
                                                        dispacth(MapAction.ToogleNetwork('ivao'))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">IVAO</span>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/ivao-flight.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-small mr-1 mdg-incline-image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={vatsim}
                                                    onChange={() => {
                                                        dispacth(MapAction.ToogleNetwork('vatsim'))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">VAT SIM</span>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/vat-sim.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-small mr-1 mdg-incline-image"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={airport}
                                                    onChange={() => {
                                                        dispacth(MapAction.ToogleNetwork('airport'))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Airports_actif"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <EnvironmentOutlined style={{ color: "red" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="mdg-list-item">
                                <h5 className="mdg-text-white mdg-setting-title">
                                    <Language word={"Setting_Option.Weather"}></Language>
                                </h5>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={light}
                                                    onChange={() => {

                                                        dispacth(MapAction.ToogleWeather("light"))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Day_Night"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <i className="fa fa-adjust" aria-hidden="true" style={{ color: "#fff" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={cloud}
                                                    onChange={(checked) => {
                                                        dispacth(MapAction.ToogleWeather("cloud"))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Cloud"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <i className="fa fa-cloud" aria-hidden="true" style={{ color: "#fff" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={precipitation}
                                                    onChange={(checked) => {
                                                        dispacth(MapAction.ToogleWeather("precipitation"))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Precipitation"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/rain.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-sm mr-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={temperature}
                                                    onChange={(checked) => {
                                                        dispacth(MapAction.ToogleWeather("temperature"))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Temperature"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <i className="fa fa-thermometer-full" aria-hidden="true" style={{ color: "#fff" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={wind}
                                                    onChange={(checked) => {
                                                        dispacth(MapAction.ToogleWeather("wind"))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Wind"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/wind-turbine.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-sm mr-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={snow}
                                                    onChange={(checked) => {
                                                        dispacth(MapAction.ToogleWeather("snow"))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Snow"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <i className="fa fa-snowflake-o" aria-hidden="true" style={{ color: "#fff" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mdg-off-active">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-10">
                                                <Switch
                                                    size="small"
                                                    checked={pressure}
                                                    onChange={(checked) => {
                                                        dispacth(MapAction.ToogleWeather("pressure"))
                                                    }}
                                                />
                                                <span className="ml-2 mdg-text-white mdg-12-text">
                                                    <Language word={"Setting_Option.Pressure"}></Language>
                                                </span>
                                            </div>
                                            <div className="col-2">
                                                <img
                                                    src="/assets/image/resilience.png"
                                                    width="10"
                                                    alt=""
                                                    height="10"
                                                    className="rounded-card-sm mr-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '250px' }}>
                        <h2 className="mdg-text-white">562</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Right;
