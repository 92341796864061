import React from 'react';
import BaseBO from '../templates/bo/BaseBo';

import StoreCategoryForm from './StoreCategoryForm';
import StoreCategoryList from './StoreCategoryList';

const StoreCategoryComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <StoreCategoryForm action="Create"></StoreCategoryForm> ;break;
        case "edit": page = <StoreCategoryForm action="Edit"></StoreCategoryForm> ; break;
        default: page = <StoreCategoryList></StoreCategoryList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default StoreCategoryComponent;