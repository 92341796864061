import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import PackService from "../../../services/pack";
import PromocodeService from "../../../services/promocode";
import SubscriptionService from "../../../services/subscription";
import { getFormatedDate, selectStyles } from "../../../Utils/Common";

const PromocodeForm = (props) => {
  const { id } = useParams();
  const actionName = props.action;
  const [code, setCode] = useState("");
  const [type, setType] = useState("");
  const [codeValue, setCodeValue] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isInfinite, setIsInfinite] = useState(true);

  const [packs, setPacks] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const [allPacks, setAllPacks] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);

  const typeList = [
    {
      label: "Fixed",
      value: 0,
    },

    {
      label: "Percent",
      value: 1,
    },
  ];

  const handleCreatePromocode = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("code", code);
    formData.append("type", type);
    formData.append("value", codeValue);
    if(!isInfinite){
      formData.append("expiryDate", expiryDate);
    }
    

    let packIds = [];
    packs.forEach((elt) => {
      packIds.push(elt.value);
    });

    formData.append("packs", JSON.stringify(packIds));

    let subIds = [];
    subscriptions.forEach((elt) => {
      subIds.push(elt.value);
    });

    formData.append("subscriptions", JSON.stringify(subIds));

    let result;
    if (id) {
      result = await PromocodeService.update(id, formData);
    } else {
      result = await PromocodeService.create(formData);
    }

    if (result.error) {
      swal(result.response.data.data.message, {
        icon: "error",
      });
    } else {
      swal(result.data.message, {
        icon: "success",
      });
    }
  };

  const getPromocode = async () => {

    if (id) {
      const result = await PromocodeService.getById(id);
      if (!result.error) {
        const data = result.data;
        setCodeValue(data.value);
        setCode(data.code);
        data.expiryDate ? setIsInfinite(false) : setIsInfinite(true);
        data.expiryDate ? setExpiryDate(getFormatedDate(data.expiryDate)) : setExpiryDate("");
        setType(data.type === 'FIXED' ? 0 : 1);
        const loadedPacks = data.packs;
        const loadedSubs = data.subscriptions;
        let packsData = [];
        let subsData = [];

        loadedPacks.forEach((el) => {
          if (el.pack) {
            packsData.push({ label: el.pack.name, value: el.pack.id });
          }
        });

        setPacks(packsData);

        loadedSubs.forEach((el) => {
          if (el.subscription) {
            subsData.push({ label: el.subscription.name + " / " + el.subscription.type, value: el.subscription.id });
          }
        });

        setSubscriptions(subsData);
      }
    }
  };

  const getAllPacks = async () => {
    const allpack = await PackService.getAllAsKeyValue();
    setAllPacks(allpack);
  };

  const getAllSubscriptions = async () => {
    const allSubs = await SubscriptionService.getAllAsKeyValue();
    setAllSubscriptions(allSubs);
  };


  useEffect(() => {
    getAllPacks();
    getAllSubscriptions();
    getPromocode();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{id ? "Edit Promocode" : "Create new Promocode"}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Promocode</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {id ? "Edit Promocode Information" : "New Promocode"}
                  </h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/promocode/"
                      type="button"
                      className="btn btn-tool bg-info"
                      
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => handleCreatePromocode(e)}>
                        <div className="row">
                          {/* <div className="col-lg-6"></div> */}
                          <div className="col-lg-12">
                            <div className="row">

                              <div className="col-lg-6">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-parent-id"
                                    type="text"
                                    placeholder=" "
                                    value={code}
                                    readOnly={id ? true : false}
                                    onChange={(event) => {
                                      setCode(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-category-parent-id">
                                    Code *
                                  </label>
                                </div>   </div>
                              <div className="col-lg-6">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-name"
                                    type="number"
                                    placeholder=" "
                                    style={{ color: "#fff" }}
                                    value={codeValue}
                                    onChange={(event) => {
                                      setCodeValue(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-name">
                                    Value *
                                  </label>
                                </div>    </div>
                              <div className="col-lg-6">
                                <div className="mdg-textfield-out-lined">
                                  <div className="mdg-textfield-out-lined">
                                    <Select
                                      placeholder="Choose a type *"
                                      options={typeList}
                                      styles={selectStyles}
                                      isClearable={true}
                                      style={{ color: "#fff" }}
                                      onChange={(newValue, actionMeta) => {
                                        newValue != null
                                          ? setType(newValue.value)
                                          : setType(null);
                                      }}
                                      value={typeList[type]}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                {allPacks && (

                                  <div className="mdg-textfield-out-lined">
                                    <Select
                                      placeholder="Choose packs"
                                      style={{ color: "#fff" }}
                                      closeMenuOnSelect={false}
                                      isMulti
                                      isClearable={true}
                                      onChange={async (
                                        newValue,
                                        actionMeta
                                      ) => {
                                        newValue && setPacks(newValue);
                                      }}
                                      options={allPacks}
                                      styles={selectStyles}
                                      value={packs}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6">
                                {allSubscriptions && (

                                  <div className="mdg-textfield-out-lined">
                                    <Select
                                      placeholder="Choose subscriptions"
                                      style={{ color: "#fff" }}
                                      closeMenuOnSelect={false}
                                      isMulti
                                      isClearable={true}
                                      onChange={async (
                                        newValue,
                                        actionMeta
                                      ) => {
                                        newValue && setSubscriptions(newValue);
                                      }}
                                      options={allSubscriptions}
                                      styles={selectStyles}
                                      value={subscriptions}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <input type="checkbox" checked={isInfinite} id="id-is-infinite" onChange={(e) => { setIsInfinite((isInfinite => !isInfinite)) }} />
                                    <label htmlFor="id-is-infinite" className="ml-2">No Expiry date</label>
                                  </div>
                                  <div className="col-lg-6">
                                    {
                                      !isInfinite && (
                                        <div className="mdg-textfield-out-lined">
                                          <input
                                            id="input-date-exp"
                                            type="date"
                                            placeholder=" "
                                            style={{ color: "#fff" }}
                                            value={expiryDate}
                                            onChange={(event) => {
                                              setExpiryDate(event.target.value);
                                            }}
                                            required
                                          />
                                          <label htmlFor="input-date-exp">
                                            Expiry date *
                                          </label>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 text-right mt-2">
                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="offset-lg-11 mt-1"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PromocodeForm;
