import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import NoticesForm from './NoticesForm';
// import ItemTypeForm from './ItemTypeForm';
// import ItemTypeList from './ItemTypeList';
import NoticesList from './NoticesList';

const NoticesComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <NoticesForm action="Create"></NoticesForm> ;break;
        case "edit": page = <NoticesForm action="Edit"></NoticesForm> ; break;
        default: page = <NoticesList></NoticesList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default NoticesComponent;