import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import DashboardBO from './DashboardBO';

const DashboardComponent = (props) => {
    return (
        <div>
            <BaseBO page={<DashboardBO/>}></BaseBO>
        </div>
    );
};

export default DashboardComponent;