import React, { useState } from "react";
import LocationTable from "./LocationTable";

const LocationList = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">List of Location</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Location</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Location</h5>
                  {/*<div className="card-tools">
                    {isLoading ? (
                      <button type="button" className="btn btn-tool bg-info">
                        Refreshing .....
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-tool bg-info"
                        onClick={async (event) => {
                          event.preventDefault();
                          setIsLoading(true);
                          const response =
                            await LocationService.refreshLocations();
                          if (response) {
                            swal
                              .fire({
                                text: `${response.data.message}`,
                                icon: response.data.error
                                  ? "warning"
                                  : "success",
                                confirmButtonText: "Ok",
                              })
                              .then((value) => {
                                setIsLoading(false);
                              });
                          } else {
                            swal
                              .fire({
                                text: `Server error`,
                                icon: "warning",
                                confirmButtonText: "Ok",
                              })
                              .then((value) => {
                                setIsLoading(false);
                              });
                          }
                        }}
                      >
                        Refresh
                      </button>
                    )}
                      </div> */}
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 mdg-historique-table">
                      <LocationTable refresh={isLoading}></LocationTable>
                    </div>
                  </div>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LocationList;
