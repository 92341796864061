import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import AwardService from "../../../services/award";
import { getImageUrl } from "../../../Utils/Common";
import AwardConfigForm from "./AwardConfigForm";

const ListCondition = ({ arrayCondition, appendCondition, setCondition, deleteCondition, setAward }) => {
    return (
        <>
            {arrayCondition.map((elt, i) => (
                <AwardConfigForm
                    condition={elt}
                    key={i}
                    i={i}
                    arrayConditionLength={arrayCondition.length}
                    appendCondition={appendCondition}
                    arrayCondition={arrayCondition}
                    setCondition={setCondition}
                    deleteCondition={deleteCondition}
                    setAward={setAward}
                >
                </AwardConfigForm>
            ))}
        </>
    )
}

const AwardForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
    const [image, setImage] = useState("");
    const [name, setName] = useState("");
    const [listCondition, setListCondition] = useState("");
    const [arrayCondition, setArrayCondition] = useState([["", ""]]);
    const [changeCondition, setChangeCondition] = useState(false);

    const appendCondition = () => {
        let temp = arrayCondition;
        temp.push(["", ""]);

        setArrayCondition(temp);
        setChangeCondition(!changeCondition);
    }

    const setCondition = (i, value) => {
        let temp = arrayCondition;
        temp[i][0] = value;
        setArrayCondition(temp);
    }

    const setAward = (i, value) => {
        let temp = arrayCondition;
        temp[i][1] = value;
        setArrayCondition(temp);
    }

    const deleteCondition = (index) => {
        let temp = [];
        for (let i = 0; i < arrayCondition.length; i++) {
            if (i !== index) {
                temp.push(arrayCondition[i])
            }
        }
        setArrayCondition(temp);
        setChangeCondition(!changeCondition);
    }

    const getScenario = async () => {
        if (id) {
            const result = await AwardService.getById(id);
            if (!result.error) {
                const data = result.data.data;
                if (data.image) setPhotoUrl(getImageUrl(data.image)); 
                setName(data.name); 
                const loadedConditionType = data.awardConfigs;
                let arrayConditionType = [];
                if (loadedConditionType.length > 0) {
                    loadedConditionType.forEach((el) => {
                        if (el.awardConditionType) {
                            let data = el.data;
                            arrayConditionType.push([el.awardConditionType, data]);
                        }
                    });
                    setArrayCondition(arrayConditionType);
                    setChangeCondition(!changeCondition);
                }
            }
        }
    };

    const handleChangeImage = () => {
        const pics = document.getElementById("id-award-pics");
        pics.click();
    };

    useEffect(() => {
        getScenario();
    }, []);

    useEffect(() => {
        setListCondition(<ListCondition arrayCondition={arrayCondition} appendCondition={appendCondition} setCondition={setCondition} setAward={setAward} deleteCondition={deleteCondition} ></ListCondition>)
    }, [changeCondition])

    const createScenarioHandler = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('photo', image);
        formData.append('name', name);
        formData.append("arrayCondition", JSON.stringify(arrayCondition));

        let result;


        if (id) {
            result = await AwardService.update(id, formData);
        } else {
            result = await AwardService.create(formData);
        }

        if (result.error) {
            swal(result.response.data.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Award</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to={``}>Admin</NavLink></li>
                                <li className="breadcrumb-item active">Award</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">New Award</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/award/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createScenarioHandler(e)}>
                                                <div className="row">
                                                    <div className="mdg-card-body mdg-row">
                                                        <div className="col-lg-3">
                                                            <div className="mdg-col-sm-12 text-center">
                                                                <input
                                                                    className="mdg-d-none"
                                                                    type="file"
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    id="id-award-pics"
                                                                    onChange={(event) => {
                                                                        setImage(event.target.files[0]);
                                                                        setPhotoUrl(
                                                                            URL.createObjectURL(event.target.files[0])
                                                                        );
                                                                    }}
                                                                />
                                                                <img
                                                                    onClick={handleChangeImage}
                                                                    className="mdg-upload-img"
                                                                    src={photoUrl && photoUrl}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-1"></div>
                                                        <div className="col-lg-8">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-scenario-title" className="mdg-normal-label">
                                                                        Name *
                                                                    </label>
                                                                    <input
                                                                        id="input-scenario-title"
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={name}
                                                                        onChange={(event) => {
                                                                            setName(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>

                                                                <div className="col-lg-12">
                                                                    {listCondition}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 text-right mt-2">
                                                            <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default AwardForm;
