import React, { useEffect, useState } from "react";
import MetaCart from "../../../../actions/cartActions";
import { getFormatedAmount } from "../../../../Utils/Common";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import translate from "../../../extra/language/translate";
import Language from "../../../extra/language/language";

const Cart = ({ cart }) => {
  const [total,setTotal] = useState(0);
  const [subtotal,setSubtotal] = useState(0);
  
  const dispatch = useDispatch();
  
  const lang = useSelector((state) => state.language);
  
  const emptyCart = () => {
    Swal.fire({
      icon: "question",
      title: translate('Cart.Confirmation',lang),
      text: translate('Cart.Do_really_want_to_empty_your_cart',lang),
      showCancelButton: true,
    }).then((res) => {
      if (res.isConfirmed) {
        dispatch(MetaCart.EmptyCart());
      }
    });
  };

  useEffect(() => {
      setTotal(MetaCart.getCartTotal(cart)) ;
      setSubtotal( MetaCart.getCartSubTotal(cart));
  },[cart])

  return (
    <ul className="list-group mb-3">
      <li className="mdg-list-group-item d-flex justify-content-between lh-condensed">
        <div>
          <h6 className="my-0">
            {" "}
            {cart.hasSubsrciption ? <Language word={"Header.Subscription"}></Language> : " "}{" "}
          </h6>
        </div>
        <span>
          <button className="btn btn-sm mdg-btn-xs btn-danger" onClick={emptyCart}>
            {" "}
            <i className="fa fa-trash"></i>
          </button>
        </span>
      </li>
      <div className="mdg-height-cart-panier">
        {cart &&
          cart.Carts &&
          cart.Carts.map((item, i) => (
            <li
              className="mdg-list-group-item d-flex justify-content-between lh-condensed "
              key={i}
            >
              <div className="col-lg-4">
                <small className="text-muted">
                  {item.name}{" "}
                  {!cart.hasSubscription && (
                    <>
                      (x{item.quantity})
                    </>
                  )}{" "}
                </small>
              </div>
              {
                item.type !== 'level' && (
<div className="col-lg-4">
                <div className="row justify-content-md-center mdg-min-h-panier">
                  <div className="col-lg-12">
                    <div className="form-group form-spinner">
                      <div className="spinner-control">
                        <button
                          type="button"
                          className="btn btn-muted btn-sm btn-decrement"
                          onClick={(event) => {
                            if (item.quantity > 0) {
                              dispatch(MetaCart.DecreaseQuantity(i))
                            }
                          }}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        <input
                          type="text"
                          data-type="number"
                          step="1"
                          min="1"
                          value={item.quantity}
                          readOnly
                          className="mdg-up-categorie"
                        />
                        <button
                          type="button"
                          className="btn btn-muted btn-sm btn-increment"
                          onClick={(e) => {
                            dispatch(MetaCart.IncreaseQuantity(i))
                          }}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                )
              }
              
              <div className="col-lg-4">
                <span className="float-right">
                  {getFormatedAmount(item.price * item.quantity)} €
                </span>
              </div>
            </li>
          ))}
      </div>
      
      <li className="mdg-list-group-item d-flex justify-content-between mdg-border-dashed">
        <span className="mdg-text-white"><Language word={"Cart.Subtotal"}></Language></span>
        <strong className="mdg-text-white"> {getFormatedAmount(subtotal)} €</strong>
      </li>
      {
        cart.coupons.map((coupon, i) => {
          return (
         
              <li className="mdg-list-group-item d-flex justify-content-between mdg-border-dashed" key={i}>
                <div className="col-lg-10">
                  <span className="text-muted"><Language word={"Cart.Promo_code"}></Language> <small> {coupon.code} </small> : <small className="mdg-color-text-meta">- {coupon.value} { coupon.type === "FIXED" ? "€" : "%"} </small></span>
                </div>
                <strong>
                  <button className="btn btn-sm btn-muet" onClick={ (e) => { dispatch(MetaCart.RemoveCoupon(coupon.code))}}>
                    <i className="fa fa-times"></i>
                  </button>
                </strong>
              </li>
          
          )
        })
      }

      <li className="mdg-list-group-item d-flex justify-content-between mdg-color-text-meta mdg-border-end">
        <span><Language word={"Cart.Total"}></Language></span>
        <strong> {getFormatedAmount(total)} €</strong>
      </li>
    </ul>
  );
};

export default Cart;
