import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { getImageUrl, tableRowStyle } from "../../../Utils/Common";
import swal from 'sweetalert'
import { NavLink } from "react-router-dom";
import ItemService from "../../../services/Item";
import Thumbnail from "../../extra/img/thumbnail";


const ItemTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [deleted, setDeleted] = useState([]);

  const removeItem = (array, item) => {
    const newArray = array.slice();
    newArray.splice(newArray.findIndex(a => a === item), 1);
  
    return newArray;
  };

  
  const getList = async () => {
    setLoading(true);

    const filter = {
        page: currentPage - 1,
        size: perPage,
        search : search
    }

    const response = await ItemService.getList(filter);
    if(!response.error){
        setData(response.data.items);
        setTotalRows(response.data.totalItems);
    }
    
    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, [currentPage,perPage]);

  const handleDelete = useCallback(
    row => async () => {
       
        swal(`Are you sure to delete the item : ${row.name}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(value => {
            if (value) {
                ItemService.deleteItem(row.id).then((res) => {
                    if (res.error) {
                        swal("Deleted!", "An error was occured!", "error");
                    }
                    else {
                        swal("Deleted!", "Item deleted with success!", "success");
                        getList();
                    }
                });
            }
        })
    },
    [currentPage, perPage, totalRows]
  );

  const changeItemStatus = async(id) =>{
    const result = await ItemService.changeItemStatusService(id);
    if (result.error) {
        swal(result.response.data.message, {
            icon: "warning",
            buttons: {
                confirm: "OK",
            },
        }).then((value) => {
           
        });
    } else {
        
    }
}

  const columns = useMemo(
    () => [
        {
            name: "Media",
            selector: (row) => row.media,
            sortable: false,
            reorder: false,
            cell: (row) => (
                <Thumbnail src={getImageUrl(row.media)}></Thumbnail>
            )
        },
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            name: "Quantity",
            selector: (row) => row.stock,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            name: "Sold",
            selector: (row) => row.selled,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            name: "Category",
            selector: (row) => row.category?.categoryName,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            name: "Status",
            left: true,
            cell:(row) => (
                <input
                    type="checkbox"
                    defaultChecked={row.status?true:false}
                    onClick={(e) =>{
                        changeItemStatus(row.id);
                    }}
                />
            )
        },
        {
            
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/item/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
           
            button: true,
            left: true,
            cell: (row) => (
                row.selled == 0 && 
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={handleDelete(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ],
    [handleDelete]
  );

  const handlePageChange = page => {
        setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <DataTable
      title=""
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => { console.log(selectedRows) } }
      conditionalRowStyles= {tableRowStyle}
    />
  );
};


export default ItemTable;

