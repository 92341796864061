import React from 'react'
import Select from 'react-select';
import CategoryService from '../../../../services/Category';
import ItemService from '../../../../services/Item';
import { selectStylesSwal } from '../../../../Utils/Common';
import Language from '../../../extra/language/language';

class ExchangeForm extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
        categoryId: undefined,
        itemId: undefined,
        priceMin: 1,
        priceMax: 1000,
        categoryList : [],
        itemList : []
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleFilterCategory = (e) => {
       
    }

    handleFilterItem = (e) => {
      
    }

    getCategories = async() => {
        const all = await CategoryService.getAvailableCategoryListAsKeyValueService();
        this.setState({ categoryList : all });
    }

    getItems = async() => {
        const all = await ItemService.getItemAsKeyValue();
        this.setState({ itemList : all });
    }

    componentDidMount() {
        this.getItems();
        this.getCategories();
    }

    componentDidUpdate() {
        this.props.setExchange( {
            categoryId: this.state.categoryId,
            itemId: this.state.itemId,
            priceMin: this.state.priceMin,
            priceMax: this.state.priceMax
        });
    }

    render() {
        return (
            <div className="row">
                <div className="col-lg-6">
                    <div className="mdg-textfield-out-lined">
                        <input id="id-min-price" type="number" min="1" max={this.state.priceMax} className="mdg-text-white" name="priceMin" placeholder=" " value={this.state.priceMin} onChange={this.onChange} />
                        <label htmlFor="id-min-price"><Language word={"Seller.Min_price"}></Language></label>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="mdg-textfield-out-lined">
                        <input id="id-max-price" type="number" min={this.state.priceMin} className="mdg-text-white" name="priceMax" placeholder=" " value={this.state.priceMax} onChange={this.onChange} />
                        <label htmlFor="id-max-price"><Language word={"Seller.Max_price"}></Language></label>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="mdg-textfield-out-lined">
                        <Select
                            options={this.state.categoryList}
                            filterOptions={this.handleFilterCategory}
                            name="category"
                            styles={selectStylesSwal}
                            placeholder="Choose an category"
                            search
                            isClearable={true}
                            onChange={(newValue) => { this.setState({ categoryId : newValue.value})}}
                            id="id-category"
                        />

                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="mdg-textfield-out-lined">
                        <Select
                            options={this.state.itemList}
                            filterOptions={this.handleFilterItem}
                            name="item"
                            styles={selectStylesSwal}
                            placeholder="Choose an item"
                            search
                            isClearable={true}
                            onChange={(newValue) => ( this.setState({ itemId : newValue.value} ) )}
                            id="id-item"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default ExchangeForm