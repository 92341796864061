import { useEffect, useState } from "react";
import MissionService from "../../../../services/missions/mission";
import { getFormatedDate } from "../../../../Utils/Common";
import Thumbnail from "../../../extra/img/thumbnail";
import Language from "../../../extra/language/language";
import MissionElement from "./missionElement";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";

const MissionList = () => {
  
  const authObj = useSelector((store) => store.auth);
  const { user } = authObj;
  const [missions, setMissions] = useState([]);
  const [mission, setMission] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isClMission, setIsClMission] = useState(false);
  const [search, setSearch] = useState("");
  const [initData, setinitData] = useState([]);
  const [sortBy, setSortBy] = useState("fcBonus");

  const getMissions = async () => {
    const res = await MissionService.getAllMissionService(sortBy);
    if (!res.error) {
      setinitData(res.data);
      setMissions(res.data);
    }
  };

  const handleModal = (mission) => {
    setMission(mission);
    setShowModal(true);
  };

  useEffect(() => {
    getMissions();
  }, [sortBy]);

  return (
    <div className="container mdg-top-container">
      <div className="mdg-title-ingame row">
        <div className="col-sm">
          <span className="mdg-h2">Missions</span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          {/* <span className="mdg-text-white">
            <Language word={"Ingame_store.Sort_by"}></Language> :
          </span> */}
          <div className="select-search">
            <select
              className="mdg-select-search"
              onChange={(val) => {
                setSortBy(val.target.value);
                if (isClMission === true) {
                  const newData = initData.filter((i) => i.carreerLevelId <= user.level.id);
                  setMissions(newData)
                } else {
                  setMissions(initData)
                }
              }}
            >
              <option value="fcBonus">FC</option>
              <option value="startDate">Date</option>
              <option value="missionTypeId">
                <Language word={"Mission.Mission_type"}></Language>
              </option>
            </select>
          </div>
        </div>
        <div className="col-lg-6 text-center">
          <Switch
            onChange={(e) => {
              const newData = initData.filter((i) => i.carreerLevelId <= user.level.id);
              setIsClMission(e)
              if (e === true) {
                setMissions(newData);
              } else {
                setMissions(initData);
              }
            }}
          />
          <small className="ml-2 mdg-text-white">
            <Language word={"Mission.Show_only_cl_missions"}></Language>
          </small>
        </div>
        <div className="col-lg-2">

          <div className="mdg-search-place">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search "
              style={{ color: "#fff" }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                let newData;
                if (isClMission === true) {
                  newData = initData.filter((i) =>
                    `${i.title} ${i.description}`
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase() && i.carreerLevelId <= user.level.id)
                  );
                } else {
                  newData = initData.filter((i) =>
                    `${i.title} ${i.description}`
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  );
                }
                setMissions(newData);
              }}
              className="mdg-search-input"
            />
            <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      {missions.length > 0 && (
        <div className="mdg-width-card-item p-3 ">
          <div className="col-lg-10 col-lg-offset-1 offset-lg-1">
            {missions.map((mission, i) => (
              <MissionElement
                mission={mission}
                key={i}
                handleModal={handleModal}
                setShowModal={setShowModal}
              ></MissionElement>
            ))}
          </div>
        </div>
      )}
      {missions.length > 0 && mission && (
        <div
          className={
            showModal === true ? "mdg-center mdg-card-meta-modal" : "mdg-d-none"
          }
        >
          <div className="mdg-content-meta-modal">
            <div className="mdg-p-modal">
              <div className="row">
                <div className="col-lg-12 mt-2">
                  <h2 className="mdg-text-meta-color text-center mdg-uppercase">
                    {mission.title}
                  </h2>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-lg-12 mt-2">
                  <h4 className="mdg-text-meta-color text-center ">
                    <Language word={"Mission.Flight"}></Language>
                  </h4>
                </div>
                <div className="col-lg-12 text-center">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mdg-text-meta-color">
                            <Language word={"Mission.Depart"}></Language>
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          <ul>
                            {mission.MissionDepAirports.map((obj, i) => (
                              <li className="mdg-text-white">
                                {obj.location.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mdg-text-meta-color">
                            <Language word={"Mission.Arrival"}></Language>
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          <ul>
                            {mission.MissionArrAirports.map((obj, i) => (
                              <li className="mdg-text-white">
                                {obj.location.label}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mdg-text-meta-color">
                            <Language word={"Mission.numFlight"}></Language>
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          <bold className="mdg-text-white">{mission.vol}</bold>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 text-center mt-5">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mdg-text-meta-color">
                        <Language word={"Mission.Requirements"}></Language>
                      </h4>
                    </div>
                    <div className="col-lg-4 mdg-text-white">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mdg-text-meta-color">
                            <Language word={"Mission.PilotLevel"}></Language>
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          {mission.carreerLevel.level}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mdg-text-white">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mdg-text-meta-color">Cargo</h5>
                        </div>
                        <div className="col-lg-12">
                          <ul>
                            {mission.MissionCargoTypes.map((obj, i) => (
                              <li className="mdg-text-white">
                                {" "}
                                {obj.cargoType.type} ( X {obj.quantity} )
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mdg-text-meta-color">
                            <Language word={"Mission.AviationType"}></Language>
                          </h5>
                        </div>
                        <div className="col-lg-12">
                          <ul>
                            {mission.MissionAviationTypes.map((obj, i) => (
                              <li className="mdg-text-white">
                                {obj.aviationType.type}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mdg-text-white">
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="mdg-text-meta-color">
                            <Language word={"Mission.Other"}></Language>
                          </h5>
                        </div>
                        <div className="col-lg-12 ">
                          <div className="row">
                            <div className="col-lg-4">
                              <ul>
                                <li className="mdg-text-white">
                                  <Language
                                    word={"Mission.passenger"}
                                  ></Language>{" "}
                                  :{" "}
                                  <span
                                    className={
                                      mission.embarqueUser
                                        ? "fa fa-check text-green"
                                        : "fa fa-close text-danger"
                                    }
                                  ></span>
                                </li>
                                <li className="mdg-text-white">
                                  <Language
                                    word={"Mission.Recurrent"}
                                  ></Language>{" "}
                                  :{" "}
                                  <span
                                    className={
                                      mission.isRecurrent
                                        ? "fa fa-check text-green"
                                        : "fa fa-close text-danger"
                                    }
                                  ></span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-4">
                              <ul>
                                <li className="mdg-text-white">
                                  <Language
                                    word={"Mission.ExactStartDate"}
                                  ></Language>
                                  :{" "}
                                  <span
                                    className={
                                      mission.isExactStartDate
                                        ? "fa fa-check text-green"
                                        : "fa fa-close text-danger"
                                    }
                                  ></span>
                                </li>
                                <li className="mdg-text-white">
                                  <Language
                                    word={"Mission.ExcatStartHour"}
                                  ></Language>{" "}
                                  :{" "}
                                  <span
                                    className={
                                      mission.isExactStartHour
                                        ? "fa fa-check text-green"
                                        : "fa fa-close text-danger"
                                    }
                                  ></span>
                                </li>
                              </ul>
                            </div>
                            <div className="col-lg-4">
                              <ul>
                                <li className="mdg-text-white">
                                  <Language
                                    word={"Mission.ExactEndDate"}
                                  ></Language>{" "}
                                  :{" "}
                                  <span
                                    className={
                                      mission.isExactEndDate
                                        ? "fa fa-check text-green"
                                        : "fa fa-close text-danger"
                                    }
                                  ></span>
                                </li>
                                <li className="mdg-text-white">
                                  <Language
                                    word={"Mission.ExcatEndHour"}
                                  ></Language>{" "}
                                  :{" "}
                                  <span
                                    className={
                                      mission.isExactEndHour
                                        ? "fa fa-check text-green"
                                        : "fa fa-close text-danger"
                                    }
                                  ></span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-between mt-5">
                <div className="col-lg-12 text-center">
                  <h4 className="mdg-text-meta-color">Bonus</h4>
                  <ul>
                    <li className="mdg-text-white">
                      <Language word={"Mission.xpPoint"}></Language> :{" "}
                      {mission.xp}{" "}
                    </li>
                    {mission.fcBonus && (
                      <li className="mdg-text-white">FC : {mission.fcBonus}</li>
                    )}{" "}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="mdg-title-modal text-justify">
                  <span className="mdg-text-white"></span>
                </div>
                <div className="mdg-modal-footer col-lg-12">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={(e) => {
                      setShowModal(false);
                    }}
                  >
                    <Language word={"Notice.Close"}></Language>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MissionList;
