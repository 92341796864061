const ItemBenefits = ({ item }) => {
    return (
        
        <div className="d-none">
            {/* TODO_MILES */}
            {
                item.conditionXMilesFixedBonus && (
                    <p>
                        <img
                            src="/assets/image/miles-coin.png"
                            width="30"
                            height="30"
                            className="rounded-circle-sm mr-1"
                        />
                        <small className="mdg-text-white">
                            {item.xMilesFixedBonus} miles fixed bonus
                        </small>
                    </p>
                )
            }
            {
                item.conditionXMilesPerDayForXDays && (
                    <p>
                        <img
                            src="/assets/image/miles-coin.png"
                            width="30"
                            height="30"
                            className="rounded-circle-sm mr-1"
                        />
                        <small className="mdg-text-white">
                            {item.xMilesPerDayForXDaysMiles} miles per day{item.xMilesPerDayForXDaysDays > 0 && (<> for {item.xMilesPerDayForXDaysDays} days</>)}.
                        </small>
                    </p>
                )
            }
            {
                item.conditionXMilesPerHourOfFlightForXDays && (
                    <p>
                        <img
                            src="/assets/image/miles-coin.png"
                            width="30"
                            height="30"
                            className="rounded-circle-sm mr-1"
                        />
                        <small className="mdg-text-white">
                            {item.xMilesPerHourOfFlightForXDaysMiles} miles per hour of flight{item.xMilesPerHourOfFlightForXDaysDays > 0 && (<> for {item.xMilesPerHourOfFlightForXDaysDays} days</>)}.
                        </small>
                    </p>
                )
            }</div>
    )
}

export default ItemBenefits