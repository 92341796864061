import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import HourZulu from "../../../extra/hour/HourZulu";
import Language from "../../../extra/language/language";
import { setLanguage } from "../../../../actions/language";

const NotConnectedNav = () => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.language);
    const [expandedNotif, setExpandedNotif] = useState(false);

    const translation = (language) => {
        dispatch(setLanguage(language));
        setExpandedNotif((expandedNotif) => !expandedNotif);
    }
    return (
        <>
            <span className="">
                <li
                    className={
                        expandedNotif
                            ? "nav-item dropdown show mdg-notif"
                            : "nav-item dropdown mdg-notif"
                    }
                >
                    <NavLink
                        className="nav-link dropdown-toggle"
                        to=""
                        onClick={(e) => {e.preventDefault();setExpandedNotif((expandedNotif) => !expandedNotif)}}
                        aria-expanded={expandedNotif}
                    >
                        <small className="">
                            <img
                                src={lang === 'en' ? "/assets/image/united-states.png" : "/assets/image/france.png"}
                                alt="logo language"
                                className="mdg-flag"
                            />
                        </small>
                    </NavLink>

                    <div
                        className={expandedNotif ? "dropdown-menu show" : "dropdown-menu"}
                        style={{ backgroundColor: "#081217", color: "#fff" }}
                    >

                        <NavLink
                            className="dropdown-item"
                            to="#"
                            style={{ color: "#fff" }}
                            onClick={((e) => {e.preventDefault(); translation('en')})}
                        >
                            <small className="mdg-use-language">
                                <img
                                    src={"/assets/image/united-states.png"}
                                    alt="logo language"
                                    className="mdg-flag"
                                /><span> English</span>
                            </small>
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to="#"
                            style={{ color: "#fff" }}
                            onClick={((e) => {e.preventDefault(); translation('fr')})}
                        >
                            <small className="mdg-use-language">
                                <img
                                    src={"/assets/image/france.png"}
                                    alt="logo language"
                                    className="mdg-flag"
                                /><span> Français</span>
                            </small>
                        </NavLink>
                    </div>
                </li>
            </span>
            <span className="ml-3 mr-3 mt-2">
                <HourZulu />
            </span>
            <span className="mr-1 mt-2">
                <NavLink to="/login" className={(navData) => navData.isActive ? "mdg-a-navig mdg-not-connected active" : "mdg-a-navig mdg-not-connected"}>
                    <i className="fa fa-sign-in mr-2"></i>
                    <small><Language word={"Header.Sign_In"}></Language></small>
                </NavLink>
            </span>
            <span className="mdg-border-link mr-2 mt-2">
                <NavLink to="/register" className={(navData) => navData.isActive ? "mdg-a-navig mdg-not-connected active" : "mdg-a-navig mdg-not-connected"}>
                    <i className="fa fa-user-plus mr-2"></i>
                    <small><Language word={"Header.Sign_Up"}></Language></small>
                </NavLink>
            </span>
        </>
    )
}

export default NotConnectedNav;