import { getJSON } from "jquery";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocations } from "../../../../services/base/location";
import { getSimulators } from "../../../../services/base/simulator";
import { getUserAvailableRoles } from "../../../../services/role";
import UserService, {
    updateUserInfoService,
    updateUserPaymentDataInfoService,
} from "../../../../services/user"; import Language from '../../../extra/language/language';
import swal from "sweetalert2";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import { updateUserInfo, verifyTokenAsync } from "../../../../asyncActions/authAsyncActions";
import translate from "../../../extra/language/translate";
import ChangePassword from "./ChangePassword";
import {
    selectStylesFo,
    getFormatedDate,
    getLocalFormatedDate,
    getFrenchFormatedDate,
    getImageUrl,
} from "../../../../Utils/Common";
import SynchroniseToAfrv from "./SynchroniseToAfrv";

const PContentProfil = ({ version }) => {
    const authObj = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [roleList, setRoleList] = useState([]);

    const [locationList, setLocationList] = useState([]);
    const [simulatorList, setSimulatorList] = useState([]);
    const [updateInfoError, setUpdateInfoError] = useState([]);
    const [updateInfoSuccess, setUpdateInfoSuccess] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [country, setCountry] = useState("");
    const [userPics, setUserPics] = useState(null);

    const [selectedDate, setselectedDate] = useState(null);

    const { user } = authObj;

    const [show, setShow] = useState(false);

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [affiliateLink, setAffiliateLink] = useState(user.mfId);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [role, setRole] = useState("");
    const [location, setLocation] = useState(user.metadata.location);
    const [simulator, setSimulator] = useState("");
    const [sims, setSims] = useState(null);
    const [city, setCity] = useState(user.metadata.city);
    const [simbrief, setSimbrief] = useState(user.simbrief);
    const [ivao, setIvao] = useState(user.ivao);
    const [vatsim, setVatsim] = useState(user.vatsim);
    const [activeProfil, setActiveProfil] = useState("PROFIL");
    const [value, setValue] = useState(1);

    const [dateOfBirth, setdateOfBirth] = useState(user.metadata.dateOfBirth);

    const onChangeValue = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    /******************************** paymentData */
    const [dataCountry, setDataCountry] = useState(
        user.payment.country ? user.payment.country : ""
    );
    const [city2, setCity2] = useState(
        user.payment.city ? user.payment.city : ""
    );
    const [adr1, setAdr1] = useState(
        user.payment.address1 ? user.payment.address1 : ""
    );
    const [adr2, setAdr2] = useState(
        user.payment.address2 ? user.payment.address2 : ""
    );
    const [postalCode, setPostalCode] = useState(
        user.payment.postalcode ? user.payment.postalcode : ""
    );

    const lang = useSelector((state) => state.language);

    const myDataCountry = () => {
        //setCountry(user.metadata.country);

        let val = countryList.filter((countryElt, i) =>
            user.payment.country
                ? countryElt.value === user.payment.country.toUpperCase()
                : null
        );

        return val[0] ? val[0] : countryList[0];
    };

    const handleUpdatePaymentData = async (e) => {
        e.preventDefault();

        const paymentFormData = new FormData();
        paymentFormData.append("city", city2);
        paymentFormData.append("country", dataCountry);
        paymentFormData.append("address1", adr1);
        paymentFormData.append("address2", adr2);
        paymentFormData.append("postalcode", postalCode);

    
        

        // const res = Object.fromEntries(
        //   [...paymentFormData].map(([key, value]) => [key, value])
        // );
        //console.log(res);

        const paymentDataResult = await updateUserPaymentDataInfoService(
            paymentFormData
        );

        // console.log(paymentDataResult);

        if (paymentDataResult.error) {
            swal
                .fire({
                    text: paymentDataResult.response.data.data.message,
                    icon: "warning",
                })
                .then((value) => { });
        } else {
            swal
                .fire({
                    text: paymentDataResult.data.message,
                    icon: "success",
                })
                .then((value) => {
                    dispatch(verifyTokenAsync(true));
                });
        }
    };

    //******************************************  */

    const getRoleList = async () => {
        await getUserAvailableRoles().then((res) => {
            const roles = [];
            for (let i = 0; i < res.data.length; ++i) {
                const rl = res.data[i];
                roles.push({ label: rl.roleName, value: rl.id });
            }
            setRoleList(roles);
        });
    };

    const getUserPaymentMethodList = async () => {
        const result = await UserService.getUserPaymentMethodListService();
        if (!result.error) {
            setPaymentMethodList(result.data.user.data);
        }
    };

    const getSimulatorList = async () => {
        await getSimulators().then((res) => {
            const simulators = [];
            for (let i = 0; i < res.data.length; ++i) {
                const simulator = res.data[i];
                simulators.push({
                    label: simulator.simulatorName,
                    value: simulator.id,
                });
            }
            setSimulatorList(simulators);
            mySimulator(simulators);
        });
    };

    const getCountryList = async () => {
        getJSON("https://trial.mobiscroll.com/content/countries.json", (resp) => {
            const countries = [];
            for (let i = 0; i < resp.length; ++i) {
                const country = resp[i];
                let elt = {
                    label: country.text,
                    value: country.value,
                };
                countries.push(elt);
            }
            setCountryList(countries);
        });
    };

    const myCountry = () => {
        //setCountry(user.metadata.country);
        let val = countryList.filter((countryElt, i) =>
            user.metadata.country
                ? countryElt.value === user.metadata.country.toUpperCase()
                : null
        );

        return val[0] ? val[0] : countryList[0];
    };

    const mySimulator = (list) => {
        const userSims = [];

        list.map((it ) => {
            const findInUserSims = user.simulators.find((usrSim ) => usrSim.simulatorId == it.value);
            if(findInUserSims){
                userSims.push(it);
            }
        })

        setSims(userSims);
    };

    const myRole = () => {
        //setRole(user.roleId);
        let val = roleList.filter((elt, i) => elt.value === user.roleId);
        return val[0];
    };

    const myLocation = () => {
        //setLocation(user.metadata.location);
        let val = locationList.filter((elt, i) => i === user.metadata.location);
        let val2 = locationList.filter((elt, i) => i === user.metadata.location);
        return val[0] ? val[0] : val2[0] ? val2[0] : null;
    };

    const getLocationList = async () => {
        setLocation(user.metadata.location);
        setRole(user.roleId);
        setSimulator(user.metadata.simulator);
        setCountry(user.metadata.country);

        await getLocations("").then((res) => {
            const locations = [];
            if (!res.error) {
                for (let i = 0; i < res.data.length; ++i) {
                    const location = res.data[i];
                    locations.push({
                        label: `${location.label} (${location.iata_code})`,
                        value: location.id,
                    });
                }
            }
            setLocationList(locations);
        });
    };

    const handleChangeImage = () => {
        const pics = document.getElementById("id-profile-pics");
        pics.click();
    };

    const filterLocation = (inputValue) => {
        return locationList.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadLocationListOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(filterLocation(inputValue));
        }, 1000);
    };

    const createOptions = (fontSize, padding) => {
        return {
            hidePostalCode: true,
            style: {
                base: {
                    fontSize: fontSize,
                    color: "#fff",
                    letterSpacing: "0.025em",
                    "::placeholder": {
                        color: "#92969a",
                    },
                    padding: padding,
                },
                invalid: {
                    color: "#9e2146",
                },
            },
        };
    };

    useEffect(() => {
        getCountryList();
        getUserPaymentMethodList();
        getRoleList();
        getLocationList();
        getSimulatorList();
        setUpdateInfoError(null);
    }, []);

    const handleLocationInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, "");

        return inputValue;
    };

    const handleUpdateInfo = async (e) => {
        e.preventDefault();

        setUpdateInfoError(null);
        setUpdateInfoSuccess(null);

        const metadata = user.isAdmin
            ? null
            : {
                role: role,
                simulator: simulator,
                location: location,
                country: country,
                city: city,
                dateOfBirth: dateOfBirth,
            };

        
            const formData = new FormData();
            formData.append("photo", userPics);
            formData.append("lastName", lastName);
            formData.append("firstName", firstName);
            formData.append("simbrief", simbrief);
            formData.append("vatsim", vatsim);
            formData.append("ivao", ivao);

            if(sims){
                const userSims = sims.reduce((values , curr) => { values.push(curr.value) ; return values},[]);
            
                formData.append("sims", JSON.stringify(userSims));
            }
            
            
            if (!user.isAdmin) {
                formData.append("metadata", JSON.stringify(metadata));
            }

            const result = await updateUserInfoService(formData);
            if (result.error) {
                // setUpdateInfoError(result.response.data.message);
                swal
                    .fire({
                        text: result.response.data.message,
                        icon: "warning",
                    })
                    .then((value) => { });
            } else {
                dispatch(updateUserInfo());
                swal
                    .fire({
                        text: result.data.message,
                        icon: "success",
                    })
                    .then((value) => {
                        
                    });
            }
        
    };

    const LinkSwall = () => {
        const link = window.location.origin + "/register/" + user.userName;
        navigator.clipboard.writeText(link);
        swal.fire({
            text: translate("Profil.Link_copied_successfuly", lang),
            icon: "success",
        });
    };

    const classDashboard = version === "v2" ? "mdg-card-dahsboard-notice" : "";
    const bgTitle = version === "v2" ? "mdg-bg-titleinformation" : "mdg-width-card-profil-information";
    return (
        <div className={classDashboard}>
            <div className="col-lg-12">
                <div className="row">
                    <div className={`${bgTitle} col-lg-12 mb-2`}>
                        <h5 className="card-title mdg-nav-span-profil ">
                            <Language word={"Profil.Personal_information"}></Language>
                        </h5>
                    </div>
                    <div className="col-lg-6 text-center">
                        <div className="mdg-card-profil-flight  text-center">
                            <div className="card-body">
                                {userPics ? (
                                    <div className="mdg-pos-relative">
                                        <img
                                            onClick={handleChangeImage}
                                            className="mdg-upload-img-profil-width"
                                            src={URL.createObjectURL(userPics)}
                                            alt=""
                                        />
                                        <i
                                            className="fa fa-camera"
                                            aria-hidden="true"
                                            onClick={handleChangeImage}
                                        ></i>
                                    </div>
                                ) : (
                                    <div className="mdg-pos-relative">
                                        <img
                                            onClick={handleChangeImage}
                                            alt=""
                                            src={
                                                user.photo
                                                    ? getImageUrl(user.photo)
                                                    : "/assets/img/up-profil.png"
                                            }
                                            className="mdg-upload-img-profil-width"
                                        />
                                        <i
                                            className="fa fa-camera"
                                            aria-hidden="true"
                                            onClick={handleChangeImage}
                                        ></i>
                                    </div>
                                )}
                                <div style={{ display: "grid" }}>
                                    <h5 className="card-title">
                                        {user.lastName + " " + user.firstName}
                                    </h5>
                                    <h5 className="card-title">{user.email}</h5>
                                    <div className="col-lg">
                                        <button
                                            className="btn mdg-btn-common btn-sm"
                                            onClick={() => setShow(!show)}
                                        >
                                            <Language word={"Profil.Change_Password"}></Language>
                                        </button>
                                    </div>
                                </div>
                                {show ? <ChangePassword></ChangePassword> : null}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ">
                        <span className="float-right mdg-b-detail "></span>
                        <form onSubmit={(e) => handleUpdateInfo(e)}>
                            <input
                                className="mdg-d-none"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                id="id-profile-pics"
                                onChange={(event) => {
                                    setUserPics(event.target.files[0]);
                                }}
                            />
                            <div className="mdg-col-sm-12">
                                <div className="mdg-textfield-out-lined">
                                    <input
                                        id="input-name"
                                        autoComplete="false"
                                        type="text"
                                        placeholder=" "
                                        value={user.userName}
                                        disabled
                                        style={{ color: "#fff" }}
                                    />
                                    <label htmlFor="input-name">
                                        <Language word={"Profil.Username"}></Language>
                                    </label>
                                </div>
                                <div className="mdg-textfield-out-lined">
                                    <input
                                        id="input-email"
                                        autoComplete="false"
                                        type="text"
                                        placeholder=" "
                                        value={user.email}
                                        disabled
                                    />
                                    <label htmlFor="input-email">
                                        <Language word={"Profil.Email"}></Language>
                                    </label>
                                </div>
                                <div className="mdg-nothing">
                                    <div className="mdg-row">
                                        <div className="mdg-col-lg-6">
                                            <div className="mdg-textfield-out-lined">
                                                <input
                                                    id="input-first-name"
                                                    type="text"
                                                    placeholder=" "
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value);
                                                    }}
                                                    value={firstName}
                                                    disabled={user.firstName && user.firstName.length !== 0}
                                                    
                                                    style={{ color: "#fff" }}
                                                />
                                                <label htmlFor="input-first-name">
                                                    <Language word={"Profil.First_name"}></Language>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mdg-col-lg-6">
                                            <div className="mdg-textfield-out-lined">
                                                <input
                                                    id="input-last-name"
                                                    type="text"
                                                    placeholder=" "
                                                    onChange={(e) => {
                                                        setLastName(e.target.value);
                                                    }}
                                                    value={lastName}
                                                    disabled={user.lastName && user.lastName.length !== 0}
                                                    
                                                    style={{ color: "#fff" }}
                                                />
                                                <label htmlFor="input-last-name">
                                                    <Language word={"Profil.Last_name"}></Language>
                                                </label>
                                            </div>
                                        </div>
                                   
                              
                                    </div>
                                </div>
                                

                              

                                {!user.isAdmin && (
                                    <div className="mdg-nothing">
                                        <div className="mdg-row">
                                            <div className="mdg-col-lg-6">
                                                <div className="mdg-textfield-out-lined mdg-change-bg-datepicker">
                                                    {user.metadata.dateOfBirth.length !== 0 ? (
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-dob"
                                                                autoComplete="false"
                                                                type="text"
                                                                placeholder=" "
                                                                value={getFrenchFormatedDate(
                                                                    user.metadata.dateOfBirth
                                                                )}
                                                                disabled
                                                            />
                                                            <label htmlFor="input-dob">
                                                                <Language
                                                                    word={"Profil.Date_of_Birth"}
                                                                ></Language>
                                                            </label>
                                                        </div>
                                                    ) : (
                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            id="input-birth"
                                                            selected={selectedDate}
                                                            maxDate={new Date()}
                                                            value={
                                                                user.metadata.dateOfBirth.length === 0
                                                                    ? null
                                                                    : getLocalFormatedDate(dateOfBirth)
                                                            }
                                                            showDisabledMonthNavigation
                                                            placeholderText={ translate('Profil.DOB',lang)}
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            autoComplete="false"
                                                            className="mdg-date-picker"
                                                            dropdownMode="select"
                                                            readOnly={
                                                                user.metadata.dateOfBirth.length !== 0
                                                            }
                                                            onChange={(date) => {
                                                                if (user.metadata.dateOfBirth.length === 0) {
                                                                    setdateOfBirth(
                                                                        getFormatedDate(new Date(date))
                                                                    );
                                                                    setselectedDate(date);
                                                                }
                                                            }}
                                                        />
                                                    )}

                                                    {/* <label className="mdg-text-white">Date of Birth</label> */}
                                                </div>
                                            </div>
                                            <div className="mdg-col-lg-6">
                                                {myRole() && (
                                                    <div className="mdg-textfield-out-lined">
                                                        {/* <label>Role</label> */}
                                                        <Select
                                                            isClearable={false}
                                                            onChange={(newValue, actionMeta) => {
                                                                setRole(newValue.value);
                                                            }}
                                                            defaultValue={myRole()}
                                                            isSearchable={false}
                                                            placeholder={
                                                                <div>
                                                                    <Language
                                                                        word={"Profil.You_will_be_join_as"}
                                                                    ></Language>
                                                                </div>
                                                            }
                                                            options={roleList}
                                                            styles={selectStylesFo}
                                                            className=""
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mdg-col-lg-12">
                                                {
                                                    sims && ( <div className="mdg-textfield-out-lined">
                                                    {/* <label>Simulator</label> */}
                                                    <Select
                                                        isClearable={false}
                                                        onChange={(newValue) => {
                                                            setSims(newValue);
                                                        }}
                                                        defaultValue={sims}
                                                        isMulti
                                                        isSearchable={false}
                                                        placeholder={
                                                            <div>
                                                                <Language
                                                                    word={"Profil.Which_sim_are_you_using"}
                                                                ></Language>
                                                                ?
                                                            </div>
                                                        }
                                                        options={simulatorList}
                                                        styles={selectStylesFo}
                                                        className=""
                                                    />
                                                </div>)
                                                }
                                                   
                                                
                                            </div>
                                            <div className="mdg-col-lg-12">
                                                {myLocation() && (
                                                    <div className="mdg-textfield-out-lined">
                                                        {/* <label>Location</label> */}
                                                        <AsyncSelect
                                                            styles={selectStylesFo}
                                                            placeholder={
                                                                <div>
                                                                    <Language
                                                                        word={"Profil.Choose_your_location"}
                                                                    ></Language>
                                                                </div>
                                                            }
                                                            
                                                            isClearable={true}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    setLocation(newValue.value);
                                                                }
                                                            }}
                                                            defaultValue={myLocation()}
                                                            cacheOptions
                                                            loadOptions={loadLocationListOptions}
                                                            defaultOptions
                                                            onInputChange={handleLocationInputChange}
                                                            
                                                            className="mdg-height-select-profil"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mdg-col-lg-6">
                                                {myCountry() && (
                                                    <div className="mdg-textfield-out-lined">
                                                        {/* <label>Country</label> */}
                                                        <Select
                                                            isClearable={true}
                                                            onChange={(newValue, actionMeta) => {
                                                                newValue && setCountry(newValue.value);
                                                            }}
                                                            defaultValue={myCountry()}
                                                            placeholder={
                                                                <div>
                                                                    <Language
                                                                        word={"Profil.Choose_a_country"}
                                                                    ></Language>
                                                                </div>
                                                            }
                                                            options={countryList}
                                                            className="mdg-height-select-profil"
                                                            styles={selectStylesFo}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="mdg-col-lg-6">
                                                <div className="mdg-textfield-out-lined">
                                                    <input
                                                        id="input-city"
                                                        type="text"
                                                        placeholder=" "
                                                        onChange={(e) => {
                                                            setCity(e.target.value);
                                                        }}
                                                        value={city}
                                                        
                                                        style={{ color: "#fff" }}
                                                    />
                                                    <label htmlFor="input-city">
                                                        <Language word={"Profil.City"}></Language>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mdg-col-lg-12">
                                                <div className="mdg-textfield-out-lined">
                                                    <input
                                                        id="input-simbrief"
                                                        type="text"
                                                        placeholder=" "
                                                        onChange={(e) => {
                                                            setSimbrief(e.target.value);
                                                        }}
                                                        value={simbrief}
                                                        maxLength="32"
                                                        
                                                        style={{ color: "#fff" }}
                                                    />
                                                    <label htmlFor="input-simbrief">
                                                        <Language word={"Profil.Simbrief"}></Language>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mdg-col-lg-6">
                                                <div className="mdg-textfield-out-lined">
                                                    <input
                                                        id="input-ivao"
                                                        type="text"
                                                        placeholder=" "
                                                        onChange={(e) => {
                                                            setIvao(e.target.value);
                                                        }}
                                                        value={ivao}
                                                        maxLength="16"
                                                        
                                                        style={{ color: "#fff" }}
                                                    />
                                                    <label htmlFor="input-ivao">
                                                        <Language word={"Profil.Ivao"}></Language>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="mdg-col-lg-6">
                                                <div className="mdg-textfield-out-lined">
                                                    <input
                                                        id="input-vatsim"
                                                        type="text"
                                                        placeholder=" "
                                                        onChange={(e) => {
                                                            setVatsim(e.target.value);
                                                        }}
                                                        value={vatsim}
                                                        maxLength="16"
                                                        
                                                        style={{ color: "#fff" }}
                                                    />
                                                    <label htmlFor="input-vatsim">
                                                        <Language word={"Profil.Vatsim"}></Language>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="col-lg-12">
                                    <div className="row justify-content-end">
                                        <button
                                            type="submit"
                                            className="btn mdg-btn-common btn-sm mt-3 mdg-btn-12"
                                        >
                                            <Language word={"Profil.Save"}></Language>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {user.userName && (
                            <div className="mt-4 mdg-top-border">
                                <div className="col-lg-12">
                                    <div className="mt-4">
                                        <h6 className="mdg-text-white">
                                            <Language word={"Profil.Affiliation_Link"}></Language>
                                        </h6>
                                    </div>
                                    <div className="mdg-pos-relative">
                                        <div className="mdg-textfield-out-lined">
                                            <input
                                                id="input-affiliation"
                                                type="text"
                                                placeholder=" "
                                                value={
                                                    window.location.origin +
                                                    "/register/" +
                                                    user.userName
                                                }
                                                required
                                                disabled
                                                style={{ color: "#fff" }}
                                            />
                                            <label htmlFor="input-affiliation">
                                                <Language word={"Profil.Link"}></Language>
                                            </label>
                                            <i
                                                className="fa fa-clone mdg-text-white mdg-icon-copy-link"
                                                aria-hidden="true"
                                                title="Copy link"
                                                onClick={LinkSwall}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <SynchroniseToAfrv/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PContentProfil;