import axios  from "../AxiosInstance"

const getAllAward = async (id) => {
    try {
      return axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/award`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const getById = async (id) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/award/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/award`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/award/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id,data) => {
  try {
      return await axios({
          method: "put",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/award/${id}`,
          data: data
      }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllAsKeyValue = async () => {
  const res = await getAllAward();
  if (res.error) {
      return [];
  } else {
      const output = [];
      const  data  = res.data;
      data.forEach((elt, i) => {
        output.push({ label: elt.name, value: elt.id });
      });

      return output;
  }
}

const AwardService = {
    create,
    getAllAward,
    getById,
    deleteById,
    update,
    getAllAsKeyValue,
}

export default AwardService;