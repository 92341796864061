import { useState } from "react";
import { verifyTokenService } from "../services/auth";
import Swal from "sweetalert2";
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const verifyToken = () => {
  verifyTokenService();
};

export const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return {
    value,
    onChange: handleChange,
  };
};

export const selectStyles = {
  option: (base) => ({
    ...base,
    height: "100%",
    textAlign: "left",
    color: "#928888",
    backgroundColor: "#fff",
    border: "2px solid transparent",

  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};

export const selectStylesFo = {
  option: (base) => ({
    ...base,
    height: "100%",
    textAlign: "left",
    color: "#fff",
    backgroundColor: "#081217",
    border: "2px solid transparent",
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};


export const selectStylesSwal = {
  option: (base) => ({
    ...base,
    height: "100%",
    textAlign: "left",
    color: "#fff",
    backgroundColor: "#34393C",
    border: "2px solid transparent",

  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};


export const appendScript = (scriptToAppend, scripts = []) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;


  script.onload = function () {
    if (scripts.length > 0) {
      let next = scripts.shift();
      appendScript(next, scripts);
    }

  }
  document.body.appendChild(script);
}

export const getFormatedDate = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

  return `${ye}-${mo}-${da}`;
}

export const getFrenchFormatedDate = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

  return `${da}/${mo}/${ye}`;
}

export const getFormatedDateWithTime = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  let hour = new Intl.DateTimeFormat('en', { hour: 'numeric', hourCycle: 'h23' }).format(d);
  let minute = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(d);
  hour = parseInt(hour) < 10 ? '0' + hour : hour;
  minute = parseInt(minute) < 10 ? '0' + minute : minute;
  return `${ye}-${mo}-${da}T${hour}:${minute}`;
}

export const getBeautifulFormatedDateWithTime = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  let hour = new Intl.DateTimeFormat('en', { hour: 'numeric', hourCycle: 'h23' }).format(d);
  let minute = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(d);
  hour = parseInt(hour) < 10 ? '0' + hour : hour;
  minute = parseInt(minute) < 10 ? '0' + minute : minute;

  return `${da} ${mo} ${hour}:${minute}`;
}

export const getBeautifulFormatedDateWithTimeS = (date) => {
  let d = new Date(date);
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(d);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
  let hour = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    hourCycle: "h23",
  }).format(d);
  let minute = new Intl.DateTimeFormat("en", { minute: "numeric" }).format(d);
  let sec = new Intl.DateTimeFormat("en", { second: "numeric" }).format(d);
  hour = parseInt(hour) < 10 ? hour : hour;
  minute = parseInt(minute) < 10 ? "0" + minute : minute;
  sec = parseInt(sec) < 10 ? "0" + sec : sec;

  return `${da}-${mo}-${ye} ${hour}:${minute}:${sec}`;
};

export const diffDateToHoursMinSec = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const ms = Math.abs(endDate - startDate);
  return new Date(ms).toISOString().slice(11, 19);
}

export const getFormatedTimeLapse = (start, end) => {
  const diff = diffDateToHoursMinSec(start,end);
  const splited = diff.split(':');
  let hour = '';
  let min = '';
  let sec = '';
  if(parseInt(splited[0]) > 0) {
    hour = splited[0] + 'h';
  }
  min = splited[1] + 'm';
  sec = splited[2] + 's';

  return hour+ ' '+ min+ ' ' + sec;
}

export const getFlightBeautifulFormatedDateWithTime = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  let hour = new Intl.DateTimeFormat('en', { hour: 'numeric', hourCycle: 'h23' }).format(d);
  let minute = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(d);
  minute = parseInt(minute) < 10 ? '0' + minute : minute;

  return `${da} ${mo} ${ye} ${hour}:${minute}`;
}

export const getLocalFormatedDate = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

  return `${da}-${mo}-${ye}`;
}

export const getFullDate = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

  return `${da} ${mo} ${ye}`;
}

export const getLongDate = (date) => {
  let d = new Date(date)
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  let mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

  return `${da} ${mo} ${ye}`;
}

export const findInList = (list, needle) => {
  const result = list.find(item => item.value == needle);

  return result;
}

export const containsObject = (obj, list) => {
  return list.find((elt)=> elt.value == obj.value);
}

export const tableRowStyle = [
  {
    when: row => row.id > 0,
    style: {
      backgroundColor: "transparent",
      color: "#fff",
      userSelect: "none"
    }
  }
];

export const getFormatedAmount = (nb, nb_virgule = 0) => {
  nb = parseFloat(nb);

  return nb.toLocaleString(undefined, { minimumFractionDigits: nb_virgule });
}


export const swalResult = (result) => {
  if (result.error) {
    Swal.fire({
      text: result.response.data.message,
      icon: "warning",
    })

  } else {
    Swal.fire({
      text: result.data.message,
      icon: result.data.message.error ? "error" : "success",
    })
  }
}

export const getNameItemOnHistoriqueDescription = (description) => {
  const split_description = description.split('of ');
  return split_description[1];
}

export const getAmountTransactionOnHistoriqueDescription = (description) => {
  const split_description = description.split('of ');
  return split_description[2]??'';
}

export const getIfCommissionDescription = (description) => {
  const split_description = description.split('of ');
  return split_description.length == 3 ? split_description[0]+' of ' +split_description[1]: description;
}

export const getImageUrl =  (url) =>  {
    if(url && (url.includes("amazonaws.com") || url.includes("http://") || url.includes("https://"))){
      return url;
    }else{
      return `${process.env.REACT_APP_REMOTE_API_BASE_URL}/${url}`;
    }
}

export const isAvailableFeature = (feature) => {
    const features = [
      'ingame',
      'miision',
      'myitems',
      'wishlist',
      'mission',
      'award'
    ];

    if(features.includes(feature)){
        if(window.location.host.includes('dev') || window.location.host.includes('localhost')){
          return true;
        }
        return false;
    }

    return true;
}

export const validationPassword = (password) => {
  var passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
  if(!passwordRegex.test(password)) {
    return {
      valid: false,
      message: 'Password.invalidFormat'
    }
  }

  return {
    valid: true,
    message: 'Password.strong_password'
  }
}


