import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import FeaturesForm from './FeaturesForm';
import FeaturesList from './FeaturesList';

const FeaturesComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <FeaturesForm action="Create"></FeaturesForm> ;break;
        case "edit": page = <FeaturesForm action="Edit"></FeaturesForm> ; break;
        default: page = <FeaturesList></FeaturesList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default FeaturesComponent;