import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Thumbnail from "../../extra/img/thumbnail";
import swal from 'sweetalert';
import Breadcrumb from "../templates/Breadcrumb";
import PackService from "../../../services/pack";
import DescriptionComponent from "../description/DescriptionComponent";

const PackUpToSellList = () => {
    const [list, setList] = useState(null);
    const [initialList, setInitialList] = useState([]);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const deletePack = async (row) => {
        swal(`Are you sure to delete this up sell : ${row.name}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await PackService.deleteById(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }

                getList()
            }
        });
    }

    const columns = [
        
        {
            id: 1,
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "FC Amount",
            selector: (row) => row.fcAmount,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 3,
            name: "Price",
            selector: (row) => row.price,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/up-to-sell/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 5,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deletePack(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const getList = async () => {
        await PackService.getAllPackUpToSell().then((res) => {

            const lists = [];
            if (!res.error) {
                for (let i = 0; i < res.data.length; ++i) {
                    const rl = res.data[i];
                    lists.push({
                        id: rl.id,
                        photo: rl.photo,
                        name: rl.name,
                        fcAmount: rl.fcAmount,
                        price: rl.price,
                        description: rl.description,
                    });
                }
                setInitialList(lists);
                filterList(lists, "");
            }
        });
    };

    useEffect(() => {
        getList();
    }, []);

    const filterList = (list, txt) => {
        let res = list.filter(
            (elt, i) =>
                elt.name.toLowerCase().includes(txt.toLowerCase())
        );
        setList(res)
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Pack</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Up sell</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Up sell</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/up-to-sell/new" type="button" className="btn btn-tool bg-info" >
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 mdg-historique-table">
                                            {list && (<DataTable
                                                columns={columns}
                                                data={list}
                                                defaultSortFieldId={1}
                                                pagination
                                                selectableRows
                                                conditionalRowStyles={rowStyle}
                                            />)}
                                        </div>


                                    </div>

                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default PackUpToSellList;
