import React, { useEffect, useRef, useState } from "react";
import SellerModeService from "../../../../services/sellerMode";
import MyStoreList from "./MyStoreList";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import SideNav from "./Side";
import RecommendedItem from "./RecommendedItem";
import translate from "../../../extra/language/translate";
import Language from "../../../extra/language/language";

const MyStore = ({ page }) => {
  const [myStoreList, setMyStoreList] = useState([]);
  const [myRecommendationList, setMyRecommendationList] = useState([]);

  const authObj = useSelector((state) => state.auth);
  const { user } = authObj;
  const MySwal = withReactContent(Swal);
  const swalHtml = useRef("");
  const lang = useSelector((state) => state.language);

  const getAllMyStoreList = async () => {
    let list = await SellerModeService.getAllMyStoreService();
    setMyStoreList(list.data);

    await SellerModeService.getAllMyRecommendationService().then((res) => {
      const rec = [];
      for (let i = 0; i < res.data.length; ++i) {
        let elt = res.data[i].item;
        rec.push(elt);
      }
      setMyRecommendationList(rec);
    });
  };

  const deleteMyStore = async (id) => {
    Swal.fire({
      title: "Confirmation !",
      text: translate('Seller.Are_you_sure_to_remove_this_item_from_the_store',lang),
      confirmButtonText: "Yes, i'm sure !",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await SellerModeService.deleteMyStore(id);
        if (res.error) {
          Swal.fire({
            title: res.response.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: res.data.message,
            icon: res.data.error ? "warning" : "success",
            confirmButtonText: "Ok",
          }).then(() => {
            getAllMyStoreList();
          });
        }
      }
    });
  };

  useEffect(() => {
    getAllMyStoreList();
  }, []);

  return (
    <>
      <div className="container mdg-top-container">
        <div className="mdg-title-ingame row">
          <div className="col-sm">
            <span className="mdg-h2"><Language word={"Seller.Seller_mode"}></Language></span>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <SideNav/>
          </div>
          <div className="col-lg-9">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="lands"
                role="tabpanel"
                aria-labelledby="lands-tab"
              >
                <div className="row justify-content-between">
                <div className="col-lg-8">
                  
                </div>
                <div className="col-lg-2 md-sm-3 ">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mdg-search-place">
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder="Search "
                          style={{ color: "#fff" }}
                          className="mdg-search-input"
                        />
                        <i
                          className="fa fa-search mdg-fa"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                <div className="mdg-width-card-item">
                  <div
                    className="tab-pane fade show active"
                    id="lands"
                    role="tabpanel"
                    aria-labelledby="lands-tab"
                  >
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="all-flight"
                        role="tabpanel"
                        aria-labelledby="all-flight-tab"
                      >
                        <div className="mdg-width-card-item p-3">
                          {page !== "recommendation" ? (
                            <div className="row">
                              {myStoreList.map((elt, i) => (
                                <MyStoreList
                                  item={elt}
                                  key={i}
                                  deleteMyStore={deleteMyStore}
                                  lang={lang}
                                ></MyStoreList>
                              ))}
                            </div>
                          ) : (
                            <div className="row">
                              {myRecommendationList.map((elt, i) => (
                                <RecommendedItem item={elt} key={i} lang={lang}/>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyStore;
