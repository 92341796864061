import axios  from "../AxiosInstance"

const getUserFlight = async (filter) => {
  try {
      return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight/get-by-user`, 
      { params: filter });
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
}

const getFlightById = async (id) => {
  try {
      return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight/${id}`)
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
}

const getAllFlight = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight`,
      { params: filter }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllFlights = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight/all`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllFlightTeated = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight/treated`,
      { params: filter }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllFlightTeateds = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight/treated/all`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const FlightService = {
  getUserFlight,
  getFlightById,
  getAllFlight,
  getAllFlightTeated,
  getAllFlights,
  getAllFlightTeateds,
};

export default FlightService;