import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import { findInList, selectStyles } from "../../../Utils/Common";
import swal from "sweetalert";
import DescriptionComponent from "../description/DescriptionComponent";
import StoreCategoryService from "../../../services/store/catogory";

const StoreCategoryForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [storeCategoryName, setStoreCategoryName] = useState("");
    const [storeCategoryList, setStoreCategoryList] = useState([]);
    const [storeCategoryParentId, setStoreCategoryParentId] = useState(null);

    

    const getStoreCategoryList = async () => {
        let store_categories_list = await StoreCategoryService.getAvailableStoreCategoryListAsKeyValueService(id);
        setStoreCategoryList(store_categories_list);
    };

    const getStoreCategory = async () => {
        if (id) {
            const result = await StoreCategoryService.getStoreCategoryById(id);
            if(!result.error){
                const data = result.data;
                setStoreCategoryParentId(data.parentCategoryId);
                setStoreCategoryName(data.storeCategoryName);
            }
        }
    };

    useEffect(() => {
        getStoreCategoryList();
        getStoreCategory();
    }, []);

    const createStoreCategoryHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('storeCategoryParentId', storeCategoryParentId);
        formData.append('storeCategoryName', storeCategoryName);

        let result;

        if (id) {
            result = await StoreCategoryService.updateStoreCategoryService(id, formData);
        } else {
            result = await StoreCategoryService.createStoreCategoryService(formData);
        }


        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Store Category</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Store Category</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName} Store Category</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/store-category/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createStoreCategoryHandler(e)}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mdg-textfield-out-lined">
                                                            <Select
                                                                isClearable={true}
                                                                onChange={(newValue, actionMeta) => {
                                                                    newValue != null ? setStoreCategoryParentId(newValue.value): setStoreCategoryParentId(null);
                                                                }}
                                                                placeholder="Choose a parent store category"
                                                                options={storeCategoryList}
                                                                styles={selectStyles}
                                                                value={findInList(storeCategoryList,storeCategoryParentId)}
                                                                style={{ color: "#fff" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-category-name"
                                                                type="text"
                                                                placeholder=" "
                                                                value={storeCategoryName}
                                                                onChange={(event) => {
                                                                    setStoreCategoryName(event.target.value);
                                                                }}
                                                                required
                                                                style={{ color: "#fff" }}
                                                            />
                                                            <label htmlFor="input-category-name">
                                                                Store Category Name
                                                            </label>
                                                        </div>
                                                            <div className="col-lg-12 text-right mt-2">
                                                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                                    Save
                                                                </button>
                                                            </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default StoreCategoryForm;
