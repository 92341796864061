import { CHANGE_LANGUAGE, SET_DEFAULT_LANGUAGE } from "../actions/language";

let initialLanguage = "en"
const userLang = navigator.language || navigator.userLanguage; 
if(userLang){
    const language = userLang.split('-');
    if(language.length > 1){
        initialLanguage = language[0];
    }
}


const language = (state = initialLanguage, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return state = action.payload.language;
        case SET_DEFAULT_LANGUAGE:
            return state  = initialLanguage;
        default:
            return state;
    }
}

export default language;