import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import swal from 'sweetalert';
import Breadcrumb from "../templates/Breadcrumb";
import { deleteItemsTypes, getItemsTypesListService } from "../../../services/ItemTypes";
import DescriptionComponent from "../description/DescriptionComponent";
import NoticesBoService from "../../../services/notices";
import { getFullDate, getImageUrl } from "../../../Utils/Common";
import Thumbnail from "../../extra/img/thumbnail";

const NoticesList = () => {
    const [list, setList] = useState(null);
    const [initialList, setInitialList] = useState([]);

    const rowStyle = [
        {
            when: row => row.id > 0,
            style: {
                backgroundColor: "transparent",
                color: "#fff",
                userSelect: "none"
            }
        }
    ];

    const columns = [
        {
            id: 1,
            name: "Photo",
            selector: (row) => row.photo,
            sortable: false,
            reorder: false,
            cell: (row) => (
                <Thumbnail src={row.photo ? getImageUrl(row.photo):"/assets/img/upload-img.png"}></Thumbnail>
            )
        },{
            id: 2,
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },{
            id: 3,
            name: "Start Date",
            selector: (row) => getFullDate(row.startDate),
            sortable: true,
            reorder: true
        },{
            id: 4,
            name: "End Date",
            selector: (row) => getFullDate(row.endDate),
            sortable: true,
            reorder: true
        },
        {
            id: 5,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/notice/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 6,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deleteNotice(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const getList = async () => {
        await NoticesBoService.getAllNoticesService().then((res) => {
            const lists = [];
            if (res.status == 200) {
                const { data } = res;

                data.map((value, i) => {
                    lists.push({
                        id: value.id,
                        name: value.name,
                        startDate: value.startDate,
                        endDate: value.endDate,
                        photo: value.photo,
                    });
                });
                setInitialList(lists);
                setList(lists)
                // filterList(lists, "");
            }
        });
    };
    
    const deleteNotice = async (row) => {
        swal(`Are you sure to delete the notice : ${row.name??''}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await NoticesBoService.deleteByIdService(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }

                getList()
            }
        });
    }

    // const filterList = (list, txt) => {
    //     let res = list.filter(
    //     (elt, i) =>
    //         elt.name.toLowerCase().includes(txt.toLowerCase())
    //     );
    //     setList(res)
    // };

    useEffect(() => {
        getList();
    }, []);

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Notices</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Notices</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Notices</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/notice/new" type="button" className="btn btn-tool bg-info" data-card-widget="collapse">
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 mdg-historique-table">
                                            {list && (<DataTable
                                                columns={columns}
                                                data={list}
                                                defaultSortFieldId={1}
                                                pagination
                                                selectableRows
                                                conditionalRowStyles={rowStyle}
                                            />)}
                                        </div>


                                    </div>

                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default NoticesList;
