import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import AviationTypeService from "../../../services/missions/aviationType";

const AviationTypeForm = (props) => {
  const { id } = useParams();
  const actionName = props.action;
  const [type, setType] = useState("");

  const handleCreateAviationType = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("type", type);

    let result;
    if (id) {
      result = await AviationTypeService.update(id, formData);
    } else {
      result = await AviationTypeService.create(formData);
    }

    if (result.error) {
      swal(result.response.data.data.message, {
        icon: "error",
      });
    } else {
      swal(result.data.message, {
        icon: "success",
      });
    }
  };

  const getAviationType = async () => {
    if (id) {
      const result = await AviationTypeService.getById(id);
      if (!result.error) {
        const data = result.data;
        setType(data.data.type);
      }
    }
  };

  useEffect(() => {
    getAviationType();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">
                {id ? "Edit Aviation type" : "Create new Aviation type"}
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Aviation type</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {id ? "Edit Aviation type Information" : "New Aviation type"}
                  </h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/aviation-type/"
                      type="button"
                      className="btn btn-tool bg-info"
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => handleCreateAviationType(e)}>
                        <div className="row">
                          {/* <div className="col-lg-6"></div> */}
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-parent-id"
                                    type="text"
                                    placeholder=" "
                                    value={type}
                                    style={{ color: "#fff" }}
                                    onChange={(event) => {
                                      setType(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-parent-id">
                                    Aviation type name *
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-12 text-right mt-2">
                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="offset-lg-11 mt-1"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AviationTypeForm;
