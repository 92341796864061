import axios  from "./AxiosInstance"

export const getItemListService = async (isAdmin) => {
  try {
    return isAdmin
      ? await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/`)
      : await axios({
          method: "post",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/client/`,
        });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getMyItemListService = async (id) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/client/`,
      data: {
        clientId: id,
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getItemListByCategoryService = async (categoryId, isAdmin) => {
  try {
    return (await isAdmin)
      ? axios.get(
          `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/category/${categoryId}`
        )
      : axios.get(
          `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/category/available/${categoryId}`
        ); //available
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getMyItemListByCategoryService = async (categoryId, userId) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/client/`,
      data: {
        categoryId: categoryId,
        clientId: userId,
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const orderItemService = async (itemId, qty) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/order`,
      data: {
        itemId: itemId,
        quantity: qty,
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const declineOfferService = async (itemId) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/order/decline-offer`,
      data: {itemId:itemId},
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const rentItemService = async (itemId, xDays) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/rent-now`,
      data: {
        itemId: itemId,
        xDays: xDays,
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getAllStoreItemsService = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/store/`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const createItemService = async (formData) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/`,
      data: formData,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const updateItemService = async (formData, id) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/${id}`,
      data: formData,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const changeItemStatusService = async (id) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/${id}/status`,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const deleteItem = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getItemById = async (id) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getDetail = async (id) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/${id}/simple`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getStoreItems = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/store/in-game`,
      { params: filter }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getPaginatedStoreItems = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/store/paginated/in-game`,
      { params: filter }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getMyItems = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/store/my-items`,
      { params: filter }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const makeBidProposal = async (itemId, bidAmount) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/make-bid-proposal/${itemId}`,
      data: {
        fcAmount: bidAmount,
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getSimpleList = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/simple-list`,
      { params: filter }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getItemAsKeyValue = async() => {
  const res = await getSimpleList();
  if (res.error) {
    return [];
  } else {
    const items = [];
    for (let i = 0; i < res.data.length; ++i) {
       
        const elt = {
            label: `${res.data[i].name}`,
            value: res.data[i].id,
        };

        items.push(elt);
    }

    return items;
  }
}

export const countRelatedItemService = async (id, type, proprioId) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/related-items-count/${id}`,
      { params: { type : type, proprioId : proprioId} }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const countRelatedItem = async (id, type,proprioId) => {
  const res = await countRelatedItemService(id,type,proprioId);
  if(res.error){
    return 0;
  }

  return res.data.number;
}

export const getRelatedItems = async (id, type, proprioId) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/related-items/${id}`,
      { params: { type : type , proprioId: proprioId} }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const makeExchangeProposal = async (itemId, exhangeId) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/make-exchange-proposal/${itemId}`,
      data: {
        exhangeId: exhangeId,
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


export const getExchangeCondition = async (itemId) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/${itemId}/exchange-condition`)  ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getList = async (filter) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/list`, 
    { params: filter });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const countAllRelatedItems = async (id, proprioId) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/related-items-count/${id}/all`,
      { params: {  proprioId : proprioId} }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getRangeMinMax = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/search/price-range`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getUserWalletItemHistory = async (filter) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/user/history/list`, 
        { params: filter });
      } catch (err) {
        return {
          error: true,
          response: err.response,
        };
      }
}

const ItemService = {
  getItemListService,
  getMyItemListService,
  getItemListByCategoryService,
  getMyItemListByCategoryService,
  orderItemService,
  getAllStoreItemsService,
  createItemService,
  updateItemService,
  deleteItem,
  getItemById,
  getStoreItems,
  getMyItems,
  changeItemStatusService,
  makeBidProposal,
  rentItemService,
  getItemAsKeyValue,
  countRelatedItemService,
  countRelatedItem,
  getRelatedItems,
  makeExchangeProposal,
  getExchangeCondition,
  getList,
  getDetail,
  countAllRelatedItems,
  getPaginatedStoreItems,
  getRangeMinMax,
  getUserWalletItemHistory,
  declineOfferService
};

export default ItemService;
