import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import BotThreshold from './BotThreshold';

const BotThresholdPage = () => {
    return (
        <div>     
             <BaseBO page={<BotThreshold />}></BaseBO>
        </div>
    );
};

export default BotThresholdPage;