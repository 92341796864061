import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SettingService from "../../../../services/setting";
import SettingNavHeader from "../NavHeader";
import NumberFormat from "react-number-format";
import FremiumLimitation from "./FremiumLimitation";

const PiloteRemuneration = () => {

  const [flightDurationPerDay, setFlightDurationPerDay] = useState("");
  const [restTimeDuration, setRestTimeDuration] = useState("");
  const [remunerationDurationLimitPerMounth, setRemunerationDurationLimitPerMounth] = useState("");

  const getInitialSetting = async () => {
    await SettingService.getSettingsByName("PILOT_FLIGHT_LIMITS").then(
      (res) => {
        if (!res.error) {
          setFlightDurationPerDay(res.data.flightDurationPerDay);
          setRestTimeDuration(res.data.restTimeDuration);
          setRemunerationDurationLimitPerMounth(res.data.remunerationDurationLimitPerMounth);
        }
      }
    );
  };

  const generateToken = (length) =>{
    var a = "PQRSTabcdKUVWXYZ1234LMNOmnopqrstuvwxyzABCefJDEFghijklIGH567890".split("");
    var b = [];  
    for (var i=0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
  }

  const handleLongFlightSetting = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      flightCoinToken: generateToken(64),
      flightDurationPerDay: flightDurationPerDay,
      restTimeDuration: restTimeDuration,
      remunerationDurationLimitPerMounth: remunerationDurationLimitPerMounth
    };
    formData.append("name", "PILOT_FLIGHT_LIMITS");
    formData.append("data", JSON.stringify(data));

    let result = await SettingService.create(formData);

    if (result.error) {
      Swal.fire({
        title: result.response.data.message
          ? result.response.data.message
          : result.response.data.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: result.data.message,
        icon: result.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => {});
    }
  };
  

  useEffect(() => {
    getInitialSetting();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <SettingNavHeader />      

      <section className="content mt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Flight Limitation</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleLongFlightSetting(e)}>
                    <div className="row mt-4">                                           
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined">
                              <NumberFormat
                                suffix={" Hours per day"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={flightDurationPerDay}
                                id="input-flight-duration"
                                required
                                onValueChange={(values) => 
                                  setFlightDurationPerDay(values.floatValue)
                                }
                              />
                              <label htmlFor="input-flight-duration">Flight Duration per day *</label>
                            </div>                            
                          </div> 
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined">
                              <NumberFormat
                                suffix={" Hours to rest"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={restTimeDuration}
                                id="input-rest-time"
                                required
                                onValueChange={(values) => 
                                  setRestTimeDuration(values.floatValue)
                                }
                              />
                              <label htmlFor="input-rest-time">Rest time before being paid for a flight *</label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined">
                              <NumberFormat
                                suffix={" Hour"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={remunerationDurationLimitPerMounth}
                                id="input-rest-time"
                                required
                                onValueChange={(values) => 
                                  setRemunerationDurationLimitPerMounth(values.floatValue)
                                }
                              />
                              <label htmlFor="input-rest-time">Flight Duration Remunerated per mounth *</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 text-right mt-2">
                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
    </div>
  );
};

export default PiloteRemuneration;
