import axios  from "../AxiosInstance"

export const getLocations = async (search) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/base/locations`,
      data: {
        search: search,
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response
    };
  }
}

export const getPaginatedLocations = async (filter) => {
  try {    
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/base/locations/paginated`,
    { params: filter });
  } catch (err) {
    return {
      error: true,
      response: err.response
    };
  }
}

export const refreshLocations = async () => {
  try {    
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/base/locations/refresh-data`);
  } catch (err) {
    return {
      error: true,
      response: err.response
    };
  }
}

export const getLocationsAsKeyValueService = async () => {
  const res = await getLocations();
  if (res.error) {
    return [];
  } else {
    const locations = [];
      for (let i = 0; i < res.data.length; ++i) {
          const location = res.data[i];
          locations.push({
              label: location.ident+'-'+location.label,
              value: location.id,
              municipality: location.municipality,
              iso_region: location.iso_region,
              ident: location.ident,
              gps_code: location.gps_code,
              continent: location.continent,
              type: location.type,
          });
      }
    return locations;
  }
};

const LocationService = {
  getLocations,
  getLocationsAsKeyValueService,
  getPaginatedLocations,
  refreshLocations
}

export default LocationService;
