import L from 'leaflet'
import { MapContainer, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import "./style.css"
import PlaneMarkers from './tracker/planeMaker/planeMaker';
import {useEffect, useMemo, useState} from 'react';
import AcarsService from '../../../services/tracker/tracker';
import DrawPlaneTrip from './tracker/planeTrip/DrawPlaneTrip';
import AircraftPathPolyline from './tracker/planeTrip/AircraftPathPolyline';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import IvaoMakers from './tracker/Ivao/IvaoMakers';
import ActiveAirports from './tracker/airport/ActiveAirport';
import VatsimMakers from './tracker/Vatsim/VatsimMakers';
import UserLocation from './User/UserLocation';
import AfrvPlanes from './tracker/planeMaker/AfrvPlane';
import MFPlanes from './tracker/planeMaker/MFPlane';

// import styles from './LiveMap.module.scss';

const INIT_MAP_POSITION = [54, -30];
const REFETCH_LIVE_MAP_INTERVAL = 60 * 1000 / 2;
const REFETCH_IVAO_VATSIM = 60 * 1000 * 2;

export default function LiveMap() {

  return <></>;
  
  const map = useRef();
  const [flights, setFlights] = useState([]);
  const [ivaoFlights, setIvaoFlights] = useState([]);
  const [vatsimFlights, setVatsimFlights] = useState([]);

  const [refresh, setRefresh] = useState(0);
  const mode = useSelector((store) => store.map.mode);
  const ref = useRef();

  const addWeather = useSelector((store) => store.map.addWeather);
  const removeWeather = useSelector((store) => store.map.removeWeather);

  const ivao = useSelector((store) => store.map.networks.ivao);
  const vatsim = useSelector((store) => store.map.networks.vatsim);
  const airport = useSelector((store) => store.map.networks.airport);


  const getLayer = useMemo(() => {
    switch (mode) {
      case "LIGHT": return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
      // case "DARK": return "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
      case "DARK": return "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png";
      case "SATELLITE": return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
      default: return "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png";
    }
  }, [mode]);

  useEffect(() => {
    if (ref.current) {
      ref.current.setUrl(getLayer);
    }
  }, [ref?.current, mode]);

  useEffect(() => {
    if (!map.current) return;
    /*if(mode === "DARK") {
      L.DomUtil.addClass(map.current.getContainer(),'mapDark');
    } else {
      L.DomUtil.removeClass(map.current.getContainer(),'mapDark');
    }*/
  }, [mode]);

  useEffect(() => {
    if (!map.current) return;
    if (addWeather) {
      map.current.addLayer(addWeather.layer);
    }

  }, [addWeather]);

  useEffect(() => {
    if (!map.current) return;
    if (removeWeather) {
      map.current.removeLayer(removeWeather.layer);
    }

  }, [removeWeather]);

  const getFlights = async () => {
    const data = await AcarsService.getFlightsWithMetaflightApi();

    setFlights(data)
  }

  const getIvaoFlights = async () => {
    const data = await AcarsService.getIvaoFlights();
    if(!data.error){
      setIvaoFlights(data.data.data)
    }
  }

  const getVatsimFlights = async () => {
    const data = await AcarsService.getVatsimFlights();
    if(!data.error){
      setVatsimFlights(data.data.data)
    }
  }

  useEffect(() => {
    getFlights();
    getIvaoFlights();
    getVatsimFlights();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getFlights();
    }, REFETCH_LIVE_MAP_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getIvaoFlights();
      getVatsimFlights();
    }, REFETCH_IVAO_VATSIM);
    return () => clearInterval(interval);
  }, []);

  return (
    <MapContainer
      center={INIT_MAP_POSITION}
      zoom={3}
      minZoom={2}
      maxZoom={18}
      style={{ height: '100%', width: '100%', flex: '1' }}
      zoomControl={false}
      className={mode === "DARK" ? "mapContainer mapDark" : "mapContainer"}
      worldCopyJump
      ref={map}
    >
      <TileLayer ref={ref} url={getLayer} attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors`}/>
       <AfrvPlanes />
       <MFPlanes flights={flights || []}  />
      {
        airport && <ActiveAirports flights={flights || []} />
      }

      {
        ivao && <IvaoMakers pilots={ivaoFlights || []} />
      }

      {
        vatsim && <VatsimMakers pilots={vatsimFlights || []} />
      }

      <DrawPlaneTrip></DrawPlaneTrip>
      <AircraftPathPolyline></AircraftPathPolyline>
      <UserLocation/>
    </MapContainer>
  );
}
