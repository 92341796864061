import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import NotificationService from "../../../../services/notification";

const Notification = (props) => {
  const [expandedNotif, setExpandedNotif] = useState(false);
  const navigate = useNavigate();
  const [countNotif, setCountNotif] = useState(0);

  useEffect(() => {
    setCountNotif(
      props.notificationList.filter((obj) => obj.seen === false).length
    );
  }, [props.notificationList]);

  return (
    <>
      <NavLink
        className="nav-link dropdown-toggle mdg-notif-icon mdg-icon-padding"
        to=""
        onClick={(e) => setExpandedNotif((expandedNotif) => !expandedNotif)}
        aria-expanded={expandedNotif}
      >
        <small className="float-right mt-1 mr-2">
          <i className="fa fa-bell-o"></i>
          {countNotif > 0 ? (
          
            <span className="mdg-badge-notif">{countNotif}</span>
          
        ) : (
          <></>
        )}
        </small>

        
      </NavLink>

      <div
        className={expandedNotif ? "dropdown-menu show" : "dropdown-menu"}
        style={{ backgroundColor: "#081217", color: "#fff" }}
      >
        {props.notificationList.length > 0 ? (
          props.notificationList.map((elt, i) => (
            <button
              className={elt.seen === false ? "dropdown-item text-cyan" : "dropdown-item"}
              style={{ color: "#fff" }}
              key={i}
              onClick={async (e) => {
                e.preventDefault();
                switch (elt.type) {
                  case "PROFIL":
                    navigate("/profil-user");
                    break;
                  case "PRIVATE_SELL":
                    navigate("/account/recommendations");
                    break;
                  case "EXCHANGE_PROPOSAL":
                    navigate(`/account/items/${elt.itemId}/details/exchange`);
                    break;
                  case "GAIN":
                    navigate("/wallet");
                    break;
                  case "BID_PROPOSAL":
                    navigate(`/account/items/${elt.itemId}/details/sell`);
                    break;
                  default:
                    navigate("/");
                    break;
                }
                await NotificationService.readNotificationService(elt.id).then(
                  (res) => {
                    props.getUserNotifications();
                  }
                );
              }}
            >
              <i
                className={elt.seen === false ? "fa fa-exclamation-triangle" : "fas fa-check text-green"}
                aria-hidden="true"
                style={{ color: "#e3ca16" }}
              ></i>
              &nbsp; {elt.notification}
            </button>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Notification;
