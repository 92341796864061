import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import NumberFormat from "react-number-format";
import FlightIncidentService from "../../../../services/paxncargo/flightIncident";

const FlightIncidentForm = (props) => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [impactWellness, setImpactWellness] = useState(0);
  const [impactWellnessResolutionPercent, setImpactWellnessResolutionPercent] =
    useState(0);
  const [impactWellnessResolutionMinutes, setImpactWellnessResolutionMinutes] =
    useState(0);

  const [impactEntertainement, setImpactEntertainement] = useState(0);
  const [
    impactEntertainementResolutionPercent,
    setImpactEntertainementResolutionPercent,
  ] = useState(0);
  const [
    impactEntertainementResolutionMinutes,
    setImpactEntertainementResolutionMinutes,
  ] = useState(0);

  const [impactSatiation, setImpactSatiation] = useState(0);
  const [
    impactSatiationResolutionPercent,
    setImpactSatiationResolutionPercent,
  ] = useState(0);
  const [
    impactSatiationResolutionMinutes,
    setImpactSatiationResolutionMinutes,
  ] = useState(0);

  const [impactCargoNormal, setImpactCargoNormal] = useState(0);
  const [impactCargoFragile, setImpactCargoFragile] = useState(0);
  const [impactCargoDangerous, setImpactCargoDangerous] = useState(0);

  const handleCreateFlightIncident = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("key", key);
    formData.append("name", name);
    formData.append("impactWellness", impactWellness);
    formData.append(
      "impactWellnessResolutionPercent",
      impactWellnessResolutionPercent
    );
    formData.append(
      "impactWellnessResolutionMinutes",
      impactWellnessResolutionMinutes
    );

    formData.append("impactEntertainement", impactEntertainement);
    formData.append(
      "impactEntertainementResolutionPercent",
      impactEntertainementResolutionPercent
    );
    formData.append(
      "impactEntertainementResolutionMinutes",
      impactEntertainementResolutionMinutes
    );

    formData.append("impactSatiation", impactSatiation);
    formData.append(
      "impactSatiationResolutionPercent",
      impactSatiationResolutionPercent
    );
    formData.append(
      "impactSatiationResolutionMinutes",
      impactSatiationResolutionMinutes
    );

    formData.append("impactCargoNormal", impactCargoNormal);
    formData.append("impactCargoFragile", impactCargoFragile);
    formData.append("impactCargoDangerous", impactCargoDangerous);

    let result;
    if (id) {
      result = await FlightIncidentService.update(id, formData);
    } else {
      result = await FlightIncidentService.create(formData);
    }

    if (result.error) {
      swal(result.response.data.data.message, {
        icon: "error",
      });
    } else {
      swal(result.data.message, {
        icon: "success",
      });
    }
  };

  const getFlightIncident = async () => {
    if (id) {
      const result = await FlightIncidentService.getById(id);
      if (!result.error) {
        const data = result.data.data;
        setName(data.name);
        setKey(data.key);
        setImpactWellness(data.impactWellness);
        setImpactWellnessResolutionPercent(
          data.impactWellnessResolutionPercent
        );
        setImpactWellnessResolutionMinutes(
          data.impactWellnessResolutionMinutes
        );
        setImpactEntertainement(data.impactEntertainement);
        setImpactEntertainementResolutionPercent(
          data.impactEntertainementResolutionPercent
        );
        setImpactEntertainementResolutionMinutes(
          data.impactEntertainementResolutionMinutes
        );
        setImpactSatiation(data.impactSatiation);
        setImpactSatiationResolutionPercent(
          data.impactSatiationResolutionPercent
        );
        setImpactSatiationResolutionMinutes(
          data.impactSatiationResolutionMinutes
        );
        setImpactCargoNormal(data.impactCargoNormal);
        setImpactCargoFragile(data.impactCargoFragile);
        setImpactCargoDangerous(data.impactCargoDangerous);
      }
    }
  };

  useEffect(() => {
    /** get pack if it's an update request */
    getFlightIncident();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">
                {id ? "Edit Food" : "Create new Flight incident"}
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Flight incident</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {id
                      ? "Edit Flight incident Information"
                      : "New Flight incident"}
                  </h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/paxncargo/flight-incident"
                      type="button"
                      className="btn btn-tool bg-info"
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => handleCreateFlightIncident(e)}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mdg-textfield-out-lined">
                              <input
                                id="input-name"
                                type="text"
                                placeholder=" "
                                value={name}
                                onChange={(event) => {
                                  setName(event.target.value);
                                }}
                                required
                                style={{ color: "#fff" }}
                              />
                              <label htmlFor="input-name">
                                Flight incident Name *
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="mdg-col-sm-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-key"
                                    type="text"
                                    placeholder=" "
                                    value={key}
                                    onChange={(event) => {
                                      setKey(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-key">
                                    Flight incident Key *
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="offset-lg-11 mt-1"></div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={"%"}
                                decimalScale={
                                  impactWellness >= 100 ? 0 : 2
                                }
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactWellness}
                                id="input-impactWellness"
                                maxLength={
                                  impactWellness >= 100
                                    ? 3
                                    : null
                                }
                                required
                                onValueChange={(values) => {
                                  if (values.floatValue <= 100) {
                                    setImpactWellness(
                                      values.floatValue
                                    );
                                  } else {
                                    setImpactWellness(100);
                                  }
                                }}
                              />
                              <label htmlFor="input-setImpactWellness">
                                Impact Wellness
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={"%"}
                                decimalScale={
                                  impactWellnessResolutionPercent >= 100 ? 0 : 2
                                }
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactWellnessResolutionPercent}
                                id="input-impactWellnessResolutionPercent"
                                maxLength={
                                  impactWellnessResolutionPercent >= 100
                                    ? 3
                                    : null
                                }
                                required
                                onValueChange={(values) => {
                                  if (values.floatValue <= 100) {
                                    setImpactWellnessResolutionPercent(
                                      values.floatValue
                                    );
                                  } else {
                                    setImpactWellnessResolutionPercent(100);
                                  }
                                }}
                              />
                              <label htmlFor="input-impactWellnessResolutionPercent">
                                Impact Wellness Resolution Percent
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={" min"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactWellnessResolutionMinutes}
                                id="input-vat"
                                required
                                onValueChange={(values) => {
                                  setImpactWellnessResolutionMinutes(
                                    values.floatValue
                                  );
                                }}
                              />
                              <label htmlFor="input-service-time">
                                Impact Wellness Resolution Duration
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={"%"}
                                decimalScale={
                                  impactEntertainement >= 100 ? 0 : 2
                                }
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactEntertainement}
                                id="input-impactEntertainement"
                                maxLength={
                                  impactEntertainement >= 100
                                    ? 3
                                    : null
                                }
                                required
                                onValueChange={(values) => {
                                  if (values.floatValue <= 100) {
                                    setImpactEntertainement(
                                      values.floatValue
                                    );
                                  } else {
                                    setImpactEntertainement(100);
                                  }
                                }}
                              />
                              <label htmlFor="input-impactEntertainement">
                                Impact Entertainement
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={"%"}
                                decimalScale={
                                  impactEntertainementResolutionPercent >= 100 ? 0 : 2
                                }
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactEntertainementResolutionPercent}
                                id="input-impactEntertainementResolutionPercent"
                                maxLength={
                                  impactEntertainementResolutionPercent >= 100
                                    ? 3
                                    : null
                                }
                                required
                                onValueChange={(values) => {
                                  if (values.floatValue <= 100) {
                                    setImpactEntertainementResolutionPercent(
                                      values.floatValue
                                    );
                                  } else {
                                    setImpactEntertainementResolutionPercent(100);
                                  }
                                }}
                              />
                              <label htmlFor="input-impactEntertainementResolutionPercent">
                                Impact Entertainement Resolution Percent
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={" min"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactEntertainementResolutionMinutes}
                                id="input-impactEntertainementResolutionMinutes"
                                required
                                onValueChange={(values) => {
                                  setImpactEntertainementResolutionMinutes(
                                    values.floatValue
                                  );
                                }}
                              />
                              <label htmlFor="input-impactEntertainementResolutionMinutes">
                                Impact Entertainement Resolution Duration
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={"%"}
                                decimalScale={
                                  impactSatiation >= 100 ? 0 : 2
                                }
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactSatiation}
                                id="input-impactSatiation"
                                maxLength={
                                  impactSatiation >= 100
                                    ? 3
                                    : null
                                }
                                required
                                onValueChange={(values) => {
                                  if (values.floatValue <= 100) {
                                    setImpactSatiation(
                                      values.floatValue
                                    );
                                  } else {
                                    setImpactSatiation(100);
                                  }
                                }}
                              />
                              <label htmlFor="input-impactSatiation">
                                Impact Satiation
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                            <NumberFormat
                                suffix={"%"}
                                decimalScale={
                                  impactSatiationResolutionPercent >= 100 ? 0 : 2
                                }
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactSatiationResolutionPercent}
                                id="input-impactSatiationResolutionPercent"
                                maxLength={
                                  impactSatiationResolutionPercent >= 100
                                    ? 3
                                    : null
                                }
                                required
                                onValueChange={(values) => {
                                  if (values.floatValue <= 100) {
                                    setImpactSatiationResolutionPercent(
                                      values.floatValue
                                    );
                                  } else {
                                    setImpactSatiationResolutionPercent(100);
                                  }
                                }}
                              />
                              <label htmlFor="input-impactSatiationResolutionPercent">
                                Impact Satiation Resolution Percent
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={" min"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactSatiationResolutionMinutes}
                                id="input-impactSatiationResolutionMinutes"
                                required
                                onValueChange={(values) => {
                                  setImpactSatiationResolutionMinutes(
                                    values.floatValue
                                  );
                                }}
                              />
                              <label htmlFor="input-impactSatiationResolutionMinutes">
                                Impact Satiation Resolution Duration
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={" XP"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactCargoNormal}
                                id="input-impactCargoNormal"
                                required
                                onValueChange={(values) => {
                                  setImpactCargoNormal(values.floatValue);
                                }}
                              />
                              <label htmlFor="input-impactCargoNormal">
                                Impact Cargo Normal
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={" XP"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactCargoFragile}
                                id="input-impactCargoFragile"
                                required
                                onValueChange={(values) => {
                                  setImpactCargoFragile(values.floatValue);
                                }}
                              />
                              <label htmlFor="input-impactCargoFragile">
                                Impact Cargo Fragile
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mdg-textfield-out-lined mt-2">
                              <NumberFormat
                                suffix={" XP"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={impactCargoDangerous}
                                id="input-impactCargoDangerous"
                                required
                                onValueChange={(values) => {
                                  setImpactCargoDangerous(values.floatValue);
                                }}
                              />
                              <label htmlFor="input-impactCargoDangerous">
                                Impact Cargo Dangerous
                              </label>
                            </div>
                            <div className="col-lg-12 text-right mt-2">
                              <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FlightIncidentForm;
