import React, { useEffect, useState } from "react";
import Select from "react-select";
import AviationTypeService from "../../../services/missions/aviationType";
import ScenarioConditionService from "../../../services/scenario/scenarioCondition";
import { findInList, selectStyles } from "../../../Utils/Common";

const ScenarioConditionForm = (props) => {
    const elt = props.i;
    const conditionKey = props.i + 1;
    const arrayConditionLength = props.arrayConditionLength;
    const condition = props.condition;

    const [conditionType, setConditionType] = useState(condition[0]);
    const [award, setAward] = useState(condition[1]);
    const [quantity, setQuantity] = useState(condition[2]);
    const [scenarioConditionTypeList, setConditionTypeList] = useState([]);

    const [allAviationType, setAllAviationType] = useState([]);
    const [aviationTypes, setAviationTypes] = useState();
    const [changeAviation, setChangeAviation] = useState(false);

    const getScenarioConditionTypeList = async () => {
        await ScenarioConditionService.getScenarioConditionTypeAsKeyValueService().then((res) => {
            setConditionTypeList(res);
        });
    }

    const getAllAviationType = async () => {
        const result = await AviationTypeService.getAviationTypeAsKeyValueService();
        setAllAviationType(result);
    }

    const getAviationType = () => {
        if(allAviationType && conditionType === "AVIATION_TYPE") {
            setAviationTypes(parseInt(condition[2]) );
            setChangeAviation(!changeAviation);
        };
    }

    useEffect(() => {
        getScenarioConditionTypeList();
    }, []);

    useEffect(() => {
        setConditionType(condition[0]);
        setAward(condition[1]);
        setQuantity(condition[2]);
        getAllAviationType();
        getAviationType();
    }, [condition,aviationTypes]);

    return (
        <div className="row">
            <div className="col-lg-4">
                {scenarioConditionTypeList && (
                    <div className="mdg-textfield-out-lined">
                        <label htmlFor="input-data" className="mdg-normal-label">
                            Condition type
                        </label>
                        <Select
                            placeholder="Condition Type *"
                            options={scenarioConditionTypeList}
                            styles={selectStyles}
                            isClearable={true}
                            style={{ color: "#fff" }}
                            onChange={(newValue, actionMeta) => {
                                if (newValue != null) {
                                    setConditionType(newValue.value);
                                    props.setCondition(elt, newValue.value);
                                } else {
                                    setConditionType(null);
                                }

                            }}
                            value={findInList(scenarioConditionTypeList, conditionType)}
                            required
                        />
                    </div>
                )}
            </div>
            <div className="col-lg-2">
                <div className="mdg-textfield-out-lined">
                    <label className="mdg-normal-label">
                        Award
                    </label>
                    <input
                        type="number"
                        placeholder=""
                        style={{ color: "#fff" }}
                        className="form-control mt-1"
                        min={0}
                        onChange={(e) => { props.setAward(elt, e.target.value); setAward(e.target.value) }}
                        value={award}
                    />
                </div>
            </div>
            {conditionType === "FLIGHT_NUMBER" && (
                <div className="col-lg-4">
                    <div className="mdg-textfield-out-lined">
                        <label className="mdg-normal-label">
                            Flight number
                        </label>
                        <input
                            type="text"
                            placeholder=""
                            style={{ color: "#fff" }}
                            className="form-control mt-1"
                            onChange={(e) => { props.setQuantity(elt, e.target.value); setQuantity(e.target.value) }}
                            value={quantity}
                        />
                    </div>
                </div>
            )}
            {(conditionType === "NUMBER_OF_FLIGHT_HOURS" || conditionType === "NUMBER_OF_COUNTRY_VISITS" || conditionType === "NUMBER_OF_AIRPORT_VISITS" || conditionType === "NUMBER_OF_PASSENGERS_TRANSPORTED" || conditionType === "NUMBER_OF_CARGO_IN_TONS_TRANSPORTED" || conditionType === "DISTANCE_TRAVELED_IN_KM") && (
                <div className="col-lg-4">
                    <div className="mdg-textfield-out-lined">
                        <label className="mdg-normal-label">
                            {conditionType === "NUMBER_OF_FLIGHT_HOURS" && "Number of Flight hours"}
                            {conditionType === "NUMBER_OF_COUNTRY_VISITS" && "Number of Country visits"}
                            {conditionType === "NUMBER_OF_AIRPORT_VISITS" && "Number of Airport visits"}
                            {conditionType === "NUMBER_OF_PASSENGERS_TRANSPORTED" && "Passengers Transported"}
                            {conditionType === "NUMBER_OF_CARGO_IN_TONS_TRANSPORTED" && "Cargo in tons transported"}
                            {conditionType === "DISTANCE_TRAVELED_IN_KM" && "Distance traveled in Km"}
                        </label>
                        <input
                            type="number"
                            placeholder=""
                            style={{ color: "#fff" }}
                            className="form-control mt-1"
                            min={0}
                            onChange={(e) => { props.setQuantity(elt, e.target.value); setQuantity(e.target.value) }}
                            value={quantity}
                        />
                    </div>
                </div>
            )}
            {allAviationType && conditionType === "AVIATION_TYPE" && (
                <div className="col-lg-4">
                    
                    <div className="mdg-textfield-out-lined">
                        <label htmlFor="input-data" className="mdg-normal-label">
                            Aviation type
                        </label>
                        <Select
                            placeholder="Aviation Type *"
                            options={allAviationType}
                            styles={selectStyles}
                            isClearable={true}
                            style={{ color: "#fff" }}
                            onChange={(newValue, actionMeta) => {
                                if (newValue != null) {
                                    setAviationTypes(newValue.value);
                                    props.setQuantity(elt, newValue.value);
                                } else {
                                    setAviationTypes(null);
                                }

                            }}
                            value={findInList(allAviationType, aviationTypes)}
                            required
                        />
                    </div>
                </div>
            )}
            <div className="col-lg-2 pt-4 d-flex pb-1">
                <button type="button" className="btn btn-danger mt-2" onClick={(e) => props.deleteCondition(elt)}><i className="fa fa-trash" aria-hidden="true"></i></button>
                {arrayConditionLength === conditionKey && <button type="button" className="btn btn-warning ml-1 mt-2" onClick={(e) => props.appendCondition()}><i className="fa fa-plus" aria-hidden="true"></i></button>}
            </div>
        </div>
    )
}

export default ScenarioConditionForm;