import axios  from "../AxiosInstance"

const getAllPack = async (id) => {
    try {
      return axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const getAllPackUpToSell = async (id) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs/up-to-sell`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


const getById = async (id) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id,data) => {
  try {
      return await axios({
          method: "put",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs/${id}`,
          data: data
      }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const orderPackService = async (packId,qty) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs/order`,
      data: {
        packId: packId,
        qty: qty
      },
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllAsKeyValue = async () => {
  const res = await getAllPack();
  if (res.error) {
      return [];
  } else {
      const output = [];
      const  data  = res.data;
      
      data.forEach( (elt,i) => {
          output.push({ label: elt.name, value: elt.id });
      })

      return output;
  }
}

const setPacksSortByService = async (data) => {
  try {
      return await axios({
          method: "post",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/packs/set-sort-by`,
          data: data
      });
  } catch (err) {
      return {
          error: true,
          response: err.response,
      };
  }
};

const PackService = {
    getAllPack,
    getById,
    deleteById,
    update,
    create,
    orderPackService,
    getAllAsKeyValue,
    getAllPackUpToSell,
    setPacksSortByService
}

export default PackService;