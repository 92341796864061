import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import { findInList, selectStyles } from "../../../Utils/Common";
import swal from "sweetalert";
import DescriptionComponent from "../description/DescriptionComponent";
import StoreCategoryService from "../../../services/store/catogory";
import ManageProfileService from "../../../services/manage-profile";

const ManageProfileForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [type, setType] = useState("");


    const getManageProfile = async () => {
        if (id) {
            const result = await ManageProfileService.getManageProfileById(id);
            if(!result.error){
                const data = result.data;
                setType(data.type);
            }
        }
    };

    useEffect(() => {
        getManageProfile();
    }, []);

    const createManageProfileHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('type', type);

        let result;

        if (id) {
            result = await ManageProfileService.updateManageProfileService(id, formData);
        } else {
            result = await ManageProfileService.createManageProfileService(formData);
        }


        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Profile type</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Profile type</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName} Profile type</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/manage-profile/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createManageProfileHandler(e)}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-profile-type"
                                                                type="text"
                                                                placeholder=" "
                                                                value={type}
                                                                onChange={(event) => {
                                                                    setType(event.target.value);
                                                                }}
                                                                required
                                                                style={{ color: "#fff" }}
                                                            />
                                                            <label htmlFor="input-profile-type">
                                                                Type *
                                                            </label>
                                                        </div>
                                                            <div className="col-lg-12 text-right mt-2">
                                                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                                    Save
                                                                </button>
                                                            </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ManageProfileForm;
