import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { tableRowStyle } from "../../../Utils/Common";
import LocationService from "../../../services/base/location";

const LocationTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const getList = async () => {
    setLoading(true);
    const filter = {
      page: currentPage - 1,
      size: perPage,
      search: search,
    };

    const res = await LocationService.getPaginatedLocations(filter);
    if (!res.error) {
      setData(res.data.items);
      setTotalRows(res.data.totalItems);
    }

    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, [currentPage, perPage, props.refresh]);

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: (row) => row.label,
      sortable: true,
      reorder: true,
    },
    {
      name: "ident",
      selector: (row) => row.ident,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      name: "type",
      selector: (row) => row.type,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      name: "gps_code",
      selector: (row) => row.gps_code,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      name: "iso_country",
      selector: (row) => row.iso_country,
      sortable: true,
      center: true,
      reorder: true,
    },
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <DataTable
      title=""
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => {
        console.log(selectedRows);
      }}
      conditionalRowStyles={tableRowStyle}
    />
  );
};

export default LocationTable;
