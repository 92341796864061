import { getJSON } from "jquery";
const getCountryListService = async (n = "") => {
    const resp = await getJSON("/countries.json");
    const countries = [];
    if (n === "all") countries.push({ label: "All", value: "ALL" });
    for (let i = 0; i < resp.length; ++i) {
        const country = resp[i];
        countries.push({ label: country.text, value: country.value });
    }
    return countries;
};

const CountryService = {
    getCountryListService,
}

export default CountryService;