import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Language from "../../../extra/language/language";

export default class HeaderBo extends Component {
  render() {
    const pendigOrder = this.props.pendigOrder;
    return (
      <nav className="main-header navbar navbar-expand navbar-dark">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <NavLink to="/" className="nav-link">
              <Language word={"Admin.Header.Home"}></Language>
            </NavLink>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink
              to="/admin/setting/general"
              className="nav-link"
              href="#"
              role="button"
              title="Logout"
            >
              <i className="fa fa-cogs" style={{ color: "#fff" }}></i>
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" style={{ color: "#fff" }}></i>
              <span className="badge badge-warning navbar-badge">{pendigOrder}</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">
                {pendigOrder} Notifications
              </span>
              <div className="dropdown-divider"></div>
              {/* <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2"></i> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2"></i> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2"></i> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a> */}
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item">
                <i className="fa fa-cart-arrow-down mr-2"></i> {pendigOrder} Store Order
                {/* <span className="float-right text-muted text-sm">2 days</span> */}
              </a>
              <div className="dropdown-divider"></div>
              <a href="#" className="dropdown-item dropdown-footer">
                See All Notifications
              </a>
            </div>
          </li>
          <li className="nav-item">
            <NavLink
              to="/logout"
              className="nav-link"
              href="#"
              role="button"
              title="Logout"
            >
              <i className="fa fa-sign-out" style={{ color: "#fff" }}></i>
              <span style={{ color: "#fff" }}>&nbsp;<Language word={"Admin.Header.Logout"}></Language></span>
            </NavLink>
          </li>
        </ul>
      </nav>
    );
  }
}
