import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import { getImageUrl, tableRowStyle } from "../../../Utils/Common";
import swal from 'sweetalert'
import { NavLink } from "react-router-dom";
import StoreProductService from "../../../services/store/product";
import Thumbnail from "../../extra/img/thumbnail";
import StoreOrderService from "../../../services/store/order";


const StoreOrderTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const getList = async () => {
    setLoading(true);

    const filter = {
      page: currentPage - 1,
      size: perPage,
      search: search
    }

    const response = await StoreOrderService.getList(filter);
    if (!response.error) {
      setData(response.data.items);
      setTotalRows(response.data.totalItems);
    }

    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, [currentPage, perPage]);

  const handleDelete = useCallback(
    row => async () => {
      swal(`Are you sure to delete the store product : ${row.title}`, {
        icon: "warning",
        buttons: {
          cancel: "Cancel",
          confirm: "Yes"
        },
      }).then(value => {
        if (value) {
          StoreProductService.deleteProduct(row.id).then((res) => {
            if (res.error) {
              swal("Deleted!", "An error was occurred!", "error");
            }
            else {
              swal("Deleted!", "Store Product deleted with success!", "success");
              getList();
            }
          });
        }
      })
    },
    [currentPage, perPage, totalRows]
  );

  const columns = useMemo(
    () => [
      {
        name: "Product image",
        selector: (row) => row.storeProduct.image,
        sortable: false,
        reorder: false,
        cell: (row) => (
          <Thumbnail src={getImageUrl(row.storeProduct.image)}></Thumbnail>
        )
      },
      {
        name: "Title",
        selector: (row) => row.storeProduct.title,
        sortable: true
      },
      {
        name: "Quantity",
        selector: (row) => row.quantity,
        sortable: true
      },
      {
        name: "Total Price",
        selector: (row) => row.totalPrice + ' ' + row.payementCurrency,
        sortable: true
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true
      },
      {
        name: "By",
        selector: (row) => row.user.userName,
        sortable: true
      },
      // {
      //   width: '200px',
      //   cell: row =>
      //   (
      //     <>
      //       <NavLink className="mdg-btn mdg-btn-info mr-2" to={`edit/${row.id}`} ><span className="fa fa-edit"></span></NavLink>
      //       <button className="mdg-btn mdg-btn-danger mr-2" onClick={handleDelete(row)}><span className="fa fa-trash"></span></button>
      //     </>
      //   )
      // }
    ],
    [handleDelete]
  );

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <DataTable
      title=""
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => { console.log(selectedRows) }}
      conditionalRowStyles={tableRowStyle}
    />
  );
};


export default StoreOrderTable;

