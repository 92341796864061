import axios  from "../AxiosInstance"

export const getStoreCategoryListService = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-category`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const createStoreCategoryService = async (data) => {
  try {
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-category`,
        data: data
    }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const updateStoreCategoryService = async (id, data) => {
  try {
    return await axios({
        method: "put",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-category/${id}`,
        data: data
    }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getCategoryListAsKeyValueService = async () => {
  const res = await getStoreCategoryListService();
  if (res.error) {
    return [];
  } else {
    const categories = [];
    for (let i = 0; i < res.data.length; ++i) {
      const elt = res.data[i];
      const parentName = elt.parentCategory
        ? elt.parentCategory.categoryName + " - "
        : "";

      categories.push({ label: parentName + elt.categoryName, value: elt.id });
    }
    return categories;
  }
};

export const getAvailableStoreCategoryListAsKeyValueService = async(id) => {
  const res = await getStoreCategoryListService();
  if (res.error) {
    return [];
  } else {
    const storeCategories = [];
    for (let i = 0; i < res.data.length; ++i) {
        const parentName = res.data[i].parentStoreCategory
            ? res.data[i].parentStoreCategory.storeCategoryName + " - "
            : "";
        const elt = {
            label: `${parentName} ${res.data[i].storeCategoryName}`,
            value: res.data[i].id,
        };

        if (id && Number(id) === Number(res.data[i].id))continue;
            
        storeCategories.push(elt);
    }
    return storeCategories;
  }
}

export const deleteCategory = async (id) => {
  try {
    return await axios.delete(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-category/${id}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getStoreCategoryById = async (id) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-category/${id}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getChilds = async (id) => {
  try {
    if(id){
      return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/categories/by/parent/?parent=${id}`);
    }
    else{
      return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/categories/by/parent`);
    }
    
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getList = async (filter) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/store-category/list`, 
    { params: filter });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const StoreCategoryService = {
  getStoreCategoryListService,
  createStoreCategoryService,
  updateStoreCategoryService,
  getCategoryListAsKeyValueService,
  deleteCategory,
  getStoreCategoryById,
  getChilds,
  getAvailableStoreCategoryListAsKeyValueService,
  getList
}

export default StoreCategoryService;