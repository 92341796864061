import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import ItemTypesService from "../../../services/ItemTypes";
import LevelService from "../../../services/missions/level";
import MissionTypeService from "../../../services/missions/types";
import DescriptionComponent from "../description/DescriptionComponent";

const TypeForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [label, setLabel] = useState("");

    const getLevel = async () => {
        
        if (id) {
            const result = await MissionTypeService.getTypeById(id);
            if (!result.error) {
                
                const data = result.data.data;
                setLabel(data.label);
            }
        }
    };

    useEffect(() => {
        getLevel();
    }, []);

    const createMissionTypeHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('label', label);

        let result;

        if (id) {
            result = await MissionTypeService.updateTypeService(id,formData);
        } else {
            result = await MissionTypeService.createTypeService(formData);
        }

        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Type</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Type</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">New Type</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/mission-type/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createMissionTypeHandler(e)}>
                                                <div className="row">
                                                    <div className="mdg-card-body mdg-row">
                                                        <div className="col-lg-12">
                                                            <div className="">
                                                                <label htmlFor="input-item-type-name" className="mdg-normal-label">
                                                                    Label
                                                                </label>
                                                                <input
                                                                    id="input-item-type-name"
                                                                    type="text"
                                                                    placeholder=""
                                                                    value={label}
                                                                    onChange={(event) => {
                                                                        setLabel(event.target.value);
                                                                    }}
                                                                    required
                                                                    style={{ color: "#fff" }}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 text-right mt-2">
                                                            <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default TypeForm;
