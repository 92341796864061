import DataTable from "react-data-table-component";
import { getFormatedDate, getIfCommissionDescription } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";

const WalletDatatable = (props) => {
  const rowStyle = [
    {
      when: (row) => row.id > 0,
      style: {
        backgroundColor: "transparent",
        color: "#fff",
        userSelect: "none",
      },
    },
  ];

  const columns = [
    {
      name: <Language word={"Wallet.Description"}></Language>,
      selector: (row) => getIfCommissionDescription(row.description),
      sortable: true,
      width: "50%",
    },    
    {
      name: <Language word={"Wallet.Date"}></Language>,
      selector: (row) => getFormatedDate(row.createdAt),
      sortable: true,
      width: "15%",      
    },
    {
      name: <Language word={"Wallet.Amount"}></Language>,
      selector: (row) =>
        row.walletType === "MFC"
          ? row.amount + " FC"
          : row.amount + " " + row.walletType,
      sortable: true,
      right: true,
      width: "15%", 
    },
    {
      name: <Language word={"Wallet.Type"}></Language>,
      selector: (row) => row.transactionType,
      sortable: true,
      right: true,
      width: "15%", 
    },
  ];
  return (
    <>
      <DataTable
        className="mdg-card-profil-flight"
        style={{ background: "transparent", color: "#fff" }}
        // title="History"
        columns={columns}
        data={props.data}
        defaultSortFieldID={1}
        progressPending={props.progressPending}
        paginationDefaultPage={props.paginationDefaultPage}
        paginationServer
        paginationTotalRows={props.paginationTotalRows}
        pagination
        selectableRows
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        conditionalRowStyles={rowStyle}
      />
    </>
  );
};

export default WalletDatatable;
