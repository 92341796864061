import React, { useState } from "react";
import Thumbnail from "../../../extra/img/thumbnail";
import Swal from "sweetalert2";
import QuantityPickerInput from "../../../extra/quantite_picker/QuantityPickerPages";
import withReactContent from "sweetalert2-react-content";
import MetaCart from "../../../../actions/cartActions";
import { connect } from "react-redux";
import Language from "../../../extra/language/language";
import { useSelector } from "react-redux";
import translate from "../../../extra/language/translate";
import { getImageUrl } from "../../../../Utils/Common";

export class PackEltContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.pack = {
      id: props.pack.id,
      name: props.pack.name,
      image: getImageUrl(props.pack.photo),
      price: props.pack.price,
      fcAmount: props.pack.fcAmount,
      description: props.pack.description,
      type: "pack",
      subscription: false,
    };

    this.handleBuyNow = this.handleBuyNow.bind(this);
    this.history = props.history;
  }

  handleBuyNow = (event) => {
    event.preventDefault();
    let item = this.pack;
    item.quantity = 1;
    this.props.addToCart(item);
  };

  handleBuyNowOld = async (event) => {
    event.preventDefault();
    const name = this.props.pack.name;
    let inputValue = 1;
    const lang = this.props.lang;
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: translate('Packs.Do_you_want_to_order_this', lang) + ` ${name}`,
      html: (
        <>
          <label htmlFor="input-miles"><Language word={"Packs.Quantity"}></Language> *</label>
          <QuantityPickerInput
            className="mt-2"
            min={1}
            inputValue={inputValue}
            onChange={(value) => {
              inputValue = value;
            }}
          ></QuantityPickerInput>
        </>
      ),
      showConfirmButton: true,
      showCloseButton: false,
      width: 600,
    }).then(async (res) => {
      if (res.isConfirmed) {
        let item = this.pack;
        item.quantity = inputValue;
        this.props.addToCart(item);
        this.checkCart();
      }
    });
  };

  checkCart() {
    if (this.props.myCart.error) {
      Swal.fire({
        icon: "error",
        text: this.props.myCart.message,
      });
    } else {
      if (this.props.myCart.numberCart >= 1) {
        this.history("/checkout");
      }
    }
  }

  render() {
    return (
      <>
        <div className={`${this.props.packClass}`}>
          <div className={`${this.props.packBg}`}>
            {this.props.bestValue === this.pack.id && (
              <div className="mdg-ribbon-right">
                <span><Language word={"Packs.Best_value"}></Language></span>
              </div>
            )}

            <div className="mdg-card-body-items">
              <div>
                <div>
                  <Thumbnail
                    src={this.pack.image}
                    alt="logo"
                    className="mdg-image-body"
                  ></Thumbnail>
                </div>
                <div
                  className="mdg-body-description mt-2 pl-2"
                  style={{ position: "relative" }}
                >
                  <div className="mdg-body-footer-description mt-3">
                    <div className="col-lg-12">
                      <span className="mdg-bg-text-items" style={{ display: 'flex' }}>
                        <Thumbnail
                          src={"/assets/image/coin.png"}
                          width="30"
                          height="30"
                          className="rounded-circle-sm mr-1"
                          style={{ marginTop: '-2px' }}
                        ></Thumbnail> {this.pack.fcAmount} FC
                      </span>
                      <span className="mdg-bg-text-items">
                        {this.pack.name}
                      </span>
                      <br />
                      <span className="mdg-bg-text-items mdg-text-marque">
                        {this.pack.description}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer mdg-card-footer-items">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-sm-6">
                    <span className="align-center mdg-bg-text-items">
                      <Language word={"Packs.Price"}></Language>: {this.pack.price}€
                    </span>
                  </div>
                  <div className="col-sm-6">
                    <button
                      className="btn mdg-btn-common btn-sm float-right"
                      onClick={this.handleBuyNow}
                    >
                      <span><Language word={"Packs.Buy_now"}></Language></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  MetaCart.mapState,
  MetaCart.mapDispach
)(PackEltContainer);
