import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import ItemTypesService from "../../../services/ItemTypes";
import DescriptionComponent from "../description/DescriptionComponent";

const ItemTypeForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [itemTypesName, setItemTypeName] = useState("");
    const [itemTypesTextColor, setItemTypeTextColor] = useState("");
    const [itemTypesColor, setItemTypeColor] = useState("");

    const getItemType = async () => {
        if (id) {
            const result = await ItemTypesService.getItemsTypesById(id);
            if(!result.error){
                const data = result.data;
                setItemTypeName(data.name);
                setItemTypeColor(data.color);
                setItemTypeTextColor(data.textColor);
            }
        }
    };

    useEffect(() => {
        getItemType();
    }, []);

    const createItemTypeHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('name', itemTypesName);
        formData.append('color', itemTypesColor);
        formData.append('textColor', itemTypesTextColor);
        
        let result;

        if (id) {
            result = await ItemTypesService.updateItemsTypesService(id,formData);
        } else {
            result = await ItemTypesService.createItemsTypesService(formData);
        }

        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Types</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Types</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">New Types</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/type/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createItemTypeHandler(e)}>
                                                <div className="row">
                                                    <div className="mdg-card-body mdg-row">
                                                        <div className="col-lg-6">
                                                            <div className="">
                                                                <label htmlFor="input-item-type-name" className="mdg-normal-label">
                                                                    Item Type Name
                                                                </label>
                                                                <input
                                                                    id="input-item-type-name"
                                                                    type="text"
                                                                    placeholder=" "
                                                                    value={itemTypesName}
                                                                    onChange={(event) => {
                                                                        setItemTypeName(event.target.value);
                                                                    }}
                                                                    required
                                                                    style={{ color: "#fff" }}
                                                                    className="form-control"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <label className="mdg-normal-label">Background</label>
                                                            <input
                                                                id="input-item-type-color"
                                                                type="color"
                                                                placeholder="text color"
                                                                value={itemTypesTextColor}
                                                                onChange={(event) => {
                                                                    setItemTypeTextColor(event.target.value);
                                                                }}
                                                                required
                                                                style={{ width: "100%", height: "50px" }}
                                                            />
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <label className="mdg-normal-label">Text Color</label>
                                                            <input
                                                                id="input-item-type-color"
                                                                type="color"
                                                                placeholder="background color"
                                                                value={itemTypesColor}
                                                                onChange={(event) => {
                                                                    setItemTypeColor(event.target.value);
                                                                }}
                                                                required
                                                                style={{ width: "100%", height: "50px", background: "transparent", color: "#fff" }}
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 text-right mt-2">
                                                            <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {/* <div className="offset-lg-11 mt-1">
                                                        <button className="mdg-btn mdg-btn-outline-meta mdg-btn-round float-right">
                                                            Save
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default ItemTypeForm;
