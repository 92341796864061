import axios  from "../AxiosInstance"

const getAllSettings = async (id) => {
    try {
      return axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/setting`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/setting`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};


const getSettingsByName = async (name) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/setting/${name}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getFremiumConfig = async () => {
  return axios.get(
    `${process.env.REACT_APP_REMOTE_API_BASE_URL}/setting/fremium-setting`
  ).then(res => {
    return res.data;
  }).catch(err => {
      return Promise.reject(err);
  });
  
};

const updateFremiumConfig = async (fcPerHour,fcPerMinutes,maxFcPerDay) => {
  return axios.post(
    `${process.env.REACT_APP_REMOTE_API_BASE_URL}/setting/update-fremium-setting`,{
      fcPerHour,
      fcPerMinutes,
      maxFcPerDay
    }
  ).then(() => {
    return Promise.resolve('Setting updated with success');
  }).catch(err => {
      return Promise.reject(err);
  });
};

const getGifConfig = async () => {
  return axios.get(
    `${process.env.REACT_APP_REMOTE_API_BASE_URL}/setting/gif-urls`
  ).then(res => {
    return res.data;
  }).catch(err => {
      return Promise.reject(err);
  });
  
};

const updateGifConfig = async (ingamestore_fr, ingamestore_en ,dashboard) => {
  return axios.post(
    `${process.env.REACT_APP_REMOTE_API_BASE_URL}/setting/update-gif-urls`,{
      ingamestore_fr,
      ingamestore_en,
      dashboard
    }
  ).then(() => {
    return Promise.resolve('Setting updated with success');
  }).catch(err => {
      return Promise.reject(err);
  });
};

const SettingService = {
    getAllSettings,
    getSettingsByName,
    create,
    getFremiumConfig,
    updateFremiumConfig,
    updateGifConfig,
    getGifConfig
}

export default SettingService;