import axios  from "../AxiosInstance"

const getAll = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/features`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteById = async (id) => {
    try {
        return await axios.delete(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/features/${id}`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/features`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const update = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/features/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

export const getById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/features/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const getAllAsKeyValue = async () => {
    const res = await getAll();
    if (res.error) {
        return [];
    } else {
        const output = [];
        const { data } = res.data;
        
        data.forEach( (elt,i) => {
            output.push({ label: elt.name, value: elt.id });
        })

        return output;
    }
}

const FeaturesService = {
    create,
    deleteById,
    update,
    getAll,
    getById,
    getAllAsKeyValue
}

export default FeaturesService;