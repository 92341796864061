import HeaderPage from "../pages/fo/header/HeaderPage";
import React from "react";
import {Outlet} from "react-router-dom";
import Flightdetailscard from "../extra/livemap/tracker/planeMaker/flight/Flightdetailscard";
import LiveMap from "../extra/livemap/map";
import Right from "../pages/fo/dashboard/right/Right";
import Panier from "../pages/fo/checkout/panier/Panier";

const MainLayout = () => {
    return (
        <div>
            <HeaderPage />
            <Flightdetailscard />
            <LiveMap/>
            <Right />
            <div className="container mdg-top-container">
                <Outlet />
            </div>
            <Panier />
        </div>
    );
}

export default MainLayout;
