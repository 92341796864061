import React, { useState, useEffect, useCallback, useMemo } from "react";
import DataTable from "react-data-table-component";
import swal from 'sweetalert'
import { NavLink } from "react-router-dom";
import ManageProfileService from "../../../services/manage-profile";
import { tableRowStyle } from "../../../Utils/Common";


const ManageProfileTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  
  const getList = async () => {
    setLoading(true);

    const filter = {
        page: currentPage - 1,
        size: perPage,
        search : search
    }

    const response = await ManageProfileService.getManageProfileListService();
    console.log(response);
    if(!response.error){
        setData(response.data);
        setTotalRows(response.data.lenght);
    }
    
    setLoading(false);
  };

  useEffect(() => {
    getList();
  }, [currentPage,perPage]);

  const handleDelete = useCallback(
    row => async () => {
        swal(`Are you sure to delete the Profile Type : ${row.type}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(value => {
            if (value) {
              ManageProfileService.deleteManageProfile(row.id).then((res) => {
                    if (res.error) {
                        swal("Deleted!", "An error was occurred!", "error");
                    }
                    else {
                        swal("Deleted!", "Profile Type deleted with success!", "success");
                        getList();
                    }
                });
            }
        })
    },
    [currentPage, perPage, totalRows]
  );

  const columns = useMemo(
    () => [
      {
        name: "Type",
        selector: (row) => row.type,
        sortable: true
      },
      {
        width:'200px',
        cell: row =>
        (
            <>
                <NavLink className="mdg-btn mdg-btn-info mr-2"  to={`edit/${row.id}`} ><span className="fa fa-edit"></span></NavLink>
                <button className="mdg-btn mdg-btn-danger mr-2" onClick={handleDelete(row)}><span className="fa fa-trash"></span></button>
            </>
        ) 
      }
    ],
    [handleDelete]
  );

  const handlePageChange = page => {
        setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <DataTable
      title=""
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => { console.log(selectedRows) } }
      conditionalRowStyles= {tableRowStyle}
    />
  );
};


export default ManageProfileTable;

