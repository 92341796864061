import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import SimulatorForm from './SimulatorForm';
import SimulatorList from './SimulatorList';

const SimulatorComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <SimulatorForm action="Create"></SimulatorForm> ;break;
        case "edit": page = <SimulatorForm action="Edit"></SimulatorForm> ; break;
        default: page = <SimulatorList></SimulatorList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default SimulatorComponent;