import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import TypeForm from './TypeForm';
import TypeList from './TypeList';

const TypeComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <TypeForm action="Create"></TypeForm> ;break;
        case "edit": page = <TypeForm action="Edit"></TypeForm> ; break;
        default: page = <TypeList></TypeList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default TypeComponent;