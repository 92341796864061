import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import ItemService from '../../../../../services/Item';
import HeaderPage from '../../header/HeaderPage';
import Exchange from './Exchange';
import ItemDetail from './ItemDetail';
import Rent from './Rent';
import Sell from './Sell';

const MarketPlacePage = (props) => {
    const { id } = useParams();
    const { action } = props;

    const [ item , setItem] = useState(null);

    const getItem = async () => {
        const res = await ItemService.getItemById(id);
        if(!res.error){
            setItem(res.data);
        }
    }

    let form ; 
    let ariane = 'Sell'; 

    switch(action){
        case 'rent': ariane = 'Rent'; form = <Rent itemId={id} item={item} ></Rent>;break;
        case 'exchange': ariane = 'Exchange'; form = <Exchange itemId={id} item={item} ></Exchange>;break;
        default: form = <Sell itemId={id} item={item} ></Sell>;break;
    }
    
    document.body.style.backgroundColor = "#081217";

    useEffect(() => {
        getItem()
    },[id])
    return (
        <div className="mdg-body-ingame">
            <div className="mdg-header-ingame">
                <HeaderPage />
                <div className="container mdg-top-container">
                    <div className="row justify-content-between mdg-title-ingame mb-5">
                        <div className="col-lg-4">
                            <h4 className="mdg-h2 mdg-text-white">Seller Mode</h4>
                        </div>
                        <div className="col-lg-6 mt-2">
                            <div className="mdg-ariane-breadcrumb float-right">
                                <NavLink to={"/account/items"} className="">Seller Mode</NavLink>
                                <NavLink to={"/account/items"} className="">Manage my item</NavLink>
                                <NavLink to={`/account/items/${id}/marketplace/${action}`} className="">{ ariane }</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-between">
                        <div className="col-lg-8">
                            {item && <ItemDetail itemId={id} action={action} item={item}></ItemDetail> }                            
                        </div>
                        <div className="col-lg-4">
                            <div className="mdg-row">
                               { form }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default MarketPlacePage;