import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import { findInList, getImageUrl, selectStyles } from "../../../../Utils/Common";
import NumberFormat from "react-number-format";
import Select from "react-select";
import FoodCategoryService from "../../../../services/paxncargo/foodCategory";
import FoodService from "../../../../services/paxncargo/food";
import { TimePicker } from "antd";
import moment from "moment";

const FoodForm = (props) => {
  const { id } = useParams();
  const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [serviceTime, setServiceTime] = useState(0);
  const [satieteRate, setSatieteRate] = useState(0);
  const [wellnessRate, setWellnessRate] = useState(0);
  const [entertainmentRate, setEntertainmentRate] = useState(0);
  const [foodCategoryList, setFoodCategoryList] = useState([]);
  const [foodCategory, setFoodCategory] = useState(0);

  const handleChangeImage = () => {
    const pics = document.getElementById("id-item-pics");
    pics.click();
  };

  const handleCreateFood = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", image);
    formData.append("name", name);
    formData.append("serviceTime", serviceTime);
    formData.append("satieteRate", satieteRate);
    formData.append("wellnessRate", wellnessRate);
    formData.append("entertainmentRate", entertainmentRate);
    formData.append("foodCategoryId", foodCategory);

    console.log(formData.get('serviceTime'))


    let result;
    if (id) {
      result = await FoodService.update(id, formData);
    } else {
      result = await FoodService.create(formData);
    }

    if (result.error) {
      swal(result.response.data.data.message, {
        icon: "error",
      });
    } else {
      swal(result.data.message, {
        icon: "success",
      });
    }
  };

  const getFoodCategoryList = async () => {
    await FoodCategoryService.getAll().then((res) => {
      const lists = [];
      if (!res.error) {
        for (let i = 0; i < res.data.length; ++i) {
          const rl = res.data[i];
          lists.push({
            value: rl.id,
            label: rl.name,
          });
        }
        setFoodCategoryList(lists);
      }
    });
  };

  const getPack = async () => {
    if (id) {
      const result = await FoodService.getById(id);
      if (!result.error) {
        const data = result.data.data;
        setName(data.name);
        setServiceTime(data.serviceTime);
        setSatieteRate(data.satieteRate);
        setWellnessRate(data.wellnessRate);
        setEntertainmentRate(data.entertainmentRate);
        setFoodCategory(data.foodCategoryId);
        if (data.icon) {
          setPhotoUrl(getImageUrl(data.icon));
        }
      }
    }
  };

  useEffect(() => {
    /** get pack if it's an update request */
    getPack();
    getFoodCategoryList();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{id ? "Edit Food" : "Create new Food"}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Food</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {id ? "Edit Food Information" : "New Food"}
                  </h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/paxncargo/food"
                      type="button"
                      className="btn btn-tool bg-info"
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => handleCreateFood(e)}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="mdg-col-sm-12 text-center">
                                <input
                                  className="mdg-d-none"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  id="id-item-pics"
                                  onChange={(event) => {
                                    setImage(event.target.files[0]);
                                    setPhotoUrl(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                  }}
                                />

                                <img
                                  onClick={handleChangeImage}
                                  className="mdg-upload-img"
                                  src={photoUrl && photoUrl}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="mdg-col-sm-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-food-name"
                                    type="text"
                                    placeholder=" "
                                    value={name}
                                    onChange={(event) => {
                                      setName(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-food-name">
                                    Food Name *
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined mt-2">
                                  <NumberFormat
                                    suffix={" min"}
                                    decimalSeparator=","
                                    style={{ color: "#fff" }}
                                    className={`text-right`}
                                    value={serviceTime}
                                    id="input-vat"
                                    required
                                    onValueChange={(values) => {
                                        setServiceTime(values.floatValue);
                                    }}
                                  />
                                  <label htmlFor="input-service-time">
                                    Satiete Rate
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined mt-2">
                                  <NumberFormat
                                    suffix={"%"}
                                    decimalScale={satieteRate >= 100 ? 0 : 2}
                                    decimalSeparator=","
                                    style={{ color: "#fff" }}
                                    className={`text-right`}
                                    value={satieteRate}
                                    id="input-vat"
                                    maxLength={satieteRate >= 100 ? 3 : null}
                                    required
                                    onValueChange={(values) => {
                                      if (values.floatValue <= 100) {
                                        setSatieteRate(values.floatValue);
                                      } else {
                                        setSatieteRate(100);
                                      }
                                    }}
                                  />
                                  <label htmlFor="input-satiete-rate">
                                    Satiete Rate
                                  </label>
                                </div>
                                
                                <div className="mdg-textfield-out-lined mt-2">
                                  <NumberFormat
                                    suffix={"%"}
                                    decimalScale={wellnessRate >= 100 ? 0 : 2}
                                    decimalSeparator=","
                                    style={{ color: "#fff" }}
                                    className={`text-right`}
                                    value={wellnessRate}
                                    id="input-vat"
                                    maxLength={wellnessRate >= 100 ? 3 : null}
                                    required
                                    onValueChange={(values) => {
                                      if (values.floatValue <= 100) {
                                        setWellnessRate(values.floatValue);
                                      } else {
                                        setWellnessRate(100);
                                      }
                                    }}
                                  />
                                  <label htmlFor="input-wellness-rate">
                                    Wellness Rate
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined mt-2">
                                  <NumberFormat
                                    suffix={"%"}
                                    decimalScale={
                                      entertainmentRate >= 100 ? 0 : 2
                                    }
                                    decimalSeparator=","
                                    style={{ color: "#fff" }}
                                    className={`text-right`}
                                    value={entertainmentRate}
                                    id="input-vat"
                                    maxLength={
                                      entertainmentRate >= 100 ? 3 : null
                                    }
                                    required
                                    onValueChange={(values) => {
                                      if (values.floatValue <= 100) {
                                        setEntertainmentRate(values.floatValue);
                                      } else {
                                        setEntertainmentRate(100);
                                      }
                                    }}
                                  />
                                  <label htmlFor="input-entertainment-rate">
                                    Entertainment Rate
                                  </label>
                                </div>

                                {foodCategoryList && (
                                  <div className="mdg-textfield-out-lined">
                                    <Select
                                      placeholder="Choose a food category *"
                                      options={foodCategoryList}
                                      styles={selectStyles}
                                      isClearable={true}
                                      style={{ color: "#fff" }}
                                      onChange={(newValue, actionMeta) => {
                                        newValue != null
                                          ? setFoodCategory(newValue.value)
                                          : setFoodCategory(null);
                                      }}
                                      value={findInList(
                                        foodCategoryList,
                                        foodCategory
                                      )}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="col-lg-12 text-right mt-2">
                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="offset-lg-11 mt-1"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FoodForm;
