import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import FoodCategoryForm from './FoodCategoryForm';
import FoodCategoryList from './FoodCategoryList';

const FoodCategoryComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <FoodCategoryForm action="create"></FoodCategoryForm> ;break;
        case "edit": page = <FoodCategoryForm action="edit"></FoodCategoryForm> ; break;
        default: page = <FoodCategoryList></FoodCategoryList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default FoodCategoryComponent;