import {
    VERIFY_TOKEN_STARTED, VERIFY_TOKEN_END,
    USER_LOGIN_STARTED, USER_LOGIN_FAILURE,
    VERIFY_USER_SUCCESS, USER_LOGOUT,
    UPDATE_USER_INFORMATION,LOGGED_WITH_SUCCESS
  } from "../actions/actionTypes";
   
  // define initial state of auth reducer
  const initialState = {
    token: null,
    xsrfToken : null, // manage the access token
    refreshToken : null, // manage the access token
    expiredAt: null, // manage expiry time of the access token
    user: null, // manage the user details
    authLoading: true, // to indicate that the auth API is in progress
    isAuthenticated: false, // consider as a authentication flag
    userLoginLoading: false, // to indicate that the user signin API is in progress
    loginError: null // manage the error of the user signin API
  }
   
  // update store based on type and payload and return the state
  const auth = (state = initialState, action) => {
    
    switch (action.type) {
      // verify token - started
      case VERIFY_TOKEN_STARTED:
        const {  silentAuth } = action.payload;
        return silentAuth ? {
          ...state
        } : initialState;
      // verify token - ended/failed
      case VERIFY_TOKEN_END:
        return {
          ...state,
          authLoading: false
        };
      // user login - started
      case USER_LOGIN_STARTED:
        return {
          ...state,
          userLoginLoading: true,
          loginError: null
        };
      // user login - ended/failed
      case USER_LOGIN_FAILURE:
        const { error } = action.payload;
        return {
          ...state,
          loginError: error,
          userLoginLoading: false
        };
      // verify token - success
      case VERIFY_USER_SUCCESS:
        const {   
          token,
          xsrfToken,
          refreshToken,
          expiredAt 
        } = action.payload;

        return {
          ...state,
          token,
          xsrfToken,
          refreshToken,
          expiredAt,
          isAuthenticated: true,
          authLoading: false,
          userLoginLoading: false
        }

      case UPDATE_USER_INFORMATION: 
        const {user} = action.payload;
        return {
          ...state,
          user,
          isAuthenticated: !!user,
      }

      case LOGGED_WITH_SUCCESS: 
          return {
            ...state,
            token : action.payload.token,
            user: action.payload.user,
            xsrfToken: action.payload.xsrfToken,
            refreshToken: action.payload.refreshToken,
            expiredAt: action.payload.expiredAt,
            isAuthenticated: true,
            authLoading: false,
            userLoginLoading: false
          }
      // handle user logout
      case USER_LOGOUT:
        return {
          ...initialState,
          authLoading: false
        }
      default:
        return state
    }
  }
   
  export default auth;