import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import UserService from "../../../../services/user";
import Language from "../../../extra/language/language";

const SynchroniseToAfrv = () => {
    const {user} = useSelector ((state) => state.auth);
    
    const [idAfrv,setIdAfrv] = useState(user.id_afrv);
    const [keyMetaAfrv,setKeyMetaAfrv] = useState(user.key_metaflight_afrv);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const synchronisation = await UserService.synchronizeToAfrv(idAfrv,keyMetaAfrv);
        if(synchronisation.error){
            Swal.fire({
                text: synchronisation.response.data.message,
                icon: "error",
            })
        }else{
            Swal.fire({
                text: synchronisation.data.message,
                icon: "success",
            })
        }
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mt-4 mdg-top-border">
                <div className="col-lg-12">
                    <div className="mt-4">
                        <h6 className="mdg-text-white">
                            <Language word={"synchronisation.description"}></Language>
                        </h6>
                    </div>
                    <div className="mdg-pos-relative">
                        <div className="mdg-textfield-out-lined">
                            <input
                                id="input-id-afrv"
                                type="text"
                                placeholder=" "
                                value={idAfrv}
                                required
                                style={{ color: "#fff" }}
                                onChange={(e) => setIdAfrv(e.target.value)}
                            />
                            <label htmlFor="input-id-afrv">
                                <Language word={"synchronisation.id_afrv_meta"}></Language>
                            </label>
                        </div>
                    </div>
                    <div className="mdg-pos-relative">
                        <div className="mdg-textfield-out-lined">
                            <input
                                id="input-key-afrv-meta"
                                type="text"
                                placeholder=" "
                                value={keyMetaAfrv}
                                required
                                style={{ color: "#fff" }}
                                onChange={(e) => setKeyMetaAfrv(e.target.value)}
                            />
                            <label htmlFor="input-key-afrv-meta">
                                <Language word={"synchronisation.key_afrv_meta"}></Language>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row justify-content-end">
                        <button
                            type="submit"
                            className="btn mdg-btn-common btn-sm mt-3 mdg-btn-12"
                        >
                            <Language word={"synchronisation.save"}></Language>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default SynchroniseToAfrv;