import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import "./styles/login.css";
import App from './App';
import store from './store';
import axios from "axios";
import 'mapbox-gl/dist/mapbox-gl.css';
axios.defaults.withCredentials = true;

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <Provider store={store}>
    <App />
  </Provider>
 
);