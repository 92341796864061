import { ADD_CART, ADD_COUPON, DECREASE_QUANTITY, DELETE_CART, DELETE_COUPON, EMPTY_CART, GET_NUMBER_CART, HIDE_ERROR, INCREASE_QUANTITY, TOGGLE_SHOW_CART } from "../actions/cartActions";

let myCart = sessionStorage.getItem('MyCart');

const initialState = {
    numberCart: 0,
    Carts: [],
    hasSubsrciption: false,
    coupons: [],
    type: 'pack',
    show : false,
}

if (!myCart) {
    sessionStorage.setItem('MyCart', JSON.stringify(initialState))
    myCart = initialState;
} else {
    try {
        myCart = JSON.parse(myCart);
    } catch (err) {
        myCart = initialState;
    }
}

const cartState = {
    numberCart: myCart.numberCart ? myCart.numberCart : initialState.numberCart,
    Carts: myCart.Carts ? myCart.Carts : initialState.Carts,
    coupons: myCart.coupons ? myCart.coupons : initialState.coupons,
    hasSubsrciption: myCart ? myCart.hasSubsrciption : initialState.hasSubsrciption,
    type: myCart ? myCart.type : initialState.type,
    show: false,
}

const cart = (state = cartState, action) => {
    switch (action.type) {
        case GET_NUMBER_CART:
            return state.numberCart;
        case ADD_CART:
            if(action.payload.type === 'level'){
                let _cart = {
                    id: action.payload.id,
                    quantity: 1,
                    name: action.payload.name,
                    image: action.payload.image,
                    price: action.payload.price,
                    type: action.payload.type
                }

                state.Carts = [_cart];
                state.numberCart = 1;
                state.hasSubsrciption = false;
                state.type = 'level';
                sessionStorage.setItem('MyCart', JSON.stringify(state))
                state.show = true;

                return {
                    ...state
                }
            }

            const findPack = state.Carts.find((item) => item.type === "level");
            if(findPack){
                state.Carts = [];
                state.numberCart = 0;
            }

            state.type = 'pack';

            if (state.numberCart === 0) {
                let cart = {
                    id: action.payload.id,
                    quantity: action.payload.subscription ? 1 : action.payload.quantity,
                    name: action.payload.name,
                    image: action.payload.image,
                    price: action.payload.price,
                    type: action.payload.type
                }
                state.Carts = [cart];
                state.numberCart = action.payload.quantity;
                if (action.payload.type === 'subscription') {
                    state.hasSubsrciption = true;
                    state.type = 'subscription';
                }
                state.show = true;
                
            }
            else {
                if (state.hasSubsrciption) {
                    
                    return {
                        ...state,
                        error: true,
                        message: "Panier.Add_pack_to_cart_error"
                    }
                }
                else if (action.payload.subscription) {

                    return {
                        ...state,
                        error: true,
                        message: "Panier.Add_subscription_to_cart_error"
                    }
                }
                let check = false;
                state.Carts.map((item, key) => {
                    if (item.id === action.payload.id) {
                        state.Carts[key].quantity++;
                        state.numberCart = state.numberCart + 1;
                        check = true;
                    }
                });
                if (!check) {
                    let _cart = {
                        id: action.payload.id,
                        quantity: action.payload.quantity,
                        name: action.payload.name,
                        image: action.payload.image,
                        price: action.payload.price,
                        type: action.payload.type
                    }
                    state.Carts.push(_cart);
                    state.numberCart += action.payload.quantity;
                }
                
                state.show = true;
                
            }
          

            sessionStorage.setItem('MyCart', JSON.stringify(state))

            return {
                ...state
            }
        case INCREASE_QUANTITY:
            if(state.Carts[action.payload].type =="subscription"){
                state.type = 'subscription';
                return {
                    ...state,
                }
            }
            state.numberCart++
            state.Carts[action.payload].quantity++;

            sessionStorage.setItem('MyCart', JSON.stringify(state))

            return {
                ...state
            }
        case DECREASE_QUANTITY:
            if(state.Carts[action.payload].type =="subscription"){
                return {
                    ...state,
                }
            }
            let quantity = state.Carts[action.payload].quantity;

            if (quantity > 1) {
                state.numberCart--;
                state.Carts[action.payload].quantity--;
            }

            sessionStorage.setItem('MyCart', JSON.stringify(state))

            return {
                ...state
            }
        case DELETE_CART:
            let quantity_ = state.Carts[action.payload].quantity;
            state.numberCart -= quantity_;
            state.Carts = state.Carts.filter(item => {
                return item.id != state.Carts[action.payload].id
            })

            sessionStorage.setItem('MyCart', JSON.stringify(state))
            return {
                ...state
            }
        case EMPTY_CART:
            sessionStorage.removeItem('MyCart')
            return {
                numberCart: 0,
                Carts: [],
                hasSubsrciption: false,
                coupons: [],
                show: false
            }
        case ADD_COUPON:
            let check = false;
            state.coupons.map((item, key) => {
                if (item.code === action.payload.code) {
                    check = true;
                }

                return item;
            });
            if (!check) {

                state.coupons.push(action.payload);
            }

            sessionStorage.setItem('MyCart', JSON.stringify(state))

            return {
                ...state
            }

        case DELETE_COUPON:
            let coupons = [];
            console.log(state.coupons);
            state.coupons.map((item, key) => {
                if (item.code != action.payload) {
                    coupons.push(item)
                }

                return item
            });
          
            state.coupons = coupons;
            sessionStorage.setItem('MyCart', JSON.stringify(state))
            
            return {
                ...state,
            }

        case TOGGLE_SHOW_CART:
            state.show = !state.show;
            
            return {
                ...state
            }
        case HIDE_ERROR:
            state.error = false;
            
            return {
                ...state
            }

        default:
            return state;
    }
}

export default cart;