import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import OrderList from './OrderList';

const OrderComponent = (props) => {
    let page ;

    switch (props.page){
        // case "new": page = <PackForm action="create"></PackForm> ;break;
        // case "edit": page = <PackForm action="edit"></PackForm> ; break;
        default: page = <OrderList></OrderList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default OrderComponent;