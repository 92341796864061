const translation = require("../../../translation/translation.json");

const getWord = (word, lang) => {
    if(!lang) lang = 'en';
    const t = translation[lang];
    const words = word.split(".");
    let wordRes = t;
    for (let i = 0; i < words.length; i++) {
        wordRes = wordRes[words[i]]
        if (!wordRes) {
            wordRes = word
        }
    }

    return wordRes;
}

const translate = (word, lang) => {
    return getWord(word, lang);
}

export default translate;
