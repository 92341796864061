import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import swal from 'sweetalert';
import Thumbnail from "../../extra/img/thumbnail";
import MissionService from "../../../services/missions/mission";
import { getImageUrl } from "../../../Utils/Common";

const MissionList = () => {
    const [missionList, setMissionList] = useState([]);
    const [initialItemTypesList, setInitialMissionList] = useState([]);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const columns = [
        {
            id: 1,
            name: "Photo",
            selector: (row) => row.photo,
            sortable: false,
            reorder: false,
            cell: (row) => (
                <Thumbnail src={getImageUrl(row.photo)}></Thumbnail>
            )
        },
        {
            id: 2,
            name: "Title",
            selector: (row) => row.title,
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            reorder: true
        },
        {
            id: 4,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/mission/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 6,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deleteMission(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const deleteMission = async (row) => {
        swal(`Are you sure to delete the mission : ${row.title??''}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await MissionService.deleteByIdService(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }

                getMissionList()
            }
        });
    }

    const getMissionList = async () => {
        
        await MissionService.getAllMissionServiceAsAdmin().then((res) => {
            const types = [];
            for (let i = 0; i < res.data.length; ++i) {
                const elt = res.data[i];
                types.push({
                    id: elt.id,
                    photo: elt.image,
                    title: elt.title,
                    description: elt.description,
                });
            }
            setInitialMissionList(missionList);
            setMissionList(types);
        });
    };

    useEffect(() => {
        getMissionList();
    }, []);


    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Missions</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Missions</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Missions</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/mission/new" type="button" className="btn btn-tool bg-info" data-card-widget="collapse">
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 mdg-historique-table">
                                            {missionList && (<DataTable
                                                columns={columns}
                                                data={missionList}
                                                defaultSortFieldId={1}
                                                pagination
                                                selectableRows
                                                conditionalRowStyles={rowStyle}
                                            />)}
                                        </div>


                                    </div>

                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default MissionList;
