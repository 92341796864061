import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CategoryService from "../../../../services/Category";
import StoreCategoryService from "../../../../services/store/catogory";
import StoreCategoryLi from "./storeCategoryLi";
import "./style.css";

const StoreCategoryDropDownList = (props) => {
    const [categories, setCategories] = useState([]);
    const [actives, setActives] = useState([]);

    const getCategories = async () => {
        const result = await StoreCategoryService.getStoreCategoryListService();
        // console.log(result);
        if (!result.error) {
            setCategories(result.data);
        }
    }



    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        getActives();
    }, [props.currentCategory, categories]);

    const getActives = () => {
        let activeCategs = [];
        activeCategs = findParent(props.currentCategory);

        setActives(actives => activeCategs);
    }

    const findParent = (id, parents = []) => {
        if (!id) return parents;
        id = Number(id)
        let categ = categories.find(item => Number(item.id) === id);
        if (categ != undefined) {
            parents.push(id);
            let parent = categ.parentStoreCategoryId;

            return findParent(parent, parents);
        }

        return parents;
    }

    return (
        <ul className="mdg-ul-height">
            <li>
                <NavLink
                    className={props.currentCategory == undefined ? "sidebar-item" : "nav-link"}
                    to={"/mf-store/all/all"}
                    onClick={(event) => {
                        props.setCategory(undefined)
                    }}
                    style={{ fontSize: "15px" }}
                >
                    All
                </NavLink>
            </li>
            {
                categories && categories.map((categ, i) => {
                    if (!categ.parentStoreCategory) {
                        return <StoreCategoryLi id={categ.id} activeCategs={actives} currentData={categ} name={categ.storeCategoryName} key={i} level={0} setCategory={props.setCategory} currentCategory={props.currentCategory} page={props.page} allCategories={categories}> </StoreCategoryLi>
                    }
                })
            }

        </ul>
    );
}

export default StoreCategoryDropDownList;
