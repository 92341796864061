import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import swal from 'sweetalert';
import LevelService from "../../../services/missions/level";

const LevelList = () => {
    const [levelList, setLevelList] = useState([]);
    const [initialItemTypesList, setInitialLevelList] = useState([]);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const columns = [
        {
            id: 1,
            name: "Level",
            selector: (row) => row.level,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Min Xp",
            selector: (row) => row.minXp,
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Max Xp",
            selector: (row) => row.maxXp,
            sortable: true,
            reorder: true
        },
        {
            id: 4,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/level/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 6,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deleteLevel(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const deleteLevel = async (row) => {
        swal(`Are you sure to delete the level : ${row.level??''}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await LevelService.deleteByIdService(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }

                getLevelsList()
            }
        });
    }

    const getLevelsList = async () => {

        await LevelService.getAllLevelService().then((res) => {
            const levels = [];
            for (let i = 0; i < res.data.length; ++i) {
                const elt = res.data[i];
                levels.push({
                    id: elt.id,
                    level: elt.level,
                    minXp: elt.minXp,
                    maxXp: elt.maxXp
                });
            }
            setInitialLevelList(levelList);
            filterLevels(levels, "");
        });
    };

    useEffect(() => {
        getLevelsList();
    }, []);

    const filterLevels = (list, txt) => {
        let res = list.filter(
            (elt, i) =>
                elt.level.toLowerCase().includes(txt.toLowerCase())
        );
        setLevelList(res)
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Levels</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Level</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Levels</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/level/new" type="button" className="btn btn-tool bg-info" data-card-widget="collapse">
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 mdg-historique-table">
                                            {levelList && (<DataTable
                                                columns={columns}
                                                data={levelList}
                                                defaultSortFieldId={1}
                                                pagination
                                                selectableRows
                                                conditionalRowStyles={rowStyle}
                                            />)}
                                        </div>


                                    </div>

                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default LevelList;
