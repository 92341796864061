import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MissionService from '../../../../services/missions/mission';
import LiveMap from '../../../extra/livemap/map';
import HeaderPage from '../header/HeaderPage';
import { getFlightBeautifulFormatedDateWithTime, getImageUrl } from '../../../../Utils/Common';
import Language from '../../../extra/language/language';

const DetailsMission = () => {
    document.body.style.backgroundColor = "#262626";
    const location = useLocation();
    const passedMission = location.state?.mission;
    const [mission, setMission] = useState(passedMission ? passedMission : null);

    return (
        <div>
            <HeaderPage />
            <div>
                <LiveMap />
                <div className="mdg-details-missions mb-4">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            
                            <div className="mdg-pos-relative" style={{ marginTop: "50px", }}>
                                <div className="mdg-body-missions text-center">
                                    <img
                                        src={mission.image ? getImageUrl(mission.image) : "/assets/img/avion1.jpg"}
                                        alt="logo" 
                                        className="mdg-img-details-missions"
                                    />
                                </div>
                                <div className="mdg-body-details-missions">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-lg-8 mdg-pos-relative">
                                                <div className="col-lg-12 text-center">
                                                    <h2 className="mdg-text-white mdg-uppercase">{mission.title}</h2>
                                                </div>
                                                <div className="col-lg-12 text-center">
                                                    <p className="mdg-text-white">
                                                        {mission.description}
                                                    </p>
                                                </div>
                                                <div className="col-lg-12 text-center" style={{ position: "absolute", bottom: "50px" }}>
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-6">
                                                            <button className="btn mdg-btn-default-missions"><i className="fa fa-angle-left" aria-hidden="true"></i> Open MetaFlight App</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mdg-bl-details-missions">
                                                <div>
                                                    <span className="mdg-text-white mdg-13-text">Bonus XP : <span className="mdg-12-text">{mission.xp}</span> </span>
                                                </div>
                                                <div>
                                                    <span className="mdg-text-white mdg-13-text">Bonus FC : <span className="mdg-12-text">{mission.fcBonus}</span></span>
                                                </div>
                                                <div>
                                                    <span className="mdg-text-white mdg-13-text">Mission Type : <span className="mdg-12-text">{mission.missionType.label}</span></span>
                                                </div>
                                                <div>
                                                    <span className="mdg-text-white mdg-13-text">Carreer Level : <span className="mdg-12-text">{mission.carreerLevel.level}</span></span>
                                                </div>
                                                <div className="mt-2">
                                                    <span className="mdg-text-white"><i className="fa fa-info-circle" aria-hidden="true"></i> <Language word={"Mission.Details"}></Language></span>
                                                    <ul className="ml-3">
                                                        <li className="mdg-text-white mdg-13-text">
                                                            <Language word={"Mission.Flight"}></Language> : <span className="mdg-12-text">{mission.vol}</span>
                                                        </li>
                                                        {mission.missionType && (
                                                            <li className="mdg-text-white mdg-13-text">
                                                                <Language word={"Mission.MissionType"}></Language> : <span className="mdg-12-text">{mission.missionType.label}</span>
                                                            </li>
                                                        )}
                                                        {mission.carreerLevel && (
                                                            <li className="mdg-text-white mdg-13-text">
                                                                <Language word={"Mission.CareerLevel"}></Language> : <span className="mdg-12-text">{mission.carreerLevel.level}</span>
                                                            </li>
                                                        )}
                                                        <li className="mdg-text-white mdg-13-text">
                                                            <Language word={"Mission.StartDate"}></Language> : <span className="mdg-12-text">{getFlightBeautifulFormatedDateWithTime(mission.startDate)}</span>
                                                        </li>
                                                        <li className="mdg-text-white mdg-13-text">
                                                            <Language word={"Mission.EndDate"}></Language> : <span className="mdg-12-text">{getFlightBeautifulFormatedDateWithTime(mission.endDate)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="mt-1">
                                                    <span className="mdg-text-white"><i className="fa fa-list" aria-hidden="true"></i> <Language word={"Mission.Rules"}></Language></span>
                                                    <ul className="ml-3">
                                                        <li className="mdg-text-white"><span className="mdg-12-text"> <Language word={"Mission.AllowAllUser"}></Language> :<i className= {mission.allowAllUser ? "fa fa-check": "fa fa-close"} aria-hidden="true"></i></span></li>
                                                        <li className="mdg-text-white"><span className="mdg-12-text"> <Language word={"Mission.Recurrent"}></Language> :<i className= {mission.isRecurrent ? "fa fa-check": "fa fa-close"} aria-hidden="true"></i></span></li>
                                                        <li className="mdg-text-white"><span className="mdg-12-text"> <Language word={"Mission.ExactStartDate"}></Language> :<i className= {mission.isExactStartDate ? "fa fa-check": "fa fa-close"} aria-hidden="true"></i></span></li>
                                                        <li className="mdg-text-white"><span className="mdg-12-text"> <Language word={"Mission.ExcatStartHour"}></Language> :<i className= {mission.isExactStartHour ? "fa fa-check": "fa fa-close"} aria-hidden="true"></i></span></li>
                                                        <li className="mdg-text-white"><span className="mdg-12-text"> <Language word={"Mission.ExactEndDate"}></Language> :<i className= {mission.isExactEndDate ? "fa fa-check": "fa fa-close"} aria-hidden="true"></i></span></li>
                                                        <li className="mdg-text-white"><span className="mdg-12-text"> <Language word={"Mission.ExcatEndHour"}></Language> :<i className= {mission.isExactEndHour ? "fa fa-check": "fa fa-close"} aria-hidden="true"></i></span></li>
                                                        <li className="mdg-text-white"><span className="mdg-12-text"> <Language word={"Mission.EmbarkUser"}></Language> :<i className= {mission.embarqueUser ? "fa fa-check": "fa fa-close"} aria-hidden="true"></i></span></li>
                                                    </ul>
                                                </div>
                                                <div className="mt-1">
                                                    <span className="mdg-text-white"><i className="fa fa-th-large" aria-hidden="true"></i> <Language word={"Mission.CargoType"}></Language></span>
                                                    <ul className="ml-3">
                                                        {mission.MissionCargoTypes.map((obj, i) => (
                                                            <li className="mdg-text-white">
                                                                <span className="mdg-12-text"><Language word={"Mission.Type"}></Language> : {obj.cargoType.type}</span><br/>
                                                                <span className="mdg-12-text"><Language word={"Mission.Quantity"}></Language> : {obj.quantity}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="mt-1">
                                                    <span className="mdg-text-white"><i className="fa fa-plane" aria-hidden="true"></i> <Language word={"Mission.AeroportDepart"}></Language></span>
                                                    <ul className="ml-3">
                                                        {mission.MissionDepAirports.map((obj, i) => (
                                                            <li className="mdg-text-white"><span className="mdg-12-text">{obj.location.label}</span></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="mt-1">
                                                    <span className="mdg-text-white"><i className="fa fa-plane" aria-hidden="true"></i> <Language word={"Mission.AeroportArrived"}></Language></span>
                                                    <ul className="ml-3">
                                                        {mission.MissionArrAirports.map((obj, i) => (
                                                            <li className="mdg-text-white"><span className="mdg-12-text">{obj.location.label}</span></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="mt-1">
                                                    <span className="mdg-text-white"><i className="fa fa-lemon-o" aria-hidden="true"></i> <Language word={"Mission.AllowedAircraft"}></Language></span>
                                                    <ul className="ml-3">
                                                        {mission.MissionAviationTypes.map((obj, i) => (
                                                            <li className="mdg-text-white"><span className="mdg-12-text">{obj.aviationType.type}</span></li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsMission;