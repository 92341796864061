import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const StoreCategoryLi = ({
    id,
    name,
    level,
    setCategory,
    currentCategory,
    page,
    currentData,
    allCategories,
    activeCategs
}) => {
    const [categories, setCategories] = useState([]);
    const [open, setOpen] = useState(Number(currentCategory) === Number(currentData.id));
    const getCategories = async () => {
        const categs = [];
        allCategories.forEach(element => {
            if (element.parentStoreCategoryId == currentData.id) {
                categs.push(element);
            }
        });

        setCategories(categs);
    };

    useEffect(() => {
        if (level < 4 && currentData.storeChilds.length > 0) {
            getCategories();
        }
    }, []);

    return (
        <li>
            <NavLink
                className={open ? "sidebar-item" : "nav-link"}
                to={`/mf-store/${id}/${page}`}
                onClick={(event) => {
                    setOpen(!open);
                    setCategory(id);
                }}
                style={{ fontSize: "15px" }}
            >
                {name}
                {categories.length > 0 && (
                    <i
                        className={open ? "fa fa-caret-up" : "fa fa-caret-down"}
                        style={{ position: "absolute", right: "40px" }}
                    ></i>
                )}
            </NavLink>
            {categories.length > 0 && (
                <div
                    id="dropdown-container"
                    style={activeCategs.includes(id) ? { display: "block" } : { display: "none" }}
                >
                    <ul>
                        {categories &&
                            categories.map((categ, i) => (
                                <StoreCategoryLi
                                    id={categ.id}
                                    name={categ.storeCategoryName}
                                    key={i}
                                    level={level + 1}
                                    setCategory={setCategory}
                                    currentCategory={currentCategory}
                                    page={page}
                                    currentData={categ}
                                    allCategories={allCategories}
                                    activeCategs={activeCategs}
                                >
                                    {" "}
                                </StoreCategoryLi>
                            ))}
                    </ul>
                </div>
            )}
        </li>
    );
};

export default StoreCategoryLi;
