
export default class Utils {
  static distance(
    lat1,
    lon1,
    lat2,
    lon2,
    unit
  ) {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'K') {
      dist *= 1.609344;
    }
    if (unit === 'N') {
      dist *= 0.8684;
    }
    return dist;
  }

  static getNauticalDistanceBetweenPosition(
    position1,
    position2
  ) {
    return this.distance(
      position1.latitude,
      position1.longitude,
      position2.latitude,
      position2.longitude,
      'N'
    );
  }

  static getTimeFromKnotSpeedAndDistance(
    speed,
    distance
  ) {
    return (60 / speed) * distance;
  }

  static getTimeRemainingBetweenAircraftAndPosition(
    { position, groundSpeed },
    targetPosition
  ) {
    const distance = this.getNauticalDistanceBetweenPosition(
      position,
      targetPosition
    );

    return this.getTimeFromKnotSpeedAndDistance(groundSpeed, distance);
  }

  static getNbHourAndMinutes(time) {


    // calculate (and subtract) whole hours
    var hours = Math.floor(time / 3600 / 1000) ;
    time -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(time / 60) % 60;
    time -= minutes * 60;

    return `${hours}h:${minutes}m`
  }

  static formatHour(hours) {
    var numHours = Math.floor(hours);
    var numMinutes = Math.round((hours - numHours) * 60);
    
    return numHours + "h:" + numMinutes + "mn";
  }
}
