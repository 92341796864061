import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import CategoryService from "../../../services/Category";
import { findInList, selectStyles } from "../../../Utils/Common";
import swal from "sweetalert";
import DescriptionComponent from "../description/DescriptionComponent";

const CategoryForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [categoryName, setCategoryName] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [categoryParentId, setCategoryParentId] = useState(null);

    const getCategoryList = async () => {
        let categories_list = await CategoryService.getAvailableCategoryListAsKeyValueService(id);
        setCategoryList(categories_list);
    };

    const getCategory = async () => {
        if (id) {
            const result = await CategoryService.getCategoryById(id);
            if(!result.error){
                const data = result.data;
                setCategoryParentId(data.parentCategoryId);
                setCategoryName(data.categoryName);
            }
        }
    };

    useEffect(() => {
        getCategoryList();
        getCategory();
    }, []);

    const createCategoryHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('categoryParentId', categoryParentId);
        formData.append('categoryName', categoryName);

        let result;

        if (id) {
            result = await CategoryService.updateCategoryService(id, formData);
        } else {
            result = await CategoryService.createCategoryService(formData);
        }


        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Category</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Category</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName} Category</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/category/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createCategoryHandler(e)}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="mdg-textfield-out-lined">
                                                            <Select
                                                                isClearable={true}
                                                                onChange={(newValue, actionMeta) => {
                                                                    newValue != null ? setCategoryParentId(newValue.value): setCategoryParentId(null);
                                                                }}
                                                                placeholder="Choose a parent category"
                                                                options={categoryList}
                                                                styles={selectStyles}
                                                                value={findInList(categoryList,categoryParentId)}
                                                                style={{ color: "#fff" }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-category-name"
                                                                type="text"
                                                                placeholder=" "
                                                                value={categoryName}
                                                                onChange={(event) => {
                                                                    setCategoryName(event.target.value);
                                                                }}
                                                                required
                                                                style={{ color: "#fff" }}
                                                            />
                                                            <label htmlFor="input-category-name">
                                                                Category Name
                                                            </label>
                                                        </div>
                                                            <div className="col-lg-12 text-right mt-2">
                                                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                                    Save
                                                                </button>
                                                            </div>
                                                    </div>
                                                    {/* <div className="offset-lg-11 mt-1">
                                                            <button className="mdg-btn mdg-btn-outline-meta mdg-btn-round float-right">
                                                                Save
                                                            </button>
                                                        </div> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default CategoryForm;
