import { CHANGE_MODE, DISPLAY_FLIGHT_DETAIL, DISPLAY_TRIP, HIDE_FLIGHT_DETAIL, TOOGLE_NETWORK, TOOGLE_WEATHER, UPDATE_FLIGHT_DETAIL } from "./mapTypes"

const DisplayAircraftDepartureArrivalPolyline = (flight) => {
    return {
        type: DISPLAY_TRIP,
        payload: {
            flight
        }
    }
}

const DisplayFlightDetail = (flight) => {
    
    return {
        type: DISPLAY_FLIGHT_DETAIL,
        payload: {
            flight
        }
    }
}

const UpdateFlightDetail = (flights) => {
    return {
        type: UPDATE_FLIGHT_DETAIL,
        payload: {
            flights
        }
    }
}

const HideFlightDetail = () => {
    
    return {
        type: HIDE_FLIGHT_DETAIL,
        payload: {           
        }
    }
}

const ChangeMode = (mode) => {
    return {
        type: CHANGE_MODE,
        payload: {
            mode
        }
    }
}

const ToogleWeather = (weather) => {
    return {
        type: TOOGLE_WEATHER,
        payload: {
            weather
        }
    }
}

const ToogleNetwork = (network) => {
    return {
        type: TOOGLE_NETWORK,
        payload: {
            network
        }
    }
}

const MapAction = {
    DisplayAircraftDepartureArrivalPolyline,
    DisplayFlightDetail,
    UpdateFlightDetail,
    HideFlightDetail,
    ChangeMode,
    ToogleWeather,
    ToogleNetwork
}

export default MapAction;