import axios  from "../AxiosInstance"

const getManageProfileListService = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/manage-profile`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const createManageProfileService = async (data) => {
  try {
    return await axios({
        method: "post",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/manage-profile`,
        data: data
    }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const updateManageProfileService = async (id, data) => {
  try {
    return await axios({
        method: "put",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/manage-profile/${id}`,
        data: data
    }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


const deleteManageProfile = async (id) => {
  try {
    return await axios.delete(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/manage-profile/${id}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getManageProfileById = async (id) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/manage-profile/${id}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const ManageProfileService = {
  getManageProfileListService,
  createManageProfileService,
  updateManageProfileService,
  deleteManageProfile,
  getManageProfileById,
}

export default ManageProfileService;