import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import NoticesBoService from "../../../services/notices";
import { getFormatedDate, getImageUrl, getLocalFormatedDate } from "../../../Utils/Common";
import { isEmptyObject } from "jquery";


const NoticesForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [isCheckedObligatory, setIsCheckedObligatory] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [notificationContent, setNotificationContent] = useState(EditorState.createEmpty());
    const [contentValue, setContentValue] = useState({});
    const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
    const [noticeImage, setNoticeImage] = useState("");
    const [noticeName, setNoticeName] = useState("");
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
    }, []);

    const createNotificationHandler = async (event) => {
        setProcessing(true);
        event.preventDefault();
        const formData = new FormData();
        formData.append('isCheckedObligatory', isCheckedObligatory);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('notificationContent', JSON.stringify(contentValue));
        formData.append("photo", noticeImage);
        formData.append("name", noticeName);

        let result;

        if (id) {
            result = await NoticesBoService.updateNoticeService(id, formData);
        } else {
            result = await NoticesBoService.createNoticesBoService(formData);
        }

       
        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
                setProcessing(false);
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
                setProcessing(false);
            });
        }
    };

    const getNotice = async () => {
        if (id) {
            setProcessing(true);
            const result = await NoticesBoService.getNoticeById(id);
            setProcessing(false);
            if (!result.error) {
                const data = result.data.data;
                
                setIsCheckedObligatory(data.obligatory);
                setStartDate(getFormatedDate(data.startDate));
                setEndDate(getFormatedDate(data.endDate));
                let content = JSON.parse(data.content);
                if(!isEmptyObject(content))setNotificationContent(EditorState.createWithContent(convertFromRaw(content)));
                setPhotoUrl(getImageUrl(data.photo));
                setNoticeName(data.name);
            }
        }
    };

    const setContent = (content) => {
        setNotificationContent(content);
        let currentContent = content.getCurrentContent();
        setContentValue(convertToRaw(currentContent));
        console.log(draftToHtml(convertToRaw(currentContent)))
    }

    const handleChangeImage = () => {
        const pics = document.getElementById("id-notice-pics");
        pics.click();
    };

    useEffect(() => {
        getNotice();
    }, []);
    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Notices</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Notices</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName} Notices</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/notice/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createNotificationHandler(e)}>
                                                <div className="row">
                                                    <div className="mdg-card-body mdg-row">
                                                        <div className="col-lg-12">
                                                            <div className="mdg-row">
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        className="mdg-d-none"
                                                                        type="file"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        id="id-notice-pics"
                                                                        onChange={(event) => {
                                                                            setNoticeImage(event.target.files[0]);
                                                                            setPhotoUrl(
                                                                                URL.createObjectURL(event.target.files[0])
                                                                            );
                                                                        }}
                                                                    />
                                                                    <img
                                                                        onClick={handleChangeImage}
                                                                        src={photoUrl}
                                                                        className="mdg-upload-img"
                                                                    ></img>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="mdg-row">
                                                                        <div className="col-lg-12">
                                                                            <input
                                                                                id="id-check-obligatory"
                                                                                className=""
                                                                                type="checkbox"
                                                                                onChange={(e) => setIsCheckedObligatory(!isCheckedObligatory)}
                                                                                checked={isCheckedObligatory ? true : false}
                                                                            />
                                                                            <label className="ml-1 mdg-normal-label" htmlFor="id-check-obligatory">Obligatory</label>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <div className="mdg-textfield-out-lined">
                                                                                <input
                                                                                    id="input-notice-name"
                                                                                    type="text"
                                                                                    value={noticeName}
                                                                                    onChange={(event) => {
                                                                                        setNoticeName(event.target.value);
                                                                                    }}
                                                                                    required
                                                                                    style={{ color: "#fff" }}
                                                                                />
                                                                                <label htmlFor="input-notice-name">
                                                                                    Name *
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <label htmlFor="input-notice-start-date" className="mdg-normal-label">
                                                                                Start Date
                                                                            </label>
                                                                            <input
                                                                                id="input-notice-start-date"
                                                                                type="date"
                                                                                className="form-control"
                                                                                value={startDate}
                                                                                onChange={(e) => { setStartDate(e.target.value) }}
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <label htmlFor="input-notice-end-date" className="mdg-normal-label">
                                                                                End Date
                                                                            </label>
                                                                            <input
                                                                                id="input-notice-end-date"
                                                                                type="date"
                                                                                className="form-control"
                                                                                value={endDate}
                                                                                onChange={(e) => { setEndDate(e.target.value) }}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-12 mt-2">
                                                            <label htmlFor="input-notice-end-date" className="mdg-normal-label">
                                                                Content
                                                            </label>

                                                            <Editor
                                                                editorState={notificationContent}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={(e) => { setContent(e); }}
                                                            />
                                                        </div>
                                                        <div className="col-lg-12 text-right mt-2">
                                                            <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right" disabled={processing}>
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default NoticesForm;
