import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import WaresForm from './WaresForm';
import WaresList from './WaresList';

const WaresComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <WaresForm action="create"></WaresForm> ;break;
        case "edit": page = <WaresForm action="edit"></WaresForm> ; break;
        default: page = <WaresList></WaresList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default WaresComponent;