import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import FoodForm from './FoodForm';
import FoodList from './FoodList';

const FoodComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <FoodForm action="create"></FoodForm> ;break;
        case "edit": page = <FoodForm action="edit"></FoodForm> ; break;
        default: page = <FoodList></FoodList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default FoodComponent;