import axios  from "./AxiosInstance"

export const getUserListService = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getUserWalletService = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/wallet`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const checkMfId = async (mfId) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/check-mfid/${mfId}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const checkUsername = async (username) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/check-username/${username}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const userSignUpService = async (
  username,
  email,
  password,
  parrain, 
) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/sign-up`,
      {
        username,
        email,
        password,
        parrain, 
      }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const userApproveSubscriptionService = async (mfId) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-approve`,
      {
        mfId,
      }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const forgotPasswordService = async (email, url) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/forgot-password`,
      {
        email,
        url,
      }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getUserPaymentMethodListService = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/payment-methods`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const resetPasswordService = async (password, token) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/forgot-password/reset-password`,
      {
        password,
        token,
      }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const updateUserInfoService = async (formData) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/update-info`,
      data: formData,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const updateUserPaymentDataInfoService = async (formData) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-payment-data`,
      data: formData,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const executeUserTransactionService = async (formData) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/wallet-transaction`,
      data: formData,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const updatePassword = async (oldPassword, newPassword) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/update-password`,
      { oldPassword, newPassword }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


export const createUserPaymentMethodService = async (paymentMethodId) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/create-user-payment-method`,
      { paymentMethodId }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const detachUserPaymentMethodService = async (customerId, paymentMethodId) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/detach-user-payment-method`,
      { customerId, paymentMethodId }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getUserInfo = async (id) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/info/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const updateUserInfo = async (id, formData) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/update/${id}`,
      data: formData,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getList = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/list`,
      { params: filter }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getNewMembers = async (filter) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/new-members`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getMyInformation = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/information`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const synchronizeToAfrv = async (id_afrv , key_metaflight_afrv) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/synchronize-to-afrv`,{
        id_afrv ,
        key_metaflight_afrv
      }
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const sendConfirmationMail = async (userId) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/users/send-confirmation-mail/${userId}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const UserService = {
  getList,
  getNewMembers,
  checkMfId,
  checkUsername,
  userApproveSubscriptionService,
  forgotPasswordService,
  resetPasswordService,
  getUserPaymentMethodListService,
  createUserPaymentMethodService,
  detachUserPaymentMethodService,
  getMyInformation,
  synchronizeToAfrv
};

export default UserService;
