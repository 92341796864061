import React, { Component } from "react";
import Language from "../../extra/language/language";
import DescriptionComponent from "../description/DescriptionComponent";
import TopWishlistComponent from "../description/TopWishlistComponent";

export default class DashboardBO extends Component {
  render() {
    return (
      <div >

        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0"><Language word={"Admin.Dashboard.Dashboard"}></Language></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#"><Language word={"Admin.Dashboard.Home"}></Language></a></li>
                  <li className="breadcrumb-item active"><Language word={"Admin.Dashboard.Items"}></Language></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">

            <DescriptionComponent></DescriptionComponent>
            <TopWishlistComponent></TopWishlistComponent>

            {/* <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Monthly Recap Report</h5>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" >
                        <i className="fas fa-minus"></i>
                      </button>
                      <div className="btn-group">
                        <button type="button" className="btn btn-tool dropdown-toggle" data-toggle="dropdown">
                          <i className="fas fa-wrench"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" role="menu">
                          <a href="#" className="dropdown-item">Action</a>
                          <a href="#" className="dropdown-item">Another action</a>
                          <a href="#" className="dropdown-item">Something else here</a>
                          <a className="dropdown-divider"></a>
                          <a href="#" className="dropdown-item">Separated link</a>
                        </div>
                      </div>
                      <button type="button" className="btn btn-tool" data-card-widget="remove">
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-8">
                        <p className="text-center">
                          <strong>Sales: 1 Jan, 2014 - 30 Jul, 2014</strong>
                        </p>

                        <div className="chart"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>

                        </div>

                      </div>

                      <div className="col-md-4">
                        <p className="text-center">
                          <strong>Goal Completion</strong>
                        </p>

                        <div className="progress-group">
                          Add Products to Cart
                          <span className="float-right"><b>160</b>/200</span>
                          <div className="progress progress-sm">
                          </div>
                        </div>

                        <div className="progress-group">
                          Complete Purchase
                          <span className="float-right"><b>310</b>/400</span>
                          <div className="progress progress-sm">
                          </div>
                        </div>

                        <div className="progress-group">
                          <span className="progress-text">Visit Premium Page</span>
                          <span className="float-right"><b>480</b>/800</span>
                          <div className="progress progress-sm">
                          </div>
                        </div>

                        <div className="progress-group">
                          Send Inquiries
                          <span className="float-right"><b>250</b>/500</span>
                          <div className="progress progress-sm">
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>
                  <div className="card-footer">
                    <div className="row">
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 17%</span>
                          <h5 className="description-header">$35,210.43</h5>
                          <span className="description-text">TOTAL REVENUE</span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-warning"><i className="fas fa-caret-left"></i> 0%</span>
                          <h5 className="description-header">$10,390.90</h5>
                          <span className="description-text">TOTAL COST</span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block border-right">
                          <span className="description-percentage text-success"><i className="fas fa-caret-up"></i> 20%</span>
                          <h5 className="description-header">$24,813.53</h5>
                          <span className="description-text">TOTAL PROFIT</span>
                        </div>
                      </div>
                      <div className="col-sm-3 col-6">
                        <div className="description-block">
                          <span className="description-percentage text-danger"><i className="fas fa-caret-down"></i> 18%</span>
                          <h5 className="description-header">1200</h5>
                          <span className="description-text">GOAL COMPLETIONS</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div className="row">
              <TopWishlistComponent></TopWishlistComponent>
              {/* <div className="col-md-8">

                <div className="card">
                  <div className="card-header border-transparent">
                    <h3 className="card-title">Latest Orders</h3>

                    <div className="card-tools">
                      <button type="button" className="btn btn-tool" >
                        <i className="fas fa-minus"></i>
                      </button>
                      <button type="button" className="btn btn-tool" data-card-widget="remove">
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Order ID</th>
                            <th>Item</th>
                            <th>Status</th>
                            <th>Popularity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><a href="pages/examples/invoice.html">OR9842</a></td>
                            <td>Call of Duty IV</td>
                            <td><span className="badge badge-success">Shipped</span></td>
                            <td>
                              <div className="sparkbar" data-color="#00a65a" data-height="20">90,80,90,-70,61,-83,63</div>
                            </td>
                          </tr>
                          <tr>
                            <td><a href="pages/examples/invoice.html">OR1848</a></td>
                            <td>Samsung Smart TV</td>
                            <td><span className="badge badge-warning">Pending</span></td>
                            <td>
                              <div className="sparkbar" data-color="#f39c12" data-height="20">90,80,-90,70,61,-83,68</div>
                            </td>
                          </tr>
                          <tr>
                            <td><a href="pages/examples/invoice.html">OR7429</a></td>
                            <td>iPhone 6 Plus</td>
                            <td><span className="badge badge-danger">Delivered</span></td>
                            <td>
                              <div className="sparkbar" data-color="#f56954" data-height="20">90,-80,90,70,-61,83,63</div>
                            </td>
                          </tr>
                          <tr>
                            <td><a href="pages/examples/invoice.html">OR7429</a></td>
                            <td>Samsung Smart TV</td>
                            <td><span className="badge badge-info">Processing</span></td>
                            <td>
                              <div className="sparkbar" data-color="#00c0ef" data-height="20">90,80,-90,70,-61,83,63</div>
                            </td>
                          </tr>
                          <tr>
                            <td><a href="pages/examples/invoice.html">OR1848</a></td>
                            <td>Samsung Smart TV</td>
                            <td><span className="badge badge-warning">Pending</span></td>
                            <td>
                              <div className="sparkbar" data-color="#f39c12" data-height="20">90,80,-90,70,61,-83,68</div>
                            </td>
                          </tr>
                          <tr>
                            <td><a href="pages/examples/invoice.html">OR7429</a></td>
                            <td>iPhone 6 Plus</td>
                            <td><span className="badge badge-danger">Delivered</span></td>
                            <td>
                              <div className="sparkbar" data-color="#f56954" data-height="20">90,-80,90,70,-61,83,63</div>
                            </td>
                          </tr>
                          <tr>
                            <td><a href="pages/examples/invoice.html">OR9842</a></td>
                            <td>Call of Duty IV</td>
                            <td><span className="badge badge-success">Shipped</span></td>
                            <td>
                              <div className="sparkbar" data-color="#00a65a" data-height="20">90,80,90,-70,61,-83,63</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="card-footer clearfix">
                    {/* <a href="javascript:void(0)" className="btn btn-sm btn-info float-left">Place New Order</a>
                    <a href="javascript:void(0)" className="btn btn-sm btn-secondary float-right">View All Orders</a> 
                  </div>
                </div>
              </div> */}

              {/* <div className="col-md-4">
                <div className="info-box mb-3 bg-warning">
                  <span className="info-box-icon"><i className="fas fa-tag"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Inventory</span>
                    <span className="info-box-number">5,200</span>
                  </div>
                </div>
                <div className="info-box mb-3 bg-success">
                  <span className="info-box-icon"><i className="far fa-heart"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Mentions</span>
                    <span className="info-box-number">92,050</span>
                  </div>
                </div>
                <div className="info-box mb-3 bg-danger">
                  <span className="info-box-icon"><i className="fas fa-cloud-download-alt"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Downloads</span>
                    <span className="info-box-number">114,381</span>
                  </div>
                </div>
                <div className="info-box mb-3 bg-info">
                  <span className="info-box-icon"><i className="far fa-comment"></i></span>

                  <div className="info-box-content">
                    <span className="info-box-text">Direct Messages</span>
                    <span className="info-box-number">163,921</span>
                  </div>
                </div>


              </div> 

            </div>*/}
          </div>
        </section>
      </div>
    );
  }
}
