import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import PackUpToSellForm from './PackUpToSellForm';
import PackUpToSellList from './PackUpToSellList';

const PackUpToSellComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <PackUpToSellForm action="create"></PackUpToSellForm> ;break;
        case "edit": page = <PackUpToSellForm action="edit"></PackUpToSellForm> ; break;
        default: page = <PackUpToSellList></PackUpToSellList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default PackUpToSellComponent;