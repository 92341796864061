export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_DEFAULT_LANGUAGE = 'SET_DEFAULT_LANGUAGE';

export const setLanguage = (language) => {
    return {
      type: CHANGE_LANGUAGE,
      payload: {
        language
      }
    }
}

export const setDefaultLanguage = () => {
    return {
      type: SET_DEFAULT_LANGUAGE
    }
}

