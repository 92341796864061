import React from "react";
import Thumbnail from "../../../extra/img/thumbnail";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import MetaCart from "../../../../actions/cartActions";
import { getFullDate, getImageUrl } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";
import UserSubscriptionService from "../../../../services/userSubscription";
import { NavLink } from "react-router-dom";

export class SubscriptionList extends React.Component {
    constructor(props) {
        
        super(props);
        this.state = {
            showDetail: false,
        };
        this.handleBuyNow = this.handleBuyNow.bind(this);
        this.history = props.history
        this.subscription = {
            id: props.subscription.id,
            name: props.subscription.name,
            image: getImageUrl(props.subscription.photo),
            price: props.subscription.price,
            description: props.subscription.description,
            type: 'subscription',
            PackFeatures: props.subscription.PackFeatures,
            subscription: true,
            
        }
        this.user = props.user;
    }

    handleBuyNow = async (event) => {
        const lang = this.props.lang;
        event.preventDefault();
        Swal.fire({
            title: translate('Seller.Do_you_want_to_subscribe_this',lang)+` ${this.subscription.name}`,
            icon: "info",
            showCancelButton: true,
        }).then(async (res) => {
            if (res.isConfirmed) {
                let item = this.subscription;
                item.quantity = 1;
                
                this.props.addToCart(item);
            }
        });
    };

    render() {

        const subscription = this.subscription;
        const active = this.props.activeSubscription;
        
        const features = subscription.PackFeatures;
        const isActive = active && subscription.id === active.subscriptionId ? true : false;
        const defaultActive = !active && subscription.price === 0 ? true : false;
        
        const isFreeTrial = this.user.freeTrial; 

        return (
            <div>
                <div className={(isActive || defaultActive) ? "mdg-card-subscribe card-body flex-fill text-center mdg-popular" : "mdg-card-subscribe card-body flex-fill text-center"}>
                    {(isActive || defaultActive) && (
                        <div className="mdg-e-ticket">
                            <span className="mdg-text-white"> {!active ||  active.trialDate===null ? <Language word={"Subscription.Active"}></Language>: <Language word={"Subscription.Free_trial"}></Language>}</span>
                        </div>
                    )}
                    <div>
                        <Thumbnail
                            src={`${subscription.image}`}
                            alt="logo"
                            className="mdg-image-body"
                        ></Thumbnail>
                    </div>
                    <h6 className="mdg-text-white">{subscription.name}</h6>
                    <div className="mdg-text-white">
                        <small className="mdg-small">{subscription.description}</small>
                    </div>
                    <div className="m-2">
                        <span className="mdg-price">{subscription.price} €</span>
                    </div>
                    {
                        !defaultActive ? (
                            <div className="row justify-content-md-center">
                                <div className="col-lg-8">
                                    {isActive ? (
                                        <button className="mdg-btn mdg-btn-round mdg-btn-meta-primary" onClick={() => this.props.unSubscribe()}><Language word={"Subscription.Unsubscribe"}></Language></button>
                                    ) : (
                                        <>
                                            {
                                                !active && (
                                                    <>
                                                        <button onClick={this.handleBuyNow} className="mdg-btn mdg-btn-round mdg-btn-meta-primary"><Language word={"Subscription.Subscribe_now"}></Language></button>
                                                       
                                                    </>
                                                )
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="row justify-content-md-center">
                                <div className="col-lg-8">
                                    <button className="mdg-btn mdg-btn-round mdg-btn-meta-primary" disabled><Language word={"Subscription.Unsubscribe"}></Language></button>
                                </div>
                            </div>
                        )
                    }

                    <div className="mdg-body-subscribe mt-3">
                        <div className="mdg-header-icon-subscribe mdg-round d-none">
                            <div className="row justify-content-md-center p-1">
                                <h6 className="mdg-text-white m-1"><Language word={"Subscription.Billed_Monthly"}></Language></h6>
                            </div>
                        </div>
                        <div className="mdg-body-content-subscribe d-none">

                            <div className="text-left mt-3">
                                {
                                    features && features.map((elt, i) => elt.feature && (

                                        <div className="mdg-list-subscribe  mt-1 mb-1" key={i}>
                                            <div className="row" >
                                                <div className="d-inline-flex">
                                                    <div className="col-sm-1">
                                                        <i className="fa fa-circle mdg-text-meta-color" aria-hidden="true"></i>
                                                    </div>
                                                    <div className="col-sm-10">
                                                        <span className="mdg-text-white" onClick={(e) => this.setState({
                                                            showDetail: !this.state.showDetail,
                                                        })}> {elt.feature.name}</span>
                                                        {this.state.showDetail &&
                                                            (<div>
                                                                <span className="mdg-text-white">623</span>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        {(isActive && active.trialDate===null) && (
                            <>
                                <div className="mdg-header-icon-subscribe mdg-round mt-3">
                                    <div className="row justify-content-md-center p-1">
                                        <h6 className="mdg-text-white m-1"><Language word={"Subscription.Payement"}></Language></h6>
                                    </div>
                                </div>
                                <div className="mdg-body-content-subscribe">
                                    <div className="text-left mt-3 mdg-list-payement">

                                        <div className="row justify-content-between">
                                            <div className="d-inline-flex mdg-full-width">
                                                <div className="col-lg-2">
                                                    <span className="mdg-text-white"><Language word={"Subscription.Last"}></Language> </span>
                                                </div>
                                                <div className="col-lg-10">
                                                    <div className="text-right">
                                                        <small className="mdg-text-white">
                                                            <i className="fa fa-calendar-check-o" aria-hidden="true" style={{ color: "green" }}></i> {getFullDate(active.lastPayment)}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row justify-content-between">
                                            <div className="d-inline-flex mdg-full-width">
                                                <div className="col-lg-2">
                                                    <span className="mdg-text-white"><Language word={"Subscription.Next"}></Language> </span>
                                                </div>
                                                <div className="col-lg-10">
                                                    <div className="text-right">
                                                        <small className="mdg-text-white">
                                                            <i className="fa fa-calendar-o" aria-hidden="true" style={{ color: "red" }}></i> {getFullDate(active.nextPayment)}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.props.portalUrl && (
                                                <div className="row justify-content-between">
                                                    <div className="d-inline-flex mdg-full-width">
                                                        <div className="col-lg-12">
                                                            <a href={this.props.portalUrl} target="_blank">
                                                                <Language word={"Subscription.ClientPortal"}></Language>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </>
                        )}
                    </div>

                </div>
            </div>
        );
    }
}

export default connect(MetaCart.mapState, MetaCart.mapDispach)(SubscriptionList);
