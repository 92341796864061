import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { getBeautifulFormatedDateWithTimeS, getFormatedDate, getFormatedDateWithTime, tableRowStyle } from "../../../Utils/Common";
import FlightService from "../../../services/flight/flight";
import { Form, Table } from "antd";

const FlightTreatedTable = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [initData, setInitData] = useState([]);

  const getList = async () => {
    setLoading(true);
    const filter = {
      page: currentPage - 1,
      size: perPage,
      search: search,
    };

    const res = await FlightService.getAllFlightTeateds();
    if (!res.error) {
      const flightData = [];
      for (let i in res.data) {
        let row = res.data[i];
        flightData.push({
          flightNumber: row.flight.flightNumber,
          aircraftRegistration: row.flight.aircraftRegistration,
          aircraftType: row.flight.aircraftType,
          duration: row.flightBlockTimes,
          distance: row.flightDistance,
          pilot: row.user.userName,
          email: row.user.email,
          miles: row.miles,
          mfc: row.fcAmount,
          xp: row.user.userwallet.xp,
          date: getBeautifulFormatedDateWithTimeS(row.createdAt),
        });
      }
      setData(flightData);
      setInitData(flightData);
    }

    setLoading(false);
  };

  const antColumns = [
    {
      title: "N°",
      dataIndex: "flightNumber",
      //   width: "10%",
      responsive: ["sm"],
      render: (flightNumber) => <small>{flightNumber}</small>,
      //   responsive: ['md'],
      sorter: (a, b) => a.flightNumber.localeCompare(b.userName),
    },
    {
      title: "Registration",
      dataIndex: "aircraftRegistration",
      //   responsive: ["lg"],
      //width: "20%",
      responsive: ["md"],
      render: (aircraftRegistration) => <small>{aircraftRegistration}</small>,
      sorter: (a, b) =>
        a.aircraftRegistration.localeCompare(b.aircraftRegistration),
    },
    {
      title: "Type",
      dataIndex: "aircraftType",
      //   width: "8%",
      responsive: ["sm"],
      sorter: (a, b) => a.aircraftType.localeCompare(b.role),
      //   responsive: ["md"],
      render: (aircraftType) => <small>{aircraftType}</small>,
    },
    {
      title: "duration",
      dataIndex: "duration",
      //   width: "7%",
      responsive: ["sm"],
      render: (duration) => <small>{duration}</small>,
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "Distance",
      dataIndex: "distance",
      //   width: "7%",
      responsive: ["sm"],
      render: (distance) => <small>{distance}</small>,
      sorter: (a, b) => a.distance - b.distance,
    },
    {
      title: "Pilot",
      dataIndex: "pilot",
      //   width: "7%",
      responsive: ["sm"],
      render: (pilot) => <small>{pilot}</small>,
      sorter: (a, b) => a.pilot.localeCompare(b.pilot),
    },
    {
      title: "Email",
      dataIndex: "email",
      //   width: "7%",
      responsive: ["sm"],
      render: (email) => <small>{email}</small>,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Miles",
      dataIndex: "miles",
      //   width: "7%",
      responsive: ["sm"],
      render: (miles) => <small>{miles} Miles</small>,
      sorter: (a, b) => a.miles - b.miles,
    },
    {
      title: "FC",
      dataIndex: "mfc",
      //   width: "7%",
      responsive: ["sm"],
      render: (mfc) => <small>{mfc} FC</small>,
      sorter: (a, b) => a.mfc - b.mfc,
    },
    {
      title: "XP",
      dataIndex: "xp",
      //   width: "7%",
      responsive: ["sm"],
      render: (xp) => <small>{xp} XP</small>,
      sorter: (a, b) => a.xp - b.xp,
    },
    {
      title: "Date",
      dataIndex: "date",
      //   width: "7%",
      responsive: ["sm"],
      render: (date) => <small>{date}</small>,
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
  ];

  useEffect(() => {
    getList();
  }, [currentPage, perPage, props.refresh]);

  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
      position: ["none", "bottomRight"], //"topRight"
      //   showQuickJumper:true,
      responsive: true,
      totalBoundaryShowSizeChanger: 2,
      size: "default",
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  return (
    <>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 45,
        }}
      >
        <div className="col md-sm-6 mt-1">
          <div className="mdg-search-place">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search "
              style={{ color: "#fff" }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                const newData = initData.filter((i) =>
                  `${i.userName} ${i.email} ${i.aircraftType} ${i.aircraftRegistration}`
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
                );
                setData(newData);
              }}
              className="mdg-search-input"
            />
            <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
          </div>
        </div>
      </Form>

      <Table
        columns={antColumns}
        dataSource={data}
        tableLayout={"fixed"}
        loading={loading}
        size="small"
        //sticky
        // scroll={{ y: 250 }}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default FlightTreatedTable;
/**
 * 
 const getList = async () => {
    setLoading(true);
    const filter = {
      page: currentPage - 1,
      size: perPage,
      search: search,
    };

    const res = await FlightService.getAllFlightTeated(filter);
    if (!res.error) {
      //console.log(res.data.items);
      setData(res.data.items);
      setTotalRows(res.data.totalItems);
    }

    setLoading(false);
  };
 
 const columns = useMemo(() => [
    {
      name: "N°",
      selector: (row) => row.flight.flightNumber,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: "Registration",
      selector: (row) => row.flight.aircraftRegistration,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: "type",
      selector: (row) => row.flight.aircraftType,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: "Duration",
      selector: (row) => row.flightBlockTimes,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: "Distance",
      selector: (row) => row.flightDistance,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: "Pilot",
      selector: (row) => row.user.userName,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: "Email",
      selector: (row) => row.user.email,
      sortable: true,
      left: true,
      reorder: true,
    },
    {
      name: "miles",
      selector: (row) => <>{row.miles} miles</>,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: "FC",
      selector: (row) => <>{row.fcAmount} FC</>,
      sortable: true,
      right: true,
      reorder: true,
    },
    {
      name: "xp",
      selector: (row) => <>{row.user.userwallet.xp} xp</>,
      sortable: true,
      right: true,
      reorder: true,
    },
  ]);
 
 <DataTable
      title=""
      columns={columns}
      data={data}
      progressPending={loading}
      pagination
      paginationServer
      responsive
      paginationTotalRows={totalRows}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      selectableRows
      onSelectedRowsChange={({ selectedRows }) => {
        console.log(selectedRows);
      }}
      conditionalRowStyles={tableRowStyle}
    />
 */