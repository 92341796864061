import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Progress, Space } from "antd";
import Language from "../../../extra/language/language";
import { getFlightBeautifulFormatedDateWithTime, getFormatedTimeLapse } from "../../../../Utils/Common";
import { NavLink } from "react-router-dom";

const DetailFlightV2 = ({ flight, mySubscription }) => {

  const nearbyAircraftHistory = (goal) => {
    const result = flight.aircraftHistories.reduce((prev, curr) => {
      return Math.abs(Date.parse(curr.createdDate) - Date.parse(goal)) <
        Math.abs(Date.parse(prev.createdDate) - Date.parse(goal))
        ? curr
        : prev;
    });

    return result;
  };

  const getNbPassenger = () => {
    if (flight && flight.flightPaxs) {
      const sumWithInitial = flight.flightPaxs.reduce(
        (accumulator, currentValue) => accumulator + currentValue.nbPax,
        0
      );

      return sumWithInitial;
    }

    return 0;
  };

  const nbPassenger = getNbPassenger();
  const passengerSatisfaction =
    flight &&
    flight.paxNCargoInformation &&
    flight.paxNCargoInformation.passengersSatiatedPoint
      ? flight.paxNCargoInformation.passengersSatiatedPoint
      : 0;
      
  const passengerCalmness = flight && flight.paxNCargoInformation &&
      flight.paxNCargoInformation.passengersCalmness
        ? flight.paxNCargoInformation.passengersCalmness
        : 0;

  const passengersEntertainement =
        flight &&
        flight.paxNCargoInformation &&
        flight.paxNCargoInformation.passengersEntertainement
          ? flight.paxNCargoInformation.passengersEntertainement
          : 0;

  const globalSaitsfatcion = parseFloat(((passengerSatisfaction + passengerCalmness +passengersEntertainement) /3).toFixed(0));

  const averageSpeeds = flight.aircraftHistories.reduce(
    (previousSpeed, aircraftHistorie) => {
      if (aircraftHistorie.trueAirSpeed >= 15) {
        previousSpeed.push(aircraftHistorie.trueAirSpeed);
      }

      return previousSpeed;
    },
    []
  );

  const averageSpeed = averageSpeeds.length > 0 ? 
    averageSpeeds.reduce((accumulator, a) => accumulator + a, 0) /
    averageSpeeds.length : 0;

  const blockOnOrPushBack = flight.flightStepHistories.find(
    (step) => step.new_step === "block_on" || step.new_step === "pushback"
  );
  const deboarding = flight.flightStepHistories.find(
    (step) => step.new_step === "deboarding"
  );

  let fuelLoaded = 0;
  if (blockOnOrPushBack) {
    let near_block_on = nearbyAircraftHistory(blockOnOrPushBack.date);
    if (near_block_on) {
      fuelLoaded = near_block_on.fuelTotalquantityweight;
    }
  }

  let fuelLoadedDeboarding = 0;

  if (deboarding) {
    let near_deboarding = nearbyAircraftHistory(deboarding.date);
    if (near_deboarding) {
      fuelLoadedDeboarding = near_deboarding.fuelTotalquantityweight;
    }
  }
  return (
    <div
      className="container mdg-top-container mt-5"
      style={{ marginTop: "20px" }}
    >
      <div className="row justify-content-between mdg-title-ingame mb-3 mt-5">
        <div className="col-lg-4 mt-5">
          <h4 className="mdg-h2 mdg-text-white">
            {/* <Language word={"My_flight.Detail"}></Language> */}
          </h4>
        </div>
          <div className="col-lg-6 mt-5">
            <div className="mdg-ariane-breadcrumb float-right">
              <NavLink to="/my-flight-list" className={"active"}>
                <Language word={"My_flight.My_flight_lists"} />
              </NavLink>
              <NavLink to="" className={"active"}>
                <Language word={"My_flight.Flight"} /> n° {flight.flightNumber}
              </NavLink>
            </div>
          </div>
      </div>
      <div className="row justify-content-center" style={{marginBottom:"30px"}}>
        <div className="col-lg-4 text-center">
          <h2 className="text-white">
           
            <strong>{flight.flightNumber}</strong>
          </h2>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-3">          
          <div className="mdg-bg-gradient rounded">
            <div
              className="border-bottom text-center p-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="mdg-color-text-meta text-white"
                style={{ fontSize: "25px" }}
              >
                <Language word={"My_flight.Detail"}></Language>
              </span>
              <span className="position-absolute" style={{ right: "10px" }}>
                <InfoCircleOutlined />
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/last-posiiton.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.Flight"} /> :{" "}
                <strong className="mdg-color-text-meta">
                  {flight.flightNumber}
                </strong>
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/plane-white.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                Aircraft :{" "}
                <strong className="mdg-color-text-meta">
                  {flight.aircraftIcao}
                </strong>
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/calendar_rose.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"Mission.StartDate"} /> :{" "}
                <strong className="mdg-color-text-meta">
                  {`${getFlightBeautifulFormatedDateWithTime(
                    flight.beginFlightTime
                  )}`}
                </strong>
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/calendar_rose.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"Mission.EndDate"} /> :{" "}
                <strong className="mdg-color-text-meta">
                  {`${getFlightBeautifulFormatedDateWithTime(
                    flight.endFlightTime
                  )}`}
                </strong>
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/clock.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.FlightTime"} /> : {" "}
                <strong className="mdg-color-text-meta">{getFormatedTimeLapse  (
                          flight.beginFlightTime,
                          flight.endFlightTime
                        )}</strong>
                
                        
              </span>
            </div>
            <div className="border-bottom  text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/clock.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.FlightBlockTime"} /> : {" "}
                <strong className="mdg-color-text-meta">{getFormatedTimeLapse(
                          flight.beginFlightBlockTime,
                          flight.endFlightBlockTime
                        )}</strong>
                
                        
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/gaz.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.FuelLoaded"} /> :{" "}
                <strong className="mdg-color-text-meta">
                  {fuelLoaded.toFixed(2)} KGS
                </strong>
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/gaz.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.FuelConsumed"} /> : {" "}
                <strong className="mdg-color-text-meta">
                  {(fuelLoaded - fuelLoadedDeboarding).toFixed(2)} KGS
                </strong>
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/compteur-de-vitesse.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.AverageSpeed"} /> : {" "}
                <strong className="mdg-color-text-meta">
                  {averageSpeed.toFixed(2)} Kts
                </strong>
              </span>
            </div>
            <div className="border-bottom text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/taux-dinteret.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.LandingRate"} /> : {" "}
                <strong className="mdg-color-text-meta">
                  {flight.landingTouchDownRate ? flight.landingTouchDownRate : 'NA' }
                </strong>
              </span>
            </div>
            <div className="text-left p-2">
              <span className="text-white ml-3">
                <img
                  src={"/assets/image/compteur-de-vitesse.png"}
                  alt="logo flight number"
                  style={{ width: '20px' }}
                />{" "}
                <Language word={"My_flight.LandingSpeed"} /> : {" "}
                <strong className="mdg-color-text-meta">
                  {flight && flight.landingSpeed ? flight.landingSpeed : 0}
                </strong>
              </span>
            </div>
            
          </div>
        </div>
        <div className="col-lg-6 d-flex pr-0">
          <div
            className="row justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="col-lg-6 d-flex">
              <div className="mdg-bg-gradient rounded">
                <div className="border-bottom text-center p-2">
                  <h4 className="mdg-color-text-meta"><Language word={"Flight.Airports"}></Language> </h4>
                </div>
                <div className="text-center p-2 mt-5">
                <span className="text-white">
                    <img
                      src={"/assets/image/detail_depart.png"}
                      alt="logo flight number"
                    />{" "}
                    <Language word={"Mission.DepartureAirport"} />
                  </span>
                  <span class="d-block">
                    <img
                      src={"/assets/image/ic_send.png"}
                      alt=" "
                      style={{ width: "15px" }}
                    />{" "}
                    <strong className="mdg-color-text-meta">
                      {flight.departureIcao.label}
                    </strong>
                  </span>
                </div>
                <div className="text-center p-2">
                  <span className="text-white">
                    <img
                      src={"/assets/image/detail_arrival.png"}
                      alt="logo flight number"
                    />{" "}
                    <Language word={"Mission.ArrivalAirport"} />
                  </span>
                  <span class="d-block">
                    <img
                      src={"/assets/image/ic_place.png"}
                      alt=""
                      style={{ width: "15px" }}
                    />{" "}
                    <strong className="mdg-color-text-meta">
                      {flight.arrivalIcao.label}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex">
              <div className="mdg-ribbon-right" style={{ right: "8px" }}>
                <span>
                  <Language word={"Coming soon"}></Language>
                </span>
              </div>
              <div className="mdg-bg-gradient rounded  bg-blur">
                <div
                  className="border-bottom text-center p-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="mdg-color-text-meta text-white"
                    style={{ fontSize: "25px" }}
                  >
                    <Language word={"My_flight.satisfaction_passengers"}></Language>
                  </span>
                </div>
                <div className="text-center p-2 mt-5" style={{ height: "80%" }}>
                  <Space wrap>
                    <Progress type="circle"  percent={globalSaitsfatcion} success={{ percent: globalSaitsfatcion }} />
                  </Space>
                  <h5 className="mt-2"><Language word={"My_flight.global_passengers_satisfaction"}></Language> </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex mt-3">
              <div className="mdg-bg-gradient rounded">
                <div
                  className="border-bottom text-center p-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="mdg-color-text-meta text-white" 
                    style={{ fontSize: "25px" }}
                  >
                    Flight coin
                  </span>
                </div>
                <div
                  className="text-center p-2"
                  style={{
                    height: "80%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5 className="mt-2">
                  <span style={{fontSize:"25px"}}>
                    {flight.flightTreateds && flight.flightTreateds.fcAmount ? flight.flightTreateds.fcAmount : 0}{" "}
                    </span>
                    <img
                      src={"/assets/image/coin.png"}
                      alt="logo flight coin"
                      style={{ width: "50px" }}
                    />{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex mt-3">
              <div className="mdg-ribbon-right" style={{ right: "8px" }}>
                <span>
                  <Language word={"Coming soon"}></Language>
                </span>
              </div>
              <div className="mdg-bg-gradient rounded bg-blur">
                <div
                  className="border-bottom text-center p-2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="mdg-color-text-meta text-white"
                    style={{ fontSize: "25px" }}
                  >
                    XP
                  </span>
                </div>
                <div
                  className="text-center p-2"
                  style={{
                    height: "80%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h5 className="mt-2">
                    <span style={{fontSize:"25px"}}>
                      0{" "}
                    </span>
                    <img
                      src={"/assets/image/xp.png"}
                      alt="logo flight coin"
                      style={{ width: "50px" }}
                    />{" "}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 px-0">
          <div className="mdg-ribbon-right">
            <span>
              <Language word={"Coming soon"}></Language>
            </span>
          </div>
          <div className="mdg-bg-gradient rounded bg-blur">
            <div
              className="text-center border-bottom p-2"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="text-white" style={{ fontSize: "25px" }}>
                  <Language word={"Mission.CargoType"}></Language>
              </span>
            </div>
            <div className="row justify-content-center mx-0">
              
              <div className="col-lg-10 mt-5">
                <input
                  type="text"
                  className="mdg-form-input"
                  placeholder="See loaded cargoes"
                />
                <button className="btn-transparent-icon">
                  <img src={"/assets/image/down.png"} alt="" />
                </button>

                <div className="ls-select mt-2">
                  <div className="row justify-content-between li-select mx-0">
                    <div className="col-lg-5 text-left">
                      <span className="text-white">Fish</span>
                    </div>
                    <div className="col-lg-5 text-right position-relative">
                      <span className="text-white">0.8 Tons</span>
                    </div>
                  </div>
                  <div className="row justify-content-between li-select mx-0">
                    <div className="col-lg-5 text-left">
                      <span className="text-white">Fish</span>
                    </div>
                    <div className="col-lg-5 text-right position-relative">
                      <span className="text-white">0.8 Tons</span>
                    </div>
                  </div>
                  <div className="row justify-content-between li-select mx-0">
                    <div className="col-lg-5 text-left">
                      <span className="text-white">Fish</span>
                    </div>
                    <div className="col-lg-5 text-right position-relative">
                      <span className="text-white">0.8 Tons</span>
                    </div>
                  </div>
                  <div className="row justify-content-between li-select mx-0">
                    <div className="col-lg-5 text-left">
                      <span className="text-white">Fish</span>
                    </div>
                    <div className="col-lg-5 text-right position-relative">
                      <span className="text-white">0.8 Tons</span>
                    </div>
                  </div>
                  <div className="row justify-content-between li-select mx-0">
                    <div className="col-lg-5 text-left">
                      <span className="text-white">Fish</span>
                    </div>
                    <div className="col-lg-5 text-right position-relative">
                      <span className="text-white">0.8 Tons</span>
                    </div>
                  </div>
                  <div className="row justify-content-between li-select mx-0">
                    <div className="col-lg-5 text-left">
                      <span className="text-white">Fish</span>
                    </div>
                    <div className="col-lg-5 text-right position-relative">
                      <span className="text-white">0.8 Tons</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailFlightV2;
