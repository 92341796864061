import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import LevelService from "../../../services/missions/level";
import MissionService from "../../../services/missions/mission";
import MissionTypeService from "../../../services/missions/types";
import { findInList, getFormatedDate, getFormatedDateWithTime, getImageUrl, selectStyles } from "../../../Utils/Common";
import Select from "react-select";
import LocationService from "../../../services/base/location";
import AviationTypeService from "../../../services/missions/aviationType";
import CargoForm from "./CargoForm";
import AsyncSelect from "react-select/async";

const ListWares = ({ arrayCargo, appendCargo, setCargo, setQuantity, deleteCargo }) => {

    return (
        <>
            {arrayCargo.map((elt, i) => (
               
                    <CargoForm
                        cargo={elt}
                        key={i}
                        i={i}
                        arrayCargoLength={arrayCargo.length}
                        appendCargo={appendCargo}
                        arrayCargo={arrayCargo}
                        setCargo={setCargo}
                        setQuantity={setQuantity}
                        deleteCargo={deleteCargo}
                    >
                    </CargoForm>
               

            ))}
        </>

    )
}
const MissionForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
    const [missionImage, setMissionImage] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [vol, setVol] = useState("");
    const [xp, setXp] = useState(0);
    const [fcBonus, setFcBonus] = useState(0);

    const [isAllowAllUser, setIsAllowAllUser] = useState(false);
    const [isRecurrent, setIsRecurrent] = useState(false);
    const [isExactStartDate, setIsExactStartDate] = useState(false);
    const [isExactEndDate, setIsExactEndDate] = useState(false);
    const [isExactStartHour, setIsExactStartHour] = useState(false);
    const [isExactEndHour, setIsExactEndHour] = useState(false);
    const [isEmbarkPassengers, setIsEmbarkPassengers] = useState(false);

    const [missionTypeList, setMissionTypeList] = useState([]);
    const [careerLevelList, setCareerLevelList] = useState([]);
    const [missionType, setMissionType] = useState("");
    const [careerLevel, setCareerLevel] = useState("");

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [allLocations, setAllLocations] = useState([]);
    const [departureAirports, setDepartureAirports] = useState([]);
    const [arrivalAirports, setArrivalAirports] = useState([]);

    const [allAviationType, setAllAviationType] = useState([]);
    const [aviationTypes, setAviationTypes] = useState([]);

    const [arrayCargo, setArrayCargo] = useState([[0, 0]]);

    const [changeCargo, setChangeCargo] = useState(false);


    const getMission = async () => {
        if (id) {
            const result = await MissionService.getMissionById(id);
            if (!result.error) {

                const data = result.data.data;
                setPhotoUrl(getImageUrl(data.image));
                setTitle(data.title);
                setDescription(data.description);
                setVol(data.vol);
                setXp(data.xp);
                setFcBonus(data.fcBonus);
                setMissionType(data.missionTypeId);
                setCareerLevel(data.carreerLevelId);
                setIsAllowAllUser(data.allowAllUser);
                setIsRecurrent(data.isRecurrent);
                setIsExactStartDate(data.isExactStartDate);
                setIsExactEndDate(data.isExactEndDate);
                setIsExactStartHour(data.isExactStartHour);
                setIsExactEndHour(data.isExactEndHour);
                setStartDate(getFormatedDateWithTime(data.startDate));
                setEndDate(getFormatedDateWithTime(data.endDate));
                setIsEmbarkPassengers(data.embarqueUser);
                const loadedDepartureAirport = data.MissionDepAirports;
                let missionDepAirports = [];
                loadedDepartureAirport.forEach((el) => {
                    if (el.location) {
                        missionDepAirports.push({ label: el.location.ident+'-'+el.location.label, value: el.location.id });
                    }
                });
                setDepartureAirports(missionDepAirports);

                const loadedArrivalAirport = data.MissionArrAirports;
                
                let missionArrAirports = [];
                loadedArrivalAirport.forEach((el) => {
                    if (el.location) {
                        missionArrAirports.push({ label: el.location.ident+'-'+el.location.label, value: el.location.id });
                    }
                });
                setArrivalAirports(missionArrAirports);
                const loadedAviationType = data.MissionAviationTypes;
                let missionAviationType = [];
                loadedAviationType.forEach((el) => {
                    if (el.aviationType) {
                        missionAviationType.push({ label: el.aviationType.type, value: el.aviationType.id });
                    }
                });
                setAviationTypes(missionAviationType);
                console.log(data);
                const loadedWares = data.MissionWares;
                let arrayWares = [];
                loadedWares.forEach((el) => {
                    if (el.wares) {
                        arrayWares.push([el.waresId, el.quantity]);
                    }
                });
                if(arrayWares.length > 0){
                    setArrayCargo(arrayWares); 
                }else{
                    setArrayCargo([[0, 0]]); 
                }
                setChangeCargo(!changeCargo);
            }
        }
    };

    const getMissionTypeList = async () => {
        const result = await MissionTypeService.getMissionTypeAsKeyValueService();
        setMissionTypeList(result);
    }

    const getCareerLevelList = async () => {
        const result = await LevelService.getCareerLevelAsKeyValueService();
        setCareerLevelList(result);
    }

    const getAllFeatures = async () => {
        const locations = await LocationService.getLocationsAsKeyValueService();
        setAllLocations(locations);
    };

    const getAllAviationType = async () => {
        const result = await AviationTypeService.getAviationTypeAsKeyValueService();
        setAllAviationType(result);
    }



    useEffect(() => {
        getMissionTypeList();
        getCareerLevelList();
        getAllFeatures();
        getAllAviationType();
        getMission();
    }, []);

    const createMissionHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('photo', missionImage);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('vol', vol);
        formData.append('xp', xp);
        formData.append('fcBonus', fcBonus);

        formData.append('isAllowAllUser', isAllowAllUser);
        formData.append('isRecurrent', isRecurrent);
        formData.append('isExactStartDate', isExactStartDate);
        formData.append('isExactEndDate', isExactEndDate);
        formData.append('isExactStartHour', isExactStartHour);
        formData.append('isExactEndHour', isExactEndHour);
        formData.append('embarqueUser', isEmbarkPassengers);

        formData.append('missionType', missionType);
        formData.append('careerLevel', careerLevel);

        formData.append('startDate', startDate);
        formData.append('endDate', endDate);

        let departureAirportsIds = [];
        departureAirports.forEach((elt) => {
            departureAirportsIds.push(elt.value);
        });

        formData.append("departureAirports", JSON.stringify(departureAirportsIds));

        let arrivalAirportsIds = [];
        arrivalAirports.forEach((elt) => {
            arrivalAirportsIds.push(elt.value);
        });

        formData.append("arrivalAirports", JSON.stringify(arrivalAirportsIds));

        let aviationTypeIds = [];
        aviationTypes.forEach((elt) => {
            aviationTypeIds.push(elt.value);
        });

        formData.append("aviationTypes", JSON.stringify(aviationTypeIds));

        formData.append("arrayWares", JSON.stringify(arrayCargo));

        let result;

        if (id) {
            result = await MissionService.updateMissionService(id, formData);
        } else {
            result = await MissionService.createMissionService(formData);
        }

        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };


    const handleChangeImage = () => {
        const pics = document.getElementById("id-mission-pics");
        pics.click();
    };

    const appendCargo = () => {
        let temp = arrayCargo;
        temp.push([0, 0]);

        setArrayCargo(temp);
        setChangeCargo(!changeCargo);
    }

    const [listCargo, setListWares] = useState("");

    const setCargo = (i, value) => {
        let temp = arrayCargo;
        temp[i][0] = value;
        setArrayCargo(temp);
    }

    const setQuantity = (i, value) => {

        let temp = arrayCargo;
        temp[i][1] = value;
        setArrayCargo(temp);
    }

    const deleteCargo = (index) => {
        let temp = [];
        for (let i = 0; i < arrayCargo.length; i++) {
            if (i != index) {
                temp.push(arrayCargo[i])
            }
        }
        setArrayCargo(temp);
        setChangeCargo(!changeCargo);
    }

    const filterAirport = (inputValue) => {
        return allLocations.filter((option) =>
        (
            `${option.value} ${option.label} ${option.municipality} ${option.iso_region} ${option.ident} ${option.gps_code} ${option.continent} ${option.type}`
        ).toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const loadAirportListOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(filterAirport(inputValue));
        }, 1000);
    };

    const handleAirportInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, "");

        return inputValue;
    };

    useEffect(() => {
        setListWares(<ListWares arrayCargo={arrayCargo} appendCargo={appendCargo} setCargo={setCargo} setQuantity={setQuantity} deleteCargo={deleteCargo} ></ListWares>)
    }, [changeCargo])


    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Mission</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Mission</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">New Mission</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/mission/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createMissionHandler(e)}>
                                                <div className="row">
                                                    <div className="mdg-card-body mdg-row">
                                                        <div className="col-lg-6">
                                                            <input
                                                                className="mdg-d-none"
                                                                type="file"
                                                                accept="image/png, image/gif, image/jpeg"
                                                                id="id-mission-pics"
                                                                onChange={(event) => {
                                                                    setMissionImage(event.target.files[0]);
                                                                    setPhotoUrl(
                                                                        URL.createObjectURL(event.target.files[0])
                                                                    );
                                                                }}
                                                            />
                                                            <img
                                                                onClick={handleChangeImage}
                                                                src={photoUrl}
                                                                className="mdg-upload-img"
                                                            ></img>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-mission-title" className="mdg-normal-label">
                                                                        Title
                                                                    </label>
                                                                    <input
                                                                        id="input-mission-title"
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={title}
                                                                        onChange={(event) => {
                                                                            setTitle(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-mission-description" className="mdg-normal-label">
                                                                        Description
                                                                    </label>
                                                                    <input
                                                                        id="input-mission-description"
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={description}
                                                                        onChange={(event) => {
                                                                            setDescription(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-mission-vol" className="mdg-normal-label">
                                                                        Vol
                                                                    </label>
                                                                    <input
                                                                        id="input-mission-vol"
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={vol}
                                                                        onChange={(event) => {
                                                                            setVol(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-mission-xp" className="mdg-normal-label">
                                                                        Xp
                                                                    </label>
                                                                    <input
                                                                        id="input-mission-xp"
                                                                        type="number"
                                                                        placeholder=""
                                                                        value={xp}
                                                                        min={0}
                                                                        onChange={(event) => {
                                                                            setXp(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-mission-fcBonus" className="mdg-normal-label">
                                                                        FC Bonus
                                                                    </label>
                                                                    <input
                                                                        id="input-mission-fcBonus"
                                                                        type="number"
                                                                        placeholder=""
                                                                        value={fcBonus}
                                                                        min={0}
                                                                        onChange={(event) => {
                                                                            setFcBonus(event.target.value);
                                                                        }}
                                                                        required
                                                                        style={{ color: "#fff" }}
                                                                        className="form-control"
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    {missionTypeList && (
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <Select
                                                                                placeholder="Choose Mission Type *"
                                                                                options={missionTypeList}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={(newValue, actionMeta) => {
                                                                                    newValue != null ? setMissionType(newValue.value) : setMissionType(null);
                                                                                }}
                                                                                value={findInList(missionTypeList, missionType)}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    {careerLevelList && (
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <Select
                                                                                placeholder="Choose Career Level *"
                                                                                options={careerLevelList}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={(newValue, actionMeta) => {
                                                                                    newValue != null ? setCareerLevel(newValue.value) : setCareerLevel(null);
                                                                                }}
                                                                                value={findInList(careerLevelList, careerLevel)}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        id="id-check-isAllowAllUser"
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={(e) => setIsAllowAllUser(!isAllowAllUser)}
                                                                        checked={isAllowAllUser ? true : false}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-isAllowAllUser">Allow All User</label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        id="id-check-isRecurrent"
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={(e) => setIsRecurrent(!isRecurrent)}
                                                                        checked={isRecurrent ? true : false}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-isRecurrent">Recurrent</label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        id="id-check-isExactStartDate"
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={(e) => setIsExactStartDate(!isExactStartDate)}
                                                                        checked={isExactStartDate ? true : false}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-isExactStartDate">Exact Start Date</label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        id="id-check-isExactEndDate"
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={(e) => setIsExactEndDate(!isExactEndDate)}
                                                                        checked={isExactEndDate ? true : false}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-isExactEndDate">Exact End Date</label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        id="id-check-isExactStartHour"
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={(e) => setIsExactStartHour(!isExactStartHour)}
                                                                        checked={isExactStartHour ? true : false}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-isExactStartHour">Exact Start Hour</label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        id="id-check-isExactEndHour"
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={(e) => setIsExactEndHour(!isExactEndHour)}
                                                                        checked={isExactEndHour ? true : false}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-isExactEndHour">Exact End Hour</label>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <input
                                                                        id="id-check-isEmbarkPassengers"
                                                                        className=""
                                                                        type="checkbox"
                                                                        onChange={(e) => setIsEmbarkPassengers(!isEmbarkPassengers)}
                                                                        checked={isEmbarkPassengers ? true : false}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-isEmbarkPassengers">Embark passengers</label>
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-notice-start-date" className="mdg-normal-label">
                                                                        Start Date
                                                                    </label>
                                                                    <input
                                                                        id="input-notice-start-date"
                                                                        type="datetime-local"
                                                                        className="form-control"
                                                                        min={new Date()}
                                                                        value={startDate}
                                                                        onChange={(e) => { setStartDate(e.target.value) }}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12">
                                                                    <label htmlFor="input-notice-end-date" className="mdg-normal-label">
                                                                        End Date
                                                                    </label>
                                                                    <input
                                                                        id="input-notice-end-date"
                                                                        type="datetime-local"
                                                                        className="form-control"
                                                                        min={startDate ?? ''}
                                                                        value={endDate}
                                                                        onChange={(e) => { setEndDate(e.target.value) }}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className="col-lg-12 mb-2">
                                                                    {listCargo}
                                                                </div>
                                                                {allAviationType && (
                                                                    <div className="col-lg-12">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <Select
                                                                                placeholder="Choose aviation types"
                                                                                style={{ color: "#fff" }}
                                                                                closeMenuOnSelect={false}
                                                                                isMulti
                                                                                isClearable={true}
                                                                                onChange={async (newValue, actionMeta) => {
                                                                                    newValue && setAviationTypes(newValue);
                                                                                }}
                                                                                options={allAviationType}
                                                                                styles={selectStyles}
                                                                                value={aviationTypes}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {allLocations && (
                                                                    <div className="col-lg-12">
                                                                        <div className="mdg-textfield-out-lined">
                                                                            {/* <Select
                                                                                placeholder="Choose departure airports"
                                                                                style={{ color: "#fff" }}
                                                                                closeMenuOnSelect={false}
                                                                                isMulti
                                                                                isClearable={true}
                                                                                onChange={async (newValue, actionMeta) => {
                                                                                    newValue && setDepartureAirports(newValue);
                                                                                }}
                                                                                options={allLocations}
                                                                                styles={selectStyles}
                                                                                value={departureAirports}
                                                                            />*/}
                                                                            <AsyncSelect
                                                                                styles={selectStyles}
                                                                                placeholder="Choose departure airports"
                                                                                isClearable={true}
                                                                                isMulti
                                                                                onChange={(newValue) => {
                                                                                    if (newValue) {
                                                                                        newValue && setDepartureAirports(newValue);
                                                                                    }
                                                                                }}
                                                                                value={departureAirports}
                                                                                cacheOptions
                                                                                loadOptions={loadAirportListOptions}
                                                                                defaultOptions
                                                                                onInputChange={handleAirportInputChange} 
                                                                            />
                                                                        </div> 
                                                                    </div>
                                                                )}
                                                            {allLocations && (
                                                                <div className="col-lg-12">
                                                                    <div className="mdg-textfield-out-lined">
                                                                        {/* <Select
                                                                            placeholder="Choose arrival airports"
                                                                            style={{ color: "#fff" }}
                                                                            closeMenuOnSelect={false}
                                                                            isMulti
                                                                            isClearable={true}
                                                                            onChange={async (newValue, actionMeta) => {
                                                                                newValue && setArrivalAirports(newValue);
                                                                            }}
                                                                            options={allLocations}
                                                                            styles={selectStyles}
                                                                            value={arrivalAirports}
                                                                        /> */}
                                                                        <AsyncSelect
                                                                            styles={selectStyles}
                                                                            placeholder="Choose arrival airports"
                                                                            isClearable={true}
                                                                            isMulti
                                                                            onChange={async (newValue, actionMeta) => {
                                                                                newValue && setArrivalAirports(newValue);
                                                                            }}
                                                                            value={arrivalAirports}
                                                                            cacheOptions
                                                                            loadOptions={loadAirportListOptions}
                                                                            defaultOptions
                                                                            onInputChange={handleAirportInputChange} 
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-right mt-2">
                                                        <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

                </div >
            </section >
        </div >
    );
};

export default MissionForm;
