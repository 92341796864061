import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import FoodCategoryService from "../../../../services/paxncargo/foodCategory";

const FoodCategoryForm = (props) => {
  const { id } = useParams();
  const [name, setName] = useState("");

  const handleCreateFoodCategory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);

    let result;
    if (id) {
      result = await FoodCategoryService.update(id, formData);
    } else {
      result = await FoodCategoryService.create(formData);
    }

    if (result.error) {
      swal(result.response.data.data.message, {
        icon: "error",
      });
    } else {
      swal(result.data.message, {
        icon: "success",
      });
    }
  };

  const getFoodCategory = async () => {
    if (id) {
      const result = await FoodCategoryService.getById(id);
      if (!result.error) {
        setName(result.data.data.name);
      }
    }
  };

  useEffect(() => {
    getFoodCategory();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{id ? "Edit Pack" : "Create new Pack"}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Food Category</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {id ? "Edit Food Category Information" : "New Food Category"}
                  </h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/paxncargo/food-category/"
                      type="button"
                      className="btn btn-tool bg-info"
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => handleCreateFoodCategory(e)}>
                        <div className="row">
                          <div className="mdg-col-sm-12">
                            <div className="mdg-textfield-out-lined">
                              <input
                                id="input-category-parent-id"
                                type="text"
                                placeholder=" "
                                value={name}
                                onChange={(event) => {
                                  setName(event.target.value);
                                }}
                                required
                                style={{ color: "#fff" }}
                              />
                              <label htmlFor="input-category-parent-id">
                                Food Category Name *
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-12 text-right mt-2">
                            <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                              Save
                            </button>
                          </div>
                          <div className="offset-lg-11 mt-1"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FoodCategoryForm;
