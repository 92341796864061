import { useRef, useState } from "react";
import Select from "react-select";
import { selectStylesSwal } from "../../../../Utils/Common";
import { IMaskInput } from 'react-imask';
import Language from "../../../extra/language/language";

const SellForm = (props) => {
    const searchInput = useRef();
    const [userList, setUserList] = useState(props.users);
    const [selectedSellType, setSelectedSellType] = useState({ label: "Sell", value: "SELL" });
    const [sellType, setSellType] = useState(props.state.sellType);
    const [price, setPrice] = useState(props.state.price);
    const [user, setUser] = useState(props.state.user);
    const [dateLimit, setDateLimit] = useState(props.state.dateLimit);
    const selectOption = [{ label: "Sell", value: "SELL" }, { label: "Bid", value: "BID" }, { label: "Private", value: "PRIVATE" }]


    const handleFilter = (items) => {
        return (searchValue) => {
            if (searchValue.length === 0) {
                return userList;
            }
            const updatedItems = items.filter((item) => {
                return item.name.toLowerCase().includes(searchValue.toLowerCase());
            });
            return updatedItems;
        };
    };

    const handleChange = (newValue) => {

        props.setUser(newValue.value);
    };


    return (
        <div className="mdg-row">
            <div className="col-lg-12">
                <div className="mdg-textfield-out-lined">
                    <Select
                        isClearable={false}
                        onChange={(newValue, actionMeta) => {
                            setSelectedSellType(newValue)
                            setSellType(newValue.value)
                            props.setSellType(newValue.value)

                        }}
                        styles={selectStylesSwal}
                        isSearchable={false}
                        placeholder={<div><Language word={"Seller.Choose_a_type"}></Language></div>}
                        options={selectOption}

                        value={selectedSellType}
                        id="id-sell-type"
                    />
                </div>
            </div>
            <div className="col-lg-12">
                <div className="mdg-textfield-out-lined">
                    <input id="id-price" type="number" min="1" className="mdg-text-white" name="price" placeholder=" " value={price} onChange={(e) => { setPrice(e.target.value); props.setPrice(e.target.value) }} />
                    {sellType == "BID" ? <label htmlFor="id-price"><Language word={"Seller.From_Price"}></Language></label> : <label htmlFor="id-price"><Language word={"Seller.Price"}></Language></label>}

                </div>
            </div>
            <div className="col-lg-12" style={{ minHeight: "100px" }}>
                <div className="row">
                    {
                        sellType == "PRIVATE" && (
                            <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                    <Select
                                        ref={searchInput}
                                        options={userList}
                                        filterOptions={handleFilter}
                                        name="User"
                                        styles={selectStylesSwal}
                                        placeholder="Choose an user"
                                        search
                                        onChange={(newValue) => handleChange(newValue)}
                                        id="id-user"
                                    />

                                </div>
                            </div>
                        )
                    }
                    {
                        sellType == "BID" && (
                            <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                    <IMaskInput
                                        mask={Date}
                                        value={dateLimit} 
                                        min={new Date()}
                                        onAccept={(value, mask) => { setDateLimit(null) }}
                                        onComplete={(value) => {setDateLimit(value); props.setDateLimit(value);}}
                                        id="id-date-limit"
                                        style={{color:"white"}}
                                        required
                                        minLength={10}
                                        maxLength={10}
                                    />
                                    <label htmlFor="id-date-limit"><Language word={"Seller.Date_Limit"}></Language></label>

                                </div>
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default SellForm;