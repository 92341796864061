import { useState } from "react";
import { useSelector } from "react-redux";
import CheckoutService from "../../../../../../services/checkout";
import Swal from "sweetalert2";
import PlaceOrder from "../../placeOrder";


const SubmitPayment = () => {
	const cart = useSelector((store) => store.cart);

	const [inProcess, setInProcess] = useState(false);
	 const createOrder = async () => {
        setInProcess(true);

        if (cart.numberCart >= 1) {
            let order = {
                coupons: cart.coupons,
                payment: {
                    method: "paypal"
                }
            };

            let res;
            if (cart.type === 'subscription') {
                order.subscription = cart.Carts[0];
                order.type = "subscription";
                res = await CheckoutService.subscribeUser(order);
                setInProcess(false);
                if(res.error){
                    Swal.fire({
                        text: res.response.data.message,
                        icon:"error"
                    })
                }else{
                    if(res.data.error){
                        Swal.fire({
                            text: res.data.message,
                            icon:"error"
                        })
                    }else{
						console.log(res.data);
                    }
                }
            } else {
                order.items = cart.Carts;
                order.type = cart.type;
                res = await CheckoutService.orderPack(order);
                setInProcess(false);
                
                if(res.error){
                    Swal.fire({
                        text: res.response.data.message,
                        icon:"error"
                    })
                }else{
                    if(res.data.error){
                        Swal.fire({
                            text: res.data.message,
                            icon:"error"
                        })
                    }else{
                       
                        window.location = res.data.data.approval_url;
                    }
                }
            }
        }
        else {
            setInProcess(false);
        }
    };

	return (
		<>
            <div className="col-lg-12 ">
                <PlaceOrder cart={cart} makeOrder={createOrder} inProcess={inProcess} ></PlaceOrder>
            </div>
        </>
	);
};

export default function PayPalPayment() {
	return (
			<SubmitPayment/>
	);
}
