import axios  from "../AxiosInstance"
import translate from '../../components/extra/language/translate';

const API_URL = process.env.REACT_APP_REMOTE_API_BASE_URL

export const Tracker = {
    METACARS: 'metacars',
    AFRV: 'afrv',
    UNKNOWN: 'unknown',
}

export const FlightStep = {
    NONE: 'none',
    GROUNG: 'ground',
    BOARDING: 'boarding',
    PUSHBACK: 'pushback',
    BLOCK_ON: 'block_on',
    INITIAL_CLIMB: 'initial_climb',
    TAXI_OUT: 'taxi_out',
    TAKEOFF: 'takeoff',
    CLIMB: 'climb',
    CRUISE: 'cruise',
    DESCENT: 'descent',
    APPROACH: 'approach',
    LANDING: 'landing',
    TAXI_IN: 'taxi_in',
    BLOCK_OFF: 'block_off',
    DEBOARDING: 'deboarding',
    PARKED: 'parked',
    EMERGENCY: 'emergency',
    CRASHED: 'crashed',
    DIVERSION: 'diversion',
}

export default class AcarsService {
    static async getFlight(id) {
        try {
            return await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker/${id}`);
        } catch (err) {
            return {
                error: true,
                response: err.response
            };
        }
    }

    static async getFlightsWithMetaflightApi() {
        try {
            const results =  await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker/metaflight`);
            const flights = results.data.data;

            let flightsFormated =  flights.map((flight) => {
                let positionHistory =  flight.aircraftHistories ? flight.aircraftHistories.map((aircraftState) => {
                    return [
                        aircraftState.positionLatitude,
                        aircraftState.positionLongitude,
                        aircraftState.positionAltitude,
                    ];
                }) : [];

                let aircraftStateHistories = flight.aircraftHistories ? flight.aircraftHistories.map((aircraftState) => {
                    return {
                        id: aircraftState.id,
                        position: {
                       
                            altitude: aircraftState.positionAltitude,
                            latitude: aircraftState.positionLatitude,
                            longitude: aircraftState.positionLongitude
                    }};
                }) : [];
                
                return {
                  ...flight,
                  aircraftStateHistories : aircraftStateHistories,
                  aircraft: {
                    heading: flight.aircraftHeading,
                    indicatedSpeed: flight.aircraftIndicatedspeed,
                    trueAirSpeed: flight.aircraftTrueAirspeed,
                    position: {
                      altitude: flight.aircraftPositionAltitude,
                      latitude: flight.aircraftPositionLatitude,
                      longitude: flight.aircraftPositionLongitude,
                    },
                    groundSpeed: flight.aircraftGroundspeed,
                    registration: flight.aircraftRegistration,
                    icao: flight.aircraftIcao,
                  },
                  tracker: Tracker.METACARS,
                  positionHistory: positionHistory,
                  departureAirport: {
                    ...flight.departureIcao,
                    position: {
                      latitude: flight.departureIcao.latitude_deg,
                      longitude: flight.departureIcao.longitude_deg,
                    },
                  },
                  arrivalAirport: {
                    ...flight.arrivalIcao,
                    position: {
                      latitude: flight.arrivalIcao.latitude_deg,
                      longitude: flight.arrivalIcao.longitude_deg,
                    },
                  },
                };
              });
          

           
           return flightsFormated;
        } catch (err) {
            return [];
        }
    }

    static async getAllFlights() {
        try {
            return await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker`);
        } catch (err) {
            return {
                error: true,
                response: err.response
            };
        }
    }

    static async getAfrvFlights() {
        try {
            const result =  await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker/afrv`);
            
            return result.data.data;
        } catch (err) {
           return [];
        }
    }

    static async getAfrvFlightById(id) {
        try {
            const result =  await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker/afrv/${id}`);
            
            return this.creatAfrvFlight(result.data.flight,id);
        } catch (err) {
           return null;
        }
    }

    static async creatAfrvFlight (flightReceive ,id) {
        if(flightReceive === null || flightReceive === undefined) return null;
        const flight = {
            actualFlightStep: FlightStep.NONE,
            airplaneStateInformations: {},
            cruiseLevel: 0,
            aircraft: {
              heading: flightReceive.png,
              indicatedSpeed: 0,
              trueAirSpeed: 0,
              position: {
                altitude: flightReceive.alt,
                latitude: flightReceive.vollat,
                longitude: flightReceive.vollng,
              },
              groundSpeed: flightReceive.gs,
              registration: flightReceive.plane.split("|")[1],
              icao: flightReceive.plane.split("|")[0],
            },
            arrivalAirport: {
              ident: flightReceive.ICAOarr,
              position: {
                latitude: flightReceive.icaoarrlat,
                longitude: flightReceive.icaoarrlng,
              },
            },
            departureAirport: {
              ident: flightReceive.ICAOdep,
              position: {
                latitude: flightReceive.icaodeplat,
                longitude: flightReceive.icaodeplng,
              },
            },
            flightNumber: flightReceive.flightnum,
            id,
            pilot: { id: flightReceive.idpil, name: flightReceive.pilotname },
            positionHistory: flightReceive.ptscourbe,
            tracker: Tracker.AFRV,
          };
      
          return flight;
    }

    static async getAfrvFlightsV2() {
        try {
            return await axios(`https://crew.airfrancevirtuel.com/lib/skins/V8/page/Manager/ManagerLiveMap.php?VOLAFRVPLANE=0`,{ withCredentials : false});
        } catch (err) {
            return {
                error: true,
                response: err.response
            };
        }
    }

    static async getUserData() {
        try {
            return await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker/data`);
        } catch (err) {
            return {
                error: true,
                response: err.response
            };
        }
    }

    static async getIvaoFlights() {
        try {
            return await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker/ivao`);
        } catch (err) {
            return {
                error: true,
                response: err.response
            };
        }
    }

    static async getIvaoFlightsV2() {
        return axios
            .get("https://api.ivao.aero/v2/tracker/whazzup")
            .then((res) => res.data)
            .then(({ clients }) => {
                return clients.pilots;
            })
            .catch((err) => {
                return [];
            });
    }


    static async getVatsimFlights() {
        try {
            return await axios(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/tracker/vatsim`);
        } catch (err) {
            return {
                error: true,
                response: err.response
            };
        }
    }

    static async getVatsimFlightsV2() {
        return axios
            .get("https://data.vatsim.net/v3/vatsim-data.json")
            .then((res) => res.data)
            .then(({ pilots }) => {
                return pilots;
            })
            .catch((err) => {
                return [];
            });
    }

    static getFlightStep(step) {
        return translate(`Flight.Phase.${step}`,'en');
    }

}
