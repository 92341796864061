import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import MissionService from "../../../../services/missions/mission";
import { getFlightBeautifulFormatedDateWithTime, getImageUrl } from "../../../../Utils/Common";
import Thumbnail from "../../../extra/img/thumbnail";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";

const MissionElement = ({ mission, setShowModal, handleModal }) => {
    
    const lang = useSelector((store) => store.language);
    const [missions, setMissions] = useState([]);

    const getMissions = async () => {
        const res = await MissionService.getAllMissionService();
        if (!res.error) {
            setMissions(res.data);
        }
    }

    useEffect(() => {
        getMissions();
    }, []);

    return (
        <>
            <div className="row justify-content-between mdg-bg-row-3 mt-4">
                <div className="col-lg-4">
                    <Thumbnail
                        src={mission.image ? getImageUrl(mission.image) : "/assets/img/avion1.jpg"}
                        alt="logo"
                        className="mdg-image-body"
                    ></Thumbnail>
                </div>
                <div className="col-lg-8">
                    <div className="pt-3">
                        <div className="row">
                            <div className="col-5">
                                <div className="float-left">
                                    <h3 className="mdg-text-white mdg-uppercase">
                                        {mission.title}
                                    </h3>
                                    <span className="mdg-text-white">
                                        <small>{mission.description}</small>
                                    </span>
                                </div>
                            </div>
                            <div className="col-4" style={{ color: "white" }}>
                                <div className="">

                                </div>
                                <div className="mt-5">
                                    <div className="col-lg-12 ml-1">
                                        <h5 className="mdg-text-white"><Language word={"Mission.Date"}></Language></h5>
                                    </div>
                                    <div className="col-lg-12 ml-1">
                                        <span className="d-block mdg-bg-text-items">{translate('Mission.StartDate', lang)} : <span className="mdg-text-white">{getFlightBeautifulFormatedDateWithTime(mission.startDate)} </span></span>
                                        <span className="d-block mdg-bg-text-items">{translate('Mission.EndDate', lang)} : <span className="mdg-text-white">{getFlightBeautifulFormatedDateWithTime(mission.endDate)} </span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3" style={{ color: "white" }}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="float-right">
                                            <span className="d-block mdg-bg-text-items mt-1">
                                                {translate('Mission.xpPoint', lang)} : <span>{mission.xp}</span>
                                            </span>

                                            {
                                                mission.fcBonus > 0 && (
                                                    <span className="d-block mdg-bg-text-items mt-1">
                                                        FC :   {mission.fcBonus}&nbsp;
                                                        <img
                                                            src="/assets/image/coin.png"
                                                            style={{ width: "15px", height: "15px" }}
                                                            alt=""
                                                            className="rounded-circle-sm mr-1"
                                                        />
                                                    </span>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <h6 className="mt-2 ml-2 mdg-text-white mt-1">{translate('Mission.numFlight', lang)} : <span className="mdg-text-white">{mission.vol} </span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div></div>
                        <p className="lead mdg-text-white"></p>
                        <div className="row mdg-footer-btn">
                            <div className="col-sm">
                                <NavLink
                                    className="btn mdg-btn-common"
                                    to="/mission/detail"
                                    state={{
                                        mission: mission,
                                    }}
                                >
                                    <span> <Language word={"Mission.Details"}></Language> </span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MissionElement;
