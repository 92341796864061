import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import PackForm from './PackForm';
import PackList from './PackList';

const PackComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <PackForm action="create"></PackForm> ;break;
        case "edit": page = <PackForm action="edit"></PackForm> ; break;
        default: page = <PackList></PackList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default PackComponent;