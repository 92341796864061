import axios  from "../AxiosInstance"

const getAllSubscriptionService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteById = async (id) => {
    try {
        return await axios.delete(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/${id}`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getSubscriptionTypeService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/type`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getSubscriptionTypeAsKeyValueService = async () => {
    const res = await getSubscriptionTypeService();
    if (res.error) {
        return [];
    } else {
        const types = [];
        const { data } = res;
        const dataArray = Object.keys(data);

        for (let i = 0; i < dataArray.length; ++i) {
            const elt = dataArray[i];

            types.push({ label: elt, value: elt });
        }
        return types;
    }
}

const createSubscriptionService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const setSubscriptionSortByService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/set-sort-by`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const updateSubscriptionService = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

export const getSubscriptionById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

export const getMySubscription = async () => {
    try {
        return await axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/my-subscription`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

export const getMySubscriptionPortal = async () => {
    try {
        return await axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/my-portal`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAllAsKeyValue = async () => {
    const res = await getAllSubscriptionService();
    if (res.error) {
        return [];
    } else {
        const output = [];
        const  data  = res.data;
        
        data.forEach( (elt,i) => {
            output.push({ label: elt.name+" / "+elt.type, value: elt.id });
        })
  
        return output;
    }
  }

export const cancelMySubscription = async () => {
    try {
        return await axios.patch(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/subscriptions/cancel-my-subscription`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const SubscriptionService = {
    getAllSubscriptionService,
    deleteById,
    getSubscriptionTypeAsKeyValueService,
    createSubscriptionService,
    updateSubscriptionService,
    getSubscriptionById,
    getMySubscription,
    getAllAsKeyValue,
    cancelMySubscription,
    setSubscriptionSortByService,
    getMySubscriptionPortal    
}

export default SubscriptionService;