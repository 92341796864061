import { CHANGE_MODE, DISPLAY_FLIGHT_DETAIL, DISPLAY_TRIP, HIDE_FLIGHT_DETAIL, TOOGLE_NETWORK, TOOGLE_WEATHER, UPDATE_FLIGHT_DETAIL } from "../actions/map/mapTypes";
import L from 'leaflet';
import 'leaflet-openweathermap';
import terminator from '@joergdietrich/leaflet.terminator';

const appId = '8a532bf6f2e23395392a46a5f455961a';

const cloud = L.layerGroup();
L.OWM.cloudsClassic({ showLegend: true, legendPosition: 'bottomleft', opacity: 0.5, appId: appId }).addTo(cloud);
const precipitation = L.layerGroup();
L.OWM.precipitationClassic({ showLegend: true, legendPosition: 'bottomleft', opacity: 0.5, appId: appId }).addTo(precipitation);
const rain = L.layerGroup();
L.OWM.rainClassic({ showLegend: true, legendPosition: 'bottomleft', opacity: 0.5, appId: appId }).addTo(rain);
const temperature = L.layerGroup();
L.OWM.temperature({ showLegend: true, legendPosition: 'bottomleft', opacity: 0.5, appId: appId }).addTo(temperature);
const wind = L.layerGroup();
L.OWM.wind({ showLegend: true, legendPosition: 'bottomleft', opacity: 0.5, appId: appId }).addTo(wind);
const snow = L.layerGroup();
L.OWM.snow({ showLegend: true, legendPosition: 'bottomleft', opacity: 0.5, appId: appId }).addTo(snow);
const pressure = L.layerGroup();
L.OWM.pressure({ showLegend: true, legendPosition: 'bottomleft', opacity: 0.5, appId: appId }).addTo(pressure);
const light = L.layerGroup();
terminator().addTo(light);

const initialState = {
    flightDepartureArrivalData: {
        displayAircraftDepartureArrivalPolyline: false,
    },
    flightDetails: {
        displayFlightDetail: false,
    },
    mode: "DARK",
    weather: {
        cloud: {
            layer: cloud,
            checked: false
        },
        precipitation: {
            layer: precipitation,
            checked: false
        },
        rain: {
            layer: rain,
            checked: false
        },
        temperature: {
            layer: temperature,
            checked: false
        }, wind: {
            layer: wind,
            checked: false
        }, snow: {
            layer: snow,
            checked: false
        },
        pressure: {
            layer: snow,
            checked: false
        },
        light: {
            layer: light,
            checked: false
        }
    },
    addWeather: null,
    removeWeather: null,
    networks: {
        ivao: false,
        afrv: true,
        metaflight: true,
        airport: false,
        vatsim: false,
        home: false,
        last_position: false
    },
    change: 0
}

const map = (state = initialState, action) => {
   
    switch (action.type) {
        case DISPLAY_TRIP:
            state.flightDepartureArrivalData.flightToDisplay = action.payload.flight;
            state.flightDepartureArrivalData.displayAircraftDepartureArrivalPolyline =
                !state.flightDepartureArrivalData
                    .displayAircraftDepartureArrivalPolyline;
            state.change = state.change + 1;
            return {
                ...state
            }
        case DISPLAY_FLIGHT_DETAIL:
            state.flightDetails.flightToDisplay = action.payload.flight;
            state.flightDetails.displayFlightDetail = true;
            state.change = state.change + 1;
            return {
                ...state
            }

        case HIDE_FLIGHT_DETAIL:
            console.log('loloe');  
            state.flightDetails.displayFlightDetail = false;
            state.change = state.change + 1;

            
            return {
                ...state
            }

        case UPDATE_FLIGHT_DETAIL:
            if (
                state.flightDetails.displayFlightDetail &&
                state.flightDetails.flightToDisplay &&
                action.payload.flights
            ) {
                state.flightDetails.flightToDisplay = action.payload.flights.find(
                    (flight) => flight.id === state.flightDetails.flightToDisplay?.id
                );
            }
            state.change = state.change + 1;
            return {
                ...state
            }

        case CHANGE_MODE:
            state.mode = action.payload.mode;

            return {
                ...state
            }

        case TOOGLE_WEATHER:
            let weather = state.weather[action.payload.weather];
            if (weather) {
                state.addWeather = null;
                state.removeWeather = null;
                weather.checked = !weather.checked
                state.weather[action.payload.weather] = weather;

                if (weather.checked) {
                    state.addWeather = weather;
                } else {
                    state.removeWeather = weather;
                }
            }
            // console.log(state.weather);
            return {
                ...state
            }

        case TOOGLE_NETWORK:
            let network = state.networks[action.payload.network];
            if (network !== undefined) {
                network = !network
                state.networks[action.payload.network] = network;
            }

            return {
                ...state
            }

        default: return state;
    }

}

export default map;