import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SettingService from "../../../../services/setting";
import SettingNavHeader from "../NavHeader";
import NumberFormat from "react-number-format";

const BotThreshold = () => {
  const [lowLimitMediumAirport, setLowLimitMediumAirport] = useState("");
  const [lowLimitLargeAirport, setLowLimitLargeAirport] = useState("");
  const [highLimitMediumAirport, setHighLimitMediumAirport] = useState("");
  const [highLimitLargeAirport, setHighLimitLargeAirport] = useState("");

  const getInitialSetting = async () => {
    await SettingService.getSettingsByName("BOT_PAX_LIMIT").then((res) => {
      if (!res.error) {
        setLowLimitMediumAirport(res.data.PAX_low_medium);
        setLowLimitLargeAirport(res.data.PAX_low_large);
        setHighLimitMediumAirport(res.data.PAX_high_medium);
        setHighLimitLargeAirport(res.data.PAX_high_large);
      }
    });
  };

  const handleThresholdLimitation = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      PAX_low_medium: lowLimitMediumAirport,
      PAX_low_large: lowLimitLargeAirport,
      PAX_high_medium: highLimitMediumAirport,
      PAX_high_large: highLimitLargeAirport,
    };
    formData.append("name", "BOT_PAX_LIMIT");
    formData.append("data", JSON.stringify(data));

    let result = await SettingService.create(formData);

    if (result.error) {
      Swal.fire({
        title: result.response.data.message
          ? result.response.data.message
          : result.response.data.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: result.data.message,
        icon: result.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => {});
    }
  };

  useEffect(() => {
    getInitialSetting();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <SettingNavHeader />

      <section className="content mt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    Pax threshold per day limitation for the bot
                  </h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleThresholdLimitation(e)}>
                    <div className="row">
                    <div className="row col-lg-12 mt-2">
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <NumberFormat
                            suffix={" pax"}
                            decimalSeparator=","
                            style={{ color: "#fff" }}
                            className={`text-right`}
                            value={lowLimitMediumAirport}
                            id="input-flight-duration"
                            required
                            onValueChange={(values) =>
                              setLowLimitMediumAirport(values.floatValue)
                            }
                          />
                          <label htmlFor="input-flight-duration">
                          Medium Airport Low Limit  *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <NumberFormat
                            suffix={" pax"}
                            decimalSeparator=","
                            style={{ color: "#fff" }}
                            className={`text-right`}
                            value={lowLimitLargeAirport}
                            id="input-rest-time"
                            required
                            onValueChange={(values) =>
                              setLowLimitLargeAirport(values.floatValue)
                            }
                          />
                          <label htmlFor="input-rest-time">
                          Large Airport Low Limit  *
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row col-lg-12 mt-2">
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <NumberFormat
                            suffix={" pax"}
                            decimalSeparator=","
                            style={{ color: "#fff" }}
                            className={`text-right`}
                            value={highLimitMediumAirport}
                            id="input-flight-duration"
                            required
                            onValueChange={(values) =>
                              setHighLimitMediumAirport(values.floatValue)
                            }
                          />
                          <label htmlFor="input-flight-duration">
                          Medium Airport High Limit  *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <NumberFormat
                            suffix={" pax"}
                            decimalSeparator=","
                            style={{ color: "#fff" }}
                            className={`text-right`}
                            value={highLimitLargeAirport}
                            id="input-rest-time"
                            required
                            onValueChange={(values) =>
                              setHighLimitLargeAirport(values.floatValue)
                            }
                          />
                          <label htmlFor="input-rest-time">
                          Large Airport High Limit  *
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 text-right mt-2">
                      <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                        Save
                      </button>
                    </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BotThreshold;
