import axios  from "../AxiosInstance"

const getAll = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/country`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getMissionTypeAsKeyValueService = async (with_all = false) => {
    const res = await getAll();
    if (res.error) {
        return [];
    } else {
        const wares = [];
        if(with_all) wares.push({ label: "ALL", value: 'all' });
        for (let i = 0; i < res.data.length; ++i) {
            const elt = res.data[i];
            
            wares.push({ label: elt.name, value: elt.iso });
        }
        return wares;
    }
};

const CountryService = {
    getAll,
    getMissionTypeAsKeyValueService
}

export default CountryService;