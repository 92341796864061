import axios  from "../AxiosInstance"

const createNoticesBoService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAllNoticesService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const getNoticeById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const updateNoticeService = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteByIdService = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getUnreadNoticesService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices/unread-notice`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const readNotice = async (noticeId) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices/read-notice/${noticeId}`,
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
}

const getLimitedNoticesService = async (limit) => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notices/limit/${limit}`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const NoticesBoService = {
    createNoticesBoService,
    getAllNoticesService,
    getNoticeById,
    updateNoticeService,
    deleteByIdService,
    getUnreadNoticesService,
    readNotice,
    getLimitedNoticesService
}

export default NoticesBoService;