import axiosApiInstance from './AxiosInstance';
// verify refresh token to generate new access token if refresh token is present
export const verifyTokenService = async () => {
  try {
    return await axiosApiInstance.post("/verify-token")
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

// user login API to validate the credential
export const userLoginService = async (username, password) => {
  try {
    return await axiosApiInstance.post("/sign-in",{ username, password })
    // return await axios.post(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/sign-in`, { username, password });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

// manage user logout
export const userLogoutService = async () => {
  try {
    return await axiosApiInstance.post("/logout")
    // return await axios.post(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/logout`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

