import { useState } from "react"
import { NavLink } from "react-router-dom"
import { getImageUrl } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";

const RightMenu = ({ user }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <li className="nav-item dropdown">
            <NavLink
                className="nav-link mdg-p-dropdown"
                to="/user-dashboard"
                // onClick={(e) => setExpanded((expanded) => !expanded)}
                // aria-expanded={expanded}
                style={{ border: '2px solid #fff', borderRadius: '20px', height: '43px' }}
            >
                <span className=" mr-1">
                    <small className="ml-2">{user.userName}</small>
                </span>
                <img
                    src={
                        user.photo
                            ? getImageUrl(user.photo)
                            : "/assets/img/default-profile-picture.png"
                    }
                    width="30"
                    height="30"
                    alt=""
                    className="rounded-circle"
                />
            </NavLink>
            <div className={expanded ? "dropdown-menu show" : "dropdown-menu"} style={{ backgroundColor: "#081217", color: "#fff" }}>
                <NavLink
                    className="dropdown-item"
                    to="/user-dashboard"
                    style={{ color: "#fff" }}
                >
                    <i className="fa fa-bar-chart mr-1"></i><Language word={"Header.Dashboard"}></Language>
                </NavLink>
                <NavLink
                    className="dropdown-item"
                    to="/logout"
                    style={{ color: "#fff" }}
                >
                    <i className="fa fa-sign-out mr-1"></i><Language word={"Header.Logout"}></Language>
                </NavLink>
            </div>
        </li>
    )
}

export default RightMenu

