import React from 'react';
import { useParams } from 'react-router-dom';
import HeaderPage from '../../../header/HeaderPage';
import MyItemDetail from './MyItemDetail';


const MyItemDetailPage = () => {
    document.body.style.backgroundColor = "#081217";
    
    const { id , type } = useParams ()
    let storeType = "SELL";
    
    switch(type){
        case "rent":  storeType = "RENT";break;
        case "exchange":  storeType = "EXCHANGE";break;
        case "finished":  storeType = "FINISHED";break;
        default  : storeType = "SELL";break;
    }

    return (
        <div className="mdg-body-ingame">
            <div className="mdg-header-ingame">
                <HeaderPage />
                <MyItemDetail item={id} type={storeType} action={type}></MyItemDetail>
            </div>
        </div>
    );
};
export default MyItemDetailPage;