import { Outlet, useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { isAvailableFeature } from "../../Utils/Common";
import Language from "../extra/language/language";
import LiveMap from "../extra/livemap/map";
import Panier from "../pages/fo/checkout/panier/Panier";
import HeaderPage from "../pages/fo/header/HeaderPage";

const UserDashboardLayout = () => {
    document.body.style.backgroundColor = "#081217";
    const navigate = useNavigate();
    const location = useLocation();
    const current = location.pathname;
    

    return (
        <section className="mdg-body dash">
            <LiveMap />
            <HeaderPage />
            <div className="container mdg-top-container" style={{ zIndex: '2' }}>
                <div className="row">
                    <div className="col-3">
                        <div className="menu-left nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button className={current == '/user-dashboard' ? "nav-link btn-icon active" : "nav-link btn-icon "} onClick={() => navigate('/user-dashboard')}>
                                <span className="mdg-focus-icon mr-4"><i className="fa fa-qrcode" aria-hidden="true"></i></span> <Language word={"DashboardMenu.Dashboard"}></Language> 
                            </button>
                            <button className={current == '/user-profile' ? "nav-link btn-icon active" : "nav-link btn-icon "}  onClick={() => navigate('/user-profile')} ><span className="mdg-focus-icon mr-4"><i className="fa fa-user" aria-hidden="true"></i></span><Language word={"DashboardMenu.Profile"}></Language> </button>
                        { isAvailableFeature('myitems') && (
                            <button className={current == '/user-items' ? "nav-link btn-icon active" : "nav-link btn-icon "}   onClick={() => navigate('/user-items')}><span className="mdg-focus-icon mr-4"><i className="fa fa-briefcase" aria-hidden="true"></i></span><Language word={"DashboardMenu.My_Items"}></Language> </button>
                        )}
                         
                        <button className={current == '/user-flights' ? "nav-link btn-icon active" : "nav-link btn-icon "}  onClick={() => navigate('/user-flights')}><span className="mdg-focus-icon mr-4"><i className="fa fa-fighter-jet" aria-hidden="true"></i></span><Language word={"DashboardMenu.MY_Flight_List"}></Language> </button>
                        <button className={current == '/pack-booster' ? "nav-link btn-icon active" : "nav-link btn-icon "}  onClick={() => navigate('/pack-booster')}><span className="mdg-focus-icon mr-4"><i className="fa fa-shopping-cart" aria-hidden="true"></i></span><Language word={"DashboardMenu.Pack_Booster"}></Language> </button>
                        <button className={current == '/user-wallet' ? "nav-link btn-icon active" : "nav-link btn-icon "}  onClick={() => navigate('/user-wallet')}><span className="mdg-focus-icon mr-4"><i className="fa fa-folder-open" aria-hidden="true"></i></span><Language word={"DashboardMenu.Wallet"}></Language> </button>
                        <button className={current == '/user-payment' ? "nav-link btn-icon active" : "nav-link btn-icon "}  onClick={() => navigate('/user-payment')}><span className="mdg-focus-icon mr-4"><i className="fa fa-usd" aria-hidden="true"></i></span><Language word={"DashboardMenu.Payment"}></Language> </button>
                        <button className={current == '/subscriptions' ? "nav-link btn-icon active" : "nav-link btn-icon "}  onClick={() => navigate('/subscriptions')}><span className="mdg-focus-icon mr-4"><i className="fa fa-plus-square" aria-hidden="true"></i></span><Language word={"DashboardMenu.Subscription"}></Language> </button>
                       
                        { isAvailableFeature('wishlist') && (
                           <button className={current == '/wishlists' ? "nav-link btn-icon active" : "nav-link btn-icon "}  onClick={() => navigate('/wishlists')}><span className="mdg-focus-icon mr-4"><i className="fa fa-heart-o" aria-hidden="true"></i></span><Language word={"DashboardMenu.Wishlist"}></Language> </button>
                         )} 
                        <button className={current == '/notices' ? "nav-link btn-icon active" : "nav-link btn-icon "} onClick={() => navigate('/notices')}><span className="mdg-focus-icon mr-4"><i className="fa fa-window-restore" aria-hidden="true"></i></span><Language word={"DashboardMenu.Notam"}></Language> </button>
                        <button className="nav-link btn-icon" to="/logout" onClick={() => navigate('/logout')}><span className="mdg-focus-icon mr-4"><i className="fa fa-sign-out" aria-hidden="true"></i></span><Language word={"DashboardMenu.Logout"}></Language> </button>
                  
                        </div>
                    </div>
                    <div className="col-9" style={{ minHeight: '750px', height: '750px', overflowY: 'scroll' }}>
                        <div className="tab-content">
                            <Outlet></Outlet>
                        </div>
                    </div>
                </div>
            </div>
            <Panier />
        </section>
    );
}

export default UserDashboardLayout;