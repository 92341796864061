import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import PromocodeForm from './PromocodeForm';
import PromocodeList from './PromocodeList';

const PromocodeComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <PromocodeForm action="create"></PromocodeForm> ;break;
        case "edit": page = <PromocodeForm action="edit"></PromocodeForm> ; break;
        default: page = <PromocodeList></PromocodeList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default PromocodeComponent;