import { REFRESH_USER_WALLET, REFRESH_USER_WALLET_END, REFRESH_USER_WALLET_START } from "./actionTypes";

export const refreshUserWallet = ({ fc , miles, xp}) => {
    return {
      type: REFRESH_USER_WALLET,
      payload: {
        fc : fc,
        miles : miles,
        xp: xp
      }
    }
}

export const refreshUserWalletStart = () => {
    return {
      type: REFRESH_USER_WALLET_START
    }
}

export const refreshUserWalletEnd = () => {
    return {
      type: REFRESH_USER_WALLET_END
    }
}
   