import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Thumbnail from "../../extra/img/thumbnail";
import swal from 'sweetalert';
import PackBoosterCarreerLevelService from "../../../services/pack/packBoosterCarreerLevel";
import { getImageUrl } from "../../../Utils/Common";

const PackBoosterCarreerLevelList = () => {
    const [list, setList] = useState(null);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const deletePack = async (row) => {
        swal(`Are you sure to delete the pack : ${row.title}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await PackBoosterCarreerLevelService.deleteById(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }

                getList()
            }
        });
    }

    const columns = [
        {
            id: 1,
            name: "Media",
            selector: (row) => row.image,
            sortable: false,
            reorder: false,
            cell: (row) => (
                row.image &&
                <Thumbnail src={getImageUrl(row.image)}></Thumbnail>
            )
        },
        {
            id: 2,
            name: "Title",
            selector: (row) => row.title,
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            name: "Price",
            selector: (row) => row.price,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 5,
            name: "Pack",
            selector: (row) => row.pack.name,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 6,
            name: "Level",
            selector: (row) => row.carreerLevel.level,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 7,
            name: "VAT",
            selector: (row) => <>{row.tva} %</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 8,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/pack-booster-carreer-level/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 9,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deletePack(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const getList = async () => {
        await PackBoosterCarreerLevelService.getAll().then((res) => {
            if (!res.error) {
                setList(res.data)
            }
        });
    };

    useEffect(() => {
        getList();
    }, []);

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Pack</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Packs booster carreer level</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Packs booster carreer level</h5>                                    
                                    <div className="card-tools">
                                        <NavLink to="/admin/pack-booster-carreer-level/new" type="button" className="btn btn-tool bg-info ml-2" >
                                            New
                                        </NavLink>
                                    </div>                                    
                                    
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12">
                                                                        
                                        <div className="row">
                                            <div className="col-lg-12 mdg-historique-table">
                                                {list && (<DataTable
                                                    columns={columns}
                                                    data={list}
                                                    defaultSortFieldId={1}
                                                    backgroundColor="transparent"
                                                    color="white"
                                                    pagination
                                                    selectableRows
                                                    conditionalRowStyles={rowStyle}
                                                />)}
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default PackBoosterCarreerLevelList;
