import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WishListService from "../../../../services/item/wishList";
import Language from "../../../extra/language/language";
import WishlistLists from "./WishlistLists";

const Wishlist = ({ version }) => {
  const authObj = useSelector((state) => state.auth);
  const { user } = authObj;
  const [wishlist, setWishlist] = useState([]);
  const lang = useSelector((state) => state.language);

  const getAllItemList = async (searchText) => {
    await WishListService.getAllMyWishList(searchText).then((res) => {
      if (!res.error) {
        setWishlist(res.data);
      }
    });
  };

  const classWidth = version === "v2" ? "col-lg-4 mt-3" : "col-sm-3 mt-3"
  const classHidden = version === "v2" ? "mdg-top-0 mdg-bg-content-v2" : "mdg-top-container";
  const hidden = version === "v2" ? "mdg-d-none" : "";
  const classBg = version === "v2" ? "mdg-width-card-body" : "mdg-width-card-body";

  useEffect(() => {
    getAllItemList();
  }, []);

  return (
    <div className={`${classHidden} container`}>
      <div className={`${hidden} mdg-title-ingame row`}>
        <div className="col-sm">
          <span className="mdg-h2"><Language word={"Wishlist.All_Wishlist"}></Language></span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-12">
          <div className="tab-content">
            <div className="row justify-content-end">
              <div className="col-lg-4">
                <div className="mdg-search-place">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search "
                    style={{ color: "#fff" }}
                    className="mdg-search-input"
                    onChange={(e) => getAllItemList(e.target.value)}
                  />
                  <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div className="mdg-width-card-item mdg-mt-6">
              <div className="mdg-width-card-item p-3">
                <div className="row">
                  {" "}
                  {wishlist.map((elt, i) => (
                    <WishlistLists
                      item={elt}
                      key={i}
                      userId={user.id}
                      lang={lang}
                      whishlistClass={classWidth}
                    ></WishlistLists>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
