import React, { useEffect, useState } from "react";
import FeaturesService from "../../../../services/subscription/features";
import Language from "../../../extra/language/language";

const checkFeat = (id, feats) => {

  const find = feats.find((feat) => {

    return feat.feature && feat.feature.id === id;
  });

  return !!find;
}

const Feat = ({ feat, subscriptions, activeType }) => {

  const [show, setShow] = useState(false);


  return (
    <tr >
      <td className={feat.available ? "mdg-text-white mdg-name-feature mdg-cursor" : "mdg-text-white mdg-name-feature"} onClick={() => setShow(!show)}>
        {feat.available && show ? <i className="fa fa-angle-up" aria-hidden="true"></i> : feat.available ? <i className="fa fa-angle-down" aria-hidden="true"></i> : null}
        <span className=""> {feat.name} {feat.available && (<span className="text-muted">(coming soon)</span>)}</span>
        {show ?
          <div className={show ? "row justify-content-md-center mdg-animate-down visible-down" : "row justify-content-md-center mdg-animate-down visible-up"}>
            <div className="col-6">
              <span style={{ fontSize: "13px" }}>{feat.description} </span>
            </div>
          </div>
          : null
        }
      </td>
      {
        subscriptions.map((sub, i) => sub.type === activeType && (
          <td key={i} className={checkFeat(feat.id, sub.PackFeatures) ? "active" : "not-active"}>{checkFeat(feat.id, sub.PackFeatures) ? <i className="fa fa-check mdg-text-meta-color"></i> : <i className="fa fa-times mdg-text-white "></i>}</td>
        ))
      }

    </tr>
  )

}
const ListFeature = ({ subscriptions, activeType, activeSub }) => {
  const [features, setFeatures] = useState([]);


  const getFeatures = async () => {
    const res = await FeaturesService.getAll();
    if (!res.error) {
      setFeatures(res.data.data);
    }
  }



  const checkIsActive = (sub) => {

    return (activeSub && activeSub.subscriptionId == sub.id) || (!activeSub && sub.price == 0);
  }



  useEffect(() => {
    getFeatures();
  }, [])



  return (
    <div className="mt-4 mb-4">
      <div className="col-lg-12 mdg-list-feature">
        <table>
          <thead>
            <tr>
              <th className=""><Language word={"Subscription.FEATURES"}></Language></th>
              {
                subscriptions.map((sub, i) => sub.type === activeType && (

                  <th key={i} className={checkIsActive(sub) ? "" : ""}>{sub.name}</th>
                ))
              }
            </tr>
          </thead>
          <tbody>
            {
              features.map((feat, i) => (
                !feat.hide &&
                <Feat feat={feat} subscriptions={subscriptions} activeType={activeType} key={i}></Feat>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListFeature;
