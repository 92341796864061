import React, { useState } from 'react';
import { getFullDate, getImageUrl } from "../../../../../../Utils/Common";
import Language from '../../../../../extra/language/language';
import BidProposal2 from '../../porposal/bid2';
import ExchangeProposal2 from '../../porposal/exchange2';
const Deal = (props) => {
    const deal = props.deal;

    const [show, setShow] = useState(false);
    const [slideClass, setSlideClass] = useState("mdg-proposal-box")
    let rentCondition;
    let exchangeCondition;
    let rentDetail;

    if (deal.storeType === "RENT" && deal.RentConditions && deal.RentConditions.length > 0) {
        rentCondition = deal.RentConditions[0];
        if (deal.currentProprio && deal.rentItem.length > 0) {
            rentDetail = deal.rentItem[deal.rentItem.length - 1];
        }
    }

    if (deal.storeType === "EXCHANGE" && deal.ExchangeConditions && deal.ExchangeConditions.length > 0) {
        exchangeCondition = deal.ExchangeConditions[0];
    }

    console.log(deal);

    return (
        <>
            <div className="row justify-content-md-center mt-2">
                <div className="col-lg-12">
                    <div className="row mdg-bg-row p-2">
                        <div className="col-lg-1">
                            <div className="row align-items-center">
                                <span className="ml-2 mdg-count-badge"> {props.index} </span>
                            </div>
                        </div>
                        <div className="col-lg text-center mdg-border-md">
                            <h6><Language word={"Seller.Date"}></Language> </h6>
                            <span>{getFullDate(deal.createdAt)}</span>
                        </div>
                        {
                            (rentCondition) && (
                                <>
                                    <div className="col-lg text-center mdg-border-md">
                                        <h6><Language word={"Seller.Rent_Condition"}></Language></h6>
                                        <small className="mdg-text-white">
                                            {rentCondition.onDayPrice} FC <Language word={"Seller.per_day"}></Language>
                                        </small>
                                        <br />
                                        <small className="mdg-text-white">
                                            {rentCondition.minDays} <Language word={"Seller.days_minimum"}></Language>
                                        </small>
                                    </div>
                                    {
                                        (rentDetail) && (
                                            <>
                                                <div className="col-lg text-center mdg-border-md">
                                                    <h6><Language word={"Seller.Rented"}></Language> </h6>
                                                    <small className="mdg-text-white">
                                                        {rentDetail.fcAmount} FC
                                                    </small>
                                                    <br />
                                                    <small className="mdg-text-white">
                                                        <Language word={"Seller.For"}></Language>  {rentDetail.nbDays} <Language word={"Seller.days"}></Language>
                                                    </small>

                                                </div>

                                                <div className="col-lg text-center mdg-border-md">
                                                    <h6><Language word={"Seller.Expiry_date"}></Language></h6>

                                                    <small className="mdg-text-white">
                                                        {getFullDate(rentDetail.expirationDate)}
                                                    </small>

                                                </div>
                                                <div className="col-lg d-inline-flex mdg-border-md">
                                                    <div className=" mdg-text-right">
                                                        <img
                                                            src={ deal.currentProprio.photo ? getImageUrl(deal.currentProprio.photo) : "/assets/img/default-profile-picture.png"}
                                                            className="mdg-upload-img-detail"
                                                            alt="user"
                                                        ></img>
                                                    </div>
                                                    <div className=" mt-2 ml-2 text-center">
                                                        <h6></h6>
                                                        <span>{deal.currentProprio.userName}</span>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                        {
                            (exchangeCondition) && (
                                <div className="col-lg text-center mdg-border-md">
                                    <h6><Language word={"Seller.Exchange_Condition"}></Language></h6>
                                    {
                                        (exchangeCondition.minPrice > 0 && exchangeCondition.maxPrice > 0 && exchangeCondition.minPrice <= exchangeCondition.maxPrice) &&
                                        (
                                            <div>
                                                <small className="mdg-text-white">
                                                    <Language word={"Seller.In_range_of"}></Language> {exchangeCondition.minPrice} FC <Language word={"Seller.and"}></Language> {exchangeCondition.maxPrice} FC
                                                </small>
                                            </div>
                                        )
                                    }
                                    {
                                        (exchangeCondition.category) &&
                                        (
                                            <div>
                                                <small className="mdg-text-white">
                                                    <Language word={"Seller.Belongs_to"}></Language> {exchangeCondition.category.categoryName}
                                                </small>
                                            </div>
                                        )
                                    }
                                    <br />
                                    <small className="mdg-text-white">

                                    </small>
                                </div>
                            )
                        }
                        {
                            (deal.storeType === "SELL" || deal.storeType === "BID") &&
                            (
                                <div className="col-lg text-center mdg-border-md">
                                    <h6>{deal.storeType === "BID" ? <Language word={"Seller.The_auction_starts_at"}></Language> : <Language word={"Seller.Price"}></Language>} </h6>
                                    <small className="mdg-text-white">
                                        {deal.price} FC
                                    </small>
                                </div>
                            )
                        }

                        {
                            !(rentDetail || props.type == "FINISHED") && (
                                <div className="col-lg text-center  mdg-align-center p-2">
                                    <button className="btn btn-danger btn-sm mr-2" onClick={(e) => props.deleteMyStore(deal.id)}>
                                        <i className="fa fa-trash"></i>
                                    </button>
                                    {
                                        (deal.storeType === "BID" || deal.storeType === "EXCHANGE") &&
                                        (
                                            <>

                                                {/* <button className="btn btn-danger mdg-btn-common btn-sm mdg-btn-12" onClick={(e)=> getProposal()} > */}
                                                <button className="btn mdg-btn-common btn-sm mdg-btn-12" onClick={() => { show ? setSlideClass('mdg-proposal-box slide-up') : setSlideClass('mdg-proposal-box slide-down'); setShow(!show); }} >
                                                    {show ? <Language word={"Seller.Hide_proposals"}></Language> : <Language word={"Seller.View_proposals"}></Language>}
                                                </button>

                                            </>
                                        )
                                    }
                                </div>
                            )
                        }


                        {
                            props.type === "FINISHED" && (
                                <div className="col-lg text-center mdg-border-md">
                                    <h6><Language word={"Seller.Finished_on"}></Language> </h6>
                                    <div>
                                        <span>{getFullDate(deal.updatedAt)}</span>
                                    </div>
                                    {
                                        deal.currentProprio && (
                                            <div >
                                                <Language word={"Seller.By"}></Language> : <span>{deal.currentProprio.userName}</span>
                                            </div>
                                        )
                                    }

                                </div>
                            )
                        }

                        {
                            (deal.storeType === "BID" || deal.storeType === "EXCHANGE") &&
                            (
                                <>
                                    <div className="col-lg-12 overflow-hidden" >
                                        {
                                            slideClass != "mdg-proposal-box" && (
                                                <div className={slideClass}>
                                                    {
                                                        deal.storeType === "BID" ? (
                                                            <><BidProposal2 itemId={deal.id}></BidProposal2></>
                                                        ) : (
                                                            <><ExchangeProposal2 itemId={deal.id} ></ExchangeProposal2></>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>


            </div>
        </>
    )
}

export default Deal;