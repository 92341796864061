import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import ItemForm from './ItemForm';
import ItemList from './ItemList';

const ItemComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <ItemForm action="create"></ItemForm> ;break;
        case "edit": page = <ItemForm action="edit"></ItemForm> ; break;
        default: page = <ItemList></ItemList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default ItemComponent;