import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import FlightIncidentForm from './FlightIncidentForm';
import FlightIncidentList from './FlightIncidentList';

const FlightIncidentComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <FlightIncidentForm action="create"></FlightIncidentForm> ;break;
        case "edit": page = <FlightIncidentForm action="edit"></FlightIncidentForm> ; break;
        default: page = <FlightIncidentList></FlightIncidentList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default FlightIncidentComponent;