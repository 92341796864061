import { useState } from "react"

const RentForm = (props) => {
    const [price , setPrice] = useState(props.state.pricePerDay);
    const [days , setDays] = useState(props.state.minDays);
    return (
        <div className="mdg-row">
            <div className="col-lg-12">
                <div className="mdg-textfield-out-lined">
                    <input id="id-price" type="number" min="1" className="mdg-text-white" name="price" placeholder=" " value={price} onChange={(e) => {setPrice(e.target.value); props.setPricePerDay(e.target.value)} } />
                    <label htmlFor="id-price">Rent amount per days</label>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="mdg-textfield-out-lined">
                    <input id="id-min-days" type="number" min="1" className="mdg-text-white" name="minDays" placeholder=" " value={days} onChange={(e) => {setDays(e.target.value); props.setMinDays(e.target.value)} } />
                    <label htmlFor="id-min-days">Minimum number of days</label>
                </div>
            </div>
        </div>
    )
}

export default RentForm;