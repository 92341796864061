import React from "react";
import { NavLink } from "react-router-dom";
import { getImageUrl } from "../../../../../Utils/Common";
import Thumbnail from "../../../../extra/img/thumbnail";
import Language from "../../../../extra/language/language";
import ItemBenefits from "../../ingame/Details/benefits";

class AccountItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forSale: 0,
      rentedOut: 0,
      exchanged: 0,
    };
  }

  componentDidMount() {
    this.countChild();
  }

  countChild() {
    const childs = this.props.item.item.childs;
    let forsale, forExchange, forRent;
    forsale = forExchange = forRent = 0;

    if (childs) {
      childs.forEach((child) => {
        switch (child.storeType) {
          case "SELL":
          case "BID":
            forsale++;
            break;
          case "EXCHANGE":
            forExchange++;
            break;
          case "RENT":
            forRent++;
            break;
          default:
            break;
        }
      });
    }

    this.setState({ forSale: forsale });
    this.setState({ exchanged: forExchange });
    this.setState({ rentedOut: forRent });
  }

  render() {
    const allData = this.props.item;
    const item = allData.item;

    const media = item.media;
    const name = item.name;
    const description = item.description;
    const stock = allData.quantity;

    /* item type */
    const itemTypeName = item.type ? item.type.name : null;
    const itemTypeTextColor = item.type
      ? item.type.textColor
        ? item.type.textColor
        : null
      : null;
    const itemTypeColor = item.type
      ? item.type.color
        ? item.type.color
        : null
      : null;
    /* item type */

    return (
      <>
        <div className="row justify-content-between mdg-bg-row-3 mt-4">
          <div className="col-lg-1 mt-2">
            <span
              className="mdg-bg-common"
              style={{
                color: itemTypeTextColor ? itemTypeTextColor : "",
                backgroundColor: itemTypeColor ? itemTypeColor : "",
              }}
            >
              {itemTypeName ? itemTypeName : <Language word={"Seller.COMMON"}></Language>}
            </span>
          </div>
          <div className="col-lg-4">
            <div className="p-2">
              <Thumbnail
                src={getImageUrl(media)}
                alt="logo"
                className="mdg-image-body"
              ></Thumbnail>
            </div>
          </div>
          <div className="col-lg-7 mdg-relative">
            <div className="pt-3">
              <div className="row justify-content-between">
                <div className="col-6">
                  <div className="row justify-content-between">
                    <div className="col-6">
                      <div className="col-lg-12">
                        <span className="ml-2 badge">{stock}</span>
                        <small> <Language word={"Seller.Available"}></Language></small>
                      </div>
                      <div className="col-lg-12">
                        <NavLink
                          state={{
                            item: item,
                          }}
                          to={`${item.id}/details/sell`}
                          style={{ color: "inherit" }}
                          className="mdg-view-detail-sell"
                        >
                          <span className="ml-2 badge">
                            {this.state.forSale}
                          </span>
                          <small> <Language word={"Seller.For_Sale"}></Language></small>
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row">
                        <div className="col-lg-12">
                          <NavLink
                            state={{
                              item: item,
                            }}
                            to={`${item.id}/details/rent`}
                            style={{ color: "inherit" }}
                            className="mdg-view-detail-sell"
                          >
                            <span className="ml-2 badge">
                              {this.state.rentedOut}
                            </span>
                            <small> <Language word={"Seller.Rented_out"}></Language></small>
                          </NavLink>
                        </div>
                        <div className="col-lg-12">
                          <NavLink
                            state={{
                              item: item,
                            }}
                            to={`${item.id}/details/exchange`}
                            style={{ color: "inherit" }}
                            className="mdg-view-detail-sell"
                          >
                            <span className="ml-2 badge">
                              {this.state.exchanged}
                            </span>
                            <small> <Language word={"Seller.Exchanged"}></Language></small>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 mt-4">
                      <span className="mdg-bg-text-items mdg-text-marque">
                        {name}
                      </span>
                      <br />
                      <span className="mdg-bg-text-items">
                        <small>{description}</small>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <h6><Language word={"Seller.Benefits"}></Language></h6>
                  <ItemBenefits item={item} />
                </div>
              </div>
              <div></div>
              <p className="lead mdg-text-white"></p>
              <div className="row mdg-footer-btn">
                {/* <div className="col-sm">
                  <NavLink
                    state={{
                      item: item,
                    }}
                    to={`/account/items/${item.id}/marketplace/sell`}
                    className="btn mdg-btn-common"
                  >
                    <span> Sell</span>
                  </NavLink>
                </div>
                <div className="col-sm">
                  <NavLink
                    state={{
                      item: item,
                    }}
                    to={`/account/items/${item.id}/marketplace/rent`}
                    className="btn mdg-btn-common"
                  >
                    <span> Rent</span>
                  </NavLink>
                </div>
                <div className="col-sm">
                  <NavLink
                    state={{
                      item: item,
                    }}
                    to={`/account/items/${item.id}/marketplace/exchange`}
                    className="btn mdg-btn-common"
                  >
                    <span> Exchange</span>
                  </NavLink>
                </div> */}
                
                <div className="col-sm">
                  <NavLink
                    state={{
                      item: item,
                    }}
                    to={`/account/items/${item.id}/details/sell`}
                    className="btn mdg-btn-common"
                  >
                    <span> <Language word={"Seller.Manage"}></Language> </span>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AccountItem;
