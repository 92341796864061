import axios from "axios";

export const getAppInfo = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const AppInfoService = {
  getAppInfo,
}

export default AppInfoService;