import React from "react";
import DatePicker from "react-date-picker";

class DatePickerDarkInput extends React.Component {
  

  constructor (props){
    super(props)
    this.state = {
      value: this.props.value
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange (value ) {
    if(!value){
      this.setState({ value: ""});
      this.props.onChange(value)
    }
    else{
      this.setState({ value });
      this.props.onChange(value)
    }
   
  } 

  render() {
    const { value } = this.state;
    return (
      <div>                
        <DatePicker
          onChange={this.onChange}
          value={value}
          returnValue="start"
          className="mdg-date-picker-dark"
          minDate={this.props.min}
          maxDate={this.props.max}
        />
      </div>
    );
  }
}

export default DatePickerDarkInput;