import React, { Component } from "react";

export default class FooterBO extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <div>
        <footer className="main-footer" style={{ zIndex: "1" }}>
          <strong>
            Copyright © 2022 <a href="/">Metaflight</a>.
          </strong>
          All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>API Version </b> {this.props.apiVersion}
            <b> | Web Version </b> {this.props.webVersion}
          </div>
        </footer>
      </div>
    );
  }
}
