import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StoreCategoryDropDownList from "./storeCategoryDropDownList";
import Language from "../../../extra/language/language";
import StoreRange from "./storeRange";
import InfiniteScroll from "react-infinite-scroll-component";
import StoreLoader from "./loader/storeLoader";
import StoreList from "./storeList";
import StoreProductService from "../../../../services/store/product";

const Store = () => {
    const dispatch = useDispatch();
    const authObj = useSelector((store) => store.auth);
    const { user } = authObj;
    //minFcPrice,maxFcPrice,minEuroPrice, maxEuroPrice

    const [incrementation, setIncrementation] = useState(0);
    const { id, type } = useParams();
    const [productList, setProductList] = useState([]);
    const [search, setSearch] = useState("");

    const [minFcPrice, setMinFcPrice] = useState(0);
    const [maxFcPrice, setMaxFcPrice] = useState(9999999999999999n);
    const [minEuroPrice, setMinEuroPrice] = useState(0);
    const [maxEuroPrice, setMaxEuroPrice] = useState(9999999999999999n);

    const perPage = 12;
    const [category, setCategory] = useState(id === "all" ? undefined : id);
    const [currentPage, setCurrentPage] = useState(1);

    const [hasMore, sethasMore] = useState(true);

    const [range, setRange] = useState(null);
    const [sortBy, setSortBy] = useState("wishlists");

    const lang = useSelector((state) => state.language);

    const getStoreRangeMinMax = async () => {
        const response = await StoreProductService.getStoreRangeMinMax();
        setRange(response.data[0]);
    };

    const fetchSearchAllItem = async (event) => {
        event.preventDefault();
        setCurrentPage(1);
        const filters = {
            category: category,
            search: search,
            page: 0,
            size: perPage,
            minFcPrice: minFcPrice,
            maxFcPrice: maxFcPrice,
            minEuroPrice: minEuroPrice,
            maxEuroPrice: maxEuroPrice,
            sortBy: sortBy,
        };

        const res = await StoreProductService.getPaginatedStoreProduct(filters);
        const data = res.data.items;
        if (currentPage === res.data.totalPages || res.data.totalPages == 0) {
            sethasMore(false);
        } else {
            sethasMore(true);
        }

        setProductList(data);
    };

    const fetchAllItem = async () => {
        const filters = {
            category: category,
            search: search,
            page: currentPage - 1,
            size: perPage,
            minFcPrice: minFcPrice,
            maxFcPrice: maxFcPrice,
            minEuroPrice: minEuroPrice,
            maxEuroPrice: maxEuroPrice,
            // sortBy: sortBy,
            // lastCountry: sortBy === 'myLocation' || type === "myLocation" ? user.lastCountry : undefined
        };
        const res = await StoreProductService.getPaginatedStoreProduct(filters);
        let data = res.data.items;
        if (currentPage === res.data.totalPages || res.data.totalPages === 0) {
            sethasMore(false);
        } else {
            sethasMore(true);
        }

        return data;
    };

    const nextPage = () => {
        setCurrentPage((currentPage) => currentPage + 1);
        setIncrementation((incrementation) => incrementation + 1);
    };

    const fetchData = async () => {
        const data = await fetchAllItem();
        if (data) {
            setProductList([...productList, ...data]);
        }
    };

    useEffect(() => {
        if (incrementation > 0) {
            fetchData();
        } else {
            getStoreRangeMinMax();
        }
    }, [incrementation]);

    useEffect(() => {
        setProductList([]);
        setCurrentPage(1);
        setIncrementation((incrementation) => incrementation + 1);
    }, [
        type,
        search,
        category,
        sortBy,
        minFcPrice,
        maxFcPrice,
        minEuroPrice,
        maxEuroPrice,
    ]);

    return (
        <div className="container mdg-top-container">
            <div className="mdg-title-ingame row">
                <div className="col-sm">
                    <span className="mdg-h2">MF Store</span>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3">
                    <StoreCategoryDropDownList
                        setCategory={setCategory}
                        currentCategory={category}
                        page={type === undefined ? "all" : type}
                    />
                </div>
                <div className="col-lg-9">
                    <div className="tab-content" id="v-pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="lands"
                            role="tabpanel"
                            aria-labelledby="lands-tab"
                        >
                            <div className="mdg-row">
                                <div className="col md-sm-4">
                                    <div className="row justify-content-center">
                                        <div className="col-3 text-center">
                                            <span className="mdg-text-white">FC</span>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-2">
                                        <div className="col-12">
                                            {range && (
                                                <StoreRange
                                                    min={Math.floor(range.minFcPrice)}
                                                    max={Math.ceil(range.maxFcPrice)}
                                                    step={100000}
                                                    onChange={({ min, max }) => {
                                                        setMinFcPrice(min);
                                                        setMaxFcPrice(max);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col md-sm-6">
                                    <div className="row justify-content-center">
                                        <div className="col-10 text-center">
                                            <span className="mdg-text-white">Euro</span>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-2">
                                        <div className="col-12">
                                            {range && (
                                                <StoreRange
                                                    min={Math.floor(range.minEuroPrice)}
                                                    max={Math.ceil(range.maxEuroPrice)}
                                                    step={10}
                                                    onChange={({ min, max }) => {
                                                        setMinEuroPrice(min);
                                                        setMaxEuroPrice(max);
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1.5 md-sm-6 mt-2">
                                    <button
                                        className="btn btn-info mdg-btn-12 col-lg-12 md-sm-6"
                                        onClick={(event) => {
                                            fetchSearchAllItem(event);
                                        }}
                                    >
                                        <Language word={"Ingame_store.Apply"}></Language>
                                    </button>
                                </div>
                                <div className="col md-sm-6 mt-2">
                                    <div className="mdg-search-place">
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            placeholder="Search "
                                            style={{ color: "#fff" }}
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            className="mdg-search-input"
                                        />
                                        <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="mdg-width-card-item mt-4">
                                <div
                                    className="tab-pane fade show active"
                                    id="lands"
                                    role="tabpanel"
                                    aria-labelledby="lands-tab"
                                >
                                    <div className="tab-content" id="pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="all-flight"
                                            role="tabpanel"
                                            aria-labelledby="all-flight-tab"
                                        >
                                            <InfiniteScroll
                                                dataLength={productList.length} //This is important field to render the next data
                                                next={nextPage}
                                                hasMore={hasMore}
                                                loader={
                                                    <div className="row">
                                                        <StoreLoader />
                                                        <StoreLoader />
                                                        <StoreLoader />
                                                    </div>
                                                }
                                                endMessage={
                                                    <p>
                                                        {" "}
                                                        {productList.length == 0
                                                            ? <Language word={"Item_List.No_product_found"}></Language>
                                                            : <Language word={"Item_List.No_more_product"}></Language>}{" "}
                                                    </p>
                                                }
                                            >
                                                <div className="mdg-width-card-item p-3">
                                                    <div className="row">
                                                        {productList &&
                                                            productList.map((elt, i) => (
                                                                <StoreList
                                                                    product={elt}
                                                                    key={i}
                                                                    type={type}
                                                                    dispatch={dispatch}
                                                                    user={user}
                                                                    lang={lang}
                                                                    buyProduct={lang}
                                                                ></StoreList>
                                                            ))}
                                                    </div>
                                                </div>
                                            </InfiniteScroll>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Store;
