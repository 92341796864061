import React, { useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements, } from '@stripe/react-stripe-js';
import PlaceOrder from "../../placeOrder";
import CheckoutService from "../../../../../../services/checkout";
import { refreshWalletAsync } from "../../../../../../asyncActions/walletAsyncActions";
import Swal from "sweetalert2";
import translate from "../../../../../extra/language/translate";
import MetaCart from "../../../../../../actions/cartActions";
import { verifyTokenAsync } from "../../../../../../asyncActions/authAsyncActions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ? process.env.REACT_APP_STRIPE_PUBLIC_KEY : 'pk_test_HORCTPcuoNEPkD4KnGOcgE2u');

const StripeCardPayment = () => {
    const cart = useSelector((store) => store.cart);
    const lang = useSelector((store) => store.lang);
    
    const [inProcess, setInProcess] = useState(false);
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();


    const createOrder = async () => {
        setInProcess(true);
        // setStripeError(null);
        if (elements == null) {
            setInProcess(false);
            return;
        }

        if (cart.numberCart >= 1) {
            let order = {
                coupons: cart.coupons,
                payment: {
                    method: "stripe"
                }
            };

            let res;
            if (cart.type === 'subscription') {
                order.subscription = cart.Carts[0];
                order.type = "subscription";
                res = await CheckoutService.subscribeUser(order);
                setInProcess(false);
                if(res.error){
                    Swal.fire({
                        text: res.response.data.message,
                        icon:"error"
                    })
                }else{
                    if(res.data.error){
                        Swal.fire({
                            text: res.data.message,
                            icon:"error"
                        })
                    }else{
                        const redirection = stripe.redirectToCheckout({
                            sessionId: res.data.data.sessionId
                        })
                        
                        return redirection;
                    }
                }
            } else {
                order.items = cart.Carts;
                order.type = cart.type;
                res = await CheckoutService.orderPack(order);
                setInProcess(false);
                
                if(res.error){
                    Swal.fire({
                        text: res.response.data.message,
                        icon:"error"
                    })
                }else{
                    if(res.data.error){
                        Swal.fire({
                            text: res.data.message,
                            icon:"error"
                        })
                    }else{
                        const redirection = stripe.redirectToCheckout({
                            sessionId: res.data.data.session_id
                        })
                        
                        return redirection;
                    }
                }
            }
        }
        else {
            setInProcess(false);
        }
    };

    return (
        <>
            <div className="col-lg-12">
              
                <PlaceOrder cart={cart} makeOrder={createOrder} inProcess={inProcess} ></PlaceOrder>
            </div>
        </>
    )
}

const StripePayment = ({ visibility, setIsVisilble }) => (
    <Elements stripe={stripePromise}>
        <StripeCardPayment></StripeCardPayment>
    </Elements>
);

export default StripePayment;


