import React, { useState, useEffect } from "react";
import ItemService from "../../../../../services/Item";
import AccountItem from "../../items/Components/AccountItem";
import { useSelector } from "react-redux";
import Language from "../../../../extra/language/language";

const MyItemsPage = (props) => {
  const [items, setItems] = useState([]);
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const getItems = async () => {
    const result = await ItemService.getMyItems(props.item);

    if (!result.error) {
      setItems(result.data);
    }
  };

  useEffect(() => {
    getItems();
  }, [props.item]);

  return (
    <>
      <div className="row justify-content-end">
        <div className="col-lg-4">
          <div className="mdg-search-place">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search "
              style={{ color: "#fff" }}
              className="mdg-search-input"
            />
            <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div className="mdg-width-card-item mdg-mt-6">
        {items.length > 0 ? (
          <div
            className="tab-pane fade show active"
            id="lands"
            role="tabpanel"
            aria-labelledby="lands-tab"
          >
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="all-flight"
                role="tabpanel"
                aria-labelledby="all-flight-tab"
              >
                <div className="mdg-width-card-item p-3">
                  {items &&
                    items.map((item, i) => (
                      <AccountItem
                        item={item}
                        key={i}
                        userId={user.id}
                      ></AccountItem>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p><Language word={"Item_List.No_item_found"}></Language></p>
        )}
      </div>
    </>
  );
};

export default MyItemsPage;
