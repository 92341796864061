import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import BotRepatriation from './BotRepatriation';

const BotRepatriationPages = () => {
    return (
        <div>            
            <BaseBO page={<BotRepatriation />}></BaseBO>
        </div>
    );
};

export default BotRepatriationPages;