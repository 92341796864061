import React, { useState } from "react";
import FlightTable from "./FlightTable";
import FlightTreatedTable from "./FlightTreatedTable";
import LocationTable from "./LocationTable";

const FlightList = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">List of Flight</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Flight</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Flight</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 mdg-historique-table">
                      <FlightTable refresh={isLoading}></FlightTable>
                    </div>
                  </div>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Flight Treated</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 mdg-historique-table">
                      <FlightTreatedTable
                        refresh={isLoading}
                      ></FlightTreatedTable>
                    </div>
                  </div>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FlightList;
