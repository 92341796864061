import React, { useEffect } from "react";
import {BrowserRouter, Route, useLocation} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import AnimatedRoutes from "./AnimatedRoutes";

function App() {

    return (
        <>
            <BrowserRouter>
                <AnimatedRoutes />
            </BrowserRouter>
        </>
    );
}

export default App;
