import React from "react";
import Thumbnail from "../../../extra/img/thumbnail";
import { orderItemService } from "../../../../services/Item";
import Swal from "sweetalert2";
import WishListService from "../../../../services/item/wishList";
import Language from "../../../extra/language/language";
import { useSelector } from "react-redux";
import translate from "../../../extra/language/translate";
import { getImageUrl } from "../../../../Utils/Common";

class WishlistLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = { wishlist: true }
    this.handleBuyItem = this.handleBuyItem.bind(this);
    this.handleToggleWishList = this.handleToggleWishList.bind(this);
  }

  handleBuyItem = async (event) => {
    event.preventDefault();
    const itemId = this.props.item.id;
    const name = this.props.item.name;
    const lang = this.props.lang;

    Swal.fire({
      title: translate('Item_List.Do_you_want_to_buy_this',lang)+` ${name}`,
      input: "number",
      icon: "info",
      inputPlaceholder: translate('Item_List.Enter_quantity_to_buy_for_this_item',lang),
      showCancelButton: true,
      inputAttributes: {
        maxLength: 2,
      },
      inputValidator: (value) => {
        if (Number(value) <= 0) {
          return translate('Item_List.Please_enter_a_valid_quantity_to_buy',lang);
        }
      },
    }).then(async (res) => {
      if (res.isConfirmed) {
        const orderResult = await orderItemService(itemId, res.value);

        if (orderResult.error) {
          Swal.fire({
            title: orderResult.response.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: orderResult.data.message,
            icon: orderResult.data.error ? "warning" : "success",
            confirmButtonText: "Ok",
          });
        }
      }
    });
  };

  componentDidMount() {

  }


  handleToggleWishList = async () => {
    const lang = this.props.lang;
    Swal.fire({
      title: translate('Seller.Confirmation',lang),
      text: translate('Seller.Are_you_sure_to_unwishlist_this_item',lang),
      confirmButtonText: translate('Seller.Yes_i_m_sure',lang),
      cancelButtonText: translate('Seller.Cancel',lang),
      showCancelButton: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await WishListService.toggleWishList(this.props.item.id);
        if (!result.error) {
          const isWishListed = result.data.data == "wishlisted" ? true : false
          this.setState({ wishlist: isWishListed })
        }
      }
    })
  }

  render() {
    const media = this.props.item.media;
    const name = this.props.item.name;
    const price = this.props.item.price;
    const symbol = this.props.item.currency.symbol;
    const description = this.props.item.description;
    const photo = this.props.item.photo;
    const id_user = this.props.item.id_user;
    const firstName = this.props.item.firstName;
    const lastName = this.props.item.lastName;
    /* item type */
    const itemTypeName = this.props.item.type ? this.props.item.type.name : null;
    const itemTypeTextColor = this.props.item.type ? this.props.item.type.textColor ? this.props.item.type.textColor : null : null;
    const itemTypeColor = this.props.item.type ? this.props.item.type.color ? this.props.item.type.color : null : null;
    /* item type */
    return (

      <>
        {
          this.state.wishlist && 
          <div className={`${this.props.whishlistClass}  mt-3`}>
            <div className="mdg-width-card-body">
              <div className="mdg-card-header-items">
                <i
                  className={this.state.wishlist ? "fa fa-heart mdg-icon-heart " : " mdg-icon-heart fa fa-heart-o"}
                  aria-hidden="true"
                  onClick={this.handleToggleWishList}
                ></i>
              </div>
              <div className="mdg-card-body-items">
                <div>
                  <Thumbnail
                    src={getImageUrl(media)}
                    alt="logo"
                    className="mdg-image-body"
                  ></Thumbnail>
                  <div className="mdg-body-description mt-2 pl-2" style={{ position: "relative" }}>
                    <p>
                      <span className="mdg-bg-common" style={{ color: itemTypeTextColor ? itemTypeTextColor : '', backgroundColor: itemTypeColor ? itemTypeColor : '' }}>{itemTypeName ? itemTypeName : <Language word={"Wishlist.COMMON"}></Language>}</span>
                    </p>
                  </div>
                  <div className="mdg-body-footer-description mt-3 pl-2">
                    <div>
                      <span>
                        <img
                          src={"/assets/image/metaflight-icon.png"}
                          alt="logo"
                          className="mdg-img-description mr-1"
                        />
                      </span>

                      <span className="mdg-bg-text-items">
                        {id_user ? firstName + " " + lastName : "Metaflight"}
                      </span>
                      <br />
                      <span className="mdg-bg-text-items mdg-text-marque">
                        {name}
                      </span>
                      <br />
                      <span className="mdg-bg-text-items">
                        <small>{description}</small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer mdg-card-footer-items">
                <span className="mdg-bg-text-items">
                  <Thumbnail
                    src={photo ? photo : "/assets/image/coin.png"}
                    width="30"
                    height="30"
                    className="rounded-circle-sm mr-1"
                  ></Thumbnail>
                  {price + " " + symbol}
                </span>
                <button
                  className="btn mdg-btn-common btn-sm float-right"
                  onClick={this.handleBuyItem}
                >
                  <span><Language word={"Wishlist.Buy_now"}></Language></span>
                </button>
              </div>
            </div>
          </div>
        }

      </>
    );
  }
}

export default WishlistLists;
