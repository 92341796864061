import React, { useEffect, useState } from 'react';
import { getImageUrl } from '../../../Utils/Common';
import Thumbnail from '../../extra/img/thumbnail';

const WishListTable = (props) => {
    const wish = props.wish;

    useEffect(() => {

    }, []);
    return (
        <tr>
            <td><Thumbnail src={getImageUrl(wish.media) }></Thumbnail></td>
            <td>{wish.name}</td>
            <td>{wish.description}</td>
            <td>{wish.nbr}</td>
        </tr>
    );
};

export default WishListTable;