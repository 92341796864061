import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ItemService from "../../../../services/Item";
import Language from "../../../extra/language/language";
import ItemList from "./ItemList";

const Items = (props) => {
  const [itemList, setItemList] = useState([]);
  const className = props.version === "v2" ? "col-lg-4" : "col-lg-3";
  const hidden = props.version === "v2" ? "mdg-d-none" : "";
  const classHidden = props.version === "v2" ? "mdg-top-0 mdg-bg-content-v2" : "mdg-top-container";
  const classBg = props.version === "v2" ? "mdg-width-card-body" : "mdg-width-card-body";

  const lang = useSelector((state) => state.language);

  const getAllItemList = async (searchText) => {
    const filter = {
      search: searchText
    }
    await ItemService.getMyItems(filter).then((res) => {
      setItemList(res.data);
    });
  };

  useEffect(() => {
    getAllItemList();

  }, []);

  return (
    <div className={`${classHidden} container`}>
      <div className="mdg-title-ingame row">
        <div className="col-sm">
          <span className={`${hidden} mdg-h2`}><Language word={"My_items.My_Items"}></Language></span>
        </div>
        <div className="col-sm">
          <NavLink type="submit" to={"/account/items"} className="btn btn-info float-right">
            <Language word={"My_items.Seller_mode"}></Language>
          </NavLink>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div className="row justify-content-end">
              <div className="col-lg-4">
                <div className="mdg-search-place">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Search "
                    style={{ color: "#fff" }}
                    className="mdg-search-input"
                    onChange={(e) => getAllItemList(e.target.value)}
                  />
                  <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div className="mdg-width-card-item mdg-mt-6">
              <div className="mdg-width-card-item p-3">
                <div className="row">
                  {" "}
                  {itemList && itemList.map((elt, i) => (
                    <ItemList
                      item={elt}
                      key={i}
                      lang={lang}
                      itemClass={className}
                      itemBg={classBg}
                    ></ItemList>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Items;
