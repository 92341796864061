import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import PackService from "../../../services/pack";
import { getFormatedDate } from "../../../Utils/Common";

const PackUpToSellForm = (props) => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fcAmount, setFcAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");

  const handleCreatePack = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    //formData.append("photo", null);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("fcAmount", fcAmount);
    formData.append("price", price);
    formData.append("tva", 0);
    formData.append("isHT", false);
    formData.append("isUpsell", true);
    // formData.append('expiryDate', expiryDate);

    let result;
    if (id) {
      result = await PackService.update(id, formData);
    } else {
      result = await PackService.create(formData);
    }

    if (result.error) {
      swal(result.response.data.data.message, {
        icon: "error",
      });
    } else {
      swal(result.data.message, {
        icon: "success",
      });
    }
  };

  const getPack = async () => {
    if (id) {
      const result = await PackService.getById(id);
      if (!result.error) {
        const data = result.data;
        setDescription(data.description);
        setName(data.name);
        setFcAmount(data.fcAmount);
        setPrice(data.price);
        if (data.expiryDate) {
          setExpiryDate(getFormatedDate(data.expiryDate));
        }
      }
    }
  };

  useEffect(() => {
    /** get pack if it's an update request */
    getPack();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">
                {id ? "Edit Up sell" : "Create new Up sell"}
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Up sell</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {id ? "Edit Up sell Information" : "New Up sell"}
                  </h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/up-to-sell/"
                      type="button"
                      className="btn btn-tool bg-info"
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => handleCreatePack(e)}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="mdg-col-sm-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-parent-id"
                                    type="text"
                                    placeholder=" "
                                    value={name}
                                    onChange={(event) => {
                                      setName(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-category-parent-id">
                                    Up sell Name *
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-name"
                                    type="text"
                                    placeholder=" "
                                    style={{ color: "#fff" }}
                                    value={description}
                                    onChange={(event) => {
                                      setDescription(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-name">
                                    Up sell Description *
                                  </label>
                                </div>

                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-name"
                                    type="number"
                                    style={{ color: "#fff" }}
                                    placeholder=" "
                                    value={price}
                                    step="0.01"
                                    onChange={(event) => {
                                      setPrice(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-name">
                                    Price *
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-name"
                                    type="number"
                                    style={{ color: "#fff" }}
                                    placeholder=" "
                                    value={fcAmount}
                                    step="0.01"
                                    onChange={(event) => {
                                      setFcAmount(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-name">
                                    Nb Flight coin *
                                  </label>
                                </div>
                                {/* <div className="mdg-textfield-out-lined">
                                                                    <input
                                                                        id="input-category-name"
                                                                        type="date"
                                                                        placeholder=" "
                                                                        style={{ color: "#fff" }}
                                                                        value={expiryDate}
                                                                        onChange={(event) => {
                                                                            setExpiryDate(event.target.value);
                                                                        }}
                                                                        min={getFormatedDate(new Date())}
                                                                    />
                                                                    <label htmlFor="input-category-name">
                                                                        Expiry date *
                                                                    </label>
                                                                </div> */}
                              </div>
                              <div className="col-lg-12 text-right mt-2">
                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="offset-lg-11 mt-1"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PackUpToSellForm;
