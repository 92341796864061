import React, { useEffect, useState } from "react";
import RoleService from "../../../services/role";
import {useParams} from "react-router-dom";

import { NavLink } from "react-router-dom";
import swal from "sweetalert";

const RoleForm = (props) => {
    const { id } = useParams();
    const [roleName, setRoleName] = useState("");
    const [isClient, setIsClient] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const actionName = props.action;

    const createRoleHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('roleName', roleName);
        formData.append('isClient', isClient);
        formData.append('isActive', isActive);

        let result;
        if (actionName === "Edit") {
            result = await RoleService.updateRoleService(id, formData);
        } else {
            result = await RoleService.createRoleService(formData);
        }
        if (result.error) {
            swal(result.response.data.message, {
                icon: "error"
            })
        } else {
            swal(result.data.message, {
                icon: "success"
            })
        }
    };

    const getUser = async () => {
        if (id) {
            const result = await RoleService.getRoleById(id);
            if(!result.error){
                const data = result.data.role;
                setRoleName(data.displayName);
                setIsActive(data.isActive);
                setIsClient(data.isClient);
            }
        }
    };

    useEffect(() => {
        /** get role if it's an update request */
        getUser();
    }, []);


    return (
        <div >
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Role</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Role</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">New Role</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/role/" type="button" className="btn btn-tool bg-info" data-card-widget="collapse">
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <form onSubmit={(e) => createRoleHandler(e)}>
                                                    <div className="row">
                                                        <div className="mdg-col-sm-4">
                                                            <div className="">
                                                                <label htmlFor="input-category-parent-id" className="mdg-normal-label">
                                                                    Role Name
                                                                </label>
                                                                <input
                                                                    id="input-category-parent-id"
                                                                    className="form-control"
                                                                    type="text"
                                                                    style={{ color: "#fff" }}
                                                                    value={roleName}
                                                                    onChange={(event) => {
                                                                        setRoleName(event.target.value);
                                                                    }}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mdg-col-sm-2">
                                                            <div className="mdg-textfield-out-lined">
                                                            <label className="mdg-normal-label">is client</label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isClient}
                                                                    onChange={(event) => {
                                                                        setIsClient(event.target.checked);
                                                                    }}
                                                                />{" "}
                                                            </div>
                                                        </div>
                                                        <div className="mdg-col-sm-2">
                                                            <div className="mdg-textfield-out-lined">
                                                            <label className="mdg-normal-label">is active</label>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isActive}
                                                                    onChange={(event) => {
                                                                        setIsActive(event.target.checked);
                                                                    }}
                                                                />{" "}
                                                                
                                                            </div>
                                                        </div>
                                                            <div className="col-lg-12 text-right mt-2">
                                                                <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                                    Save
                                                                </button>
                                                            </div>
                                                    </div>
                                                </form>
                                            </div>
                                            {/* <div className="offset-lg-11 mt-1">
                                                <button className="mdg-btn mdg-btn-outline-meta mdg-btn-round float-right">
                                                    Save
                                                </button>
                                            </div> */}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default RoleForm;
