import { useEffect } from "react";
import { useState } from "react";
import swal from "sweetalert";
import SettingService from "../../../services/setting";

const GIFUrls = () => {
    const [ingamestore_fr,setIngamestoreFr] = useState("");
    const [ingamestore_en,setIngamestoreEn] = useState("");
    const [dashboard,setDashboard] = useState("");

    const getConfiguration = async () => {
        try{
            const res = await SettingService.getGifConfig();
            setIngamestoreFr(res.ingamestore_fr);
            setIngamestoreEn(res.ingamestore_en);
            setDashboard(res.dashboard);
            
        }catch(err){
            swal({
                'text' : 'An error was occured while getting GIF URL setting',
                'icon': 'error'
            })
        }
    }

    const handleUpdateSetting = async (e) => {
        e.preventDefault();
        try {
            await  SettingService.updateGifConfig(ingamestore_fr,ingamestore_en,dashboard);
            swal({
                'text' : 'Setting udpated with success',
                'icon': 'success'
            })
        }catch(err){
            console.log(err);
            swal({
                'text' : 'An error was occured while updating Fremium setting',
                'icon': 'error'
            })
        }
    }

    useEffect(() => {
        getConfiguration();
    },[])

    return (
        
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">GIF URL</h5>
          </div>
          <div className="card-body">
            <form onSubmit={(e) => handleUpdateSetting(e)}>
              <div className="row mt-4">                                           
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="mdg-textfield-out-lined">
                        <input
                          
                          style={{ color: "#fff" }}
                          className={`text-right`}
                          value={ingamestore_en}
                          id="ingamestore_en"
                          required
                          onChange={(e) => 
                            setIngamestoreEn(e.target.value)
                          }
                        />
                        <label htmlFor="ingamestore_en">GIF image for en in Ingamestore </label>
                      </div>                            
                    </div> 
                    <div className="col-lg-4">
                    <div className="mdg-textfield-out-lined">
                        <input
                          
                          style={{ color: "#fff" }}
                          className={`text-right`}
                          value={ingamestore_fr}
                          id="ingamestore_en"
                          required
                          onChange={(e) => 
                            setIngamestoreFr(e.target.value)
                          }
                        />
                        <label htmlFor="ingamestore_en">GIF image for fr in Ingamestore </label>
                      </div> 
                    </div>
                    <div className="col-lg-4">
                    <div className="mdg-textfield-out-lined">
                        <input
                          
                          style={{ color: "#fff" }}
                          className={`text-right`}
                          value={dashboard}
                          id="ingamestore_en"
                          required
                          onChange={(e) => 
                            setDashboard(e.target.value)
                          }
                        />
                        <label htmlFor="ingamestore_en">GIF image  in user Dashboard </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 text-right mt-2">
                  <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer"></div>
        </div>
    )
}

export default GIFUrls;