import React, { Component } from 'react';
import Panier from '../checkout/panier/Panier';
import HeaderPage from '../header/HeaderPage';
import Subscription from './Subscription';


class SubscriptionPage extends Component {
    render() {
    document.body.style.backgroundColor = "#081217";
    return (
        <div className="mdg-body-ingame">
            <div className="mdg-header-ingame">
                <HeaderPage />
                <Subscription/>
            </div>
        </div>
    );
    }
}
export default SubscriptionPage;