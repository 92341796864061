import { useEffect } from "react";
import { useState } from "react";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import SettingService from "../../../../services/setting";

const FremiumLimitation = () => {
    const [fcPerHour,setFcPerHour] = useState(0);
    const [fcPerMinutes,setFcPerMinutes] = useState(0);
    const [maxFcPerDay,setMaxFcPerDay] = useState(0);

    const getConfiguration = async () => {
        try{
            const res = await SettingService.getFremiumConfig();
            setFcPerHour(res.fcPerHour);
            setFcPerMinutes(res.fcPerMinutes);
            setMaxFcPerDay(res.maxFcPerDay);
            
        }catch(err){
            swal({
                'text' : 'An error was occured while getting Fremium setting',
                'icon': 'error'
            })
        }
    }

    const handleUpdateSetting = async (e) => {
        e.preventDefault();
        try {
            await  SettingService.updateFremiumConfig( fcPerHour,fcPerMinutes,maxFcPerDay);
            swal({
                'text' : 'Setting udpated with success',
                'icon': 'success'
            })
        }catch(err){
            console.log(err);
            swal({
                'text' : 'An error was occured while updating Fremium setting',
                'icon': 'error'
            })
        }
    }

    useEffect(() => {
        getConfiguration();
    },[])

    return (
        
        <div className="card">
          <div className="card-header">
            <h5 className="card-title">Fremium remuneration</h5>
          </div>
          <div className="card-body">
            <form onSubmit={(e) => handleUpdateSetting(e)}>
              <div className="row mt-4">                                           
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="mdg-textfield-out-lined">
                        <NumberFormat
                          suffix={" fc per hour"}
                          decimalSeparator=","
                          style={{ color: "#fff" }}
                          className={`text-right`}
                          value={fcPerHour}
                          id="input-flight-duration"
                          required
                          onValueChange={(values) => 
                            setFcPerHour(values.floatValue)
                          }
                        />
                        <label htmlFor="input-flight-duration">FC per Hour *</label>
                      </div>                            
                    </div> 
                    <div className="col-lg-4">
                      <div className="mdg-textfield-out-lined">
                        <NumberFormat
                          suffix={" fc per minute"}
                          decimalSeparator=","
                          style={{ color: "#fff" }}
                          className={`text-right`}
                          value={fcPerMinutes}
                          id="input-rest-time"
                          required
                          onValueChange={(values) => 
                            setFcPerMinutes(values.floatValue)
                          }
                        />
                        <label htmlFor="input-rest-time">FC per minutes *</label>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="mdg-textfield-out-lined">
                        <NumberFormat
                          suffix={" fc max per day"}
                          decimalSeparator=","
                          style={{ color: "#fff" }}
                          className={`text-right`}
                          value={maxFcPerDay}
                          id="input-rest-time"
                          required
                          onValueChange={(values) => 
                            setMaxFcPerDay(values.floatValue)
                          }
                        />
                        <label htmlFor="input-rest-time">FC per days *</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 text-right mt-2">
                  <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer"></div>
        </div>
    )
}

export default FremiumLimitation;