import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import UserSubscriptionList from './UserSubscriptionList';

const UserSubscriptionComponent = (props) => {
    return (
        <div>
            <BaseBO page={<UserSubscriptionList></UserSubscriptionList>}>
            </BaseBO>
           
        </div>
    );
};

export default UserSubscriptionComponent;