import { Navigate, Outlet } from "react-router-dom";

const AdminRoute = ({ redirectPath = "/dashboard",isAdmin = false,children }) => {
  
    if (!isAdmin) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children ? children : <Outlet/>;
};

export default AdminRoute;