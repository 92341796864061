import axios  from "../AxiosInstance"

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/aviation-type`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAll = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/aviation-type`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const getById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/aviation-type/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const update = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/aviation-type/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteById = async (id) => {
    try {
        return await axios.delete(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/aviation-type/${id}`,
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAviationTypeAsKeyValueService = async () => {
  const res = await getAll();
  if (res.error) {
    return [];
  } else {
    const aviationType = [];
      for (let i = 0; i < res.data.length; ++i) {
          const row = res.data[i];
          aviationType.push({
              label: row.type,
              value: row.id,
          });
      }
    return aviationType;
  }
};

const AviationTypeService = {
    create,
    getAll,
    getById,
    update,
    deleteById,
    getAviationTypeAsKeyValueService
}

export default AviationTypeService;