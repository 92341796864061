import React, { useState, useEffect } from "react";
import SellerModeService from "../../../../../services/sellerMode";
import Language from "../../../../extra/language/language";
import MyRentedItemsList from "./MyRentedItemsList";

const MyRentedItems = () => {
  const [myRentedItems, setMyRentedItems] = useState([]);

  const getMyRentedItems = async () => {
    const result = await SellerModeService.getAllMyRentedItemsService();
    if (!result.error) {
      setMyRentedItems(result.data);
    }
  };

  useEffect(() => {
    getMyRentedItems();
  }, []);

  return (
    <>
      <div className="row justify-content-end">
        <div className="col-lg-4">
          <div className="mdg-search-place">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search "
              style={{ color: "#fff" }}
              className="mdg-search-input"
            />
            <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
          </div>
        </div>
      </div>

      <div className="mdg-width-card-item mdg-mt-6">
        <div
          className="tab-pane fade show active"
          id="lands"
          role="tabpanel"
          aria-labelledby="lands-tab"
        >
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="all-flight"
              role="tabpanel"
              aria-labelledby="all-flight-tab"
            >
              <div className="mdg-width-card-item p-3">
                {myRentedItems.length > 0 ? (
                  <div className="row">
                    {myRentedItems.map(
                      (elt, i) =>
                        elt.rentItem.length > 0 &&
                        new Date(elt.rentItem[0].expirationDate) >=
                          new Date() && (
                          <MyRentedItemsList
                            item={elt}
                            key={i}
                          ></MyRentedItemsList>
                        )
                    )}
                  </div>
                ) : (
                  <p><Language word={"Seller.You_do_not_have_rented_item"}></Language></p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyRentedItems;
