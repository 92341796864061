import React, { useEffect, useState } from "react";
import SettingService from "../../../services/setting";
import Swal from "sweetalert2";
import SettingNavHeader from "./NavHeader";
import GIFUrls from "./GIFUrls";

const GeneralSettings = () => {
  const [G_email, setG_email] = useState("");

  const handleGeneral = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      G_email: G_email,
    };
    formData.append("name", "G_email");
    formData.append("data", JSON.stringify(data));

    let result = await SettingService.create(formData);

    if (result.error) {
      Swal.fire({
        title: result.response.data.message
          ? result.response.data.message
          : result.response.data.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: result.data.message,
        icon: result.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => {});
    }
  };

  const getSetting = async () => {
    const result = await SettingService.getAllSettings();
    if (!result.error) {
      const { data } = result;
      data.map((value, i) => {
        let configuration = JSON.parse(value.configuration);
        const name = value.name;
        switch (name) {
          case "G_email":
            setG_email(configuration.G_email);
            break;
        }
      });
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <SettingNavHeader />

      <section className="content mt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Admin Email</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleGeneral(e)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-mfc-miles"
                            type="text"
                            value={G_email}
                            onChange={(event) => {
                              setG_email(event.target.value);
                            }}
                            style={{ color: "#fff" }}
                            required
                          />
                          <label htmlFor="input-mfc-miles">Email *</label>
                        </div>
                      </div>
                      <div className="col-lg-12 text-right mt-2">
                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <GIFUrls></GIFUrls>

                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default GeneralSettings;
