import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import LevelService from "../../../services/missions/level";
import ScenarioService from "../../../services/scenario";
import { findInList, selectStyles } from "../../../Utils/Common";
import Select from "react-select";
import AwardService from "../../../services/award";

const LevelForm = (props) => {
  const { id } = useParams();
  const actionName = props.action;
  const [level, setLevel] = useState("");
  const [minXp, setMinXp] = useState("");
  const [maxXp, setMaxXp] = useState("");
  const [scenarios, setScenarios] = useState([]);
  const [awards, setAwards] = useState([]);

  const [scenarioList, setScenarioList] = useState([]);
  const [awardList, setAwardList] = useState([]);

  const getScenarioList = async () => {
    let scenario_list = await ScenarioService.getAllAsKeyValue();
    setScenarioList(scenario_list);
  };

  const getAwardList = async () => {
    let award_list = await AwardService.getAllAsKeyValue();
    setAwardList(award_list);
  };

  const getLevel = async () => {
    if (id) {
      const result = await LevelService.getLevelById(id);
      if (!result.error) {
        const data = result.data.data;
        setLevel(data.level);
        setMinXp(data.minXp);
        setMaxXp(data.maxXp);
        const loadedCarreerSenarios = data.CarreerLevelScenarios;
        const loadedLevelAwards = data.LevelAwards;
        let carreerSenarios = [];
        let levelAwards = [];
        loadedCarreerSenarios.forEach((el) => {
          if (el) {
            carreerSenarios.push(findInList(scenarioList, el.scenarioId));
          }
        });
        loadedLevelAwards.forEach((el) => {
          if (el) {
            levelAwards.push(findInList(awardList, el.awardId));
          }
        });
        setScenarios(carreerSenarios);
        setAwards(levelAwards);
      }
    }
  };

  useEffect(() => {
    getScenarioList();
    getAwardList();
    getLevel();
  }, [scenarioList.length, awardList.length]);

  const createLevelHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("level", level);
    formData.append("minXp", minXp);
    formData.append("maxXp", maxXp);

    let scenariosIds = [];
    let awardsIds = [];
    console.log(awards);
    scenarios.forEach((elt) => {
      scenariosIds.push(elt);
    });

    awards.forEach((elt) => {
      awardsIds.push(elt);
    });

    formData.append("scenarios", JSON.stringify(scenariosIds));
    formData.append("awards", JSON.stringify(awardsIds));

    let result;

    if (id) {
      result = await LevelService.updateLevelService(id, formData);
    } else {
      result = await LevelService.createLevelService(formData);
    }

    if (result.error) {
      swal(result.response.data.message, {
        icon: "warning",
        buttons: {
          confirm: "OK",
        },
      }).then((value) => {
        event.preventDefault();
      });
    } else {
      swal(result.data.message, {
        icon: "success",
        buttons: {
          confirm: "OK",
        },
      }).then((value) => {
        if (value) {
          event.preventDefault();
        }
      });
    }
  };

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{actionName} Level</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Level</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">New Level</h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/level/"
                      type="button"
                      className="btn btn-tool bg-info"
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => createLevelHandler(e)}>
                        <div className="row">
                          <div className="mdg-card-body mdg-row">
                            <div className="col-lg-6">
                              <div className="">
                                <label
                                  htmlFor="input-item-type-name"
                                  className="mdg-normal-label"
                                >
                                  Level
                                </label>
                                <input
                                  id="input-item-type-name"
                                  type="text"
                                  placeholder=""
                                  value={level}
                                  onChange={(event) => {
                                    setLevel(event.target.value);
                                  }}
                                  required
                                  style={{ color: "#fff" }}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <label
                                htmlFor="input-level-minXp"
                                className="mdg-normal-label"
                              >
                                Min Xp
                              </label>
                              <input
                                id="input-level-minXp"
                                type="number"
                                min="0"
                                step="0.01"
                                placeholder=" "
                                value={minXp}
                                onChange={(event) => {
                                  setMinXp(event.target.value);
                                }}
                                required
                                style={{ color: "#fff" }}
                                className="form-control"
                              />
                            </div>
                            <div className="col-lg-3">
                              <label
                                htmlFor="input-level-maxXp"
                                className="mdg-normal-label"
                              >
                                Max Xp
                              </label>
                              <input
                                id="input-level-maxXp"
                                type="number"
                                min="0"
                                step="0.01"
                                placeholder=" "
                                value={maxXp}
                                onChange={(event) => {
                                  setMaxXp(event.target.value);
                                }}
                                required
                                style={{ color: "#fff" }}
                                className="form-control"
                              />
                            </div>
                          </div>

                          {/* <div className="offset-lg-11 mt-1">
                                                        <button className="mdg-btn mdg-btn-outline-meta mdg-btn-round float-right">
                                                            Save
                                                        </button>
                                                    </div> */}
                        </div>
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mdg-textfield-out-lined">
                                <Select
                                  isClearable={true}
                                  style={{ color: "#fff" }}
                                  closeMenuOnSelect={false}
                                  isMulti
                                  onChange={async (newValue, actionMeta) => {
                                    newValue && setAwards(newValue);
                                  }}
                                  options={awardList}
                                  styles={selectStyles}
                                  value={awards}
                                  placeholder="Choose award(s)"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mdg-textfield-out-lined">
                                <Select
                                  isClearable={true}
                                  style={{ color: "#fff" }}
                                  closeMenuOnSelect={false}
                                  isMulti
                                  onChange={async (newValue, actionMeta) => {
                                    newValue && setScenarios(newValue);
                                  }}
                                  options={scenarioList}
                                  styles={selectStyles}
                                  value={scenarios}
                                  placeholder="Choose scenario(s)"
                                />
                              </div>
                              <div className="col-lg-12 text-right mt-2">
                                <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LevelForm;
