import axios  from "../AxiosInstance"

export const deleteFromMyWishList = async (id) => {
    try {
      return await axios.delete(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wishlist/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

export const addToMyWishList = async (id) => {
    try {
      return await axios.post(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wishlist/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

export const toggleWishList = async (id) => {
    try {
      return await axios.put(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wishlist/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

export const checkWishList = async (id) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wishlist/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

export const getAllMyWishList = async (search) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wishlist`,
        {
          params: { search : search }
        }
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

export const getTopWishlist = async (limit) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wishlist/top/`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


const WishListService = {
    addToMyWishList,
    deleteFromMyWishList,
    toggleWishList,
    checkWishList,
    getAllMyWishList,
    getTopWishlist
}

export default WishListService;