import { useState } from "react";
import Language from "../../../extra/language/language";

const PlaceOrder = ({ makeOrder , cart , inProcess }) => {
  const [agree, setAgree] = useState(false);
  return (
    <>
      <p className="mdg-text-privacy mt-2 text-center">
				<label for="id-agreement">
          <input type="checkbox" id="id-agreement" checked={agree} onClick={(e) => setAgree(e.target.checked)}  style={{ verticalAlign: "top" , marginRight:"2px"}} />
				  <Language word={"Term.Agreement"}></Language>
        </label>
			</p>

      <button
        type="button"
        className="btn mdg-btn-meta-payment col-lg-12"
        disabled= {inProcess || !agree}
        
        onClick={() => { if(agree) makeOrder() }}
				
      >
        {
          inProcess ? "In process ..." : (
            <>{cart.hasSubsrciption ? <Language word={"Subscription.Subscribe_now"}></Language> : <Language word={"Subscription.Place_order"}></Language>}</>
          )
        }
        
      </button>
      
    </>
  );
};

export default PlaceOrder;
