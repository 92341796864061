import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import StoreOrderList from './StoreOrderList';

const StoreOrderComponent = (props) => {
    let page ;

    switch (props.page){
        // case "new": page = <StoreProductForm action="Create"></StoreProductForm> ;break;
        // case "edit": page = <StoreProductForm action="Edit"></StoreProductForm> ; break;
        default: page = <StoreOrderList></StoreOrderList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default StoreOrderComponent;