import axios  from "../AxiosInstance"

const getAwardConfigTypeService = async () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/award-config/config-list`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAwardConfigTypeAsKeyValueService = async () => {
  const res = await getAwardConfigTypeService();

  if (res.error) {
    return [];
  } else {
    const status = [];
    const { data } = res;
    const dataArray = Object.keys(data);

    for (let i = 0; i < dataArray.length; ++i) {
      const elt = dataArray[i];

      status.push({ label: elt.split("_").join(" "), value: elt });
    }

    return status;
  }
}

const AwardConfigService = {
  getAwardConfigTypeAsKeyValueService,
}

export default AwardConfigService;