import React, { useEffect, useState } from "react";
import SettingService from "../../../services/setting";
import Swal from "sweetalert2";
import SettingNavHeader from "./NavHeader";
import FremiumLimitation from "./pilote_remuneration/FremiumLimitation";

const Settings = () => {
  const [MFC_miles, setMFC_miles] = useState(null);
  const [MFC_miles_min, setMFC_miles_min] = useState(null);
  const [MFC_FC, setMFC_FC] = useState(1);

  const [EBFC_ebillet, setEBFC_ebillet] = useState(null);
  const [EBFC_FC, setEBFC_FC] = useState(1);

  const [FTFC_hour, setFTFC_hour] = useState(null);
  const [FTFC_minute, setFTFC_minute] = useState(null);
  const [FTFC_hour_FC, setFTFC_hour_FC] = useState(null);
  const [FTFC_minute_FC, setFTFC_minute_FC] = useState(null);

  const handleMilesToFC = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      MFC_miles: MFC_miles,
      MFC_miles_min: MFC_miles_min,
      MFC_FC: MFC_FC,
    };
    formData.append("name", "MFC");
    formData.append("data", JSON.stringify(data));

    let result = await SettingService.create(formData);

    if (result.error) {
      Swal.fire({
        title: result.response.data.message
          ? result.response.data.message
          : result.response.data.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: result.data.message,
        icon: result.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => {});
    }
  };

  const handleEBilletToFC = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      EBFC_ebillet: EBFC_ebillet,
      EBFC_FC: EBFC_FC,
    };
    formData.append("name", "EBFC");
    formData.append("data", JSON.stringify(data));

    let result = await SettingService.create(formData);

    if (result.error) {
      Swal.fire({
        title: result.response.data.message
          ? result.response.data.message
          : result.response.data.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: result.data.message,
        icon: result.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => {});
    }
  };

  const handleFlightTimeToFC = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      FTFC_hour: FTFC_hour,
      FTFC_hour_FC: FTFC_hour_FC,
      FTFC_minute: FTFC_minute,
      FTFC_minute_FC: FTFC_minute_FC,
    };
    formData.append("name", "FTFC");
    formData.append("data", JSON.stringify(data));

    let result = await SettingService.create(formData);

    if (result.error) {
      Swal.fire({
        title: result.response.data.message
          ? result.response.data.message
          : result.response.data.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: result.data.message,
        icon: result.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => {});
    }
  };

  const getSetting = async () => {
    const result = await SettingService.getAllSettings();
    if (!result.error) {
      const { data } = result;
      data.map((value, i) => {
        let configuration = JSON.parse(value.configuration);
        const name = value.name;
        switch (name) {
          case "MFC":
            setMFC_miles(configuration.MFC_miles);
            setMFC_miles_min(
              configuration.MFC_miles_min ? configuration.MFC_miles_min : 0
            );
            break;
          case "EBFC":
            setEBFC_ebillet(configuration.EBFC_ebillet);
            break;
          case "FTFC":
            setFTFC_hour(configuration.FTFC_hour);
            setFTFC_minute(configuration.FTFC_minute);
            setFTFC_hour_FC(configuration.FTFC_hour_FC);
            setFTFC_minute_FC(configuration.FTFC_minute_FC);
            break;
        }
      });
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <SettingNavHeader />

      <section className="content mt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Miles to 1 FC</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleMilesToFC(e)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-mfc-miles"
                            type="number"
                            value={MFC_miles}
                            onChange={(event) => {
                              setMFC_miles(event.target.value);
                            }}
                            style={{ color: "#fff" }}
                            min={0}
                            required
                          />
                          <label htmlFor="input-mfc-miles">Miles *</label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-mfc-miles-min"
                            type="number"
                            value={MFC_miles_min}
                            onChange={(event) => {
                              setMFC_miles_min(event.target.value);
                            }}
                            style={{ color: "#fff" }}
                            min={0}
                            required
                          />
                          <label htmlFor="input-mfc-miles-min">
                            Minimum miles *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12 text-right mt-2">
                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="card-footer"></div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">eBillet to 1 FC</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleEBilletToFC(e)}>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-eBillet-ebillet"
                            type="number"
                            value={EBFC_ebillet}
                            onChange={(event) => {
                              setEBFC_ebillet(event.target.value);
                            }}
                            required
                            style={{ color: "#fff" }}
                            step="1"
                          />
                          <label htmlFor="input-eBillet-ebillet">
                            eBillet *
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12 text-right mt-2">
                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="card-footer"></div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Flight time to Flight Coin</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleFlightTimeToFC(e)}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-ftfc-hour"
                            type="number"
                            value={FTFC_hour}
                            onChange={(event) => {
                              setFTFC_hour(event.target.value);
                            }}
                            required
                            style={{ color: "#fff" }}
                            step="1"
                          />
                          <label htmlFor="input-ftfc-hour">Hour *</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-ftfc-fc"
                            type="number"
                            value={FTFC_hour_FC}
                            onChange={(event) => {
                              setFTFC_hour_FC(event.target.value);
                            }}
                            required
                            style={{ color: "#fff" }}
                            step="1"
                          />
                          <label htmlFor="input-ftfc-fc">FC *</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-ftfc-minute"
                            type="number"
                            value={FTFC_minute}
                            onChange={(event) => {
                              setFTFC_minute(event.target.value);
                            }}
                            required
                            style={{ color: "#fff" }}
                            step="1"
                          />
                          <label htmlFor="input-ftfc-minute">Minute *</label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mdg-textfield-out-lined">
                          <input
                            id="input-ftfc-fc"
                            type="number"
                            value={FTFC_minute_FC}
                            onChange={(event) => {
                              setFTFC_minute_FC(event.target.value);
                            }}
                            required
                            style={{ color: "#fff" }}
                            step="1"
                          />
                          <label htmlFor="input-ftfc-fc">FC *</label>
                        </div>
                      </div>
                      <div className="col-lg-12 text-right mt-2">
                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="card-footer"></div>
              </div>
              <FremiumLimitation></FremiumLimitation>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Settings;
