import React from "react";
import Thumbnail from "../../../extra/img/thumbnail";
import ItemService from "../../../../services/Item";
import Swal from "sweetalert2";
import Language from "../../../extra/language/language";
import { useSelector } from "react-redux";
import translate from "../../../extra/language/translate";
import { getImageUrl } from "../../../../Utils/Common";

class RecommendedItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleBuyItem = this.handleBuyItem.bind(this);
  }

  handleBuyItem = async (event) => {
    event.preventDefault();
    const item = this.props.item.item;
    const stock = item.stock;
    const itemId = item.id;
    const itemName = item.name;
    const storeType = item.storeType.toLowerCase();

    const lang = this.props.lang;
    let swalTitle;

    switch (storeType) {
      case "rent":
        swalTitle = translate('Seller.Do_you_want_to_accept_to_rent_this',lang)+` ${itemName}`;
        break;
      // case 'exchange':
      case "bid":
        swalTitle = translate('Seller.Do_you_want_to_accept_to_bid_on_this',lang)+` ${itemName}`;
        break;
      default:
        swalTitle = translate('Seller.Do_you_want_to_accept_to_buy_this',lang)+` ${itemName}`;
    }

    Swal.fire({
      title: `${swalTitle}`,
      icon: "info",
      showCancelButton: true,
    }).then(async (res) => {
      if (res.isConfirmed) {
        let orderResult;

        switch (storeType) {
          case "rent":
            orderResult = await ItemService.rentItemService(itemId, stock);
            break;
          // case 'exchange':
          case "bid":
            orderResult = await ItemService.makeBidProposal(itemId, stock);
            break;
          default:
            orderResult = await ItemService.orderItemService(itemId, stock);
        }

        if (orderResult.error) {
          Swal.fire({
            title: orderResult.response.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: orderResult.data.message,
            icon: orderResult.data.error ? "warning" : "success",
            confirmButtonText: "Ok",
          });
        }
      }
    });
  };

  handleDeclineProposal = async (event) => {
    event.preventDefault();
    const item = this.props.item.item;
    const lang = this.props.lang;

    Swal.fire({
      title: translate('Seller.Do_you_realy_want_to_decline_this_offer',lang),
      icon: "info",
      showCancelButton: true,
    }).then(async (res) => {
      if (res.isConfirmed) {
        let declineResult = await ItemService.declineOfferService(item.id);

        if (declineResult.error) {
          Swal.fire({
            title: declineResult.response.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: declineResult.data.message,
            icon: declineResult.data.error ? "warning" : "success",
            confirmButtonText: "Ok",
          });
        }
      }
    });
  };

  componentDidMount() {}

  render() {
    const item = this.props.item.item;
    const media = item.media;
    const name = item.name;
    const price = item.price;
    // const symbol = item.currency.symbol;
    const symbol = "FC";
    const description = item.description;
    const storeType = item.storeType;
    const photo = item.photo;
    const proprio = item.proprio;
    /* item type */
    const itemTypeName = item.type ? item.type.name : null;
    const itemTypeTextColor = item.type
      ? item.type.textColor
        ? item.type.textColor
        : null
      : null;
    const itemTypeColor = item.type
      ? item.type.color
        ? item.type.color
        : null
      : null;

    let actionName = "Buy";
    switch (storeType) {
      case "BID":
        actionName = "Bid";
        break;
      case "EXCHANGE":
        actionName = "Exchange";
        break;
      default:
        actionName = "Buy";
        break;
    }
    /* item type */
    return (
      <div className="col-sm-4 mt-3">
        <div className="mdg-width-card-body">
          <div className="mdg-card-body-items">
            <div>
              <Thumbnail
                src={getImageUrl(media)}
                alt="logo"
                className="mdg-image-body"
              ></Thumbnail>
              <div
                className="mdg-body-description mt-2 pl-2"
                style={{ position: "relative" }}
              >
                <p>
                  <span
                    className="mdg-bg-common"
                    style={{
                      color: itemTypeTextColor ? itemTypeTextColor : "",
                      backgroundColor: itemTypeColor ? itemTypeColor : "",
                    }}
                  >
                    {itemTypeName ? itemTypeName : <Language word={"Seller.COMMON"}></Language>}
                  </span>
                </p>
              </div>
              <div className="mdg-body-footer-description mt-3 pl-2 mdg-row">
                <div className="mdg-col-lg-6">
                  <span>
                    {proprio && proprio.photo ? (
                      <Thumbnail
                        src={getImageUrl(proprio.photo)}
                        alt="logo"
                        className="mdg-img-description mr-1"
                      ></Thumbnail>
                    ) : (
                      <img
                        src={"/assets/image/metaflight-icon.png"}
                        alt="logo"
                        className="mdg-img-description mr-1"
                      />
                    )}
                  </span>

                  <span className="mdg-bg-text-items">
                    {proprio
                      ? proprio.firstName + " " + proprio.lastName
                      : "Metaflight"}
                  </span>
                  <br />
                  <span className="mdg-bg-text-items mdg-text-marque">
                    {name}
                  </span>
                  <br />
                  <span className="mdg-bg-text-items">
                    <small>{description}</small>
                  </span>
                </div>
                <span className="mdg-bg-text-items float-right"  >
                  <Thumbnail
                    src={photo ? photo : "/assets/image/coin.png"}
                    width="30"
                    height="30"
                    className="rounded-circle-sm mr-1"
                  ></Thumbnail>
                  {price + " " + symbol}
                </span>
              </div>
            </div>
          </div>
          <div className="card-footer mdg-card-footer-items">
          <button
              className="btn mdg-btn-common btn-sm float-left"
              onClick={this.handleDeclineProposal}
            >
              <span><Language word={"Seller.Decline"}></Language></span>
            </button>
            <button
              className="btn mdg-btn-common btn-sm float-right"
              onClick={this.handleBuyItem}
            >
              <span><Language word={"Seller.Accept"}></Language></span>
            </button>

            
          </div>
        </div>
      </div>
    );
  }
}

export default RecommendedItem;
