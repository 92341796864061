import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import { selectStyles } from "../../../Utils/Common";
import swal from "sweetalert";
import { creditUserWallet, debitUserWallet } from "../../../services/wallet/wallet";


const UserWalletForm = (props) => {
    const { id } = useParams();
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const wallet = 'fc';
    const actionName = props.action;

    const setTransactionFunction = async (event) => {
        event.preventDefault();

        if (id) {
            const amountFC = wallet === "fc" ? amount : 0;
            const amountMiles = wallet === "miles" ? amount : 0;

            const formData = new FormData();
            formData.append("userId", id);
            formData.append("fc", amountFC);
            formData.append("miles", amountMiles);
            formData.append("description", description);

            let result;
            if (actionName == "credit") {
                result = await creditUserWallet(formData);
            }
            else {
                result = await debitUserWallet(formData);
            }

            if (result.error) {
                swal({
                    text: result.response.data.message,
                    icon: "error",
                    buttons: {
                        confirm: "OK",
                    },
                })
            } else {

                swal(result.data.message, {
                    icon: result.data.error ? "error" : "success",
                    buttons: {
                        confirm: "OK",
                    },
                })
            }
        }
    };


    return (
        <div >
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">User Wallet</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item">User</li>
                                <li className="breadcrumb-item active">Profile</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box">
                                <span className="info-box-icon bg-info elevation-1"><i className="fas fa-cog"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">CPU Traffic</span>
                                    <span className="info-box-number">
                                        10
                                        <small>%</small>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-thumbs-up"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Likes</span>
                                    <span className="info-box-number">41,410</span>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix hidden-md-up"></div>

                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-success elevation-1"><i className="fas fa-shopping-cart"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Sales</span>
                                    <span className="info-box-number">760</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-3">
                            <div className="info-box mb-3">
                                <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-users"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">New Members</span>
                                    <span className="info-box-number">2,000</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName == "credit" ? "Credit user wallet" : "Debit user wallet"}</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/user" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12">
                                        <form onSubmit={(e) => setTransactionFunction(e)}>
                                            <div className="row">
                                                
                                                <div className="mdg-col-sm-6">
                                                    <div className="mdg-textfield-outlined">
                                                        <input
                                                            id="input-category-name"
                                                            type="number"
                                                            placeholder=" "
                                                            value={amount}
                                                            onChange={(event) => {
                                                                setAmount(event.target.value);
                                                            }}
                                                            required
                                                        />
                                                        <label htmlFor="input-category-name">
                                                            Amount to {actionName.toLocaleLowerCase()}
                                                        </label>
                                                    </div>
                                                    
                                                           
                                                </div>
                                                <div className="mdg-col-sm-6">
                                                    
                                                    <div className="mdg-textfield-outlined">
                                                        <input
                                                            id="input-description"
                                                            type="text"
                                                            placeholder=" "
                                                            value={description}
                                                            onChange={(event) => {
                                                                setDescription(event.target.value);
                                                            }}
                                                            
                                                        />
                                                        <label htmlFor="input-description">
                                                           Description
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-12 text-right mt-2">
                                                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                                
                                                {/* <div className="offset-lg-11 mt-1">
                                                    <button className="mdg-btn mdg-btn-outline-meta mdg-btn-round float-right">
                                                        Save
                                                    </button>
                                                </div> */}
                                            </div>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default UserWalletForm;
