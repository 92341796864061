import { NavLink } from "react-router-dom";

const SettingNavHeader = () => {
  return (
    <section className="content">
      <div className="container-fluid">
        <ul className="nav nav-pills nav-justified">
          <li className="nav-item">
            <NavLink
              to="/admin/setting/general"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              General
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/setting/flight-coin-conversion"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              Flight coin Conversion
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/setting/remuneration-eTicket"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              eTickets Remuneration
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/setting/ingame-store-virtual-properties-commission"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              InGame Store Virtual Properties Commission
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/admin/setting/pilote-remuneration"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              Pilote Remuneration
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/setting/affiliate-bonus"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              Affiliate Bonus
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/setting/bot-threshold"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              Bot pax threshold
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/admin/setting/bot-repatriation"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              Bot repatriation
            </NavLink>
          </li>

          <li className="nav-item">
            <NavLink
              to="/admin/setting/malus-last-position"
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
            >
              Malus last position
            </NavLink>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default SettingNavHeader;
