import axios  from "../AxiosInstance"

const createTypeService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission-type`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAllTypeService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission-type`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const getTypeById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission-type/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const updateTypeService = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission-type/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteByIdService = async (id) => {
    try {
        return await axios.delete(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission-type/${id}`,
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getMissionTypeAsKeyValueService = async () => {
    const res = await getAllTypeService();
    if (res.error) {
        return [];
    } else {
        const types = [];
        for (let i = 0; i < res.data.length; ++i) {
            const elt = res.data[i];
            
            types.push({ label: elt.label, value: elt.id });
        }
        return types;
    }
};

const MissionTypeService = {
    createTypeService,
    getAllTypeService,
    getTypeById,
    updateTypeService,
    deleteByIdService,
    getMissionTypeAsKeyValueService
}

export default MissionTypeService;