import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapAction from '../../../../../../actions/map/actions';
import Utils from '../../../../../../services/tracker/Utils';
import moment from 'moment/moment';
import { NavLink } from 'react-router-dom';
import 'moment-duration-format';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { DoubleLeftOutlined, DoubleRightOutlined, CloseOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';
import Language from '../../../../language/language';
import AcarsService from '../../../../../../services/tracker/tracker';
import { useEffect } from 'react';

const Flightdetailscard = () => {
    const [isShow, setIsShow] = useState('DETAILS');
    const [altitudeData, setAltitudeData] = useState([0, 50, 100, 400, 450, 500]);
    const [altitudeLabel, setAltitudeDataLabel] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']);
    //const isShow = "DETAILS";

    const displayFlightDetail = useSelector(
        (state) => state.map.flightDetails.displayFlightDetail
    );

    const flightDetail = useSelector(
        (state) => state.map.flightDetails.flightToDisplay
    );

    const dispatch = useDispatch();

    let timeRemainingFormatted = 'NaN';
    if (flightDetail && flightDetail.aircraft.groundSpeed > 40) {
        const timeRemaining = Utils.getTimeRemainingBetweenAircraftAndPosition(
            flightDetail.aircraft,
            flightDetail.arrivalAirport.position
        );

        if (timeRemaining) {
            timeRemainingFormatted = moment
                .duration(timeRemaining, 'minutes')
                .format('h [hrs] mm [min]');
        } else {
            timeRemainingFormatted = 'NaN';
        }
    }



    let timeEllapsedFormatted = 'NaN';
    if (flightDetail && flightDetail.aircraft.groundSpeed > 40) {
        const timeEllapsedRemaining = Utils.getTimeRemainingBetweenAircraftAndPosition(
            flightDetail.aircraft,
            flightDetail.departureAirport.position
        );

        if (timeEllapsedRemaining) {
            timeEllapsedFormatted = moment
                .duration(timeEllapsedRemaining, 'minutes')
                .format('h [hrs] mm [min]');
        } else {
            timeEllapsedFormatted = 'NaN';
        }
    }

    const distanceRemaining = flightDetail
        ? `${Utils.getNauticalDistanceBetweenPosition(
            flightDetail.aircraft.position,
            flightDetail.arrivalAirport.position
        ).toFixed(0)} NM`
        : '0 NM';

    const distanceTraveled = flightDetail
        ? `${Utils.getNauticalDistanceBetweenPosition(
            flightDetail.aircraft.position,
            flightDetail.departureAirport.position
        ).toFixed(0)} NM`
        : '0 NM';

    const distanceTotal = flightDetail
        ? `${Utils.getNauticalDistanceBetweenPosition(
            flightDetail.departureAirport.position,
            flightDetail.arrivalAirport.position
        ).toFixed(0)} NM`
        : '0 NM';

    const onClose = () => {
        dispatch(MapAction.HideFlightDetail());
        setIsShow('DETAILS')
    };

    const getPercent = () => {
        const distanceTotal = flightDetail
            ? Utils.getNauticalDistanceBetweenPosition(
                flightDetail.departureAirport.position,
                flightDetail.arrivalAirport.position
            ).toFixed(0) : 0;

        const traveled = flightDetail
            ? Utils.getNauticalDistanceBetweenPosition(
                flightDetail.aircraft.position,
                flightDetail.departureAirport.position
            ).toFixed(0) : 0;

        const percent = Math.floor((traveled * 100 / distanceTotal));
        if (percent > 90) {
            return percent - 15;
        }
        else if (percent > 80) {
            return percent - 17;
        }
        else {
            return percent - 5;
        }
    }




    const data = {
        labels: altitudeLabel,
        datasets: [
            {
                label: "Aircraft altitude",
                data: altitudeData,
                fill: true,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)"
            }
        ]
    };

    const options = {
        responsive: true,

    }

    const getAltitudeData = () => {
        if (flightDetail && flightDetail.tracker === "metacars") {
            let altitudeArr = []
            let altitudeIds = []
            for (let i = 0; i < flightDetail.aircraftStateHistories.length; ++i) {
                altitudeArr.push(flightDetail.aircraftStateHistories[i].position.altitude)
                altitudeIds.push(flightDetail.aircraftStateHistories[i].id.toString())
            }
            setAltitudeData(altitudeArr);
            setAltitudeDataLabel(altitudeIds);
        }

    }

    useEffect(() => {
        getAltitudeData()
    }, [isShow])

    useEffect(() => {
        if (!displayFlightDetail) {
            setIsShow('DETAILS')
        }
    }, [displayFlightDetail])

    return displayFlightDetail ? (
        <Draggable>
            <div className="mdg-width-info-plane">
                <div className="col-lg-12">
                    <div className="mdg-title-details-vols">
                        <div className="row">
                            <div className="col-lg-2 text-center">
                                {isShow === "STAT" &&
                                    <>
                                        <NavLink
                                            to={``}
                                            className="mdg-text-white"
                                            onClick={() => {
                                                setIsShow("DETAILS")
                                            }}
                                        >
                                            <DoubleLeftOutlined />
                                        </NavLink>
                                    </>
                                }
                                {isShow === "DETAILS" &&
                                    <>
                                        <NavLink
                                            to={``}
                                            onClick={(e) => onClose()}
                                            className="mdg-text-white"
                                        >
                                            <CloseOutlined />
                                        </NavLink>
                                    </>
                                }
                            </div>
                            <div className="col-lg-8 text-center">
                                <h4 className="mdg-text-white">

                                    {  flightDetail.tracker === 'afrv' ? 'AFR VIRTUEL' :AcarsService.getFlightStep(flightDetail.actualFlightStep)}
                                </h4>
                            </div>
                            <div className="col-lg-2 text-center">
                                {isShow === "DETAILS" && flightDetail.tracker === "metacars" &&
                                    <>
                                        <NavLink
                                            to={``}
                                            className="mdg-text-white"
                                            onClick={() => {
                                                setIsShow("STAT")
                                            }}
                                        >
                                            <DoubleRightOutlined />
                                        </NavLink>
                                    </>
                                }
                                {isShow === "STAT" &&
                                    <>
                                        <NavLink
                                            to={``}
                                            className="mdg-text-white"
                                            onClick={(e) => onClose()}
                                        >
                                            <CloseOutlined />
                                        </NavLink>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mdg-body-details-vols">
                    {isShow === "DETAILS" &&
                        <>
                            <div className="row justify-content-md-center pt-2 pb-2" style={{ borderBottom: "1px solid" }}>
                                <div className="col-lg-3">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Departure"} /></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{flightDetail?.departureAirport.ident}</span>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <div className="mdg-content-progress">
                                        <div className="mdg-bar-progress" style={{ width: `${getPercent()}%` }}>
                                            <img
                                                src={"/assets/image/6.blue.png"}
                                                alt=""
                                                className="mdg-incline-images"
                                                style={{ left: `${getPercent() > 90 ? getPercent() - 15 : getPercent()}%` }}
                                            />
                                        </div>
                                    </div>
                                    {/* <img
                                        src={getFligtImg()}
                                        alt=""
                                        style={{ width: "100%", marginTop: "20px" }}
                                    /> */}
                                    {/* <Progress percent={getPercent()} size="small" showInfo={false} />
                                    <img
                                        src={"/assets/image/6.blue.png"}
                                        alt=""
                                        className="mdg-incline-images"
                                        style={{ left: `${distance_plane}%` }}
                                    /> */}
                                    {/* <i className="fa fa-plane mdg-incline-image mdg-text-meta-color" aria-hidden="true" style={{ left: `${distance_plane}%` }}></i> */}
                                </div>
                                <div className="col-lg-3">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Arrival"} /></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{flightDetail?.arrivalAirport.ident}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-md-start p-2 mt-4">
                                <div className="col text-center">
                                    <img
                                        src="/assets/image/metaflight-icon.png"
                                        width="30"
                                        alt=""
                                        height="30"
                                        className="rounded-circle-sm mr-1 ml-4 d-none"
                                    /><span className="mdg-text-white ">{flightDetail?.flightNumber} <small>{flightDetail?.aircraft.icao} (
                                        {flightDetail?.aircraft.registration?.trim()})</small></span>
                                    <p>
                                        <span className="mdg-text-white mdg-12-text">{ flightDetail.tracker == 'metacars' ? flightDetail.pilot?.userName: flightDetail.pilot?.name }</span>
                                    </p>
                                </div>
                            </div>
                            <div className="row p-1">
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Altitude"} />  </span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{flightDetail.aircraft.position.altitude} ft</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Speed"} /></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{flightDetail.aircraft.groundSpeed} Kts</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-1">
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Time.Elapse"} />  </span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{timeEllapsedFormatted}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Distance.Traveled"} /></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{distanceTraveled}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row p-1">
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Time.Rest"} /></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{timeRemainingFormatted}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> <Language word={"Flight.Distance.Rest"} /></span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{distanceRemaining}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row p-2 mdg-d-none">
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> Altitude</span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{`${flightDetail?.aircraft.position.altitude} ft`}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="col-lg-12">
                                        <span className="text-muted mdg-12-text"> Pilot</span>
                                    </div>
                                    <div className="col-lg-12">
                                        <span className="mdg-text-white mdg-12-text">{`${flightDetail?.pilot?.name}`}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {isShow === "STAT" &&
                        <div style={{ marginTop: "20px" }}>
                            <Line
                                data={data} />
                        </div>
                    }
                </div>
                <div className="row justify-content-center mdg-d-none">
                    <div className="col-lg-6 text-center mdg-d-none">
                        <button onClick={onClose} className="btn btn-info mdg-d-none">
                            Close
                        </button>
                    </div>
                </div>
            </div >
        </Draggable >
    ) : null;
};

export default Flightdetailscard;
