import axios  from "../AxiosInstance"


export const creditUserWallet = async (formData) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wallet/credit`,
            data: formData
        });
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const debitUserWallet = async (formData) => {
    try {

        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wallet/debit`,
            data: formData
        });
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const getWalletHistoryListService = async () => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/wallet/history`);
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

export const getWalletAllHistoryService = async (filter) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/wallet/history/list`, 
        { params: filter });
      } catch (err) {
        return {
          error: true,
          response: err.response,
        };
      }
}

export const getWalletAllHistoryAsAdminService = async (filter) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/wallet/history/user-wallet/list`, 
        { params: filter });
      } catch (err) {
        return {
          error: true,
          response: err.response,
        };
      }
}

export const getWalletAllCommissionHistoryAsAdminService = async (filter) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/wallet/history/commission/list`, 
        { params: filter });
      } catch (err) {
        return {
          error: true,
          response: err.response,
        };
      }
}

export const convertMilesToFCService = async (formData) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wallet/convert-miles-to-FC`,
            data: formData
        });
    } catch (err) {
        return {
            error: true,
            response: err.response
        };
    }
}

const WalletService = {
    creditUserWallet,
    debitUserWallet,
    getWalletHistoryListService,
    convertMilesToFCService,
    getWalletAllHistoryAsAdminService,
    getWalletAllCommissionHistoryAsAdminService
}

export default WalletService;