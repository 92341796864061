import React from "react";
import { NavLink } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Thumbnail from "../../../extra/img/thumbnail";
import Language from "../../../extra/language/language";
import Swal from "sweetalert2";
import translate from "../../../extra/language/translate";
import { getImageUrl } from "../../../../Utils/Common";
class ItemList extends React.Component {

  getFcAmountRentedItem = (childs) => {
    let sum = 0;
    if (childs.length > 0) {
      childs.forEach((obj) => {
        if (obj.rentItem.length > 0) {
          obj.rentItem.forEach((obj) => {
            sum += obj.fcAmount;
          });
        }
      });
    }
    return sum;
  };

  getBonusMiles = (list) => {
    let bonus = {
      milesPerHourOfFlight: 0,
      milesPerDay: 0,
      milesFixedBonus: 0
    };

    if (list && list.length > 0) {
      for (let i in list) {
        if (list[i].type === "PER_DAY") {
          bonus.milesPerDay = bonus.milesPerDay + list[i].credited;
        } else if (list[i].type === "PER_HOUR_OF_FLIGHT") {
          bonus.milesPerHourOfFlight = bonus.milesPerHourOfFlight + list[i].credited;
        } else if (list[i].type === "BONUS_MILES") {
          bonus.milesFixedBonus = bonus.milesFixedBonus + list[i].credited;
        }
      }
    }
    return bonus;
  }

  showMilesDetails = (milesPerHourOfFlight, milesPerDay, milesFixedBonus) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: translate("Item_List.Miles_details", this.props.lang),
      html: (
        <div className="row">
          <div className="col-lg-12 text-center">
            {translate("Item_List.Miles_per_hour_of_flight", this.props.lang)}: {milesPerHourOfFlight} miles
          </div>
          <div className="col-lg-12 text-center">
            {translate("Item_List.Miles_per_Day", this.props.lang)}: {milesPerDay} miles
          </div>
          <div className="col-lg-12 text-center">
            {translate("Item_List.Miles_Fixed_Bonus", this.props.lang)}: {milesFixedBonus} miles
          </div>
        </div>
      ),
      // width: countryList.length > 80 ? "95%" : undefined,
      showConfirmButton: true,
      showCloseButton: false,
      showCancelButton: true,
      cancelButtonText: translate("Item_List.Cancel", this.props.lang),
    });
  };

  render() {
    const allData = this.props.item;
    const item = allData.item;

    const bonusMiles = this.getBonusMiles(item.bonusMiles);

    const xMilesFixedBonus = item.xMilesFixedBonus;

    const sumAmount = this.getFcAmountRentedItem(item.childs);

    const media = item.media;
    const name = item.name;
    const description = item.description;
    const stock = allData.quantity;

    /* item type */
    const itemTypeName = item.type ? item.type.name : null;
    const itemTypeTextColor = item.type
      ? item.type.textColor
        ? item.type.textColor
        : null
      : null;
    const itemTypeColor = item.type
      ? item.type.color
        ? item.type.color
        : null
      : null;
    /* item type */

    const fixedBonus = bonusMiles.milesFixedBonus === 0 ? xMilesFixedBonus : bonusMiles.milesFixedBonus;
    const sumMilesBonus = bonusMiles.milesPerHourOfFlight + bonusMiles.milesPerDay + fixedBonus;

    return (
      <>
        <div className={`${this.props.itemClass}  mt-3`}>
          <div className={`${this.props.itemBg}`}>
            <div className="mdg-card-header-items"></div>
            <div className="mdg-card-body-items">
              <div>
                <Thumbnail
                  src={getImageUrl(media) }
                  alt="logos"
                  className="mdg-image-body"
                ></Thumbnail>
                <div
                  className="mdg-body-description mt-2 pl-2"
                  style={{ position: "relative" }}
                >
                  <div className="row justify-content-between">
                    <div className="col-5">
                      <span
                        className="mdg-bg-common"
                        style={{
                          color: itemTypeTextColor ? itemTypeTextColor : "",
                          backgroundColor: itemTypeColor ? itemTypeColor : "",
                        }}
                      >
                        {itemTypeName ? itemTypeName : <Language word={"My_items.COMMON"}></Language>}
                      </span>
                    </div>
                    <div className="col-5">
                      <div className="float-right">
                        <small><Language word={"My_items.Quantity"}></Language></small>
                        <span className="ml-2 badge mr-2"> {stock}</span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-7 ml-2">
                   
                    </div>
                    <div className="col-lg-5" style={{ position: "absolute", right: "-30px" }}>
                      <small>Quantity</small><span className="ml-2 badge"> {stock}</span>
                     </div>
                  </div> */}
                </div>
                <div className="mdg-body-footer-description mt-1">
                  <div className="col-lg-12">
                    <img
                      src={"/assets/img/logo-meta-plane.png"}
                      alt="logo"
                      className="mdg-img-description mr-1"
                    />
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="">
                          <span className="mdg-bg-text-items mdg-text-marque d-inline-flex">
                            {name}
                          </span>
                        </div>
                        <div className="">
                          <span className="mdg-bg-text-items">
                            <small>{description}</small>
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">

                        {/* <span className="mdg-bg-text-items">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mt-1">
                                <div>
                                  <img
                                    src="/assets/image/miles-coin.png"
                                    width="20"
                                    height="20"
                                    alt=''
                                    className="rounded-circle-sm mr-1"
                                  />
                                  <small>{bonusMiles.milesPerHourOfFlight + bonusMiles.milesPerDay + bonusMiles.milesFixedBonus} miles </small>
                                  <NavLink
                                    className="ml-3"
                                    to={""}
                                  >
                                    <i className="fa fa-info-circle mdg-bg-info" title="See more details" onClick={(e) => this.showMilesDetails(bonusMiles.milesPerHourOfFlight, bonusMiles.milesPerDay, bonusMiles.milesFixedBonus)} ></i>
                                  </NavLink>
                                </div>
                                {/* <div>
                                <img
                                  src="/assets/image/miles-coin.png"
                                  width="20"
                                  height="20"
                                  alt=''
                                  className="rounded-circle-sm mr-1"
                                />                           
                              <small>{bonusMiles.milesPerHourOfFlight} miles <Language word={"My_items.Per_Hour_Of_Flight"}></Language></small>
                              </div>
                              <div className="mt-1">  
                                  <div>
                                    <img
                                        src="/assets/image/miles-coin.png"
                                        width="20"
                                        height="20"
                                        alt=''
                                        className="rounded-circle-sm mr-1"
                                    />
                                    <small>{bonusMiles.milesPerDay} miles <Language word={"My_items.Per_Day"}></Language> </small>
                                  </div>
                              </div> 
                              </div>
                            </div>
                          </div>
                        </span> */}


                        <span className="mdg-bg-text-items">
                          <div className="row">
                            <div className="col-lg-12">
                              <small><Language word={"My_items.You_earn"}></Language>: </small>
                            </div>
                            <div className="col-lg-12">
                              <div className="mt-1">
                                <img
                                  src="/assets/image/coin.png"
                                  width="30"
                                  height="30"
                                  alt=""
                                  className="rounded-circle-sm mr-1"
                                />

                                <small>{sumAmount} FC <Language word={"My_items.with_rent"}></Language></small>
                                {/*TODO_MILES */}
                                <div className="mt-1 d-none">
                                  {xMilesFixedBonus > 0 && (
                                    <div>
                                      <img
                                        src="/assets/image/miles-coin.png"
                                        width="20"
                                        height="20"
                                        alt=''
                                        className="rounded-circle-sm mr-1"
                                      />
                                      <small>{sumMilesBonus} miles </small>
                                      <NavLink
                                        className="ml-3"
                                        to={""}
                                      >
                                        <i className="fa fa-info-circle mdg-bg-info" title="See more details" onClick={(e) => this.showMilesDetails(bonusMiles.milesPerHourOfFlight, bonusMiles.milesPerDay, fixedBonus)} ></i>
                                      </NavLink>
                                      {/* <small> {xMilesFixedBonus} <Language word={"My_items.Miles_generated"}></Language> </small> */}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>

                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer mdg-card-footer-items">
              <NavLink
                state={{
                  item: item,
                }}
                className="btn mdg-btn-common btn-sm float-left"
                to={`/account/items/${item.id}/details/sell`}
              >
                <span><Language word={"My_items.Manage"}></Language></span>
              </NavLink>

              <NavLink
                className="btn mdg-btn-common btn-sm float-right"
                to={`/ingame-store/details/${item.id}/new`}
              >
                <span><Language word={"My_items.Market"}></Language></span>
              </NavLink>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ItemList;
