import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getFlightBeautifulFormatedDateWithTime } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";

const FlightElement = ({ flight, flightClass }) => {
  const lang = useSelector((store) => store.language);
  return (
    <>
      {/* <div className="row mdg-bg-row-3 mt-4"> */}
      <div className={`${flightClass} row mt-4`}>
        <div className="col-lg-12">
          <div className="pt-3">
            <NavLink
              to={`/my-flight-list/detail/${flight.id}`}
              style={{ color: "#fff" }}
            >
              <div className="row align-items-center">
                <div className="col-lg-12">
                  
                  <h6 className="mdg-text-white">
                    {translate("My_flight.Flight_number", lang)} :{" "}
                    {flight.flightNumber}
                  </h6>
                 
                </div>
                <div className="col-lg-2 text-center mt-2">
                  <img
                    src={"/assets/image/plane-white.png"}
                    alt=""
                    className="mdg-incline-image"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="col-lg-10">
                  <div className="row align-items-center">
                    <div className="col-lg-6 float-right">
                      <span>
                        {translate("My_flight.Departure", lang)} :{" "}
                        <small className="mdg-bg-text-items">
                          {flight.departureIcao.label}
                        </small>
                      </span>
                    </div>
                    <div className="col-lg-6 float-right">
                      <span>
                        {translate("My_flight.Arrival", lang)} :{" "}
                        <small className="mdg-bg-text-items">
                          {flight.arrivalIcao.label}
                        </small>
                      </span>
                    </div>
                    <div className="col-lg-6 float-right">
                      <span>
                        {translate("My_flight.Date", lang)} :{" "}
                        <small className="mdg-bg-text-items">
                            {getFlightBeautifulFormatedDateWithTime(
                              flight.beginFlightTime
                            )}
                        </small>
                      </span>
                    </div>
                    <div className="col-lg-6 float-right">
                      <span>
                        {translate("My_flight.Aircraft_type", lang)} :{" "}
                        <small className="mdg-bg-text-items">
                          {flight.aircraftIcao}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
              <p className="lead mdg-text-white"></p>
            </NavLink>
          </div>
          <div className=" mdg-footer-btn">
            <div className="col-sm">
              <NavLink
                className="btn mdg-btn-common"
                to={`/my-flight-list/detail/${flight.id}`}
                state={{ flight: flight}}
              >
                <span>
                  {" "}
                  <Language word={"Mission.Details"}></Language>{" "}
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlightElement;
