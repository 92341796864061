import { NavLink } from "react-router-dom";
import Language from "../../../extra/language/language";

const SideNav = () => {
  return (
    <div
    >
      <NavLink
        to="/account/items"
        className={(navDatas) =>
          navDatas.isActive ? "nav-link seller active" : "nav-link"
        }
      >
        <Language word={"Seller.Manage_my_items"}></Language>
      </NavLink>
      <NavLink
        to="/account/store"
        className={(navDatas) =>
          navDatas.isActive ? "nav-link seller active" : "nav-link"
        }
      >
        <Language word={"Seller.My_Personal_Store"}></Language>
      </NavLink>
      <NavLink
        to="/account/my-rented-items"
        className={(navDatas) =>
          navDatas.isActive ? "nav-link seller active" : "nav-link"
        }
      >
        <Language word={"Seller.My_Rented_Items"}></Language>
      </NavLink>
      <NavLink
        to="/account/recommendations"
        className={(navDatas) =>
          navDatas.isActive ? "nav-link seller active" : "nav-link"
        }
      >
        <Language word={"Seller.My_Deals"}></Language>
      </NavLink>
    </div>
  );
};

export default SideNav;
