import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SettingService from "../../../../services/setting";
import SettingNavHeader from "../NavHeader";
import NumberFormat from "react-number-format";

const MalusLastPosition = () => {
  const [amount, setAmount] = useState("");
  const [distance, setDistance] = useState("");

  const getInitialSetting = async () => {
    await SettingService.getSettingsByName("MALUS_LAST_POSITION").then(
      (res) => {
        if (!res.error) {
          setAmount(res.data.flightDurationPerDay);
          setDistance(res.data.restTimeDuration);
        }
      }
    );
  };

  const handleMalusLastPositionSetting = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      flightDurationPerDay: amount,
      restTimeDuration: distance,
    };
    formData.append("name", "MALUS_LAST_POSITION");
    formData.append("data", JSON.stringify(data));

    let result = await SettingService.create(formData);

    if (result.error) {
      Swal.fire({
        title: result.response.data.message
          ? result.response.data.message
          : result.response.data.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: result.data.message,
        icon: result.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => {});
    }
  };

  useEffect(() => {
    getInitialSetting();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0"> Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <SettingNavHeader />

      <section className="content mt-2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Malus last position</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleMalusLastPositionSetting(e)}>
                    <div className="row mt-4">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mdg-textfield-out-lined">
                              <NumberFormat
                                suffix={" FC"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={amount}
                                id="input-amount"
                                required
                                onValueChange={(values) =>
                                  setAmount(values.floatValue)
                                }
                              />
                              <label htmlFor="input-amount">Amount *</label>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mdg-textfield-out-lined">
                              <NumberFormat
                                suffix={" NM"}
                                decimalSeparator=","
                                style={{ color: "#fff" }}
                                className={`text-right`}
                                value={distance}
                                id="input-distance"
                                required
                                onValueChange={(values) =>
                                  setDistance(values.floatValue)
                                }
                              />
                              <label htmlFor="input-distance">
                                Distance traveled *
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 text-right mt-2">
                        <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                          Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MalusLastPosition;
