import React from "react";
import Thumbnail from "../../../extra/img/thumbnail";
import Swal from "sweetalert2";
import QuantityPickerInput from "../../../extra/quantite_picker/QuantityPickerPages";
import withReactContent from "sweetalert2-react-content";
import MetaCart from "../../../../actions/cartActions";
import { connect } from "react-redux";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";
import { refreshWalletAsync } from "../../../../asyncActions/walletAsyncActions";
import PackBoosterCarreerLevelService from "../../../../services/pack/packBoosterCarreerLevel";
import { getImageUrl } from "../../../../Utils/Common";

export class PackBoosterCarreerLevelEltContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.pack = {
      id: props.pack.id,
      name: props.pack.title,
      image: getImageUrl(props.pack.image),
      price: props.pack.price,
      tva: props.pack.tva,
      description: props.pack.description,
      type: "level",
      subscription: false,
      carreerLevel: props.pack.carreerLevel,
    };

    this.handleBuyNow = this.handleBuyNow.bind(this);
    this.history = props.history;
  }

  handleBuyNow = (event) => {
    event.preventDefault();
    let item = this.pack;
    item.quantity = 1;
    this.props.addToCart(item);
 };

  handleBuyNowV2 = async (event) => {
    event.preventDefault();
    const pack = this.props.pack;
    const lang = this.props.lang;

    Swal.fire({
      title: "Would you like to order this pack booster",
      showConfirmButton: true,
      showCloseButton: false,
      showCancelButton: true,
      cancelButtonText: translate('Item_List.Cancel', lang),
    }).then(async (res) => {
      if (res.isConfirmed) {
        const formData = new FormData();

        formData.append("carreerLevelId", pack.carreerLevelId);
        formData.append("packBoosterCarreerLevelId", pack.id);

        const orderResult = await PackBoosterCarreerLevelService.order(formData)

        if (orderResult.error) {
          Swal.fire({
            title: orderResult.response.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: orderResult.data.message,
            icon: orderResult.data.error ? "warning" : "success",
            confirmButtonText: "Ok",
          });
        }
      }
    });
  };

  handleBuyNowOld = async (event) => {
    event.preventDefault();
    const name = this.props.pack.name;
    let inputValue = 1;
    const lang = this.props.lang;
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: translate('Packs.Do_you_want_to_order_this', lang) + ` ${name}`,
      html: (
        <>
          <label htmlFor="input-miles"><Language word={"Packs.Quantity"}></Language> *</label>
          <QuantityPickerInput
            className="mt-2"
            min={1}
            inputValue={inputValue}
            onChange={(value) => {
              inputValue = value;
            }}
          ></QuantityPickerInput>
        </>
      ),
      showConfirmButton: true,
      showCloseButton: false,
      width: 600,
    }).then(async (res) => {
      if (res.isConfirmed) {
        let item = this.pack;
        item.quantity = inputValue;
        this.props.addToCart(item);
        this.checkCart();
      }
    });
  };

  checkCart() {
    if (this.props.myCart.error) {
      Swal.fire({
        icon: "error",
        text: this.props.myCart.message,
      });
    } else {
      if (this.props.myCart.numberCart >= 1) {
        this.history("/checkout");
      }
    }
  }

  render() {
    return (
      <div className={`${this.props.packClass}`}>
        <div className={`${this.props.packBg}`}>
          <div className="mdg-card-body-items">
            <div>
              <div>
                <Thumbnail
                  src={this.pack.image}
                  alt="logo"
                  className="mdg-image-body"
                ></Thumbnail>
              </div>
              <div
                className="mdg-body-description mt-2 pl-2"
                style={{ position: "relative" }}
              >
                <div className="mdg-body-footer-description mt-3">
                  <div className="col-lg-12">
                    <span className="mdg-bg-text-items">{this.pack.name}</span>
                    <br />
                    <span className="mdg-bg-text-items mdg-text-marque">
                      {this.pack.description}
                    </span>
                    <br />
                    <i
                      className="fa fa-play mdg-rotate-90 mdg-text-white mr-1"
                      aria-hidden="true"
                    ></i>{" "}
                    <b className="mdg-text-white">
                      {this.pack.carreerLevel.level}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer mdg-card-footer-items">
            <div className="container">
              <div className="row justify-content-between">
                
                <div className="col-6">
                  <span className="align-center mdg-bg-text-items">
                    <Language word={"Packs.Price"}></Language>:{" "}
                    {this.pack.price}€
                  </span>
                </div>
                <div className="col-6">
                  <button
                    className="btn mdg-btn-common btn-sm float-right"
                    onClick={this.handleBuyNow}
                    disabled={this.props.level && this.props.level.id >= this.pack.carreerLevel.id}
                  >
                    <span>
                      <Language word={"Packs.Buy_now"}></Language>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  MetaCart.mapState,
  MetaCart.mapDispach
)(PackBoosterCarreerLevelEltContainer);
