import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import CargoCategoryForm from './CargoCategoryForm';
import CargoCategoryList from './CargoCategoryList';

const CargoCategoryComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <CargoCategoryForm action="create"></CargoCategoryForm> ;break;
        case "edit": page = <CargoCategoryForm action="edit"></CargoCategoryForm> ; break;
        default: page = <CargoCategoryList></CargoCategoryList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default CargoCategoryComponent;