import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import PackBoosterCarreerLevelForm from './PackBoosterCarreerLevelForm';
import PackBoosterCarreerLevelList from './PackBoosterCarreerLevelList';
import PackBoosterCarreerLevelOrderList from './PackBoosterCarreerLevelOrderList';

const PackBoosterCarreerLevelComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <PackBoosterCarreerLevelForm action="create"></PackBoosterCarreerLevelForm> ;break;
        case "edit": page = <PackBoosterCarreerLevelForm action="edit"></PackBoosterCarreerLevelForm> ; break;
        case "order": page = <PackBoosterCarreerLevelOrderList></PackBoosterCarreerLevelOrderList> ; break;
        default: page = <PackBoosterCarreerLevelList></PackBoosterCarreerLevelList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default PackBoosterCarreerLevelComponent;