import React, { Component } from 'react';
import Panier from '../checkout/panier/Panier';
import HeaderPage from '../header/HeaderPage';
import PackBoosterCarreerLevel from './PackBoosterCarreerLevel';



class PackBoosterCarreerLevelFo extends Component {
    render() {
    document.body.style.backgroundColor = "#081217";
    return (
        <div className="mdg-body-ingame">
            <div className="mdg-header-ingame">
                <HeaderPage />
                <PackBoosterCarreerLevel />
            </div>
        </div>
    );
    }
}

export default PackBoosterCarreerLevelFo;