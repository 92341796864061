import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ redirectPath = "/login",isAuthenticated = false,children }) => {
  
    if (!isAuthenticated) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children ? children : <Outlet/>;
};

export default ProtectedRoute;