import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SellerModeService from "../../../../../services/sellerMode";
import Thumbnail from "../../../../extra/img/thumbnail";
import { useSelector } from "react-redux";

import "./style.css"
import translate from "../../../../extra/language/translate";
import Language from "../../../../extra/language/language";
import { getImageUrl } from "../../../../../Utils/Common";

const ExchangeProposal = ({ itemId }) => {
    const [proposals, setProposals] = useState([]);
    const [loading, setLoading] = useState(true);
    const lang = useSelector((state) => state.language);

    const getExchangeProposals = async () => {
        const result = await SellerModeService.getExchangeProposalByItemId(itemId);
        if (!result.error) {
            setProposals(result.data)
        }
        setLoading(false);
    }


    const refuseExchangeProposal = async (id) => {
        Swal.fire({
            title: translate('Seller.Confirmation',lang),
            text: translate('Seller.Are_you_sure_that_you_want_to_accept_this_offer',lang),
            icon: "question",
            confirmButtonText: "Ok",
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
                const data = new FormData();
                data.append("exchangeProposalStatus", "DENIED");
                const res = await SellerModeService.updateExchangeProposalStatus(id, data);
                if (res.error) {
                    Swal.fire({
                        title: res.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: res.data.message,
                        icon: res.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        getExchangeProposals();
                    });
                }
            }
        });
    }

    const validProposal = async (id) => {
        Swal.fire({
            title: translate('Seller.Confirmation',lang),
            text: translate('Seller.Are_you_sure_that_you_want_to_accept_this_offer',lang),
            icon: "question",
            confirmButtonText: "Ok",
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
                const data = new FormData();
                data.append("exchangeProposalStatus", "ACCEPTED");
                const res = await SellerModeService.updateExchangeProposalStatus(id, data);
                if (res.error) {
                    Swal.fire({
                        title: res.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: res.data.message,
                        icon: res.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        getExchangeProposals();
                    });
                }
            }
        });

    }

    useEffect(() => {
        getExchangeProposals();
    }, []);


    return (
        <table >
            <thead>
                <tr>
                    <th ><Language word={"Seller.Name"}></Language></th>
                    <th ><Language word={"Seller.Item"}></Language></th>
                    <th ><Language word={"Seller.Price"}></Language></th>
                    <th ><Language word={"Seller.Action"}></Language></th>
                </tr>
            </thead>
            <tbody>
                {
                    loading && <tr ><td colSpan="4" ><Language word={"Seller.loading"}></Language></td></tr>
                }
                {
                    !loading && proposals.map((proposal, i) => (
                        <tr key={i}>
                            <td> {proposal.user.userName}</td>
                            <td>
                                <Thumbnail
                                    src={getImageUrl(proposal.exchangeItem.media)}
                                    alt="logo"
                                ></Thumbnail>
                            </td>
                            <td> {proposal.exchangeItem.price} FC</td>
                            {proposal.status == "PENDING" ? (
                                <td>
                                    <button className="mdg-btn-deny" onClick={(e) => { refuseExchangeProposal(proposal.id) }}><Language word={"Seller.Refuse"}></Language></button>
                                    <button className="mdg-btn-accept" onClick={(e) => { validProposal(proposal.id) }}><Language word={"Seller.Valid"}></Language></button>
                                </td>
                            ) : (<td>{proposal.status}</td>)}
                        </tr>
                    ))
                }
                {!loading && proposals.length == 0 && <tr ><td colSpan="4" ><Language word={"Seller.No_proposal_found"}></Language></td></tr>}
            </tbody>
        </table>);
}

export default ExchangeProposal;