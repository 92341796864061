import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import MalusLastPosition from './MalusLastPosition';

const MalusLastPositionPages = () => {
  return (
    <div>
      <BaseBO page={<MalusLastPosition />}></BaseBO>
    </div>
  );
};

export default MalusLastPositionPages;