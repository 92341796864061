import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Thumbnail from "../../../extra/img/thumbnail";
import swal from 'sweetalert';
import FoodService from "../../../../services/paxncargo/food";
import { getImageUrl } from "../../../../Utils/Common";

const FoodList = () => {
    const [list, setList] = useState(null);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const deleteFood = async (row) => {
        swal(`Are you sure to delete this food : ${row.name}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await FoodService.deleteById(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }
                getList()
            }
        });
    }

    const columns = [
        {
            id: 1,
            name: "Media",
            selector: (row) => row.icon,
            sortable: false,
            reorder: false,
            cell: (row) => (
                row.icon &&
                <Thumbnail src={getImageUrl(row.icon)}></Thumbnail>
            )
        },
        {
            id: 2,
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Service Time",
            selector: (row) =>  <>{Math.floor(row.serviceTime / 60)} h : {row.serviceTime % 60} min</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            name: "Satiete Rate",
            selector: (row) => <>{row.satieteRate} %</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 5,
            name: "Wellness Rate",
            selector: (row) => <>{row.wellnessRate} %</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 6,
            name: "Entertainment Rate",
            selector: (row) =>  <>{row.entertainmentRate} %</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 7,
            name: "Category",
            selector: (row) => row.foodCategory.name,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 8,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/paxncargo/food/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 9,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deleteFood(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const getList = async () => {
        await FoodService.getAll().then((res) => {
            setList(res.data)
        });
    };

    useEffect(() => {
        getList();
    }, []);

    return (
        <div >
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Food</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Food</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Foods</h5>                                    
                                    <div className="card-tools">
                                        <NavLink to="/admin/paxncargo/food/new" type="button" className="btn btn-tool bg-info ml-2" >
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12">                                                                            
                                        <div className="row">
                                            <div className="col-lg-12 mdg-historique-table">
                                                {list && (<DataTable
                                                    columns={columns}
                                                    data={list}
                                                    defaultSortFieldId={1}
                                                    pagination
                                                    selectableRows
                                                    conditionalRowStyles={rowStyle}
                                                />)}
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default FoodList;
