import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { getFullDate, getImageUrl, tableRowStyle } from "../../../Utils/Common";
import UserSubscriptionService from "../../../services/userSubscription";
import Thumbnail from "../../extra/img/thumbnail";


const UserSubscriptionTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    const getList = async () => {
        setLoading(true);

        const filter = {
            page: currentPage - 1,
            size: perPage,
            search: search
        }

        const response = await UserSubscriptionService.getList(filter);
        
        if (!response.error) {
            setData(response.data.items);
            setTotalRows(response.data.totalItems);
        }

        setLoading(false);
    };

    useEffect(() => {
        getList();
    }, [currentPage, perPage]);


    const columns = useMemo(
        () => [
            {
                name: "User",
                selector: (row) => row.user.photo,
                sortable: false,
                cell: (row) => (
                    <><Thumbnail src={row.user.photo?getImageUrl(row.user.photo):"/assets/img/default-profile-picture.png"}></Thumbnail><div className="ml-2">{row.user.userName}</div></>
                )
            }, {
                name: "Pack Name",
                selector: (row) => row.subscription.name,
                sortable: true
            }, {
                name: "Last Payment",
                selector: (row) => getFullDate(row.lastPayment),
                sortable: true
            }, {
                name: "Next Payment",
                selector: (row) => getFullDate(row.nextPayment),
                sortable: true
            }, {
                name: "Status",
                selector: (row) => row.status,
                sortable: true
            }
        ],
    );

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    return (
        <DataTable
            title=""
            columns={columns}
            data={data}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) => { console.log(selectedRows) }}
            conditionalRowStyles={tableRowStyle}
        />
    );
};


export default UserSubscriptionTable;

