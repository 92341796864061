import React from "react";
import { NavLink } from "react-router-dom";

const NotFound = () => {
    document.body.style.backgroundColor = "#081217";
  return (
    <div className="mdg-container-notfound">
      <h1 className="mdg-tittle-not-found">404 Error Page #3</h1>
      <h6 className="mdg-tittle-not-found">we can't seem to find the page you're looking for</h6>
      <section className="error-container">
        <span>4</span>
        <span>
          <span className="screen-reader-text">0</span>
        </span>
        <span>4</span>
      </section>
      <div className="mdg-link-container">
        <NavLink
          to="/"
          className="btn mdg-btn-common"
        >
         GO TO HOME
        </NavLink>
      </div>
    </div>
  );
};

export default NotFound;
