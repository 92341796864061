/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { isAvailableFeature } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";
import ConnectedNav from "./ConnectedNav";
import NotConnectedNav from "./NotConnectedNav";

const Header = () => {
  document.body.style.backgroundColor = "#081217";
  const authObj = useSelector((state) => state.auth);
  const { user } = authObj;

  return (
    <nav className="navbar navbar-expand-lg mdg-bg-nav fixed-top m-3 mr-2 p-1">
      <NavLink className="navbar-brand ml-2" to="/home">
        <img
          src="/assets/img/bg-meta-white-blue.png"
          width="80"
          height="30"
          className="d-inline-block align-top"
          alt=""
        />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
        <i className="fa fa-bars mdg-fa-bar-mobile" aria-hidden="true"></i>
      </button>
      <div className="collapse navbar-collapse" id="navbarText">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <NavLink
              className={(navData) =>
                navData.isActive ? "nav-link active" : "nav-link"
              }
              to="/home"
            >
              <img
                src="/assets/image/icon-home.png"
                width="30"
                alt=""
                height="30"
                className="rounded-card-md mr-1"
              />
              <small>
                <Language word={"Header.Home"}></Language>
              </small>{" "}
              <span className="sr-only">(current)</span>
            </NavLink>
          </li>
          { isAvailableFeature('ingame') && (
            <li className="nav-item">
              <NavLink
                className={(navData) =>
                  navData.isActive ? "nav-link active" : "nav-link"
                }
                to="/ingame-store/all/all"
              >
                <img
                  src="/assets/image/logo_store.png"
                  width="30"
                  alt=""
                  height="30"
                  className="rounded-card-md mr-1"
                  style={{ color: "red" }}
                />
                <small>
                  <Language word={"Header.InGame_Store"}></Language>
                </small>
              </NavLink>
            </li>
          )}
          
          <li className="nav-item d-none">
            <NavLink
              className={(navData) =>
                navData.isActive ? "nav-link " : "nav-link"
              }
              to="/mf-store"
            >
              <i className="fa fa-shopping-basket mr-1"></i>
              <small>MF Store</small>
            </NavLink>
          </li>
          {(isAvailableFeature('mission')) && (
            <li className="nav-item">
              <NavLink
                className={(navData) =>
                  navData.isActive ? "nav-link " : "nav-link"
                }
                to="/mission"
              >
                <i className="fa fa-bullseye mr-1"></i>
                <small>Mission</small>
              </NavLink>
            </li>
          )}
          
        </ul>
        <div className="aligns-items-center mdg-d-block">
          {user ? (
            <ConnectedNav user={user}></ConnectedNav>
          ) : (
            <NotConnectedNav></NotConnectedNav>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
