import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getWalletAllHistoryService } from "../../../../services/wallet/wallet";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SettingService from "../../../../services/setting";
import MilesToFC from "./MilesToFC";
import { useDispatch } from "react-redux";
import WalletDatatable from "./WalletDatatable";
import { getFormatedAmount } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";

const Wallet = ({ version }) => {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet);
  const [walletHistoryList, setWalletHistoryList] = useState([]);
  const [MFCSetting, setMFCSetting] = useState([]);
  const [validMFCConversion, setValidMFCConversion] = useState(false);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const lang = useSelector((state) => state.language);

  const getHistory = async () => {
    setLoading(true);
    const filter = {
      page: currentPage - 1,
      size: perPage,
    };
    const res = await getWalletAllHistoryService(filter);
    if (!res.error) {
      //setWalletHistoryList(res.data);
      setWalletHistoryList(res.data.items);

      setTotalRows(res.data.totalItems);
    }
    setLoading(false);
  };

  const convertToFC = (id) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title:
        translate('Wallet.Convert_to', lang) + " FC ( " +
        MFCSetting.MFC_miles +
        " Miles -> " +
        MFCSetting.MFC_FC +
        " FC )",
      html: (
        <>
          <MilesToFC
            MFCSetting={MFCSetting}
            MySwal={MySwal}
            dispatch={dispatch}
            language={lang}
          ></MilesToFC>
        </>
      ),
      showConfirmButton: false,
      showCloseButton: false,
      width: 800,
    });
  };

  const getMFCSetting = async () => {
    const setting = await SettingService.getSettingsByName("MFC");
    const { data } = setting;
    if (data) {
      setMFCSetting(data);
      setValidMFCConversion((data.MFC_miles_min ? Number(data.MFC_miles_min) : 0) <= Number(wallet.miles));
    }
  };

  useEffect(() => {
    getHistory();
    getMFCSetting();
  }, [currentPage, perPage, wallet.miles, wallet.fc]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const classHidden = version === "v2" ? "mdg-top-0" : "mdg-top-container";
  const classBackground = version === "v2" ? "mdg-card-dahsboard-notice" : "mdg-card-dashboard";
  const classBg = version === "v2" ? "mdg-card-dahsboard-flight" : "";
  const hidden = version === "v2" ? "mdg-d-none" : "";

  return (
    <div className={`${classHidden} container`}>
      <div className={`${hidden} mdg-title-ingame row`}>
        <div className="col-sm">
          <span className="mdg-h2"><Language word={"Wallet.Wallet"}></Language></span>
        </div>
        <div className="col-sm">
          {/* <button type="submit" className="btn btn-info float-right">Seller mode</button> */}
        </div>
      </div>
      <div className="mdg-history-content-new mdg-blur-new">
        <div className="row">
          <div className="col-lg-6">
            <div className={`${classBackground}`}>
              <div className="card-body" style={{ position: "relative" }}>
                <h5 className="card-title mdg-nav-span-profil">Flight Coin</h5>
                <NavLink to="/packs">
                  <i
                    className="fa fa-plus"
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "0px",
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  ></i>
                </NavLink>
                <p className="card-text mdg-nav-span-profil mdg-nav-span-profil-text">
                  <img
                    src="/assets/image/coin.png"
                    width="30"
                    height="30"
                    alt=""
                    className="rounded-circle-lg mr-1"
                  />{" "}
                  {getFormatedAmount(wallet.fc)} FC
                </p>
                <button
                  type="submit"
                  className="btn mdg-btn-common btn-sm mdg-btn-12"
                >
                  <Language word={"Wallet.See"}></Language>
                </button>
              </div>
            </div>
          </div>
          {/* TODO_MILES */}
          <div className="col-lg-6 d-none">
            <div className={`${classBackground}`}>
              <div className="card-body" style={{ position: "relative" }}>
                <h5 className="card-title mdg-nav-span-profil">Miles</h5>
                <NavLink to="/packs">
                  <i
                    className="fa fa-plus"
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "0px",
                      cursor: "pointer",
                      color: "#fff",
                    }}
                  ></i>
                </NavLink>
                <p className="card-text mdg-nav-span-profil mdg-nav-span-profil-text">
                  <img
                    src="/assets/image/miles-coin.png"
                    width="30"
                    alt=""
                    height="30"
                    className="rounded-circle-lg mr-1"
                  />{" "}
                  {getFormatedAmount(wallet.miles)} miles
                </p>
                <button
                  type="submit"
                  className="btn mdg-btn-common btn-sm mdg-btn-12"
                >
                  <Language word={"Wallet.See"}></Language>
                </button>
                {//(MFCSetting.MFC_miles && validMFCConversion) && (
                  <button
                    type="button"
                    className="ml-2 btn mdg-btn-common btn-sm mdg-btn-12"
                    onClick={(e) => convertToFC()}
                  >
                    <Language word={"Wallet.Convert_to"}></Language> FC
                  </button>//)
               }
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
            <div className="col-lg-12">
          <div className={`${classBg} mdg-historique-table mdg-historique-table-new`}>
              {walletHistoryList && (
                <WalletDatatable
                  data={walletHistoryList}
                  progressPending={loading}
                  paginationDefaultPage={currentPage}
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
