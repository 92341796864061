import React, { useEffect, useState } from "react";
import NoticesBoService from "../../../../services/notices";
import NoticeList from "./NoticeList";
import draftToHtml from 'draftjs-to-html';
import Thumbnail from "../../../extra/img/thumbnail";
import Language from "../../../extra/language/language";

const Notice = ({ version }) => {

    const [noticesList, setNoticesList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState(false);
    const [photo, setPhoto] = useState("")
    const [name, setName] = useState("")

    const getNoticeLisst = async () => {
        const result = await NoticesBoService.getAllNoticesService();
        if (result.status === 200) {
            const { data } = result;
            setNoticesList(data);
        }
    }

    const handleModal = (modal_content, modal_name, modal_photo) => {
        setName(modal_name);
        setPhoto(modal_photo);
        setContent(draftToHtml(JSON.parse(modal_content)));
        setShowModal(true);
    }
    const classWidth = version === "v2" ? "col-lg-4 mt-3" : "col-sm-3 mt-3"
    const classHidden = version === "v2" ? "mdg-top-0 mdg-bg-content-v2" : "mdg-top-container";
    const hidden = version === "v2" ? "mdg-d-none" : "";
    const classBg = version === "v2" ? "mdg-width-card-body" : "mdg-width-card-body";

    useEffect(() => {
        getNoticeLisst();
    }, []);

    return (
        <div className={`${classHidden} container`}>
            <div className={`${hidden} mdg-title-ingame`}>
                <div className="row justify-content-between">
                    <div className="col-4">
                        <span className="mdg-h2"><Language word={"Notice.Notice"}></Language></span>
                    </div>
                </div>
            </div>
            <div>
                <div className="row">
                    {noticesList.map((elt, i) => (
                        <NoticeList
                            notice={elt}
                            key={i}
                            handleModal={handleModal}
                        // noticeClass={classWidth}
                        ></NoticeList>
                    ))}
                </div>
            </div>
            <div className={showModal == true ? "mdg-center mdg-card-meta-modal" : "mdg-d-none"}>
                <div className="mdg-content-meta-modal">
                    <div className="mdg-p-modal">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-12 text-center">
                                <h2 className="mdg-text-white mdg-uppercase">{name}</h2>
                                <Thumbnail
                                    src={photo}
                                    alt="logo"
                                    className="mdg-notice-image"
                                ></Thumbnail>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mdg-title-modal text-justify">
                                <span className="mdg-text-white">
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                </span>
                            </div>
                            <div className="mdg-modal-footer col-lg-12">
                                <button type="button" className="btn btn-info" onClick={(e) => {
                                    setShowModal(false);

                                }}><Language word={"Notice.Close"}></Language></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notice;
