import axios  from "../AxiosInstance"

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wares`, 
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAll = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wares`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const getById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/wares/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const update = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wares/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteById = async (id) => {
    try {
        return await axios.delete(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/wares/${id}`,
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getWaresAsKeyValueService = async () => {
    const res = await getAll();
    if (res.error) {
        return [];
    } else {
        const wares = [];
        for (let i = 0; i < res.data.length; ++i) {
            const elt = res.data[i];
            
            wares.push({ label: elt.name, value: elt.id });
        }
        return wares;
    }
};

const WaresService = {
    create,
    getAll,
    getById,
    update,
    deleteById,
    getWaresAsKeyValueService
}

export default WaresService;