import axios  from "../AxiosInstance"

export const getSimulators = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/base/simulators`);
  } catch (err) {
    return {
      error: true,
      response: err.response
    };
  }
}

export const getSimulatorsAsKeyValueService = async () => {
  const res = await getSimulators();
  if (res.error) {
    return [];
  } else {
    const simulators = [];
      for (let i = 0; i < res.data.length; ++i) {
          const simulator = res.data[i];
          simulators.push({
              label: simulator.simulatorName,
              value: simulator.id,
          });
      }
    return simulators;
  }
};


const SimulatorService = {
  getSimulators,
  getSimulatorsAsKeyValueService,
}

export default SimulatorService;
