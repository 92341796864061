import axios  from "../AxiosInstance"

const getAllMyStoreService = async () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/items`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }

};

const deleteMyStore = async (id) => {
  try {
    return await axios.delete(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/items/${id}`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getProposalByItemId = async (id) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/items/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const sellMyItem = async (formData) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/sell-my-item`,
      data: formData,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


const getAllMyRecommendationService = async () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/recommendation`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const updateProposalStatus = async (id,data) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/update-status/${id}`,
      data: data
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
}

const rentMyItem = async (formData) => {
  try {
      return await axios({
        method: "post",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/rent-my-item`,
        data: formData,
      });
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
  }
};

const getExchangeProposalByItemId = async (id) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/items/exchange/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
}
const exchangeMyItem = async (formData) => {
  try {
      return await axios({
        method: "post",
        url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/exchange-my-item`,
        data: formData,
      });
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
  }
};


const updateExchangeProposalStatus = async (id,data) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/exchange-update-status/${id}`,
      data: data
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
}

const getAllExchangeableItems = async (id) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/items/${id}/exchangeable`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const closeBid = async (id) => {
  try {
    return axios.put(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/my-store/items/${id}/close`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllMyRentedItemsService = async () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/items/rent-item/my-rented-items`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }

};

const SellerModeService = {
    getAllMyStoreService,
    getAllMyRecommendationService,
    deleteMyStore,
    getProposalByItemId,
    sellMyItem,
    updateProposalStatus,
    rentMyItem,
    getExchangeProposalByItemId,
    exchangeMyItem,
    updateExchangeProposalStatus,
    getAllExchangeableItems,
    closeBid,
    getAllMyRentedItemsService
};

export default SellerModeService;