import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import MetaCart from "../../../../../actions/cartActions";

const CartMenu = () => {
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    const handeClick = (e) => {
        e.preventDefault();
        dispatch(MetaCart.toggleShowCart());
        return false;
    }
    return (
        <NavLink to="" className="nav-link dropdown-toggle mdg-notif-icon mdg-icon-padding" onClick={(e) => handeClick(e)}>
            
            <small className="float-right mt-1 mr-1">
                {/* <i className="fa fa-bell-o"></i> */}
                <i className="fa fa-shopping-cart" aria-hidden="true"></i>    
                <span className="mdg-badge-notif">{cart.numberCart}</span>
            </small>
        </NavLink>
    )
}

export default CartMenu;