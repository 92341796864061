import React, { useEffect, useState } from 'react';

const HourZulu = () => {
    const [time, setTime] = useState();
  
    const uploadDate = async () => {
      let date = new Date();
      let hour = date.getUTCHours();
      let min = date.getUTCMinutes();
      
      setTime(String(hour).padStart(2, '0')+":"+String(min).padStart(2, '0')+"Z");
    }
  
    useEffect(() => {
      setInterval(uploadDate, 1000);
    })
    return (
        <>
            <small className="mdg-nav-span">{time}</small>
        </>
    );
};

export default HourZulu;