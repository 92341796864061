import { NavLink } from "react-router-dom";
import { getImageUrl } from "../../../../../Utils/Common";
import Thumbnail from "../../../../extra/img/thumbnail";
import ItemBenefits from "../../ingame/Details/benefits";

const ItemDetail = (props) => {
    const { action , itemId,  item} = props;
    
    const photo = getImageUrl(item.media);
  
    return (
        <div className="row">
            <div className="col-lg-12">
                <Thumbnail
                    src={photo}
                    alt="item image"
                    className="mdg-image-body-sale"
                ></Thumbnail>
                <div className="row justify-content-md-center">
                    <ul
                        className="nav nav-pills mt-3 text-center"
                        id="pills-tab"
                        role="tablist"
                    >
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={ action == "sell" ? "nav-link mdg-active": "nav-link" }
                                to={`/account/items/${itemId}/marketplace/sell`}
                            >
                                Sell Now
                            </NavLink>
                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={ action == "rent" ? "nav-link mdg-active": "nav-link" }
                                to={`/account/items/${itemId}/marketplace/rent`}
                            >
                                Rent Now
                            </NavLink>
                        </li>
                        <li className="nav-item" role="presentation">
                            <NavLink
                                className={ action == "exchange" ? "nav-link mdg-active": "nav-link" }
                                to={`/account/items/${itemId}/marketplace/exchange`}
                            >
                                Exchange Now
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
             {/*TODO_MILES*/}
            <div className="col-lg-12 mt-5 d-none">
                <div className="">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-12 text-center">
                            <h2 className="mdg-text-white">Benefits</h2>
                        </div>
                       
                        <div className="col-lg-5 ">
                            <ItemBenefits item={item}></ItemBenefits>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemDetail;