import { Navigate, Outlet } from "react-router-dom";
import { getToken } from "./Common";
import LiveMap from "../components/extra/livemap/map";
import Header from "../components/pages/fo/header/Header";
import React from "react";

const PublicRoute = ({ redirectPath = '/' ,isAuthenticated = false ,children }) => {
    if (isAuthenticated) {
      return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <>
        <section className="mdg-body">
            <LiveMap />
            <Header />
            <Outlet/>
        </section>
        </>;
};

export default PublicRoute;
