import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import FeaturesService from "../../../../services/subscription/features";
import Switch from "react-switch";

const FeaturesForm = (props) => {
  const { id } = useParams();
  const actionName = props.action;
  const [name, setName] = useState("");
  const [description, setdescription] = useState("");
  const [isHidden, setisHidden] = useState(false);
  const [isAvailable, setisAvailable] = useState(false);

  const createSubscriptionHandler = async (event) => {
    event.preventDefault();
    const data = new FormData();

    data.append("name", name);
    data.append("hide", isHidden);
    data.append("available", isAvailable);
    data.append("description", description);

    let result;

    if (id) {
      result = await FeaturesService.update(id, data);
    } else {
      result = await FeaturesService.create(data);
    }

    if (result.error) {
      swal(result.response.data.message, {
        icon: "warning",
        buttons: {
          confirm: "OK",
        },
      }).then((value) => {
        event.preventDefault();
      });
    } else {
      swal(result.data.message, {
        icon: "success",
        buttons: {
          confirm: "OK",
        },
      }).then((value) => {
        if (value) {
          event.preventDefault();
        }
      });
    }
  };

  const getFeature = async () => {
    if (id) {
      const result = await FeaturesService.getById(id);

      if (!result.error) {
        const data = result.data.data;

        setName(data.name);
        console.log(data)
        setisAvailable(data.available)
        setisHidden(data.hide)
        setdescription(data.description)
      }
    }
  };

  useEffect(() => {
    getFeature();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{actionName} Feature</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Feature</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">{actionName} Feature</h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/features"
                      type="button"
                      className="btn btn-tool bg-info"
                      
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => createSubscriptionHandler(e)}>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-subscription-name"
                                    type="text"
                                    value={name}
                                    onChange={(event) => {
                                      setName(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-simulator-name">
                                    Name
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-subscription-name"
                                    type="text"
                                    value={description}
                                    onChange={(event) => {
                                      setdescription(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-simulator-name">
                                    Description
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="mdg-d-flex ml-3">
                                <Switch
                                  onColor="#117a8b"
                                  id="pack-vat"
                                  onChange={(checked) => {
                                    setisHidden(checked);
                                  }}
                                  checked={isHidden}
                                />{" "}
                                <label
                                  className="ml-2 mdg-normal-label"
                                  htmlFor="pack-vat"
                                  style={{ color: "#92969a" }}
                                >
                                  Hide
                                </label>
                              </div>
                              <div className="mdg-d-flex ml-3">
                                <Switch
                                  onColor="#117a8b"
                                  id="pack-vat"
                                  onChange={(checked) => {
                                    setisAvailable(checked);
                                  }}
                                  checked={isAvailable}
                                />{" "}
                                <label
                                  className="ml-2 mdg-normal-label"
                                  htmlFor="pack-vat"
                                  style={{ color: "#92969a" }}
                                >
                                  Available
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 text-right mt-2">
                            <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FeaturesForm;
