import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Thumbnail from "../../../../extra/img/thumbnail";
import { useSelector } from "react-redux";
import { getFormatedAmount, getImageUrl } from "../../../../../Utils/Common";
import Language from "../../../../extra/language/language";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import translate from "../../../../extra/language/translate";

const ItemDetail = (props) => {
  const item = props.item;
  const [countryList, setCountryList] = useState([]);
  const [checkCountry, setCheckCountry] = useState(undefined)

  const wallet = useSelector((state) => state.wallet);

  useEffect(() => {
    if (item) {
      try {
        setCountryList(JSON.parse(item.countries))
        setCheckCountry(JSON.parse(item.countries).find(x => x.value === props.user.lastCountry))
        
      } catch (e) {
      }
    }
  }, [item])


  const showCountryList = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: translate("Seller.List_of_country", props.lang),
      html: (
        <>
          <div className="col-lg-12 text-center">
            {countryList.map((elt) => (
              <small className="mdg-text-white ml-2">{elt.label},</small>
            ))}
          </div>
        </>
      ),
      width: countryList.length > 80 ? "95%" : undefined,
      showConfirmButton: true,
      showCloseButton: false,
      showCancelButton: true,
      cancelButtonText: translate("Item_List.Cancel", props.lang),
    });
  };

  return (
    <>
      {item && (
        <div className="row">
          <div className="col-lg-8">
            {item && (
              <Thumbnail
                src={getImageUrl(item.media)}
                alt="item image"
                className="mdg-image-body-sale"
              ></Thumbnail>
            )}
           
            
            <div className="col-lg-12 text-center mt-3">
            {
              !checkCountry  ? 
              
                <div className="">

                  <i class="fa fa-exclamation-triangle mdg-text-red" aria-hidden="true"></i>  
                  <small className="mdg-text-red ml-2"><Language word={"Seller.item_not_available_current_country"}></Language></small>
                </div>:""
            }
            
              <small className="mdg-text-white ml-2">
                <Language word={"Seller.Should_be_localized_on"}></Language> :{" "}
              </small>
              <button
                className="btn mdg-btn-common btn-sm mdg-btn-12 ml-2"
                onClick={(e) => showCountryList()}
              >
                <Language word={"Seller.List_of_country"}></Language>
              </button>
            </div>
          </div>
          <div className="col-lg-4 ">
            <div className="row">
              <div className="col-lg-12">
                {/*TODO_MILES*/}
                <h2  className="d-none">
                  <Language word={"Item_detail.Benefits"}></Language>
                </h2>
                {/*TODO_MILES*/}
                {(false && item) && (
                  <div className="mb-3">
                    {item.conditionXMilesFixedBonus && (
                      <div>
                        <img
                          src="/assets/image/miles-coin.png"
                          width="30"
                          height="30"
                          className="rounded-circle-sm mr-1"
                          alt=""
                        />
                        <small className="mdg-text-white">
                          {item.xMilesFixedBonus}{" "}
                          <Language
                            word={"Item_detail.miles_fixed_bonus"}
                          ></Language>
                        </small>
                      </div>
                    )}
                    {item.conditionXMilesPerDayForXDays && (
                      <div>
                        <img
                          src="/assets/image/miles-coin.png"
                          width="30"
                          height="30"
                          className="rounded-circle-sm mr-1"
                          alt=""
                        />
                        <small className="mdg-text-white">
                          {item.xMilesPerDayForXDaysMiles} miles{" "}
                          <Language word={"Item_detail.per_day"}></Language>
                          {item.xMilesPerDayForXDaysDays > 0 && (
                            <>
                              {" "}
                              <Language word={"Item_detail.for"}></Language>{" "}
                              {item.xMilesPerDayForXDaysDays}{" "}
                              <Language word={"Item_detail.days"}></Language>
                            </>
                          )}
                          .
                        </small>
                      </div>
                    )}
                    {item.conditionXMilesPerHourOfFlightForXDays && (
                      <div>
                        <img
                          src="/assets/image/miles-coin.png"
                          width="30"
                          height="30"
                          className="rounded-circle-sm mr-1"
                          alt=""
                        />
                        <small className="mdg-text-white">
                          {item.xMilesPerHourOfFlightForXDaysMiles}{" "}
                          <Language
                            word={"Item_detail.miles_per_hour_of_flight"}
                          ></Language>
                          {item.xMilesPerHourOfFlightForXDaysDays > 0 && (
                            <>
                              {" "}
                              <Language word={"Item_detail.for"}></Language>{" "}
                              {item.xMilesPerHourOfFlightForXDaysDays}{" "}
                              <Language word={"Item_detail.days"}></Language>
                            </>
                          )}
                          .
                        </small>
                      </div>
                    )}
                  </div>
                )}
                {!props.hide && (
                  <div className="col-lg-12 ml-1">
                    {!item.canSell ? (

                      <div className="row">
                        <i
                          className="fa fa-times mdg-text-red"
                          aria-hidden="true"
                        ></i>
                        <small className="mdg-text-white  ml-2">
                          <Language
                            word={"Item_detail.Cannot_be_resell"}
                          ></Language>
                        </small>
                      </div>
                    ) : (
                      <div className="row">
                        <i
                          className="fa fa-check mdg-text-green"
                          aria-hidden="true"
                        ></i>
                        {item.afterXDaysSell !== 0 ? (
                          <small className="mdg-text-white  ml-2">
                            <Language
                              word={"Item_detail.Can_be_resell_after"}
                            ></Language>{" "}
                            {item.afterXDaysSell}{" "}
                            <Language word={"Item_detail.day_s"}></Language>
                          </small>
                        ) : (
                          <small className="mdg-text-white  ml-2">
                            <Language
                              word={"Item_detail.Can_be_resell"}
                            ></Language>
                          </small>
                        )}
                      </div>
                    )}
                    {!item.canRent ? (
                      <div className="row">
                        <i
                          className="fa fa-times mdg-text-red"
                          aria-hidden="true"
                        ></i>
                        <small className="mdg-text-white  ml-2">
                          <Language
                            word={"Item_detail.Cannot_be_rented_out"}
                          ></Language>
                        </small>
                      </div>
                    ) : (
                      <div className="row">
                        <i
                          className="fa fa-check mdg-text-green"
                          aria-hidden="true"
                        ></i>
                        {item.afterXDaysRent !== 0 ? (
                          <small className="mdg-text-white  ml-2">
                            <Language
                              word={"Item_detail.Can_be_rented_after"}
                            ></Language>{" "}
                            {item.afterXDaysRent}{" "}
                            <Language word={"Item_detail.day_s"}></Language>
                          </small>
                        ) : (
                          <small className="mdg-text-white  ml-2">
                            <Language
                              word={"Item_detail.Can_be_rented"}
                            ></Language>
                          </small>
                        )}
                      </div>

                    )}
                    {!item.canExchange ? (
                      <div className="row">
                        <i
                          className="fa fa-times mdg-text-red"
                          aria-hidden="true"
                        ></i>
                        <small className="mdg-text-white  ml-2">
                          <Language
                            word={"Item_detail.Cannot_be_exchanged"}
                          ></Language>
                        </small>
                      </div>
                    ) : (
                      <div className="row">
                        <i
                          className="fa fa-check mdg-text-green"
                          aria-hidden="true"
                        ></i>
                        {item.afterXDaysExchange !== 0 ? (
                          <small className="mdg-text-white  ml-2">
                            <Language
                              word={"Item_detail.Can_be_exchanged_after"}
                            ></Language>{" "}
                            {item.afterXDaysExchange}{" "}
                            <Language word={"Item_detail.day_s"}></Language>
                          </small>
                        ) : (
                          <small className="mdg-text-white  ml-2">
                            <Language
                              word={"Item_detail.Can_be_exchanged"}
                            ></Language>
                          </small>
                        )}
                      </div>
                    )}

                    {
                      wallet.fc ? (

                        <div className="row ml-0 mt-2">
                          <div className="row">
                            {wallet.fc >= item.price ? (
                              <>
                                <i className="fas fa-check text-green" aria-hidden="true"></i>
                                <small className="mdg-text-white ml-2">
                                  <Language
                                    word={
                                      "Item_detail.Remaining_FC_to_purchase_this_item_check"
                                    }
                                  ></Language>
                                </small>
                              </>
                            ) : (
                              <>
                                <i className="fas fa-wallet" aria-hidden="true"></i>
                                <small className="mdg-text-white ml-2">
                                  <Language
                                    word={
                                      "Item_detail.Remaining_FC_to_purchase_this_item"
                                    }
                                  ></Language>{" "}
                                  : {getFormatedAmount(item.price - wallet.fc)} FC
                                </small>
                              </>
                            )}

                          </div>
                          <div className="ml-1"></div>
                          <img
                            src="/assets/image/coin.png"
                            width="30"
                            height="30"
                            className="rounded-circle-sm ml-3"
                            alt=""
                          />
                        </div>
                      ) : (
                        <></>
                      )
                    }

                    {(!wallet.fc || (wallet.fc < item.price)) && (
                      <NavLink
                        className="btn mdg-btn-common btn-sm mdg-btn-12 mt-2"
                        to={`/packs`}
                      >
                        <Language word={"Item_detail.Purchase"}></Language> FC
                      </NavLink>
                    )}

                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      )}
    </>
  );
};

export default ItemDetail;
