import DataTable from "react-data-table-component";
import { getAmountTransactionOnHistoriqueDescription, getFormatedDate, getNameItemOnHistoriqueDescription } from "../../../../Utils/Common";
import Language from "../../../extra/language/language";

const CommissionDatatable = (props) => {
  const rowStyle = [
    {
      when: (row) => row.id > 0,
      style: {
        backgroundColor: "transparent",
        color: "#fff",
        userSelect: "none",
      },
    },
  ];

  const columns = [
    {
      name: "Items Name", //<Language word={"Wallet.Description"}></Language>,
      selector: (row) => getNameItemOnHistoriqueDescription(row.description) ,
      sortable: true,
      width: "20%",
    },    
    {
      name: "Initiated by", //<Language word={"Wallet.Date"}></Language>,
      selector: (row) => row.agent.userName,
      sortable: true,
      width: "15%",      
    },   
    {
      name: "Received by", //<Language word={"Wallet.Date"}></Language>,
      selector: (row) => row.userWallet.user.userName,
      sortable: true,
      width: "15%",      
    }, 
    {
      name: "Date of transaction", //<Language word={"Wallet.Date"}></Language>,
      selector: (row) => getFormatedDate(row.createdAt),
      sortable: true,
      width: "15%",      
    },
    {
      name: "Amount of transaction", //<Language word={"Wallet.Amount"}></Language>,
      selector: (row) =>
        row.walletType === "MFC"
          ? getAmountTransactionOnHistoriqueDescription(row.description) + " FC"
          : getAmountTransactionOnHistoriqueDescription(row.description) + " " + row.walletType,
      sortable: true,
      right: true,
      width: "15%", 
    },
    {
      name: "Amount of commission", //<Language word={"Wallet.Type"}></Language>,
      selector: (row) => row.transactionType,
      sortable: true,
      right: true,
      width: "15%", 
    },
  ];
  return (
    <>
      <DataTable
        className="mdg-card-profil-flight"
        style={{ background: "transparent", color: "#fff" }}
        // title="History"
        columns={columns}
        data={props.data}
        defaultSortFieldID={1}
        progressPending={props.progressPending}
        paginationDefaultPage={props.paginationDefaultPage}
        paginationServer
        paginationTotalRows={props.paginationTotalRows}
        pagination
        selectableRows
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        conditionalRowStyles={rowStyle}
      />
    </>
  );
};

export default CommissionDatatable;
