import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshWalletAsync } from "../../../../asyncActions/walletAsyncActions";
import { NavLink } from "react-router-dom";
import { getFormatedAmount } from "../../../../Utils/Common";
import Notification from "./Notification";
import RightMenu from "./Right";
import HourZulu from "../../../extra/hour/HourZulu";
import NotificationService from "../../../../services/notification";
import Language from "../../../extra/language/language";
import { setLanguage } from "../../../../actions/language";
import Panier from "../checkout/panier/Panier";
import CartMenu from "../checkout/panier/CartMenu";


const ConnectedNav = ({ user }) => {
    const wallet = useSelector((state) => state.wallet);
    const lang = useSelector((state) => state.language);
    const dispatch = useDispatch();
    const [expandedNotif, setExpandedNotif] = useState(false);

    const translation = (language) => {
        dispatch(setLanguage(language));
        setExpandedNotif((expandedNotif) => !expandedNotif);
    }

    const [notificationList, setNotificationList] = useState([])

    const getUserWallet = async () => {
        if (!wallet.fc || !wallet.miles) {
            dispatch(refreshWalletAsync());
        }
    };

    const getUserNotifications = async () => {
        await NotificationService.getUserNotifications().then((res) => {
            setNotificationList(res.data)
        })
    }

    useEffect(() => {
        getUserWallet();
        getUserNotifications()
    }, []);


    return (
        <>

            <span className="mt-2 mr-3 ml-3">
                <HourZulu />
            </span>
            {user.isAdmin && (
                <span className="mt-2 mr-3">
                    <NavLink to="/admin/dashboard" className="mdg-a-navig">
                        <i className="fa fa-bar-chart mr-1"></i>
                        <small><Language word={"Header.Dashboard"}></Language></small>
                    </NavLink>
                </span>
            )}

            <span className="mt-2 mr-3">
                <img
                    src="/assets/image/coin.png"
                    width="30"
                    height="30"
                    alt=""
                    className="rounded-circle-sm mr-1"
                />
                <small className="mdg-nav-span">
                    {getFormatedAmount(wallet.fc)} FC
                </small>
                <NavLink to="/packs">
                    <img
                        src="/assets/image/icon-plus.png"
                        width="30"
                        alt=""
                        height="30"
                        className="rounded-card-sm ml-1"
                    />
                </NavLink>
            </span>
            {/*TODO_MILES*/}
            <span className="mt-2 d-none">
                <img
                    src="/assets/image/miles-coin.png"
                    width="30"
                    alt=""
                    height="30"
                    className="rounded-circle-sm mr-1"
                />
                <small className="mdg-nav-span">
                    {getFormatedAmount(wallet.miles)} miles
                </small>
            </span>
            <CartMenu />

            <Notification user={user} notificationList={notificationList} getUserNotifications={getUserNotifications}></Notification>

            <span className="mr-2">
                <li
                    className={
                        expandedNotif
                            ? "nav-item dropdown show mdg-notif ml-2"
                            : "nav-item dropdown mdg-notif ml-2"
                    }
                >
                    <NavLink
                        className="nav-link dropdown-toggle"
                        to="#"
                        onClick={(e) => {e.preventDefault();setExpandedNotif((expandedNotif) => !expandedNotif)}}
                        aria-expanded={expandedNotif}
                    >
                        <small className="mdg-use-language">
                            <img
                                src={lang === 'en' ? "/assets/image/united-states.png" : "/assets/image/france.png"}
                                alt="logo language"
                                className="mdg-flag"
                            />
                        </small>
                    </NavLink>

                    <div
                        className={expandedNotif ? "dropdown-menu show" : "dropdown-menu"}
                        style={{ backgroundColor: "#081217", color: "#fff" }}
                    >

                        <NavLink
                            className="dropdown-item"
                            to="#"
                            style={{ color: "#fff" }}
                            onClick={((e) => {e.preventDefault(); translation('en')})}
                        >
                            <small className="mdg-use-language">
                                <img
                                    src={"/assets/image/united-states.png"}
                                    alt="logo language"
                                    className="mdg-flag"
                                /><span> English</span>
                            </small>
                        </NavLink>
                        <NavLink
                            className="dropdown-item"
                            to="#"
                            style={{ color: "#fff" }}
                            onClick={((e) => {e.preventDefault(); translation('fr')})}
                        >
                            <small className="mdg-use-language">
                                <img
                                    src={"/assets/image/france.png"}
                                    alt="logo language"
                                    className="mdg-flag"
                                /><span> Français</span>
                            </small>
                        </NavLink>
                    </div>
                </li>
            </span>
            <div style={{ borderRight: '1px solid #fff', marginRight: '10px', marginTop: '5px', marginBottom: '5px' }} />
            <RightMenu user={user}></RightMenu>
        </>
    )
}

export default ConnectedNav;