import React, { useEffect, useState } from "react";
import Select from "react-select";
import AwardConfigService from "../../../services/award/awardConfigService";
import {
  containsObject,
  findInList,
  selectStyles,
} from "../../../Utils/Common";
import AsyncSelect from "react-select/async";
import LocationService from "../../../services/base/location";
import NumberFormat from "react-number-format";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import { TimePicker } from "antd";
import moment from "moment";
import CountryService from "../../../services/base/country";

const AwardConfigForm = (props) => {
  const elt = props.i;
  const conditionKey = props.i + 1;
  const arrayConditionLength = props.arrayConditionLength;
  const condition = props.condition;

  const [conditionType, setConditionType] = useState(condition[0]);
  const [dataConfig, setDataConfig] = useState(condition[1]);
  const [awardConditionTypeList, setAwardConditionTypeList] = useState([]);

  const [airport, setAirport] = useState([]);
  const [continent, setContinent] = useState(null);
  const [allLocations, setAllLocations] = useState([]);
  const [flightDuration, setFlightDuration] = useState(0);
  const [aircraftType, setAircraftType] = useState('');
  const [careerLevel, setCareerLevel] = useState('');
  const [flightCount, setFlightCount] = useState(0);
  const [flightDistance, setFlightDistance] = useState(0);
  const [milesDetention, setMilesDetention] = useState(0);
  const [xpDetention, setXPDetention] = useState(0);
  const [flightDurationRange, setFlightDurationRange] = useState(undefined);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryListWithoutAll, setCountryListWithoutALL] = useState([]);

  const continentList = [
    { value: "AF", label: "Africa" },
    { value: "AN", label: "Antarctica" },
    { value: "AS", label: "Asia" },
    { value: "EU", label: "Europe" },
    { value: "NA", label: "North america" },
    { value: "OC", label: "Oceania" },
    { value: "SA", label: "South america" },
  ];

  const getAwardConditionTypeList = async () => {
    await AwardConfigService.getAwardConfigTypeAsKeyValueService().then(
      (res) => {
        setAwardConditionTypeList(res);
      }
    );
  };

  const getCountryList = async () => {
    let countries_list_with_all = await CountryService.getCountryListService(
      "all"
    );
    setCountryList(countries_list_with_all);
  };

  const getInitData = () => {
    try {
      switch (conditionType) {
        case "AIRPORT":
          if (containsObject(JSON.parse(dataConfig)[0], allLocations)) {
            setAirport(JSON.parse(dataConfig));
          }
          break;
        case "DESTINATION_COUNTRY":
          if (containsObject(JSON.parse(dataConfig)[0], countryList)) {
            setCountry(JSON.parse(dataConfig));
          }
          break;
        case "FLIGHT_DURATION":
          if (Number(dataConfig)) {
            setFlightDuration(dataConfig);
          }
          break;
        case "FLIGHT_DISTANCE":
          if (Number(dataConfig)) {
            setFlightDistance(dataConfig);
          }
          break;
        case "XP_DETENTION":
          if (Number(dataConfig)) {
            setXPDetention(dataConfig);
          }
          break;
        case "MILES_DETENTION":
          if (Number(dataConfig)) {
            setMilesDetention(dataConfig);
          }
          break;
        case "FLIGHT_COUNT":
          if (Number(dataConfig)) {
            setFlightCount(dataConfig);
          }
          break;
        case "AIRCRAFT_TYPE":
          if(!Array.isArray(JSON.parse(dataConfig))){
            setAircraftType(dataConfig);
          }  
          break;
        case "CAREER_LEVEL":
            if(!Array.isArray(JSON.parse(dataConfig))){
                setCareerLevel(dataConfig);
            }            
          break;
        case "FLIGHT_DURATION_RANGE":
          if (moment(JSON.parse(dataConfig)[0])) {
            setFlightDurationRange([
              moment(JSON.parse(dataConfig)[0]),
              moment(JSON.parse(dataConfig)[1]),
            ]);
          }
          break;
        case "CONTINENT":
          if (findInList(continentList, dataConfig)) {
            setContinent(dataConfig);
          }
          break;
        default:
          break;
      }
    } catch (e){}
  };

  const getAllFeatures = async () => {
    const locations = await LocationService.getLocationsAsKeyValueService();
    setAllLocations(locations);
  };

  const filterAirport = (inputValue) => {
    return allLocations.filter((option) =>
    (
      `${option.value} ${option.label} ${option.municipality} ${option.iso_region} ${option.ident} ${option.gps_code} ${option.continent} ${option.type}`
      ).toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadAirportListOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterAirport(inputValue));
    }, 1000);
  };

  const handleAirportInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");

    return inputValue;
  };

  useEffect(() => {
    getAllFeatures();
    getCountryList();
    getAwardConditionTypeList();
  }, []);

  useEffect(() => {
    setConditionType(condition[0]);
    setDataConfig(condition[1]);
    getInitData();
  }, [condition, conditionType, allLocations, countryList]);

  return (
    <div className="row">
      <div className="col-lg-5">
        {awardConditionTypeList && (
          <div className="mdg-textfield-out-lined">
            <label htmlFor="input-data" className="mdg-normal-label">
              Condition type
            </label>
            <Select
              placeholder="Condition Type *"
              options={awardConditionTypeList}
              styles={selectStyles}
              isClearable={true}
              style={{ color: "#fff" }}
              onChange={(newValue) => {
                if (newValue != null) {
                  setConditionType(newValue.value);
                  props.setCondition(elt, newValue.value);
                } else {
                  setConditionType(null);
                }
              }}
              value={findInList(awardConditionTypeList, conditionType)}
              required
            />
          </div>
        )}
      </div>
      {conditionType === "AIRPORT" && allLocations.length > 0 && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Airport name</label>
            <AsyncSelect
              styles={selectStyles}
              placeholder="Choose airports"
              isClearable={true}
              isMulti
              required
              closeMenuOnSelect={false}
              onChange={(newValue) => {
                if (newValue) {
                  newValue && setAirport(newValue);
                  props.setAward(elt, newValue);
                }
              }}
              value={airport}
              cacheOptions
              loadOptions={loadAirportListOptions}
              defaultOptions
              onInputChange={handleAirportInputChange}
            />
          </div>
        </div>
      )}

      {conditionType === "FLIGHT_DURATION" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Flight duration</label>
            <NumberFormat
              suffix={" Hours"}
              decimalSeparator=","
              style={{ color: "#fff" }}
              className={`text-right`}
              value={flightDuration}
              required
              onValueChange={(values) => {
                setFlightDuration(values.floatValue);
                props.setAward(elt, values.floatValue);
              }}
            />
          </div>
        </div>
      )}
      {conditionType === "XP_DETENTION" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">XP detention</label>
            <NumberFormat
              suffix={" XP"}
              decimalSeparator=","
              style={{ color: "#fff" }}
              className={`text-right`}
              value={xpDetention}
              required
              onValueChange={(values) => {
                setXPDetention(values.floatValue);
                props.setAward(elt, values.floatValue);
              }}
            />
          </div>
        </div>
      )}
      {conditionType === "MILES_DETENTION" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Miles detention</label>
            <NumberFormat
              suffix={" Miles"}
              decimalSeparator=","
              style={{ color: "#fff" }}
              className={`text-right`}
              value={milesDetention}
              required
              onValueChange={(values) => {
                setMilesDetention(values.floatValue);
                props.setAward(elt, values.floatValue);
              }}
            />
          </div>
        </div>
      )}

      {conditionType === "AIRCRAFT_TYPE" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Aircraft type</label>
            <input
              id="input-aircraft-type-title"
              type="text"
              placeholder=""
              value={aircraftType}
              onChange={(event) => {
                setAircraftType(event.target.value);
                props.setAward(elt, event.target.value);
              }}
              required
              style={{ color: "#fff" }}
              className="form-control"
            />
          </div>
        </div>
      )}

      {conditionType === "CAREER_LEVEL" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Career level</label>
            <input
              id="input-career-level-title"
              type="text"
              placeholder=""
              value={careerLevel}
              onChange={(event) => {
                setCareerLevel(event.target.value);
                props.setAward(elt, event.target.value);
              }}
              required
              style={{ color: "#fff" }}
              className="form-control"
            />
          </div>
        </div>
      )}

      {conditionType === "FLIGHT_COUNT" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Flight count</label>
            <NumberFormat
              suffix={" Flight(s)"}
              decimalSeparator=","
              style={{ color: "#fff" }}
              className={`text-right`}
              value={flightCount}
              required
              onValueChange={(values) => {
                setFlightCount(values.floatValue);
                props.setAward(elt, values.floatValue);
              }}
            />
          </div>
        </div>
      )}

      {conditionType === "FLIGHT_DISTANCE" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Flight distance</label>
            <NumberFormat
              suffix={" NM"}
              decimalSeparator=","
              style={{ color: "#fff" }}
              className={`text-right`}
              value={flightDistance}
              required
              onValueChange={(values) => {
                setFlightDistance(values.floatValue);
                props.setAward(elt, values.floatValue);
              }}
            />
          </div>
        </div>
      )}
      {conditionType === "CONTINENT" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Continent</label>
            <Select
              placeholder="Select a continent *"
              options={continentList}
              styles={selectStyles}
              isClearable={true}
              style={{ color: "#fff" }}
              onChange={(newValue) => {
                if (newValue != null) {
                  setContinent(newValue.value);
                  props.setAward(elt, newValue.value);
                } else {
                  setContinent(null);
                }
              }}
              value={findInList(continentList, continent)}
              required
            />
          </div>
        </div>
      )}

      {conditionType === "DESTINATION_COUNTRY" && (
        <div className="col-lg-5">
          <div className="mdg-textfield-out-lined">
            <label className="mdg-normal-label">Continent</label>
            <Select
              placeholder="Country"
              styles={selectStyles}
              closeMenuOnSelect={false}
              isMulti
              required
              isClearable={true}
              onChange={(newValue) => {
                if (newValue) {
                  newValue && setCountry(newValue);
                  props.setAward(elt, newValue);
                }
              }}
              options={countryList}
              value={country}
            />
          </div>
        </div>
      )}
      {conditionType === "FLIGHT_DURATION_RANGE" && (
        <div className="col-lg-5">
          <label className="mdg-normal-label mt-1">Flight duration range</label>
          {/* <TimeRangePicker
                        onChange={(val) => {
                            setFlightDurationRange(val);
                            props.setAward(elt, val)
                        }}
                        value={flightDurationRange}
                        required
                    /> */}
          <TimePicker.RangePicker
            value={flightDurationRange}
            required
            onChange={(val) => {
              setFlightDurationRange(val);
              props.setAward(elt, val);
            }}
          />
        </div>
      )}

      <div className="col-lg-2 pt-4 d-flex pb-1">
        <button
          type="button"
          className="btn btn-danger mt-2"
          onClick={(e) => props.deleteCondition(elt)}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
        {arrayConditionLength === conditionKey && (
          <button
            type="button"
            className="btn btn-warning ml-1 mt-2"
            onClick={(e) => props.appendCondition()}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default AwardConfigForm;
