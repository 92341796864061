import axios  from "../AxiosInstance"

const getAll = async () => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight-incident`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};


const getById = async (id) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight-incident/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight-incident`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight-incident/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id,data) => {
  try {
      return await axios({
          method: "put",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/flight-incident/${id}`,
          data: data
      }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const FlightIncidentService = {
    getAll,
    getById,
    deleteById,
    update,
    create
}

export default FlightIncidentService;