import axios  from "./AxiosInstance"

export const getUserNotifications = async () => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notification/`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const readNotificationService = async (id) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/notification/read/${id}`,
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const NotificationService = {
  getUserNotifications,
  readNotificationService,
};

export default NotificationService;
