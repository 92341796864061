import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SellerModeService from "../../../../../services/sellerMode";
import { getImageUrl } from "../../../../../Utils/Common";
import Language from "../../../../extra/language/language";

import "./style.css"

const BidProposal2 = ({ itemId }) => {
    const [proposals, setProposals] = useState([]);
    const [loading, setLoading] = useState(true);
    const getProposals = async () => {
        const result = await SellerModeService.getProposalByItemId(itemId);
        if (!result.error) {
            setProposals(result.data)
        }
        setLoading(false);
    }

    const refuseProposal = async (id) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure that you want to decline this offer?',
            icon: "question",
            confirmButtonText: "Ok",
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
                const data = new FormData();
                data.append("proposalStatus", "DENIED");
                const res = await SellerModeService.updateProposalStatus(id, data);
                if (res.error) {
                    Swal.fire({
                        title: res.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: res.data.message,
                        icon: res.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        getProposals();
                    });
                }
            }
        });

    }

    const validProposal = async (id) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure that you want to accept this offer ?',
            icon: "question",
            confirmButtonText: "Ok",
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
                const data = new FormData();
                data.append("proposalStatus", "ACCEPTED");
                const res = await SellerModeService.updateProposalStatus(id, data);
                if (res.error) {
                    Swal.fire({
                        title: res.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: res.data.message,
                        icon: res.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        getProposals();
                    });
                }
            }
        });
    }

    const closeBid = async (e) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Are you sure that you want to close this bid ?',
            icon: "question",
            confirmButtonText: "Ok",
            showCancelButton: true
        }).then(async (res) => {
            if (res.isConfirmed) {
               
                const res = await SellerModeService.closeBid(itemId);
                if (res.error) {
                    Swal.fire({
                        title: res.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                    });
                } else {
                    Swal.fire({
                        title: res.data.message,
                        icon: res.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        getProposals();
                    });
                }
            }
        });
    }

    useEffect(() => {
        getProposals();
    }, []);


    return (
        <>


            {
                loading && (
                    <div className="row justify-content-md-center mt-2" >
                        <div className="col-lg-12">
                            <h3 className="text-center"><Language word={"Seller.loading"}></Language></h3>
                        </div>
                    </div>
                )
            }
            {
                (!loading && proposals.length == 0) ? (
                    <div className="row justify-content-md-center mt-2">
                        <div className="col-lg-12">
                            <h3 className="text-center"><Language word={"Seller.No_proposal_found"}></Language></h3>
                        </div>
                    </div>
                ) : (
                    <>
                    <div className="row justify-content-md-center mt-2">
                        <div className="col-lg-12">
                            <button className="btn mdg-btn-common btn-sm mdg-btn-12" onClick={(e) => { closeBid(itemId)  }}><Language word={"Seller.Close_this_BID"}></Language></button>
                        </div>
                    </div>
                    </>
                )
            }
            {
                !loading && proposals.map((proposal, i) => (
                 
                        <div className="row justify-content-md-center mt-2" key={i}>
                            <div className="col-lg-12">
                                <div className="row mdg-bg-row-proposals p-4">

                                    <div className="col-lg d-inline-flex mdg-border-md">
                                        <div className=" mdg-text-right">
                                            <img
                                                src={getImageUrl(proposal.user.photo)}
                                                className="mdg-upload-img-detail"
                                            ></img>
                                        </div>
                                        <div className=" mt-2 ml-2 text-center">
                                            <h6></h6>
                                            <span> {proposal.user.userName}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg text-center mdg-border-md">
                                        <h6><Language word={"Seller.Amount"}></Language></h6>
                                        <span> {proposal.fcAmount}</span>
                                    </div>

                                    <div className="col-lg text-center mdg-border-md mdg-align-center">
                                        {proposal.status == "PENDING" ? (
                                            <>
                                                <button className="btn  btn-sm btn-danger mr-2" onClick={(e) => { refuseProposal(proposal.id) }}><Language word={"Seller.Decline"}></Language></button>
                                                <button className="btn mdg-btn-common btn-sm mdg-btn-12" onClick={(e) => { validProposal(proposal.id) }}><Language word={"Seller.Accept"}></Language></button>
                                            </>
                                        ) : (<>{proposal.status == "DENIED" ? "DECLINED" : proposal.status}</>)}
                                    </div>
                                </div>
                            </div>
                        </div>

                 

                ))
            }

        </>

    );
}

export default BidProposal2;