import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { getImageUrl, tableRowStyle } from "../../../Utils/Common";
import { NavLink } from "react-router-dom";
import UserService, { sendConfirmationMail } from "../../../services/user";
import Thumbnail from "../../extra/img/thumbnail";
// import Switch from "react-switch";
import { Form, Table, Space, Switch } from "antd";
import swal from "sweetalert";

const UserTable = () => {
  const [initData, setInitData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const getAllUser = async () => {
    setLoading(true);
    const result = await UserService.getList();
    const userData = [];
    for (let i in result.data) {
      let elt = result.data[i];
      userData.push({
        key: i,
        id: elt.id,
        email: elt.email,
        userName: elt.userName,
        detail: {
          lastName: elt.lastName,
          firstName: elt.firstName,
          photo: elt.photo,
        },
        approvedAdmin: elt.approvedAdmin,
        role: elt.role.displayName,
        mfc: elt.userwallet ? Math.round(elt.userwallet.mfc) : 0,
        miles: elt.userwallet ? Math.round(elt.userwallet.miles) : 0,
        xp: elt.userwallet ? Math.round(elt.userwallet.xp) : 0,
      });
    }
    setInitData(userData);
    setData(userData);

    setLoading(false);
  };

  const sendConfirmationMail = async (id) => {
    swal(`Are you sure to send a confirmation mail to this user`, {
      icon: "warning",
      buttons: {
          cancel: "Cancel",
          confirm: "Yes"
      },
  }).then(async (value) => {
    if (value) {
        const result = await UserService.sendConfirmationMail(id);
        console.log(result);
        if (result.error) {
            swal(result.response.data.message, {
                icon: "error"
            })
        } else {
            swal(result.data.message, {
                icon: "success"
            })
        }

        
    }
});

  }

  const columns = [
    {
      title: "",
      dataIndex: "detail",
      width: "20%",
      //   responsive: ["sm"],
      //   responsive: ['lg'],
      sorter: (a, b) => a.detail.lastName.localeCompare(b.detail.lastName),
      render: (row) => (
        <Space size="middle">
          <Thumbnail
            src={
              row.photo
                ? getImageUrl(row.photo)
                : "/assets/img/default-profile-picture.png"
            }
          ></Thumbnail>
          <small>{row.lastName + " " + row.firstName}</small>
        </Space>
      ),
    },
    {
      title: "Username",
      dataIndex: "userName",
      //   width: "10%",
      responsive: ["sm"],
      render: (email) => <small>{email}</small>,
      //   responsive: ['md'],
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      //   responsive: ["lg"],
      width: "20%",
      //   responsive: ["md"],
      render: (email) => <small>{email}</small>,
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Role",
      dataIndex: "role",
      //   width: "8%",
      responsive: ["sm"],
      sorter: (a, b) => a.role.localeCompare(b.role),
      //   responsive: ["md"],
      render: (role) => <small>{role}</small>,
    },
    {
      title: "FC",
      dataIndex: "mfc",
      //   width: "7%",
      responsive: ["sm"],
      render: (mfc) => <small>{mfc}</small>,
      sorter: (a, b) => a.mfc - b.mfc,
    },
    {
      title: "Miles",
      dataIndex: "miles",
      //   width: "7%",
      responsive: ["sm"],
      render: (miles) => <small>{miles}</small>,
      sorter: (a, b) => a.miles - b.miles,
    },
    {
      title: "Status",
      dataIndex: "approvedAdmin",
      //   width: "10%",

      responsive: ["sm"],
      filters: [
        {
          text: "is approved",
          value: true,
        },
        {
          text: "is not approved",
          value: false,
        },
      ],
      onFilter: (value, record) => record.approvedAdmin === value,
      //   responsive: ["sm"],
      render: (approvedAdmin) => (
        <Switch checked={approvedAdmin} disabled onChange={() => {}} />
      ),
    },
    {
      title: "",
      dataIndex: "id",
      width: "15%",
      //   responsive: ["lg"],
      render: (id) =>
        id !== 1 ? (
          <div className="row mr-1">
            <div className="col-lg-4">
              <NavLink
                className="mdg-btn mdg-btn-danger "
                to={"/admin/user/profil/" + id}
              >
                <i className="fa fa-edit " aria-hidden="true"></i>
              </NavLink>
            </div>
            <div className="col-lg-4">
              <NavLink
                className="mdg-btn mdg-btn-danger"
                to={"/admin/user/wallet/credit/" + id}
              >
                <i className="fa fa-level-up " aria-hidden="true"></i>
              </NavLink>
            </div>
            <div className="col-lg-4">
              <NavLink
                className="mdg-btn mdg-btn-danger"
                to={"/admin/user/wallet/debit/" + id}
              >
                <i className="fa fa-level-down " aria-hidden="true"></i>
              </NavLink>
            </div>
            <div className="col-lg-4">
              <button
                className="mdg-btn mdg-btn-danger "
                onClick={() => sendConfirmationMail(id)}
              >
                Confirmation Mail
              </button>
            </div>
          </div>
        ) : (
          ""
        ),
    },
  ];

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
      position: ["none", "bottomRight"], //"topRight"
    //   showQuickJumper:true,
      responsive:true,
      totalBoundaryShowSizeChanger: 2,
      size: 'default'
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  useEffect(() => {
    getAllUser();
  }, []);
  return (
    <>
      <Form
        layout="inline"
        className="components-table-demo-control-bar"
        style={{
          marginBottom: 45,
        }}
      >
        <div className="col md-sm-6 mt-1">
          <div className="mdg-search-place">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search "
              style={{ color: "#fff" }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                const newData = initData.filter((i) =>
                (`${i.userName} ${i.email} ${i.lastName} ${i.firstName} ${i.role}`).toLowerCase().includes(e.target.value.toLowerCase())
              );
                setData(newData)
              }}
              className="mdg-search-input"
            />
            <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
          </div>
        </div>
      </Form>

      <Table
        columns={columns}
        dataSource={data}
        tableLayout={"fixed"}
        loading={loading}
        size="small"
        sticky
        
        // scroll={{ y: 250 }}
        pagination={tableParams.pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default UserTable;
