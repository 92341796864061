import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import MissionForm from './MissionForm';
import MissionList from './MissionList';

const MissionComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <MissionForm action="Create"></MissionForm> ;break;
        case "edit": page = <MissionForm action="Edit"></MissionForm> ; break;
        default: page = <MissionList></MissionList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default MissionComponent;