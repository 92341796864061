import React from "react";
import BaseBO from "../templates/bo/BaseBo";
import FlightList from "./FlightList";
import LocationList from "./LocationList";

const LocationComponent = (props) => {
  let page;

  switch (props.page) {
    case "flight":
      page = <FlightList></FlightList>;
      break;
    default:
      page = <LocationList></LocationList>;
      break;
  }

  return (
    <div>
      <BaseBO page={page}></BaseBO>
    </div>
  );
};

export default LocationComponent;
