import React from "react";
import { NavLink } from "react-router-dom";
import StoreProductTable from "./StoreProductTable";

const StoreProductList = () => {
    return (
        <div >
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Store Product</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Store Product</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Store Product</h5>
                                    <div className="card-tools">
                                        <NavLink to="/admin/store-product/new" type="button" className="btn btn-tool bg-info" >
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12 mdg-historique-table">
                                            <StoreProductTable></StoreProductTable>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default StoreProductList;
