import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import CategoryForm from './CategoryForm';

import CategoryList from './CategoryList';

const CategoryComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <CategoryForm action="Create"></CategoryForm> ;break;
        case "edit": page = <CategoryForm action="Edit"></CategoryForm> ; break;
        default: page = <CategoryList></CategoryList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default CategoryComponent;