import React, { useState } from "react";
import { useDispatch ,useSelector} from "react-redux";
import MetaCart from "../../../../actions/cartActions";
import Language from "../../../extra/language/language";

const Coupon = () => {
    const [code , setCode] = useState("");
    const dispatch = useDispatch();
    const cart = useSelector((store) => store.cart)
    

    const applyCode = async () => {
       dispatch(MetaCart.addCouponAsync(code));
    }
    return (
        <div className="mdg-card-payment p-2 mb-3">
            <div className="mdg-row">
                <div className="mdg-col-md-6">
                    <div className="mdg-textfield-out-lined">
                        <input 
                            id="coupon-code"
                            type="text"
                            placeholder=" "
                            style={{ color: "#fff" }}
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        <label htmlFor="coupon-code"><Language word={"Checkout.Coupon_code"}></Language></label>
                    </div>
                </div>
                <div className="mdg-col-md-6">
                    <button type="button" className="btn mdg-btn-meta-payment col-lg-12 mt-2" onClick={applyCode} disabled={code.length < 3}>
                        <Language word={"Checkout.Apply_Coupon"}></Language>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Coupon;