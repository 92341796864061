import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import LevelForm from './LevelForm';
import LevelList from './LevelList';

const LevelComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <LevelForm action="Create"></LevelForm> ;break;
        case "edit": page = <LevelForm action="Edit"></LevelForm> ; break;
        default: page = <LevelList></LevelList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default LevelComponent;