import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import CategoryService from "../../../services/Category";
import { findInList, selectStyles } from "../../../Utils/Common";
import swal from "sweetalert";
import SimulatorService from "../../../services/simulator.js";
import DescriptionComponent from "../description/DescriptionComponent";

const SimulatorForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [simulatorName, setSimulatorName] = useState("");


    const getSimulator = async () => {
        if (id) {
            const result = await SimulatorService.getSimulatorById(id);
            if(!result.error){
                const data = result.data;
                setSimulatorName(data.simulatorName);
            }
        }
    };

    useEffect(() => {
        getSimulator();
    }, []);

    const createSimulatorHandler = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append('simulatorName', simulatorName);

        let result;

        if (id) {
            result = await SimulatorService.updateSimulatorService(id, data);
        } else {
            result = await SimulatorService.createSimulatorService(data);
        }


        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Simulator</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Simulator</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName} Simulator</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/category/" type="button" className="btn btn-tool bg-info" data-card-widget="collapse">
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createSimulatorHandler(e)}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-simulator-name"
                                                                type="text"
                                                                placeholder=" "
                                                                value={simulatorName}
                                                                onChange={(event) => {
                                                                    setSimulatorName(event.target.value);
                                                                }}
                                                                required
                                                                style={{ color: "#fff" }}
                                                            />
                                                            <label htmlFor="input-simulator-name">
                                                                Simulator Name
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12 text-right mt-2">
                                                            <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                                                Save
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default SimulatorForm;
