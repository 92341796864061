import React, { useState, useEffect } from "react";
import ItemService from "../../../../../services/Item";
import SellerModeService from "../../../../../services/sellerMode";
import Language from "../../../../extra/language/language";
import RecommendedItem from "../RecommendedItem";
import { useSelector } from "react-redux";

const RecommendationPage = (props) => {
    const [items, setItems] = useState([]);

    const lang = useSelector((state) => state.language);
    const getItems = async () => {
        const result = await SellerModeService.getAllMyRecommendationService(props.item);
       
        if (!result.error) {
            setItems(result.data);
        }
    }

    useEffect(() => {

        getItems();
    }, []);

    return (
        <>
            <div className="row justify-content-end">                
                <div className="col-lg-4">
                    <div className="mdg-search-place">
                        <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Search "
                            style={{ color: "#fff" }}
                            className="mdg-search-input"
                        />
                        <i className="fa fa-search mdg-fa" aria-hidden="true"></i>
                    </div>
                </div>
            </div>

            <div className="mdg-width-card-item mdg-mt-6">
                <div
                    className="tab-pane fade show active"
                    id="lands"
                    role="tabpanel"
                    aria-labelledby="lands-tab"
                >
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="all-flight"
                            role="tabpanel"
                            aria-labelledby="all-flight-tab"
                        >
                            <div className="mdg-width-card-item p-3">
                                {items.length>0?<div className="row">
                                    {items.map((elt, i) => (
                                        <RecommendedItem item={elt} key={i} lang={lang} />
                                    ))}
                                </div>:<p><Language word={"Seller.You_do_not_have_recommended_item"}></Language></p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RecommendationPage;