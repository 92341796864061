import React, { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FlightService from '../../../../services/flight/flight';
import HeaderPage from '../header/HeaderPage';
import DetailFlightV2 from './DetailFlightV2';
import SubscriptionService from '../../../../services/subscription';

const DetailsFlightPages = () => {
    
    const { id } = useParams();
    const [flight, setFlight] = useState(null);
    const [mySubscription, setMySubcription] = useState(null);
    const getFlightById = async () => {
        const result = await FlightService.getFlightById(id);
        setFlight(result.data)
    }

    const getMySubscription = async () => {
        await SubscriptionService.getMySubscription().then((res) => {
          if (res.data.length > 0) {
            setMySubcription(res.data[0]);
          }
        });
    };

    useEffect(()=>{
        getFlightById()
        getMySubscription()
    },[])
    return (
        <div className="mdg-body-ingame">
            <div className="mdg-header-ingame">
                <HeaderPage />
                {
                    flight && (
                        <DetailFlightV2 flight={flight} mySubscription={mySubscription} />
                    )
                }                
            </div>
        </div>
    );
};

export default DetailsFlightPages;