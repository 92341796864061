import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Thumbnail from "../../extra/img/thumbnail";
import swal from 'sweetalert';
import Breadcrumb from "../templates/Breadcrumb";
import PackService from "../../../services/pack";
import DescriptionComponent from "../description/DescriptionComponent";
import { getImageUrl } from "../../../Utils/Common";

const PackList = () => {
    const [list, setList] = useState(null);
    const [initialList, setInitialList] = useState([]);
    const [showConfigSort, setShowConfigSort] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const [orderBy, setOrderBy] = useState('');
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const deletePack = async (row) => {
        swal(`Are you sure to delete the pack : ${row.name}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await PackService.deleteById(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }

                getList()
            }
        });
    }

    const columns = [
        {
            id: 1,
            name: "Media",
            selector: (row) => row.photo,
            sortable: false,
            reorder: false,
            cell: (row) => (
                row.photo &&
                <Thumbnail src={getImageUrl(row.photo)}></Thumbnail>
            )
        },
        {
            id: 2,
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "FC Amount",
            selector: (row) => row.fcAmount,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            name: "Price",
            selector: (row) => row.price,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 5,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/pack/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 6,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deletePack(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const getList = async () => {
        await PackService.getAllPack().then((res) => {
            const lists = [];
            if (!res.error) {
                for (let i = 0; i < res.data.packList.length; ++i) {
                    const rl = res.data.packList[i];
                    lists.push({
                        id: rl.id,
                        photo: rl.photo,
                        name: rl.name,
                        fcAmount: rl.fcAmount,
                        price: rl.price,
                        description: rl.description,
                    });
                }
                setOrderBy(res.data.setting.orderBy);
                setSortBy(res.data.setting.sortBy);
                setInitialList(lists);
                filterList(lists, "");
            }
        });
    };

    const setPacksSortByhandler = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append("sortBy", sortBy);
        data.append("orderBy", orderBy);
    
        let result = await PackService.setPacksSortByService(data);       
    
        if (result.error) {
          swal(result.response.data.message, {
            icon: "warning",
            buttons: {
              confirm: "OK",
            },
          }).then((value) => {
            event.preventDefault();
          });
        } else {
          swal(result.data.message, {
            icon: "success",
            buttons: {
              confirm: "OK",
            },
          }).then((value) => {
            if (value) {
              event.preventDefault();
            }
          });
        }
      };

    useEffect(() => {
        getList();
    }, []);

    const filterList = (list, txt) => {
        let res = list.filter(
            (elt, i) =>
                elt.name.toLowerCase().includes(txt.toLowerCase())
        );
        setList(res)
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Pack</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Pack</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Packs</h5>                                    
                                    <div className="card-tools">
                                        <button className="btn btn-tool" onClick={(e)=>{setShowConfigSort(!showConfigSort)}} >
                                            <i className="fa fa-cogs mdg-text-white"></i>
                                        </button>
                                        <NavLink to="/admin/pack/new" type="button" className="btn btn-tool bg-info ml-2" >
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12">
                                    {showConfigSort && 
                                        <div className="col-lg-12">
                                            <div className="row justify-content-md-center">
                                                <div className="row mr-4">
                                                    <label className="mdg-text-blue"> Sort by :</label>
                                                    <div className="ml-3" onChange={(e)=>{
                                                        setSortBy(e.target.value);
                                                    }}>
                                                        <input type="radio" value="name" name="sortBy" checked={sortBy === "name"} />
                                                        <label className="mdg-text-white ml-2"> Name</label>
                                                        <br/>
                                                        <input type="radio" value="price" name="sortBy" checked={sortBy === "price"} />
                                                        <label className="mdg-text-white ml-2"> Price</label>
                                                    </div>
                                                </div>
                                                <div className = "vertical ml-4 mr-4"></div>
                                                <div className="row ml-4 mr-4">
                                                    <label className="mdg-text-blue"> Order by :</label>
                                                    <div className="ml-3" onChange={(e)=>{
                                                        setOrderBy(e.target.value);
                                                    }}>
                                                        <input type="radio" value="ASC" name="orderBy" checked={orderBy === "ASC"} />
                                                        <label className="mdg-text-white ml-2"> Ascending</label>
                                                        <br/>
                                                        <input type="radio" value="DESC" name="orderBy" checked={orderBy === "DESC"} />
                                                        <label className="mdg-text-white ml-2"> Descending</label>
                                                    </div>
                                                </div>
                                                <div className="ml-4 mt-3">
                                                    <button 
                                                        className="btn btn-tool bg-info" 
                                                        onClick={(e)=>{
                                                            setPacksSortByhandler(e);
                                                            getList();
                                                        }} >
                                                            Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>}                                        
                                        <div className="row">
                                            <div className="col-lg-12 mdg-historique-table">
                                                {list && (<DataTable
                                                    columns={columns}
                                                    data={list}
                                                    defaultSortFieldId={1}
                                                    pagination
                                                    selectableRows
                                                    conditionalRowStyles={rowStyle}
                                                />)}
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default PackList;
