import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import AffiliateBonus from './AffiliateBonus';

const AffiliateBonusPages = () => {
    return (
        <div>     
             <BaseBO page={<AffiliateBonus />}></BaseBO>
        </div>
    );
};

export default AffiliateBonusPages;