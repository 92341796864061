import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import ScenarioForm from './ScenarioForm';
import ScenarioList from './ScenarioList';

const ScenarioComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <ScenarioForm action="Create"></ScenarioForm> ;break;
        case "edit": page = <ScenarioForm action="Edit"></ScenarioForm> ; break;
        default: page = <ScenarioList></ScenarioList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default ScenarioComponent;