import React from "react";
import { NavLink } from "react-router-dom";
import Thumbnail from "../../../extra/img/thumbnail";
import Language from "../../../extra/language/language";
import Swal from "sweetalert2";
import QuantityPickerInput from "../../../extra/quantite_picker/QuantityPickerPages";
import translate from "../../../extra/language/translate";
import withReactContent from "sweetalert2-react-content";
import { useState } from "react";
import StoreOrderService from "../../../../services/store/order";
import { useDispatch } from "react-redux";
import { refreshWalletAsync } from "../../../../asyncActions/walletAsyncActions";
import { getImageUrl } from "../../../../Utils/Common";

const StoreList = (props) => {
    const image = props.product.image;
    const id = props.product.id;
    const title = props.product.title;
    const description = props.product.description;
    const fcPrice = props.product.fcPrice;
    const euroPrice = props.product.euroPrice;
    const swalTitle = translate('Ingame_store.Buy', props.lang) + " " + title;
    const payInEuroLabel = translate('Ingame_store.Pay_in_Euros', props.lang);
    let inputValue = 1;

    const buyProductSwal = (id) => {
        const MySwal = withReactContent(Swal);
        let withEuro = false;
        MySwal.fire({
            title: swalTitle,
            html: (
                <>
                    {/* <input
                        id="id-with-euro"
                        className=""
                        type="checkbox"
                        onClick={(e) => {
                            withEuro = e.target.checked;
                        }
                        }
                    />
                    <label className="ml-1 mdg-normal-label" htmlFor="id-with-euro" style={{ color: "#92969a" }}>{payInEuroLabel}</label> */}
                    <QuantityPickerInput
                        className="mt-2"
                        min={inputValue}
                        inputValue={inputValue}
                        onChange={(value) => {
                            inputValue = value;
                        }}
                    ></QuantityPickerInput>
                </>
            ),
            showConfirmButton: true,
            showCloseButton: false,
            showCancelButton: true,
            cancelButtonText: translate('Item_List.Cancel', props.lang),
        }).then(async (res) => {
            if (res.isConfirmed) {
                const data = new FormData();
                // const paymentCurrency = withEuro ? "EUR" : "FC";
                const paymentCurrency = "FC";
                data.append("storeProductId", id);
                data.append("quantity", inputValue);
                data.append("payementCurrency", paymentCurrency);
                const result = await StoreOrderService.createStoreOrderService(data);
                if (result.error) {
                    Swal.fire({
                        title: result.response.data.message,
                        icon: "warning",
                        confirmButtonText: "Ok",
                        showCancelButton: result.response.data.redirect,
                        cancelButtonText: translate('Item_List.Cancel', props.lang),
                    }).then((res) => {

                    });
                } else {
                    props.dispatch(refreshWalletAsync());
                    Swal.fire({
                        title: result.data.message,
                        icon: result.data.error ? "warning" : "success",
                        confirmButtonText: "Ok",
                    });
                }
            }
        });
    }

    return (
        <>
            {
                <div className="col-sm-4 mt-3">
                    <div className="mdg-width-card-body">
                        <div className="mdg-card-body-items">
                            <div>
                                <Thumbnail
                                    src={getImageUrl(image)}
                                    alt="logo"
                                    className="mdg-image-body"
                                ></Thumbnail>
                                <div
                                    className="mdg-body-description mt-2 pl-2"
                                    style={{ position: "relative" }}
                                >
                                    <div className="row justify-content-between">
                                    </div>
                                </div>
                                <div className="mdg-body-footer-description mt-3 pl-2">
                                    <div className="row justify-content-between">
                                        <div className="col-7">
                                            <span>
                                                <img
                                                    src={"/assets/image/metaflight-icon.png"}
                                                    alt="logo"
                                                    className="mdg-img-description mr-1"
                                                />
                                            </span>

                                            <span className="mdg-bg-text-items">
                                                {"Metaflight"}
                                            </span>
                                            <br />
                                            <span className="mdg-bg-text-items mdg-text-marque">
                                                {title}
                                            </span>
                                            <br />
                                            <span className="mdg-bg-text-items">
                                                <small>{description}</small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer mdg-card-footer-items">
                            <span className="mdg-bg-text-items">
                                <Thumbnail
                                    src={"/assets/image/coin.png"}
                                    width="30"
                                    height="30"
                                    className="rounded-circle-sm mr-1"
                                ></Thumbnail>
                                {
                                    fcPrice + " FC"
                                }
                            </span>
                            <span
                                className="btn mdg-btn-common btn-sm float-right"
                                onClick={(e) => {
                                    buyProductSwal(id);
                                }}
                            >
                                <Language word={"Ingame_store.Buy"}></Language>
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>
    );

}

export default StoreList;
