import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import GeneralSettings from './GeneralSettings';

const GeneralSettingsPages = () => {
    return (
        <div>     
             <BaseBO page={<GeneralSettings />}></BaseBO>
        </div>
    );
};

export default GeneralSettingsPages;