import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Thumbnail from "../../extra/img/thumbnail";
import swal from 'sweetalert';
import PackBoosterCarreerLevelService from "../../../services/pack/packBoosterCarreerLevel";
import { findInList, getFullDate, selectStyles } from "../../../Utils/Common";
import Select from "react-select";

const PackBoosterCarreerLevelOrderList = () => {
    const [list, setList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const updateOrderStatus = (status, id, userId) => {
        const data = new FormData();
        data.append('status', status);
        data.append('userId', userId);
        swal(`Are you sure to update the status to : ${status}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await PackBoosterCarreerLevelService.updateOrder(id, data);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }
                getList();
            }

        });

    }
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const columns = [
        {
            id: 1,
            name: "Order n°",
            selector: (row) => <>Order n° {row.id}</>,
            sortable: false,
            reorder: false,
        },
        {
            id: 2,
            name: "User",
            selector: (row) => <>{row.user.email}({row.user.userName})</>,
            sortable: true,
            reorder: true
        },
        {
            id: 3,
            name: "Pack booster",
            selector: (row) => <>{JSON.parse(row.packData).title}</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            name: "Price",
            selector: (row) => <>{JSON.parse(row.packData).price}</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 5,
            name: "Level",
            selector: (row) => row.carreerLevel.level,
            sortable: true,
            center: true,
            reorder: true
        },{
            width: '180px',
            name: "Status",
            selector: (row) => getFullDate(row.createdAt),
            sortable: true,
            cell: (row) => (
                <div className="status">
                    {(row.status == "COMPLETED") ? <div className="d-flex align-items-center"><i className="text-success fa fa-2x fa-check"></i><span className="ml-1">COMPLETED</span></div> : (
                        <Select
                            options={statusList}
                            styles={selectStyles}
                            isClearable={true}
                            style={{ color: "#fff" }}
                            onChange={(newValue, actionMeta) => {
                                updateOrderStatus(newValue.value, row.id, row.userId);
                            }}
                            defaultValue={findInList(statusList, row.status)}
                            required
                        />)
                    }
                </div>
            )
        },
    ];

    const getList = async () => {
        await PackBoosterCarreerLevelService.getAllOrder().then((res) => {
            if (!res.error) {
                setList(res.data)
            }
        });
    };

    const getStatusList = async () => {
        await PackBoosterCarreerLevelService.getPackBoosterOrdersStatusAsKeyValueService().then((res) => {
            setStatusList(res);
        });
    }

    useEffect(() => {
        getStatusList();
        getList();
    }, []);

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Pack booster carreer level order</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Packs booster carreer level order</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Packs booster carreer level order</h5>                                   
                                    
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12">
                                                                        
                                        <div className="row">
                                            <div className="col-lg-12 mdg-historique-table">
                                                {list && (<DataTable
                                                    columns={columns}
                                                    data={list}
                                                    defaultSortFieldId={1}
                                                    pagination
                                                    selectableRows
                                                    conditionalRowStyles={rowStyle}
                                                />)}
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default PackBoosterCarreerLevelOrderList;
