import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import swal from "sweetalert";
import PromocodeService from "../../../services/promocode";

const PromocodeList = () => {
  const [list, setList] = useState(null);
  const [initialList, setInitialList] = useState([]);

  const rowStyle = [
    {
      when: (row) => row.id > 0,
      style: {
        backgroundColor: "transparent",
        color: "#fff",
        userSelect: "none",
      },
    },
  ];

  const deletePromocode = async (row) => {
    swal(`Are you sure to delete the promocode : ${row.code}`, {
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: "Yes",
      },
    }).then(async (value) => {
      if (value) {
        const result = await PromocodeService.deleteById(row.id);
        if (result.error) {
          swal(result.response.data.message, {
            icon: "error",
          });
        } else {
          swal(result.data.message, {
            icon: "success",
          });
        }

        getList();
      }
    });
  };

  const columns = [
    {
      id: 1,
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
    },
    {
      id: 2,
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: "Value",
      selector: (row) => row.codeValue,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      id: 4,
      name: "Type",
      selector: (row) => row.codeType,
      sortable: true,
      center: true,
      reorder: true,
    },
    {
      id: 5,
      button: true,
      left: true,
      cell: (row) => (
        <NavLink
          className="mdg-btn mdg-btn-outline-meta mr-2"
          to={"/admin/promocode/edit/" + row.id}
        >
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
        </NavLink>
      ),
    },
    {
      id: 6,
      button: true,
      left: true,
      cell: (row) => (
        <button
          type="button"
          className="mdg-btn mdg-btn-danger mr-2"
          onClick={async () => deletePromocode(row)}
        >
          <i className="fa fa-trash-o" aria-hidden="true"></i>{" "}
        </button>
      ),
    },
  ];

  const getList = async () => {
    await PromocodeService.getAll().then((res) => {
      const lists = [];
      if (!res.error) {
        for (let i = 0; i < res.data.length; ++i) {
          const elt = res.data[i];
          lists.push({
            id: elt.id,
            code: elt.code,
            codeValue: elt.value,
            codeType: elt.type,
          });
        }
        setInitialList(lists);
        filterList(lists, "");
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  const filterList = (list, txt) => {
    let res = list.filter((elt, i) =>
      elt.code.toLowerCase().includes(txt.toLowerCase())
    );
    setList(res);
  };

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">List of Promocode</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Promocode</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Promocode</h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/promocode/new"
                      type="button"
                      className="btn btn-tool bg-info"
                    
                    >
                      New
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 mdg-historique-table">
                      {list && (
                        <DataTable
                          columns={columns}
                          data={list}
                          defaultSortFieldId={1}
                          pagination
                          selectableRows
                          conditionalRowStyles={rowStyle}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PromocodeList;
