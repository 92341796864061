import { useEffect } from "react";
import { useState } from "react";
import Countdown from "react-countdown";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import ReactTooltip from "react-tooltip"
import { InfoCircleOutlined } from '@ant-design/icons';
import LevelService from "../../../../services/missions/level";
import MissionService from "../../../../services/missions/mission";
import AcarsService from "../../../../services/tracker/tracker";
import Utils from "../../../../services/tracker/Utils"
import { getFormatedAmount, getImageUrl, isAvailableFeature } from "../../../../Utils/Common";
import Language from "../../../extra/language/language"

const DashboardUser = () => {
    const wallet = useSelector ((state) => state.wallet);
    const authObj = useSelector((state) => state.auth);
    const { user } = authObj;
    const [myMissionsList, setMyMissionsList] = useState([]);
    const [checkActive, setCheckActive] = useState("FLIGHT");
    const [levelList, setLevelList] = useState([]);

    const [hour, setHour] = useState();
    const [distance, setDistance] = useState();
    const [flights, setFlights] = useState([]);


    const navigate = useNavigate();

    
   
    const getLevelList = async () => {
        const levelResult = await LevelService .getAllLevelService()
        setLevelList(levelResult.data)

    }

    const getMyMissionsList = async () => {
        const myMissions = await MissionService.getUserMissionsService(user.id);
        setMyMissionsList(myMissions.data);
    }


    const getUserData = async () => {
        const res = await AcarsService.getUserData();
        if (!res.error) {
            const data = res.data.data;

            let flightsData = data.flights;
            flightsData.sort((a, b) => b.id - a.id);
            setFlights(flightsData)
            setHour(user.flightHours)
          
             setDistance(user.distanceTraveledInNm)
        }
    }



    useEffect(() => {
        getLevelList();
        getUserData();
        getMyMissionsList();
    }, []);



    const countDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        let hoursDays = hours;
        if (days) {
            hoursDays = (days * 24) + hours;
        }
        if (completed) {
            return <span className="text-green"><Language word={"User_Dashboard.Finished"}></Language></span>;
        } else {
            return <span>{hoursDays} h {minutes} mn {seconds} s</span>;
        }
    };

    const handleActive = (value) => {
        setCheckActive(value)
    }

    return (
        <div className="row">
            <div className="col-lg-4 d-flex">
                <div className="mdg-card-dahsboard-notice mdg-cursors">
                    <div className="card-body" style={{ position: "relative" }}>
                        <h5 className="card-title mdg-nav-span-user-dashboard" style={{ float: "none" }}>Flight Coin <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-flight-coin" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /></h5>
                        <div className="card-text mdg-nav-span-user-dashboard mdg-nav-span-user-dashboard-text">
                            <div className="mdg-aligns-center">
                                <div style={{ float: 'left', textAlign: 'left', color: '#17A2B8' }}>
                                    {getFormatedAmount(wallet.fc)} FC
                                </div>
                                <img
                                    src="/assets/image/coin.png"
                                    width="30"
                                    height="30"
                                    style={{ float: 'right', textAlign: 'right', width: '55px', height: '55px', position: 'absolute', right: '0' }}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ReactTooltip id="id-flight-coin" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                    <Language word={"info_bulle.dashboard_flight_coin"}></Language>
                </ReactTooltip>
            </div>
            {/*TODO_MILES*/}
            <div className="col-lg-3 d-none">
                <div className="mdg-card-dahsboard-notice mdg-cursors">
                    <div className="card-body" style={{ position: "relative" }}>
                        <h5 className="card-title mdg-nav-span-user-dashboard" style={{ float: "none" }}>Miles <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-miles" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /></h5>
                        <div className="card-text mdg-nav-span-user-dashboard mdg-nav-span-user-dashboard-text">
                            <div className="mdg-aligns-center">
                                <div style={{ float: 'left', textAlign: 'left', color: '#17A2B8' }}>
                                    {getFormatedAmount(wallet.miles)} miles
                                </div>
                                <img
                                    src="/assets/image/miles-coin.png"
                                    width="30"
                                    height="30"
                                    style={{ float: 'right', textAlign: 'right', width: '55px', height: '55px', position: 'absolute', right: '0' }}
                                    className="mdg-incline-image"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ReactTooltip id="id-miles" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                    <Language word={"info_bulle.dashboard_miles"}></Language>
                </ReactTooltip>
            </div>
            <div className="col-lg-4 d-flex">
                <div className="mdg-card-dahsboard-notice mdg-cursors">
                    <div className="card-body" style={{ position: "relative" }}>
                        <h5 className="card-title mdg-nav-span-user-dashboard" style={{ float: "none" }}>Xp  <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-xp" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /></h5>
                        <div className="card-text mdg-nav-span-user-dashboard mdg-nav-span-user-dashboard-text">
                            <div className="mdg-aligns-center">
                                <div className="mr-2" style={{ float: 'left', textAlign: 'left', color: '#17A2B8' }}>
                                    {getFormatedAmount(wallet.xp)} XP
                                </div>
                                <img
                                    src="/assets/image/xp.png"
                                    width="30"
                                    height="30"
                                    style={{ float: 'right', textAlign: 'right', width: '55px', height: '55px', position: 'absolute', right: '0' }}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ReactTooltip id="id-xp" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                    <Language word={"info_bulle.dashboard_xp"}></Language>
                </ReactTooltip>
            </div>
            <div className="col-lg-4 d-flex">
                <div className="mdg-card-dahsboard-notice mdg-cursors">
                    <div className="card-body" style={{ position: "relative" }}>
                        <h5 className="card-title mdg-nav-span-user-dashboard" style={{ float: "none" }}><Language word={"User_Dashboard.Compulsory"}></Language> <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-rest-time" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /><br /><Language word={"User_Dashboard.Rest_time"}></Language></h5>
                        <div className="card-text mdg-nav-span-user-dashboard mdg-nav-span-user-dashboard-text">
                            <div className="mdg-aligns-center">
                                <div className="mr-3" style={{ float: 'left', textAlign: 'left', color: '#17A2B8' }}>
                                    <Countdown date={user.nextRemuneration ? user.nextRemuneration : Date.now()} renderer={countDownRenderer} />
                                </div>
                                <img
                                    src="/assets/image/time-left.png"
                                    width="30"
                                    height="30"
                                    style={{ float: 'right', textAlign: 'right', width: '55px', height: '55px', position: 'absolute', right: '0' }}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ReactTooltip id="id-rest-time" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                    <Language word={"info_bulle.dashboard_rest_time"}></Language>
                </ReactTooltip>
            </div>
            <div className="col-lg-6 mt-4 d-flex">
                <div className="mdg-card-dahsboard-notice">
                    <div className="mdg-menu-filter-dashboard">
                        <div className="btn-group" role="group" aria-label="Basic example" style={{ width: '100%' }}>
                            <button type="button" className={checkActive === "FLIGHT" ? "mdg-btn-fl-mission btn btn-info mdg-btn-12 active" : "mdg-btn-fl-mission btn btn-info mdg-btn-12"} style={{ boxShadow: 'none' }} onClick={(value) => {
                                handleActive("FLIGHT")
                            }}>FLIGHT</button>
                            {isAvailableFeature('mission') && (
                                <button type="button" className={checkActive === "MISSION" ? "mdg-btn-fl-mission btn btn-info mdg-btn-12 active" : "mdg-btn-fl-mission btn btn-info mdg-btn-12"} style={{ boxShadow: 'none' }} onClick={(value) => {
                                handleActive("MISSION")
                                }}>MISSION</button>
                            )}
                            {isAvailableFeature('award') && (
                            <button type="button" className={checkActive === "AWARD" ? "mdg-btn-fl-mission btn btn-info mdg-btn-12 active" : "mdg-btn-fl-mission btn btn-info mdg-btn-12"} style={{ boxShadow: 'none' }} onClick={(value) => {
                                handleActive("AWARD")
                            }}>AWARD</button>
                            )}
                        </div>
                    </div>
                    <div className="text-center">
                        {checkActive === "MISSION" && (
                            <div className="mdg-body-list-filter-mission">
                                <div className="text-center mt-2">
                                    <h3 className="mdg-text-white mb-0"><Language word={"User_Dashboard.YourLastMission"}/></h3>
                                </div>
                                <div className="mdg-height-m">
                                    {
                                        myMissionsList.map((mission, i) => (i < 5) && mission.mission  &&  (
                                            <div className="mdg-list-m mt-3">
                                                <div className="row justify-content-md-center">
                                                    <div className="col-4 list">
                                                        <i className="fa fa-plane mdg-text-meta-color" aria-hidden="true"></i>
                                                        <span className="mdg-text-meta-color ml-2">
                                                            <img
                                                                src={mission.mission.image ? getImageUrl(mission.mission.image) : "/assets/img/avion1.jpg"}
                                                                width="30"
                                                                alt=""
                                                                height="30"
                                                                className="rounded-card-md ml-2"
                                                                style={{ color: "red" }}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="col-4 list">
                                                        <span className="mdg-text-meta-color">
                                                            {mission.mission.title}
                                                        </span>
                                                    </div>
                                                    <div className="col-4 list">
                                                        <span className="mdg-text-meta-color">{mission.mission.fcBonus}
                                                            <img
                                                                src="/assets/image/coin.png"
                                                                width="30"
                                                                alt=""
                                                                height="30"
                                                                className="rounded-card-md ml-2"
                                                                style={{ color: "red" }}
                                                            /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )}
                        {checkActive === "FLIGHT" && (
                            <div className="mdg-body-list-filter-mission">
                                <div className="text-center mt-2">
                                    <h3 className="mdg-text-white mb-0"><Language word={"User_Dashboard.YourLastFlight"}/></h3>
                                </div>
                                <div className="mdg-height-m">
                                    {
                                        flights.map((flight, i) => i < 5 && (
                                            (
                                                flight && (
                                                    <div className="mdg-list-m mt-3">
                                                        <div className="row justify-content-md-center" onClick={() => navigate(`/my-flight-list/detail/${flight.id}`)}>
                                                            <div className="col-4 list">
                                                                <i className="fa fa-plane mdg-text-meta-color" aria-hidden="true"></i>
                                                                <span className="mdg-text-meta-color ml-2">{flight.flightNumber}</span>
                                                            </div>
                                                            <div className="col-4 list">
                                                                <span className="mdg-text-meta-color">{flight.departure_airport_icao} <i className="fa fa-long-arrow-right mdg-text-meta-color" aria-hidden="true"></i> {flight.arrival_airport_icao}</span>
                                                            </div>
                                                            <div className="col-4 list">
                                                                <span className="mdg-text-meta-color">{flight.flightTreateds ? flight.flightTreateds.fcAmount : 0}
                                                                    <img
                                                                        src="/assets/image/coin.png"
                                                                        width="30"
                                                                        alt=""
                                                                        height="30"
                                                                        className="rounded-card-md ml-2"
                                                                        style={{ color: "red" }}
                                                                    /></span>
                                                            </div>
                                                    </div>
                                                    </div>
                                                )
                                            )
                                        ))
                                    }
                                </div>
                            </div>
                        )}
                        {checkActive === "AWARD" && (
                            <div className="mdg-body-list-filter-mission">
                                <div className="text-center mt-2">
                                    <h3 className="mdg-text-white"><Language word={"User_Dashboard.YourLastAward"}/></h3>
                                </div>
                                <div className="mdg-height-m">
                                </div>
                            </div>
                        )}
                        <div className="mdg-pos-relative">
                            <NavLink
                                to="/my-flight-list"
                                style={{}}>
                                <img
                                    src="/assets/image/ic_add_box.png"
                                    alt=""
                                    style={{ position: 'absolute', right: '-5px', bottom: '-5px', width: '30px' }}
                                />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 mt-4 d-flex">
                <div className="row">
                    <div className="col-lg-6 mdg-card-dahsboard-notice">
                        
                        <div className="mdg-ribbon-right">
                            <span><Language word={"Coming soon"}></Language></span>
                        </div>
                        <div className="" style={{ height: '100%', filter: 'blur(1px)' }}>
                            <h4 className="mdg-text-white text-center">Carrer Lev <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-carrer-level" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /></h4>
                            <table className="mdg-ls-carrer-l" style={{ height: '90%', display: 'none' }}>
                                {
                                    levelList.sort((a, b) => b.id - a.id).map((levelElt, i) => (
                                        <tr>
                                            <td className={levelElt === levelList[0] ? `mdg-cl-first mdg-first-column mdg-max-ls l5 ${user.level.id === levelElt.id ? 'check' : ''}` :
                                                levelElt === levelList[levelList.length - 1] ? `mdg-cl-last mdg-first-column mdg-min-ls l2 ${user.level.id === levelElt.id ? 'check' : ''}` :
                                                    `mdg-cl-other mdg-first-column l4 ${user.level.id === levelElt.id ? 'check' : ''}`
                                            }><i className={`fa ${user.level.id === levelElt.id ? 'fa-unlock' : 'fa-lock'}`} aria-hidden="true"></i></td>
                                            <td style={{ border: 'none' }}><i className="fa fa-play mdg-rotate-90 mdg-text-white mr-1" aria-hidden="true"></i> {levelElt.level}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                            <div className="row" style={{ height: '90%' }}>
                                <div className="col-5">
                                    <ul className="andrana">
                                        {
                                            levelList.sort((a, b) => b.id - a.id).map((levelElt, i) => (
                                                <li><i className={`fa ${user.level.id === levelElt.id ? 'fa-unlock' : 'fa-lock'}`} aria-hidden="true"></i></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className="col-7">
                                    <ul className="andrana2">
                                        {
                                            levelList.sort((a, b) => b.id - a.id).map((levelElt, i) => (
                                                <li><i className="fa fa-play mdg-rotate-90 mdg-text-white mr-1" aria-hidden="true"></i> {levelElt.level}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ReactTooltip id="id-carrer-level" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                            <Language word={"info_bulle.dashboard_carrer_level"}></Language>
                        </ReactTooltip>
                    </div>
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mdg-card-dahsboard-notice mdg-cursors">
                                    <div className="card-body" style={{ position: "relative" }}>
                                        <h5 className="card-title mdg-nav-span-user-dashboard" style={{ float: "none" }}><Language word={"User_Dashboard.Number_of_flights"}></Language>  <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-number-flight" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /></h5>
                                        <div className="card-text mdg-nav-span-user-dashboard mdg-nav-span-user-dashboard-text">
                                            <div className="mdg-aligns-center">
                                                <div className="mr-3" style={{ float: 'left', textAlign: 'left', color: '#17A2B8' }}>
                                                    {user.numberOfFlight}
                                                </div>
                                                <img
                                                    src="/assets/image/plane-white.png"
                                                    width="30"
                                                    height="30"
                                                    style={{ float: 'right', textAlign: 'right', width: '55px', height: '55px', position: 'absolute', right: '0' }}
                                                    className="mdg-incline-image"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReactTooltip id="id-number-flight" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                                    <Language word={"info_bulle.dashboard_number_flight"}></Language>
                                </ReactTooltip>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="mdg-card-dahsboard-notice mdg-cursors">
                                    <div className="card-body" style={{ position: "relative" }}>
                                        <h5 className="card-title mdg-nav-span-user-dashboard" style={{ float: "none" }}><Language word={"User_Dashboard.Flight_hours"}></Language>  <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-flight-hour" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /></h5>
                                        <div className="card-text mdg-nav-span-user-dashboard mdg-nav-span-user-dashboard-text">
                                            <div className="mdg-aligns-center">
                                                <div style={{ float: 'left', textAlign: 'left', color: '#17A2B8' }}>
                                                    { Utils.formatHour(hour)}
                                                </div>
                                                <img
                                                    src="/assets/image/clock.png"
                                                    width="30"
                                                    height="30"
                                                    style={{ float: 'right', textAlign: 'right', width: '55px', height: '55px', position: 'absolute', right: '0' }}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReactTooltip id="id-flight-hour" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                                    <Language word={"info_bulle.dashboard_flight_hour"}></Language>
                                </ReactTooltip>
                            </div>
                            <div className="col-lg-12 mt-4">
                                <div className="mdg-card-dahsboard-notice mdg-cursors">
                                    <div className="card-body" style={{ position: "relative" }}>
                                        <h6 className="card-title mdg-nav-span-user-dashboard d-line" style={{ float: "none" }}><Language word={"User_Dashboard.Distances_traveled"}></Language> <InfoCircleOutlined bg-tooltip="bg-tooltip" data-arrow-color="#081217" data-tip data-for="id-distance-traveled" style={{ fontSize: '14px', position: 'absolute', marginTop: '3px', marginLeft: '5px' }} /></h6>
                                        <div className="card-text mdg-nav-span-user-dashboard mdg-nav-span-user-dashboard-text">
                                            <div className="mdg-aligns-center">
                                                <div style={{ float: 'left', textAlign: 'left', color: '#17A2B8' }}>
                                                    {distance ? distance : 0} NM
                                                </div>
                                                <img
                                                    src="/assets/image/distance.png"
                                                    width="30"
                                                    height="30"
                                                    style={{ float: 'right', textAlign: 'right', width: '55px', height: '55px', position: 'absolute', right: '0' }}
                                                    className=" mr-1"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ReactTooltip id="id-distance-traveled" place="top" className="bg-tooltip" arrowColor="#081217" effect="solid">
                                    <Language word={"info_bulle.dashboard_distance_traveled"}></Language>
                                </ReactTooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardUser;