
import React, { useEffect, useRef, useState } from "react";
import ItemService from "../../../services/Item";
import { findInList, getImageUrl, selectStyles } from "../../../Utils/Common";
import Select from "react-select";
import { getCategoryListAsKeyValueService } from "../../../services/Category";
import { NavLink, useParams } from "react-router-dom";

import swal from "sweetalert";
import { getCurrencyListAsKeyValueService } from "../../../services/base/currency";
import { getItemTypesListAsKeyValueService } from "../../../services/ItemTypes";
import CountryService from "../../../services/base/country";

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import DescriptionComponent from "../description/DescriptionComponent";
mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;// eslint-disable-line import/no-webpack-loader-syntax
const ItemForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;

    const [categoryList, setCategoryList] = useState([]);
    const [itemTypesList, setItemTypesList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");

    const [itemName, setItemName] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [itemImage, setItemImage] = useState("");
    const [category, setCategory] = useState("");
    const [itemTypes, setItemTypes] = useState(null);

    /* days for sell exchange rent */
    const [afterXDaysSell, setAfterXDaysSell] = useState(0);
    const [afterXDaysExchange, setAfterXDaysExchange] = useState(0);
    const [afterXDaysRent, setAfterXDaysRent] = useState(0);
    /* days for sell exchange rent */

    const [isCheckedSell, setIsCheckedSell] = useState(false);
    const [isCheckedRent, setIsCheckedRent] = useState(false);
    const [isCheckedExchange, setIsCheckedExchange] = useState(false);
    const [isCheckedStatus, setIsCheckedStatus] = useState(false);

    const [isCheckedMilesPerDay, setIsCheckedMilesPerDay] = useState(false);
    const [isCheckedMilesPerHour, setIsCheckedMilesPerHour] = useState(false);
    const [isCheckedFixedBonusMiles, setIsCheckedFixedBonusMiles] = useState(false);

    const [itemPrice, setItemPrice] = useState("");

    const [itemCurrency, setItemCurrency] = useState(null);

    const [countryList, setCountryList] = useState([]);
    const [countryListWithoutAll, setCountryListWithoutALL] = useState([]);
    const [itemCountry, setCountry] = useState([]);

    const [itemQuantity, setItemQuantity] = useState(1);
    const [itemMilesPerDay_Miles, setItemMilesPerDay_Miles] = useState("");
    const [itemMilesPerDay_Day, setItemMilesPerDay_Day] = useState("");
    const [itemMilesPerHour_Miles, setItemMilesPerHour_Miles] = useState("");
    const [itemMilesPerHour_Day, setItemMilesPerHour_Day] = useState("");
    const [itemFixedBonusMiles, setItemFixedBonusMiles] = useState("");

    /* --------map-------- */
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [itemLongitude, setItemLongitude] = useState(2.8686105717949317);
    const [itemLatitude, setItemLatitude] = useState(46.76964055820548);

    const [zoom, setZoom] = useState(5);


    /* --------map-------- */

    const getCategoryList = async () => {
        await getCategoryListAsKeyValueService().then((res) => {
            setCategoryList(res);
        });
    };

    const getItemTypesList = async () => {
        await getItemTypesListAsKeyValueService().then((res) => {
            setItemTypesList(res);
        });
    }

    const getCurrenciesList = async () => {
        await getCurrencyListAsKeyValueService().then((res) => {
            setCurrencyList(res);
        });
    };

    const getCountryList = async () => {
        let countries_list_with_all = await CountryService.getCountryListService("all");
        setCountryList(countries_list_with_all);
    };

    const getCountryListWithoutALL = async () => {
        let countries_list_without_all = await CountryService.getCountryListService();
        setCountryListWithoutALL(countries_list_without_all);
    };

    const createItemHandler = async (event) => {
        event.preventDefault();

        const data = new FormData();
        data.append("file", itemImage);
        data.append("itemName", itemName);
        data.append("itemDescription", itemDescription);
        data.append("category", category);
        data.append("itemTypes", itemTypes);
        data.append("sell", isCheckedSell);
        data.append("exchange", isCheckedExchange);
        data.append("rent", isCheckedRent);
        data.append("status", isCheckedStatus);
        data.append("itemPrice", itemPrice);
        data.append("itemCurrency", itemCurrency);
        data.append("itemLatitude", itemLatitude);
        data.append("itemLongitude", itemLongitude);
        data.append("itemCountry", JSON.stringify(itemCountry));
        data.append("itemQuantity", itemQuantity);

        data.append("itemMilesPerDayCheck", isCheckedMilesPerDay);
        data.append("itemMilesPerDay_Miles", itemMilesPerDay_Miles);
        data.append("itemMilesPerDay_Day", itemMilesPerDay_Day);

        data.append("itemMilesPerHourCheck", isCheckedMilesPerHour);
        data.append("itemMilesPerHour_Miles", itemMilesPerHour_Miles);
        data.append("itemMilesPerHour_Day", itemMilesPerHour_Day);

        data.append("itemFixedBonusMilesCheck", isCheckedFixedBonusMiles);
        data.append("itemFixedBonusMiles", itemFixedBonusMiles);

        /* days echeance sell, exchange ,rent */
        data.append("afterXDaysExchange", afterXDaysExchange);
        data.append("afterXDaysRent", afterXDaysRent);
        data.append("afterXDaysSell", afterXDaysSell);
        /* */

        let result;
        if (id) {
            result = await ItemService.updateItemService(data, id);
        } else {
            result = await ItemService.createItemService(data);
        }

        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {

                }
            });
        }
    };



    const handleChangeImage = () => {
        const pics = document.getElementById("id-item-pics");
        pics.click();
    };

    const setLngLat = () => {
        initMap(itemLongitude, itemLatitude);
    }

    function initMap(lng, lat) {

        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [lng, lat],
            zoom: zoom
        });

        let marker = new mapboxgl.Marker({
            draggable: true
        })
            .setLngLat([lng, lat])
            .setPopup(new mapboxgl.Popup({
                offset: 25
            }) // add popups
                .setHTML('<div class="mdg-marker-popup-content mdg-custom">Votre emplacement</div>'))
            .addTo(map.current);

        function onDragEnd() {
            let lngLat = marker.getLngLat();
            setItemLongitude(lngLat.lng);
            setItemLatitude(lngLat.lat);
        }

        marker.on('dragend', onDragEnd);
    }

    const getItem = async () => {
        if (id) {
            const result = await ItemService.getItemById(id);
            if (!result.error) {
                const data = result.data;

                setItemDescription(data.description);
                setItemName(data.name);
                setIsCheckedStatus(data.status);
                setItemTypes(data.itemTypeId);
                setCategory(data.categoryId);
                setItemPrice(data.price);
                setItemCurrency(data.currencyId);
                setPhotoUrl(getImageUrl(data.media)  );
                setAfterXDaysSell(data.afterXDaysSell);
                setAfterXDaysRent(data.afterXDaysRent);
                setAfterXDaysExchange(data.afterXDaysExchange);

                setItemQuantity(data.stock);

                setIsCheckedMilesPerDay(data.conditionXMilesPerDayForXDays);
                setItemMilesPerDay_Miles(data.xMilesPerDayForXDaysMiles);
                setItemMilesPerDay_Day(data.xMilesPerDayForXDaysDays);

                setIsCheckedMilesPerHour(data.conditionXMilesPerHourOfFlightForXDays);
                setItemMilesPerHour_Day(data.xMilesPerHourOfFlightForXDaysDays);
                setItemMilesPerHour_Miles(data.xMilesPerHourOfFlightForXDaysMiles);

                setIsCheckedFixedBonusMiles(data.conditionXMilesFixedBonus);
                setItemFixedBonusMiles(data.xMilesFixedBonus);
                setCountry(JSON.parse(data.countries));

                setIsCheckedExchange(data.canExchange)
                setIsCheckedRent(data.canRent);
                setIsCheckedSell(data.canSell);

                setItemLatitude(data.latitude);
                setItemLatitude(data.longitude);

                initMap(data.longitude, data.latitude);
            } else {
                setLngLat();
            }
        } else {
            setLngLat();
        }
    }

    useEffect(() => {
        getCategoryList();
        getCurrenciesList();
        getCountryList();
        getItemTypesList();
        getCountryListWithoutALL();
        getItem();

    }, []);


    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0"> {actionName == "create" ? "Create new Item" : "Edit Item"}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Item</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName == "create" ? "New Item" : "Edit Item informations"}</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/item/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createItemHandler(e)}>
                                                <div className="mdg-card-body mdg-row">
                                                    <div className="mdg-col-sm-6 mdg-row">
                                                        <div className="mdg-col-sm-12 text-center">
                                                            <input
                                                                className="mdg-d-none"
                                                                type="file"
                                                                accept="image/png, image/gif, image/jpeg"
                                                                id="id-item-pics"
                                                                onChange={(event) => {
                                                                    setItemImage(event.target.files[0]);
                                                                    setPhotoUrl(URL.createObjectURL(event.target.files[0]));
                                                                }}
                                                            />

                                                            <img
                                                                onClick={handleChangeImage}
                                                                src={photoUrl}
                                                                className="mdg-upload-img"
                                                                alt=""
                                                            ></img>

                                                        </div>
                                                        <div className="mdg-col-sm-12 text-center mt-3">
                                                            <div>
                                                                <div ref={mapContainer} className="map-container" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mdg-col-sm-6 mdg-row">
                                                        <div className="mdg-col-sm-12">
                                                            <input
                                                                id="id-item-can-sell"
                                                                className=""
                                                                type="checkbox"
                                                                onChange={(e) => setIsCheckedStatus(!isCheckedStatus)}
                                                                checked={isCheckedStatus ? true : false}
                                                            />
                                                            <label className="ml-1 mdg-normal-label" htmlFor="id-item-can-sell" style={{ color: "#92969a" }}>Status</label>
                                                        </div>
                                                        <div className="mdg-col-sm-12">
                                                            <div className="mdg-textfield-out-lined">
                                                                <input
                                                                    id="input-category-parent-id"
                                                                    type="text"
                                                                    placeholder=" "
                                                                    value={itemName}
                                                                    style={{ color: "#fff" }}
                                                                    onChange={(event) => {
                                                                        setItemName(event.target.value);
                                                                    }}
                                                                    required
                                                                />
                                                                <label htmlFor="input-category-parent-id">
                                                                    Item Name *
                                                                </label>
                                                            </div>
                                                            <div className="mdg-textfield-out-lined">
                                                                <input
                                                                    id="input-category-name"
                                                                    type="text"
                                                                    placeholder=" "
                                                                    style={{ color: "#fff" }}
                                                                    value={itemDescription}
                                                                    onChange={(event) => {
                                                                        setItemDescription(event.target.value);
                                                                    }}
                                                                    required
                                                                />
                                                                <label htmlFor="input-category-name">
                                                                    Item Description *
                                                                </label>
                                                            </div>
                                                            {categoryList && (
                                                                <div className="mdg-textfield-out-lined">
                                                                    <Select
                                                                        placeholder="Choose a category *"
                                                                        options={categoryList}
                                                                        styles={selectStyles}
                                                                        isClearable={true}
                                                                        style={{ color: "#fff" }}
                                                                        onChange={(newValue, actionMeta) => {
                                                                            newValue != null ? setCategory(newValue.value) : setCategory(null);
                                                                        }}
                                                                        value={findInList(categoryList, category)}
                                                                        required
                                                                    />
                                                                </div>
                                                            )}
                                                            {itemTypesList && (
                                                                <div className="mdg-textfield-out-lined">
                                                                    <Select
                                                                        placeholder="Choose a item type *"
                                                                        options={itemTypesList}
                                                                        styles={selectStyles}
                                                                        isClearable={true}
                                                                        style={{ color: "#fff" }}
                                                                        onChange={(newValue, actionMeta) => {
                                                                            newValue != null ? setItemTypes(newValue.value) : setItemTypes(null);
                                                                        }}
                                                                        value={findInList(itemTypesList, itemTypes)}
                                                                        required
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="mdg-col-sm-12">
                                                            <div className="mdg-row ">

                                                                <div className="mdg-col-sm-4">
                                                                    <div className="row m-1">
                                                                        <div className="mdg-col-sm-12">
                                                                            <input
                                                                                id="id-item-can-sell"
                                                                                className=""
                                                                                type="checkbox"
                                                                                checked={isCheckedSell ? true : false}
                                                                                onChange={(e) => setIsCheckedSell(!isCheckedSell)}
                                                                            />
                                                                            <label className="ml-1 mdg-normal-label" htmlFor="id-item-can-sell" style={{ color: "#92969a" }}>Can Sell</label>
                                                                        </div>
                                                                        {
                                                                            isCheckedSell &&
                                                                            (

                                                                                <div className="mdg-col-sm-12">
                                                                                    <div className="mdg-textfield-out-lined">
                                                                                        <input
                                                                                            id="id-item-can-exchange-echeance-days"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            style={{ color: "#fff" }}
                                                                                            placeholder=" "
                                                                                            value={afterXDaysSell}
                                                                                            onChange={(event) => {
                                                                                                setAfterXDaysSell(event.target.value);
                                                                                            }}
                                                                                        />
                                                                                        <label className="ml-1" htmlFor="id-item-can-exchange-echeance-days">After x days</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>
                                                                <div className="mdg-col-sm-4">
                                                                    <div className="row m-1">
                                                                        <div className="mdg-col-sm-12">
                                                                            <input
                                                                                id="id-item-can-exchange"
                                                                                className=""
                                                                                type="checkbox"
                                                                                checked={isCheckedExchange ? true : false}
                                                                                onChange={(e) => setIsCheckedExchange(!isCheckedExchange)}
                                                                            />
                                                                            <label className="ml-1 mdg-normal-label" htmlFor="id-item-can-exchange" style={{ color: "#92969a" }}>Can Exchange</label>
                                                                        </div>
                                                                        {
                                                                            isCheckedExchange &&
                                                                            (

                                                                                <div className="mdg-col-sm-12">
                                                                                    <div className="mdg-textfield-out-lined">
                                                                                        <input
                                                                                            id="id-item-can-exchange-echeance-days"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            style={{ color: "#fff" }}
                                                                                            placeholder=" "
                                                                                            value={afterXDaysExchange}
                                                                                            onChange={(event) => {
                                                                                                setAfterXDaysExchange(event.target.value);
                                                                                            }}
                                                                                        />
                                                                                        <label className="ml-1" htmlFor="id-item-can-exchange-echeance-days">After x days</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>
                                                                <div className="mdg-col-sm-4">
                                                                    <div className="row m-1">
                                                                        <div className="mdg-col-sm-12">
                                                                            <input
                                                                                id="id-item-can-rent"
                                                                                className=""
                                                                                type="checkbox"
                                                                                checked={isCheckedRent ? true : false}
                                                                                onChange={(e) => setIsCheckedRent(!isCheckedRent)}
                                                                            />
                                                                            <label className="ml-1 mdg-normal-label" htmlFor="id-item-can-rent" style={{ color: "#92969a" }}>Can Rent</label>
                                                                        </div>
                                                                        {
                                                                            isCheckedRent &&
                                                                            (

                                                                                <div className="mdg-col-sm-12">
                                                                                    <div className="mdg-textfield-out-lined">
                                                                                        <input
                                                                                            id="id-item-can-exchange-echeance-days"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            style={{ color: "#fff" }}
                                                                                            placeholder=" "
                                                                                            value={afterXDaysRent}
                                                                                            onChange={(event) => {
                                                                                                setAfterXDaysRent(event.target.value);
                                                                                            }}
                                                                                        />
                                                                                        <label className="ml-1" htmlFor="id-item-can-exchange-echeance-days">After x days</label>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>

                                                                <div className="mdg-col-sm-6">
                                                                    <div className="mdg-textfield-out-lined">
                                                                        <input
                                                                            id="id-item-price"
                                                                            type="number"
                                                                            min="0.01"
                                                                            step="0.01"
                                                                            style={{ color: "#fff" }}
                                                                            placeholder=" "
                                                                            value={itemPrice}
                                                                            onChange={(event) => {
                                                                                setItemPrice(event.target.value);
                                                                            }}
                                                                            required
                                                                        />
                                                                        <label htmlFor="id-item-price">Price *</label>
                                                                    </div>
                                                                </div>
                                                                <div className="mdg-col-sm-6">
                                                                    {currencyList && (
                                                                        <div className="mdg-textfield-out-lined">
                                                                            <Select
                                                                                placeholder={
                                                                                    <div>Choose a currency *</div>
                                                                                }
                                                                                options={currencyList}
                                                                                styles={selectStyles}
                                                                                isClearable={true}
                                                                                style={{ color: "#fff" }}
                                                                                onChange={(newValue, actionMeta) => {
                                                                                    newValue ? setItemCurrency(newValue.value) : setItemCurrency(null);
                                                                                }}
                                                                                required
                                                                                value={findInList(currencyList, itemCurrency)}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="mdg-col-sm-12">
                                                                    <div className="mdg-textfield-out-lined">
                                                                        <Select
                                                                            placeholder="Country"
                                                                            styles={selectStyles}
                                                                            closeMenuOnSelect={false}
                                                                            isMulti
                                                                            isClearable={true}
                                                                            onChange={async (newValue, actionMeta) => {
                                                                                for (let i = 0; i < newValue.length; ++i) {
                                                                                    if (newValue[i].value === "ALL") {
                                                                                        newValue = countryListWithoutAll;
                                                                                    }
                                                                                }

                                                                                newValue && setCountry(newValue);

                                                                            }}
                                                                            options={countryList}
                                                                            value={itemCountry}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="mdg-col-sm-12">
                                                                    <div className="mdg-textfield-out-lined">
                                                                        <input
                                                                            id="id-item-quantity"
                                                                            type="number"
                                                                            min="0"
                                                                            placeholder=" "
                                                                            style={{ color: "#fff" }}
                                                                            value={itemQuantity}
                                                                            onChange={(event) => {
                                                                                setItemQuantity(event.target.value);
                                                                            }}
                                                                        />
                                                                        <label htmlFor="id-item-quantity">Quantity</label>
                                                                    </div>
                                                                </div>
                                                                <div className="mdg-col-sm-12">
                                                                    <input
                                                                        id="id-check-item-miles-per-day"
                                                                        className=""
                                                                        type="checkbox"
                                                                        checked={isCheckedMilesPerDay ? true : false}
                                                                        onChange={(e) => setIsCheckedMilesPerDay(!isCheckedMilesPerDay)}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-item-miles-per-day" style={{ color: "#92969a" }}>X FC per day for X days</label>
                                                                </div>
                                                                {isCheckedMilesPerDay && (
                                                                    <div className="mdg-col-sm-12 mdg-row">
                                                                        <div className="mdg-col-sm-6">
                                                                            <div className="mdg-textfield-out-lined">
                                                                                <input
                                                                                    id="id-input-item-miles-per-day-miles"
                                                                                    type="number"
                                                                                    placeholder=" "
                                                                                    style={{ color: "#fff" }}
                                                                                    value={itemMilesPerDay_Miles}
                                                                                    onChange={(event) => {
                                                                                        setItemMilesPerDay_Miles(event.target.value);
                                                                                    }}
                                                                                />
                                                                                <label htmlFor="id-input-item-miles-per-day-miles">FC per day</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mdg-col-sm-6">
                                                                            <div className="mdg-textfield-out-lined">
                                                                                <input
                                                                                    id="id-input-item-miles-per-day-day"
                                                                                    type="number"
                                                                                    placeholder=" "
                                                                                    style={{ color: "#fff" }}
                                                                                    value={itemMilesPerDay_Day}
                                                                                    onChange={(event) => {
                                                                                        setItemMilesPerDay_Day(event.target.value);
                                                                                    }}
                                                                                />
                                                                                <label htmlFor="id-input-item-miles-per-day-day">Day</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="mdg-col-sm-12">
                                                                    <input
                                                                        id="id-check-item-miles-per-hour"
                                                                        className=""
                                                                        type="checkbox"
                                                                        checked={isCheckedMilesPerHour ? true : false}
                                                                        onChange={(e) => setIsCheckedMilesPerHour(!isCheckedMilesPerHour)}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-item-miles-per-hour" style={{ color: "#92969a" }}>X FC per hour of flights for X days</label>
                                                                </div>
                                                                {isCheckedMilesPerHour && (
                                                                    <div className="mdg-col-sm-12 mdg-row">
                                                                        <div className="mdg-col-sm-6">
                                                                            <div className="mdg-textfield-out-lined">
                                                                                <input
                                                                                    id="id-input-item-miles-per-hour-miles"
                                                                                    type="number"
                                                                                    min="0"
                                                                                    step="0.01"
                                                                                    placeholder=" "
                                                                                    style={{ color: "#fff" }}
                                                                                    value={itemMilesPerHour_Miles}
                                                                                    onChange={(event) => {
                                                                                        setItemMilesPerHour_Miles(event.target.value);
                                                                                    }}
                                                                                />
                                                                                <label htmlFor="id-input-item-miles-per-hour-miles">FC per hour of flights</label>
                                                                            </div>

                                                                        </div>
                                                                        <div className="mdg-col-sm-6">
                                                                            <div className="mdg-textfield-out-lined">
                                                                                <input
                                                                                    id="id-input-item-miles-per-hour-day"
                                                                                    type="number"
                                                                                    placeholder=" "
                                                                                    style={{ color: "#fff" }}
                                                                                    value={itemMilesPerHour_Day}
                                                                                    onChange={(event) => {
                                                                                        setItemMilesPerHour_Day(event.target.value);
                                                                                    }}
                                                                                />
                                                                                <label htmlFor="id-input-item-miles-per-hour-day">Day</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                <div className="mdg-col-sm-12">
                                                                    <input
                                                                        id="id-check-item-fixed-bonus-miles"
                                                                        className=""
                                                                        type="checkbox"
                                                                        checked={isCheckedFixedBonusMiles ? true : false}
                                                                        onChange={(e) => setIsCheckedFixedBonusMiles(!isCheckedFixedBonusMiles)}
                                                                    />
                                                                    <label className="ml-1 mdg-normal-label" htmlFor="id-check-item-fixed-bonus-miles" style={{ color: "#92969a" }}>X fixed bonus FC</label>
                                                                </div>
                                                                {isCheckedFixedBonusMiles && (
                                                                    <div className="mdg-col-sm-12 mdg-row">
                                                                        <div className="mdg-col-sm-6">
                                                                            <div className="mdg-textfield-out-lined">
                                                                                <input
                                                                                    id="id-input-item-fixed-bonus-miles"
                                                                                    type="number"
                                                                                    placeholder=" "
                                                                                    style={{ color: "#fff" }}
                                                                                    value={itemFixedBonusMiles}
                                                                                    onChange={(event) => {
                                                                                        setItemFixedBonusMiles(event.target.value);
                                                                                    }}
                                                                                />
                                                                                <label htmlFor="id-input-item-fixed-bonus-miles">X fixed bonus FC</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 text-right mt-2">
                                                        <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                            Save
                                                        </button>
                                                    </div>

                                                </div>
                                                {/* <div className="mdg-modal-footer">
                                                    <div className="mdg-col-sm-2">
                                                        <button className="mdg-btn mdg-btn-outline-meta mdg-btn-round">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default ItemForm;
