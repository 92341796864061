import React, { Component } from 'react';
import LiveMap from '../../../extra/livemap/map';
import HeaderPage from '../header/HeaderPage';
import MissionList from './list';

class MissionPage extends Component {
    render() {
        document.body.style.backgroundColor = "#081217";
        return (
            <div className="mdg-body-ingame">
                <LiveMap />
                <div className="mdg-header-ingame">
                    <HeaderPage />
                    <MissionList />
                </div>
            </div>
        );
    }
}
export default MissionPage;