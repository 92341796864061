import axios  from "../AxiosInstance"

const getAllOrders = async () => {
    try {
      return axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const getPackOrdersStatusService = async () => {
    try {
      return axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders/pack-order-status`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const getPackOrdersStatusAsKeyValueService = async () =>{
    const res = await getPackOrdersStatusService();
  if (res.error) {
    return [];
  } else {
    const status = [];
    const {data} = res;
    const dataArray = Object.keys(data);
    
    for (let i = 0; i < dataArray.length; ++i) {
        const elt = dataArray[i];
        
        status.push({ label: elt, value: elt });
    }
    return status;
  }
}

const getById = async (id) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id,data) => {
  try {
      return await axios({
          method: "put",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders/${id}`,
          data: data
      }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const updateStatus = async (id,data) => {
  try {
      return await axios({
          method: "put",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders/update-status/${id}`,
          data: data
      }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getList = async (filter) => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders/list`, 
    { params: filter });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const getCompletedOrders = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/orders/completed`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const OrderService = {
    getAllOrders,
    getById,
    deleteById,
    update,
    create,
    getPackOrdersStatusService,
    updateStatus,
    getPackOrdersStatusAsKeyValueService,
    getList,
    getCompletedOrders
}

export default OrderService;