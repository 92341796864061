import axios  from "../AxiosInstance"
import { getJSON } from "jquery";

export const getCurrenciesListService = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/base/currencies`);
  } catch (err) {
    return {
      error: true,
      response: err.response
    };
  }
}

export const getCurrencyListAsKeyValueService = async () => {
    const res = await getCurrenciesListService();
    if (res.error) {
      return [];
    } else {
      const currencies = [];
      for (let i = 0; i < res.data.length; ++i) {
        const elt = res.data[i];
        if(elt.status){
            const name = elt.name + '(' + elt.symbol + ')';
            currencies.push({ label: name, value: elt.id });
        }
      }
      return currencies;
    }
  };

 export const getCountryListService = async () => {
    await getJSON("https://trial.mobiscroll.com/content/countries.json", (resp) => {
      const countries = [];
      for (let i = 0; i < resp.length; ++i) {
        const country = resp[i];
        countries.push({ label: country.text, value: country.value });
      }
      return countries;
    });
  };

const CurrencyService = {
    getCurrenciesListService,
    getCurrencyListAsKeyValueService
}

export default CurrencyService;