import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DataTable from "react-data-table-component";
import Thumbnail from "../../../extra/img/thumbnail";
import swal from 'sweetalert';
import FlightIncidentService from "../../../../services/paxncargo/flightIncident";

const FlightIncidentList = () => {
    const [list, setList] = useState(null);
    
    const rowStyle = [
      {
        when: row => row.id > 0,
        style: {
          backgroundColor: "transparent",
          color: "#fff",
          userSelect: "none"
        }
      }
    ];

    const deleteFlightIncident = async (row) => {
        swal(`Are you sure to delete this flight incident : ${row.name}`, {
            icon: "warning",
            buttons: {
                cancel: "Cancel",
                confirm: "Yes"
            },
        }).then(async (value) => {
            if (value) {
                const result = await FlightIncidentService.deleteById(row.id);
                if (result.error) {
                    swal(result.response.data.message, {
                        icon: "error"
                    })
                } else {
                    swal(result.data.message, {
                        icon: "success"
                    })
                }
                getList()
            }
        });
    }

    const columns = [
        {
            id: 1,
            name: "Name",
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            id: 2,
            name: "Key",
            selector: (row) =>  row.key,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 3,
            name: "Impact Wellness",
            selector: (row) => <>{row.impactWellness} %, rate: {row.impactWellnessResolutionPercent} %, duration: {row.impactWellnessResolutionMinutes} min</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 4,
            name: "Impact Entertainement",
            selector: (row) => <>{row.impactEntertainement} %, rate: {row.impactEntertainementResolutionPercent ? `${row.impactEntertainementResolutionPercent} %`: 'No impact'}, duration: {row.impactEntertainementResolutionMinutes ? `${row.impactEntertainementResolutionMinutes} min` : 'No impact'}</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 5,
            name: "Impact Cargo",
            selector: (row) => <>Normal: {row.impactCargoNormal ? row.impactCargoNormal : "no impact"}, Fragile: {row.impactCargoFragile ? `${row.impactCargoFragile} XP`: 'No impact'}, Dangerous: {row.impactCargoDangerous ? `${row.impactCargoDangerous} XP` : 'No impact'}</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 6,
            name: "Impact Satiation",
            selector: (row) => <>{row.impactSatiation} XP, rate: {row.impactSatiationResolutionPercent ? row.impactSatiationResolutionPercent: 'No impact'}, duration: {row.impactSatiationResolutionMinutes ? row.impactSatiationResolutionMinutes : 'No impact'}</>,
            sortable: true,
            center: true,
            reorder: true
        },
        {
            id: 7,
            button: true,
            left: true,
            cell: (row) => (
                <NavLink className="mdg-btn mdg-btn-outline-meta mr-2" to={"/admin/paxncargo/flight-incident/edit/" + row.id}><i className="fa fa-pencil-square-o" aria-hidden="true"></i>  </NavLink>
            )
        },
        {
            id: 8,
            button: true,
            left: true,
            cell: (row) => (
                <button type="button" className="mdg-btn mdg-btn-danger mr-2" onClick={async () => deleteFlightIncident(row)}><i className="fa fa-trash-o" aria-hidden="true"></i>  </button>
            )
        }
    ];

    const getList = async () => {
        await FlightIncidentService.getAll().then((res) => {
            setList(res.data)
        });
    };

    useEffect(() => {
        getList();
    }, []);

    return (
        <div >
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">List of Flight Incident</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Flight Incident</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Flight Incident</h5>                                    
                                    <div className="card-tools">
                                        <NavLink to="/admin/paxncargo/flight-incident/new" type="button" className="btn btn-tool bg-info ml-2" >
                                            New
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12">                                                                            
                                        <div className="row">
                                            <div className="col-lg-12 mdg-historique-table">
                                                {list && (<DataTable
                                                    columns={columns}
                                                    data={list}
                                                    defaultSortFieldId={1}
                                                    pagination
                                                    selectableRows
                                                    conditionalRowStyles={rowStyle}
                                                />)}
                                            </div>
                                        </div>
                                    </div>                                
                                </div>
                                <div className="card-footer">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
};

export default FlightIncidentList;
