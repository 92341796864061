import React from 'react';
import BaseBO from '../templates/bo/BaseBo';
import RoleForm from './RoleForm';
import RoleList from './RoleList';

const RoleComponent = (props) => {
    let page ;

    switch (props.page){
        case "new": page = <RoleForm action="Create"></RoleForm> ;break;
        case "edit": page = <RoleForm action="Edit"></RoleForm> ; break;
        default: page = <RoleList></RoleList>;break;
    }

    return (
        <div>
            <BaseBO page={page}></BaseBO>
        </div>
    );
};

export default RoleComponent;