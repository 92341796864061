import React from 'react';
import Checkout from './Checkout';
import { useDispatch, useSelector } from 'react-redux';

import MetaCart from '../../../../../actions/cartActions';
import { useEffect } from 'react';
import { useState } from 'react';

const Panier = () => {
    const [visible,setVisible] = useState(null);
    const show = useSelector((state) => state.cart.show);
    const dispatch = useDispatch();
   
    const setIsVisilble = () => {
        dispatch(MetaCart.toggleShowCart());
    }

    useEffect(() => {
        if(!show){
            if(visible != null){
                setVisible(show);
            }
        }else{
            setVisible(show);
        }
    },[show]);
    return (
        <div className="mdg-shopping">
            <Checkout visibility={visible} setIsVisilble={setIsVisilble} />
        </div>
    );
};

export default Panier;