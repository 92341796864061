import React from "react";
import "./style.css"
const StoreLoader = () => {
  return (
    <div className="col-sm-4 mt-3">
      <div className="mdg-width-card-body ">
        <div className="mdg-card-header-items">
          <i className="fa fa-heart-o mdg-icon-heart" aria-hidden="true"></i>
        </div>
        <div className="mdg-card-body-items  position-relative lmask">
          <div className="">
            <div className="mask-image mdg-image-body" >
            {/* <img
              src={"/assets/img/voiiture1.png"}
              alt="logo"
              srcSet=""
              className="mdg-image-body"
            /> */}
            </div>
            
            <div className="mdg-body-description mt-2 pl-2">
              <p>
                <span className="mdg-bg-rare" style={{ color: "transparent"}}>COMMON</span>
              </p>
            </div>
            <div className="mdg-body-footer-description mt-3 pl-2">
              <p>
                <img
                  src={"/assets/img/logo-meta-plane.png"}
                  alt="logo"
                  srcSet=""
                  style={{ width: '10%' }}
                />{" "}
                <span className="mdg-bg-text-items"></span>
                <span className="mdg-bg-text-items mdg-text-marque">
                  
                </span>
                <span className="mdg-bg-text-items">
                  <small></small>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="card-footer mdg-card-footer-items">
          <span className="mdg-bg-text-items">
            <img
              src={"/assets/img/my-profil.jpg"}
              width="30"
              height="30"
              className="rounded-circle-sm mr-2"
            />
            
          </span>
          <button type="submit" className="btn mdg-btn-common btn-sm float-right" >
            <span style={{ color: "transparent"}}>Rent now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreLoader;
