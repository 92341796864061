import { useState } from "react";
import Swal from "sweetalert2";
import { refreshWalletAsync } from "../../../../asyncActions/walletAsyncActions";
import WalletService from "../../../../services/wallet/wallet";
import Language from "../../../extra/language/language";
import translate from "../../../extra/language/translate";
import QuantityPickerInput from "../../../extra/quantite_picker/QuantityPickerPages";

const MilesToFC = ({MFCSetting, MySwal,dispatch, language }) => {
    const min = MFCSetting.MFC_miles_min? Number(MFCSetting.MFC_miles_min):0;
    const [miles, setMiles] = useState(Number(min));
    let inputValue = Number(min) ;
    const convertMilesToFCHandler = async (event) => {
        event.preventDefault();
        const data = new FormData();
        const fc_amount = Number(miles)  / Number(MFCSetting.MFC_miles);
        data.append('miles', miles);
        data.append('fc_amount', fc_amount);
       
        const result = await WalletService.convertMilesToFCService(data);

        if (result.error) {
            Swal.fire({
                title: result.response.data.message ? result.response.data.message : result.response.data.data.message ,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            MySwal.close();
            Swal.fire({
                title: result.data.message,
                icon: result.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
                dispatch(refreshWalletAsync())
            });
        }
    };
    return (
        <>
            <form onSubmit={(e) => convertMilesToFCHandler(e)}>
                <div className="row">
                    <div className="mdg-col-12 mr-2 ml-2">
                        <div className="mdg-textfield-out-lined">
                            <label htmlFor="input-miles">
                                Miles *
                            </label>
                            <QuantityPickerInput className="mt-2" min={min} step={Number(MFCSetting.MFC_miles)} inputValue={inputValue} onChange={(value) => { inputValue = value; setMiles(inputValue)} }></QuantityPickerInput>
                        </div>
                    </div>
                    <small className="mdg-col-12 mr-2 ml-2 ">
                        { translate('Wallet.Minimum_to_convert',language)} {min} miles
                    </small>
                    <div className="mdg-col-12 mr-2 ml-2 mt-2">
                        { translate('Wallet.Will_be_converted_to',language)} {(Number(miles)  / Number(MFCSetting.MFC_miles)).toFixed(6)
                        } FC
                    </div>
                    <div className="col-lg-12 text-right mt-2">
                        <button type="submit" className="col-lg-2 mdg-btn btn-info mdg-btn-round float-right">
                            { translate('Wallet.Convert',language)} 
                        </button>
                    </div>
                </div>
            </form>

        </>
    );
}

export default MilesToFC;