import React from 'react'
import Select from 'react-select';
import CategoryService from '../../../../../services/Category';
import ItemService from '../../../../../services/Item';
import SellerModeService from '../../../../../services/sellerMode';
import { selectStylesFo } from '../../../../../Utils/Common';
import Swal from 'sweetalert2';
import QuantityPickerInput from '../../../../extra/quantite_picker/QuantityPickerPages';
import Language from '../../../../extra/language/language';

class Exchange extends React.Component {
    constructor(props) {
        super(props)
    }
    state = {
        categoryId: undefined,
        itemId: undefined,
        priceMin: 1,
        priceMax: 1000,
        priceMaxForAttribute: 1000,
        categoryList: [],
        itemList: [],
        inputValuePriceMin : 1,
        inputValuePriceMax : 1000,
    }

    onChange = (e,name) => {
        if(name == "priceMin"){
            this.state.inputValuePriceMin = e;
        }else{
            this.state.inputValuePriceMax = e;
            console.log(this.state.inputValuePriceMax);
        }
        this.setState({ [name]: e });
    }

    handleFilterCategory = (e) => {

    }

    handleFilterItem = (e) => {

    }

    getCategories = async () => {
        const all = await CategoryService.getAvailableCategoryListAsKeyValueService();
        this.setState({ categoryList: all });
    }

    getItems = async () => {
        const all = await ItemService.getItemAsKeyValue();
        
        this.setState({ itemList: all });
    }

    componentDidMount() {
        this.getItems();
        this.getCategories();
    }

    componentDidUpdate() {

    }
    handleExchangeItem = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append("itemId", this.props.itemId);
        formData.append("minPrice", this.state.priceMin);
        formData.append("maxPrice", this.state.priceMax);
        if(this.state.categoryId){
            formData.append("category", this.state.categoryId);
        }
        if(this.state.itemId){
            formData.append("exchangeItem", this.state.itemId);
        }
        
        const res = await SellerModeService.exchangeMyItem(formData);
        if (res.error) {
            Swal.fire({
                title: res.response.data.message ? res.response.data.message : res.response.data.data.message ,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: res.data.message,
                icon: res.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
                // this.getItems();
            });
        }
    }
    
    render() {
        return (
            <form onSubmit={this.handleExchangeItem}>
                <div className="row">
                    <div className='col-lg-12'>
                        <h1 className="mdg-text-white"><Language word={"Seller.Exchange_this_Item"}></Language></h1>
                    </div>
                    <div className="col-lg-6">
                        <div className="mdg-form-seller">
                            {/* <input id="id-min-price" type="number" min="1" max={this.state.priceMax} className="mdg-text-white" name="priceMin" placeholder=" " value={this.state.priceMin} onChange={this.onChange} /> */}
                            <label htmlFor="id-min-price" className="mdg-text-white"><Language word={"Seller.Min_price"}></Language></label>
                            <QuantityPickerInput className="mt-2" min={1} max={this.state.priceMax} inputValue={this.state.inputValuePriceMin} onChange={(e) => this.onChange(e,"priceMin") } isDisabled={!this.props.item.canExchange}></QuantityPickerInput>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mdg-form-seller">
                            {/* <input id="id-max-price" type="number" min={this.state.priceMin} className="mdg-text-white" name="priceMax" placeholder=" " value={this.state.priceMax} onChange={this.onChange} /> */}
                            <label htmlFor="id-max-price" className="mdg-text-white"><Language word={"Seller.Max_price"}></Language></label>
                            <QuantityPickerInput className="mt-2" min={this.state.priceMin} inputValue={this.state.inputValuePriceMax} onChange={(e) => this.onChange(e,"priceMax") } isDisabled={!this.props.item.canExchange}></QuantityPickerInput>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mdg-form-seller mdg-textfield-out-lined">
                            <Select
                                options={this.state.categoryList}
                                filterOptions={this.handleFilterCategory}
                                name="categoryId"
                                styles={selectStylesFo}
                                placeholder={<Language word={"Seller.Choose_an_category"}></Language>}
                                className="mdg-seller-type"
                                search
                                isDisabled={!this.props.item.canExchange}
                                isClearable={true}
                                onChange={(newValue) => { 
                                    if(newValue){
                                        this.setState({ categoryId: newValue.value })
                                    }
                                    else{
                                        this.setState({ categoryId: null })
                                    }
                                }}
                                id="id-category"
                            />

                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="mdg-form-seller mdg-textfield-out-lined">
                            <Select
                                options={this.state.itemList}
                                filterOptions={this.handleFilterItem}
                                name="item"
                                styles={selectStylesFo}
                                placeholder={<Language word={"Seller.Choose_an_item"}></Language>}
                                className="mdg-seller-type"
                                search
                                isDisabled={!this.props.item.canExchange}
                                isClearable={true}
                                onChange={(newValue) => { 
                                    if(newValue){
                                        this.setState({ itemId: newValue.value })
                                    }
                                    else{
                                        this.setState({ itemId: null })
                                    }
                                }}
                                id="id-item"
                            />
                        </div>
                    </div>
                    <div className="col align-self-end mt-3">
                        {this.props.item.canSell ? 
                            <button className="btn mdg-btn-common btn-sm float-right mdg-btn-12">
                                <span><Language word={"Seller.Exchange_now"}></Language></span>
                            </button> : <small className="mdg-text-red"><Language word={"Seller.This_item_can_not_be_exchanged"}></Language></small>
                        }
                    </div>
                </div>
            </form>

        )
    }
}

export default Exchange