import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PackService from "../../../../services/pack";
import Language from "../../../extra/language/language";
import PackEltContainer from "./packContainer";
import PackBoosterEltContainer from "../packBoosterCarreerLevel/PackBoosterCarreerLevelEltContainer";
import { useDispatch, useSelector } from "react-redux";
import PackBoosterCarreerLevelService from "../../../../services/pack/packBoosterCarreerLevel";
import { Carousel } from "antd";
import Swal from "sweetalert2";
import translate from "../../../extra/language/translate";
import MetaCart from "../../../../actions/cartActions";

const Pack = ({ version }) => {
  const authObj = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { user } = authObj;
  const history = useNavigate();
  const [packList, setPackList] = useState([]);
  const [bestValue = 0, setBestValue] = useState(0);
  const lang = useSelector((state) => state.language);
  const cart = useSelector((state) => state.cart);
  const [sortBy, setSortBy] = useState("all");
  const boosterRef = useRef();
  const ref = useRef();

  const [packBoosterList, setPackBoosterList] = useState([]);

  const getPackBoosterList = async () => {
    await PackBoosterCarreerLevelService.getAll().then(
      (res) => {
        setPackBoosterList(res.data);
      }
    );
  };

  const getBestValue = () => {
    let best = 0;
    let bestAmount = 0;
    packList.forEach((pack, i) => {
      if (pack.fcAmount > bestAmount) {
        best = pack.id;
        bestAmount = pack.fcAmount;
      }
    });

    setBestValue(best);
  };

  const getPackList = async () => {
    await PackService.getAllPack().then((res) => {
      setPackList(res.data.packList);
    });
  };

  const classHidden =
    version === "v2" ? "mdg-top-0 mdg-bg-content-v2" : "mdg-top-container";
  
  const classBg =
    version === "v2" ? "mdg-width-card-body-v2" : "mdg-width-card-body";

  useEffect(() => {
    getBestValue();
  }, [packList]);

  useEffect(() => {
    getPackList();
    getPackBoosterList();
  }, []);

  useEffect(() => {
    if(cart.error){
        Swal.fire({
            'icon': 'error',
            'text': translate(cart.message,lang)
        })
        dispatch(MetaCart.HideError())
    }
  },[cart.error])

  return (
    <div className={`${classHidden} container`}>
      <div className={`mdg-title-ingame row`}>
        <div className="col-sm">
          <span className="mdg-h2">
            <Language word={"Packs.All_Packs"}></Language>
          </span>
        </div>
      </div>
      <div className="cards-1" style={{ marginBottom: "30px" }}>
        <div className="row">
          <div
            className="col-lg-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              onClick={() => {
                ref.current.prev();
              }}
              style={{ background: "transparent", border: "none" }}
            >
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-lg-10">
            <h3 className="mdg-text-white">Pack Booster</h3>
            <Carousel
              slidesToShow={packList.length < 3 ? packList.length : 3}
              draggable
              dots={false}
              ref={ref}
              autoplay={true}
            >
              {packList &&
                (sortBy === "all" || sortBy === "pack") &&
                packList.map((pack, i) => {
                  return (
                    <PackEltContainer
                      packClass={
                        packList.length === 1
                          ? "col-lg-4 mt-3"
                          : packList.length === 2
                          ? "col-lg-8 mt-3"
                          : "col-lg-12 mt-3"
                      }
                      packBg={classBg}
                      pack={pack}
                      
                      key={i}
                      history={history}
                      bestValue={bestValue}
                      lang={lang}
                    />
                  );
                })}
            </Carousel>
          </div>
          <div
            className="col-lg-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              onClick={() => {
                ref.current.next();
              }}
              style={{ background: "transparent", border: "none" }}
            >
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        {/*<div className={`${hidden} mdg-title-ingame row`}>*/}
        {/* <div className={`mdg-title-ingame row`}>
          <div className="col-sm">
            <span className="mdg-h2">
              <Language word={"Packs.All_Packs_Carreer_Level_Booster"}></Language>
            </span>
          </div>
        </div> */}
        <div className="row justify-content-center">
          <div
            className="col-lg-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              onClick={() => {
                boosterRef.current.prev();
              }}
              style={{ background: "transparent", border: "none" }}
            >
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
          </div>
          <div className="col-lg-10 mt-3">
            <h3 className="mdg-text-white">Booster Carrer Level</h3>
            {packBoosterList &&
              (sortBy === "all" || sortBy === "pack_booster") && (
                <Carousel
                  slidesToShow={
                    packBoosterList.length < 3 ? packBoosterList.length : 3
                  }
                  draggable
                  dots={false}
                  ref={boosterRef}
                  autoplay={true}
                >
                  {packBoosterList.map((packBooster, i) => {
                    return (
                      <PackBoosterEltContainer
                        packClass={
                          packBoosterList.length === 1
                            ? "col-lg-4 mt-3"
                            : packBoosterList.length === 2
                            ? "col-lg-8 mt-3"
                            : "col-lg-12 mt-3"
                        }
                        packBg={classBg}
                        pack={packBooster}
                        key={i}
                        history={history}
                        level={user.level}
                        lang={lang}
                      />
                    );
                  })}
                </Carousel>
              )}
          </div>
          <div
            className="col-lg-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <button
              onClick={() => {
                boosterRef.current.next();
              }}
              style={{ background: "transparent", border: "none" }}
            >
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pack;
