import { useEffect, useState } from "react"
import SellerModeService from "../../../../../services/sellerMode";
import Swal from 'sweetalert2'
import QuantityPickerInput from "../../../../extra/quantite_picker/QuantityPickerPages";
import Language from "../../../../extra/language/language";

const Rent = ({ itemId, item }) => {
    const [price, setPrice] = useState(1000);
    const [days, setDays] = useState(1);
    let inputValue = 1;
    let inputValuePrice = 1000;
    
    const rentItem = async (e) => {
        e.preventDefault();
        
        const formData = new FormData();

        formData.append("itemId", itemId);
        formData.append("pricePerDay", price);
        formData.append("minDays", days);
        
        const res = await SellerModeService.rentMyItem(formData);
        if (res.error) {
            Swal.fire({
                title: res.response.data.message ? res.response.data.message : res.response.data.data.message ,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: res.data.message,
                icon: res.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
               
            });
        }
    }
    

    return (
        <form onSubmit={ rentItem }>
            <div className="row">
                <div className='col-lg-12'>
                    <h1 className="mdg-text-white"><Language word={"Seller.Rent_this_Item"}></Language></h1>
                </div>
                <div className="col-lg-12">
                    <div className="mdg-textfield-out-lined ">
                        {/* <input id="id-price" type="number" min="1" max="" className="mdg-text-white" name="price" placeholder=" " value={price} onChange={(e) => { setPrice(e.target.value); }} /> */}
                        <label htmlFor="id-price" className="mdg-text-white"><Language word={"Seller.Rent_amount_per_days"}></Language></label>
                        <QuantityPickerInput className="mt-2" min={1} inputValue={inputValuePrice} onChange={(value) => { inputValuePrice = value; setPrice(inputValuePrice)} } isDisabled={!item.canRent}></QuantityPickerInput>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="mdg-textfield-out-lined">
                        {/* <input id="id-min-days" type="number" min="1" className="mdg-text-white" name="minDays" placeholder=" " value={days} onChange={(e) => { setDays(e.target.value); }} />*/}
                        <label htmlFor="id-min-days" className="mdg-text-white"><Language word={"Seller.Minimum_number_of_days"}></Language></label> 
                        <QuantityPickerInput className="mt-2" min={1} inputValue={inputValue} onChange={(value) => { inputValue = value; setDays(inputValue)} } isDisabled={!item.canRent}></QuantityPickerInput>
                    </div>
                </div>
                <div className="col align-self-end mt-3">
                    {item.canRent ? 
                        <button type="submit" className="btn mdg-btn-common btn-sm float-right mdg-btn-12">
                            <span><Language word={"Seller.Rent_now"}></Language></span>
                        </button>: <small className="mdg-text-red"><Language word={"Seller.This_item_can_not_be_rented"}></Language></small>
                    }
                </div>
            </div>
        </form>

    )
}

export default Rent;