import { data } from "jquery";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import swal from "sweetalert";
import Switch from "react-switch";
import PackService from "../../../services/pack";
import { getFormatedDate, getImageUrl } from "../../../Utils/Common";
import DescriptionComponent from "../description/DescriptionComponent";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { findInList, selectStyles } from "../../../Utils/Common";

const PackForm = (props) => {
  const { id } = useParams();
  const actionName = props.action;
  const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fcAmount, setFcAmount] = useState(0);
  const [price, setPrice] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");
  const [isHT, setIsHT] = useState(false);
  const [vat, setVat] = useState(0);
  const [upsellList, setUpSellList] = useState([]);
  const [upSell, setUpSell] = useState(0);
  const [productId, setProductId] = useState("");

  const handleChangeImage = () => {
    const pics = document.getElementById("id-item-pics");
    pics.click();
  };

  const handleCreatePack = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("photo", image);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("fcAmount", fcAmount);
    formData.append("price", price);
    formData.append("tva", vat);
    formData.append("isHT", isHT);
    formData.append("upSell", upSell);
    formData.append("productId", productId);
    //formData.append("isUpsell", false);
    // formData.append('expiryDate', expiryDate);

    let result;
    if (id) {
      result = await PackService.update(id, formData);
    } else {
      result = await PackService.create(formData);
    }

    if (result.error) {
      swal(result.response.data.data.message, {
        icon: "error",
      });
    } else {
      swal(result.data.message, {
        icon: "success",
      });
    }
  };

  const getListUpSell = async () => {
    await PackService.getAllPackUpToSell().then((res) => {

        const lists = [];
        if (!res.error) {
            for (let i = 0; i < res.data.length; ++i) {
                const rl = res.data[i];
                lists.push({
                    value: rl.id,
                    label: rl.name,
                });
            }
            setUpSellList(lists);
        }
    });
};

  const getPack = async () => {
    if (id) {
      const result = await PackService.getById(id);
      if (!result.error) {
        const data = result.data;
        setDescription(data.description);
        setName(data.name);
        setFcAmount(data.fcAmount);
        setPrice(data.price);
        setVat(data.tva);
        setIsHT(data.isHT);
        setUpSell(data.upsell)
        setProductId(data.productId)
        if (data.expiryDate) {
          setExpiryDate(getFormatedDate(data.expiryDate));
        }
        if (data.photo) {
          setPhotoUrl(getImageUrl(data.photo));
        }
      }
    }
  };

  useEffect(() => {
    /** get pack if it's an update request */
    getPack();
    getListUpSell();
  }, []);

  return (
    <div>
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">{id ? "Edit Pack" : "Create new Pack"}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="#">Admin</a>
                </li>
                <li className="breadcrumb-item active">Pack</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">
                    {id ? "Edit Pack Information" : "New Pack"}
                  </h5>

                  <div className="card-tools">
                    <NavLink
                      to="/admin/pack/"
                      type="button"
                      className="btn btn-tool bg-info"
                    >
                      List
                    </NavLink>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={(e) => handleCreatePack(e)}>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="mdg-col-sm-12 text-center">
                                <input
                                  className="mdg-d-none"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  id="id-item-pics"
                                  onChange={(event) => {
                                    setImage(event.target.files[0]);
                                    setPhotoUrl(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                  }}
                                />

                                <img
                                  onClick={handleChangeImage}
                                  className="mdg-upload-img"
                                  src={photoUrl && photoUrl}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="row">
                              <div className="mdg-col-sm-12">
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-parent-id"
                                    type="text"
                                    placeholder=" "
                                    value={name}
                                    onChange={(event) => {
                                      setName(event.target.value);
                                    }}
                                    required
                                    style={{ color: "#fff" }}
                                  />
                                  <label htmlFor="input-category-parent-id">
                                    Pack Name *
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-name"
                                    type="text"
                                    placeholder=" "
                                    style={{ color: "#fff" }}
                                    value={description}
                                    onChange={(event) => {
                                      setDescription(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-name">
                                    Pack Description *
                                  </label>
                                </div>

                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-stripe-product"
                                    type="text"
                                    placeholder=" "
                                    style={{ color: "#fff" }}
                                    value={productId}
                                    onChange={(event) => {
                                      setProductId(event.target.value);
                                    }}
                                  />
                                  <label htmlFor="input-stripe-product">
                                    Stripe product price id
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-name"
                                    type="number"
                                    style={{ color: "#fff" }}
                                    placeholder=" "
                                    value={price}
                                    step="0.01"
                                    onChange={(event) => {
                                      setPrice(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-name">
                                    Price *
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined">
                                  <input
                                    id="input-category-name"
                                    type="number"
                                    style={{ color: "#fff" }}
                                    placeholder=" "
                                    value={fcAmount}
                                    step="0.01"
                                    onChange={(event) => {
                                      setFcAmount(event.target.value);
                                    }}
                                    required
                                  />
                                  <label htmlFor="input-category-name">
                                    Nb Flight coin *
                                  </label>
                                </div>
                                <div className="mdg-d-flex mt-2">
                                  <Switch
                                    onColor="#117a8b"
                                    id="pack-vat"
                                    onChange={(checked) => {
                                      setIsHT(checked);
                                    }}
                                    checked={isHT}
                                  />{" "}
                                  <label
                                    className="ml-2 mdg-normal-label"
                                    htmlFor="pack-vat"
                                    style={{ color: "#92969a" }}
                                  >
                                    excluding VAT
                                  </label>
                                </div>
                                <div className="mdg-textfield-out-lined mt-2">
                                  <NumberFormat
                                    suffix={"%"}
                                    decimalScale={vat >= 100 ? 0 : 2}
                                    decimalSeparator=","
                                    style={{ color: "#fff" }}
                                    className={`text-right`}
                                    value={vat}
                                    id="input-vat"
                                    maxLength={vat >= 100 ? 3 : null}
                                    required
                                    onValueChange={(values) => {
                                      if (values.floatValue <= 100) {
                                        setVat(values.floatValue);
                                      } else {
                                        setVat(100);
                                      }
                                    }}
                                  />
                                  <label htmlFor="input-vat">VAT</label>
                                </div>

                                {upsellList && (
                                  <div className="mdg-textfield-out-lined">
                                    <Select
                                      placeholder="Choose an up sell *"
                                      options={upsellList}
                                      styles={selectStyles}
                                      isClearable={true}
                                      style={{ color: "#fff" }}
                                      onChange={(newValue, actionMeta) => {
                                        newValue != null
                                          ? setUpSell(newValue.value)
                                          : setUpSell(null);
                                      }}
                                      value={findInList(upsellList, upSell)}                                  
                                    />
                                  </div>
                                )}
                                {/* <div className="mdg-textfield-out-lined">
                                                                    <input
                                                                        id="input-category-name"
                                                                        type="date"
                                                                        placeholder=" "
                                                                        style={{ color: "#fff" }}
                                                                        value={expiryDate}
                                                                        onChange={(event) => {
                                                                            setExpiryDate(event.target.value);
                                                                        }}
                                                                        min={getFormatedDate(new Date())}
                                                                    />
                                                                    <label htmlFor="input-category-name">
                                                                        Expiry date *
                                                                    </label>
                                                                </div> */}
                              </div>
                              <div className="col-lg-12 text-right mt-2">
                                <button className="col-lg-4 mdg-btn btn-primary mdg-btn-round float-right">
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="offset-lg-11 mt-1"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PackForm;
