import axios  from "../AxiosInstance"

const getAll = async (id) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/promocode`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};


const getById = async (id) => {
  try {
    return await axios.get(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/promocode/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const create = async (data) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/promocode`,
      data: data
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/promocode/${id}`
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id, data) => {
  try {
    return await axios({
      method: "put",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/promocode/${id}`,
      data: data
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const checkCode = async (code) => {
  try {
    return await axios({
      method: "post",
      url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/promocode/check-code`,
      data: {
        code : code
      }
    });
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const PromocodeService = {
  getAll,
  getById,
  deleteById,
  update,
  create,
  checkCode
}

export default PromocodeService;