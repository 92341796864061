
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit'
import auth from './reducers/authReducer';
import wallet from './reducers/walletReducer';
import cart from './reducers/cart';
import language from './reducers/language';
import map from './reducers/map';
const reducer = {
    auth: auth,
    wallet : wallet,
    cart : cart,
    language: language,
    map: map
}
const middleware = [thunk]
const store = configureStore({
    reducer,
    middleware,
})

export default store