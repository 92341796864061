/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import FlightService from "../../../../services/flight/flight";
import Language from "../../../extra/language/language";
import FlightElement from "./flightElement";
import InfiniteScroll from "react-infinite-scroll-component";

const Flight = ({ version }) => {
  const [flightList, setFlightList] = useState([]);
  const [search, setSearch] = useState("");
  const [hasMore, sethasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [incrementation, setIncrementation] = useState(0);

  const nextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
    setIncrementation((incrementation) => incrementation + 1);
  };

  const getAllMyFlight = async () => {
    const filters = {
      search: search,
      page: currentPage - 1,
      size: 12,
    };
    const res = await FlightService.getUserFlight(filters);
    const data = res.data.items;
    if (currentPage === res.data.totalPages || res.data.totalPages === 0) {
      sethasMore(false);
    } else {
      sethasMore(true);
    }
    return data;
  };

  const fetchData = async () => {
    const data = await getAllMyFlight();
    if (data) {
      setFlightList([...flightList, ...data]);
    }
  };

  const classHidden = version === "v2" ? "mdg-top-0 mdg-bg-content-v2" : "mdg-top-container";
  const hidden = version === "v2" ? "mdg-d-none" : "";
  const classBackground = version === "v2" ? "mdg-bg-row-3" : "mdg-bg-row-3";
  const classWidth = version === "v2" ? "col-lg-12" : "col-lg-10 col-lg-offset-1 offset-lg-1";

  useEffect(() => {
    if (incrementation > 0) {
      fetchData();
    }
  }, [incrementation]);

  useEffect(() => {

    setFlightList([]);
    setCurrentPage(1);
    setIncrementation((incrementation) => incrementation + 1);
  }, [search]);
  return (
    <div className={`${classHidden} container`}>
      <div className={`${hidden} mdg-title-ingame row`}>
        <div className="col-sm">
          <span className="mdg-h2">
            <Language word={"My_flight.My_flight_lists"}></Language>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="all-flight"
              role="tabpanel"
              aria-labelledby="all-flight-tab"
            >
              <div className="mdg-width-card-item">
                <div className={classWidth}>
                  <InfiniteScroll
                    dataLength={flightList.length} //This is important field to render the next data
                    next={nextPage}
                    hasMore={hasMore}
                    loader={
                      <div className="lmask"></div>
                    }
                    endMessage={
                      <p>
                        {" "}
                        {flightList.length === 0 ? (
                          <Language word={"My_flight.No_more_flight"}></Language>
                        ) : (
                          <Language word={"My_flight.No_more_flight"}></Language>
                        )}{" "}
                      </p>
                    }
                  >
                    <div className="mdg-width-card-item">
                      {flightList.map((elt, i) => (
                        <div key={i}>
                          <FlightElement flight={elt} flightClass={classBackground} />
                        </div>
                      ))}
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flight;
