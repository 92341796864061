import React, { useEffect } from 'react';
import LiveMap from '../../../extra/livemap/map';
import HeaderPage from '../header/HeaderPage';
import Store from './store';

const StorePage = () => {
    document.body.style.backgroundColor = "#081217";
    return (
        <div className="mdg-body-ingame">
            <LiveMap />
            <div className="mdg-header-ingame">
                <HeaderPage />
                <Store />
            </div>
        </div>
    );
};

export default StorePage;