import React, { Component } from 'react';
import LiveMap from '../../../extra/livemap/map';
import HeaderPage from '../header/HeaderPage';
import Items from './Items';


class ItemsPage extends Component {
    render() {
        document.body.style.backgroundColor = "#081217";
        return (
            <div className="mdg-body-ingame">
                <LiveMap />
                <div className="mdg-header-ingame">
                    <HeaderPage />
                    <Items />
                </div>
            </div>
        );
    }
}

export default ItemsPage;