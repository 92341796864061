import axios  from "../AxiosInstance"

const createMissionService = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAllMissionService = async (sortBy) => {
    try {
        return await axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission/available`,
            {
              params: { sortBy : sortBy }
            }
          );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getAllMissionServiceAsAdmin = async (sortBy) => {
    try {
        return await axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission`,
            {
              params: { sortBy : sortBy }
            }
          );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};


const getMissionById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const updateMissionService = async (id, data) => {
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission/${id}`,
            data: data
        });
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const deleteByIdService = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getLimitedMissionsService = async (limit) => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/mission/limit/${limit}`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const getUserMissionsService = async () => {
    try {
        return axios.get(
            `${process.env.REACT_APP_REMOTE_API_BASE_URL}/user-missions`
        );
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const MissionService = {
    createMissionService,
    getAllMissionService,
    getMissionById,
    updateMissionService,
    deleteByIdService,
    getLimitedMissionsService,
    getUserMissionsService,
    getAllMissionServiceAsAdmin    
}

export default MissionService;