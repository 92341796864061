import React, { useEffect, useState } from 'react';
import DashboardService from '../../../services/dashboard';
import ItemService from '../../../services/Item';
import OrderService from '../../../services/order';
import UserService from '../../../services/user';
import UserSubscriptionService from '../../../services/userSubscription';
import Language from '../../extra/language/language';

const DescriptionComponent = (props) => {
    const [countItem, setCountItem] = useState(0);
    const [activeSubscription, setActiveSubscription] = useState(0);
    const [completedOrders, setCompletedOrders] = useState(0);
    const [newMembers, setNewMembers] = useState(0);

    const [totalUsers, setTotalUsers] = useState(0);

    const [dashboardData, setDashboardData] = useState(null);

    const getItems = async () => {
        const result = await ItemService.getList();
        if (result.status == 200) {
            setCountItem(result.data.totalItems);
        }
    }

    const getActiveSubscriptions = async () => {
        const result = await UserSubscriptionService.getActive();
        if (result.status == 200) {
            setActiveSubscription(result.data.count);
        }
    }

    const getCompletedOrders = async () => {
        const result = await OrderService.getCompletedOrders();
        if (result.status == 200) {
            setCompletedOrders(result.data.count);
        }
    }

    const getNewMembers = async () => {
        const result = await UserService.getNewMembers();
        if (result.status == 200) {
            setNewMembers(result.data.count);
        }
    }

    const getCountTotalUser = async () => {
        const filter = {
            page: 0,
            size: 10,
            search: ""
        }
        const response = await UserService.getList(filter);
        if (response.status === 200) setTotalUsers(response.data.totalItems - 1);
    }

    const getDashboardData = async () => {
        const result = await DashboardService.getDashboardData();
        setDashboardData(result.data);
    }

    useEffect(() => {
        getItems();
        getActiveSubscriptions();
        getCompletedOrders();
        getNewMembers();
        getCountTotalUser();
        getDashboardData();
    }, []);
    return (
        <>
            {dashboardData && (
                <div className="row">
                    <div className="col-lg-12 mdg-space-12">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-users"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Total_users"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countUser}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-lemon-o"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Discover"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countDiscover}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-ils"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Current_silver"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countCurrentSilver}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-hacker-news"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.New_subscriber_to_Silver"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countNewSubOnMonthSilver}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-houzz"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Unsubscriber_to_Silver"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countUnsubOnMonthSilver}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-users"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Total_Current_Free_Trial_Users"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countFreeTrial}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mdg-space-12">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-pinterest"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Current_platinum"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countCurrentPlatinum}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-pinterest-p"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.New_subscriber_to_Platinium"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countNewSubOnMonthPlatinium}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-default elevation-1"><i className="fa fa-pinterest-square"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Unsubscriber_to_Platinium"}></Language></span>
                                        <span className="info-box-number">{dashboardData.countUnsubOnMonthPlatinium}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mdg-space-12">
                        <div className="row">
                            {dashboardData.packsOrderData.map((elt, i) => {
                                return (
                                    <div className="col-12 col-sm-6 col-md-4">

                                        <div className="info-box mb-3">
                                            <span className="info-box-icon bg-info elevation-1"><i className="fas fa-bars"></i></span>

                                            <div className="info-box-content">
                                                <span className="info-box-text">{elt.packName}</span>
                                                <div className='info-box-text d-flex'>
                                                    <span className="info-box-number w-50">{elt.price} €</span>
                                                    <span className="info-box-number w-50">Qty: {elt.qty}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-lg-12 mdg-space-12">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-shopping-cart"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Total_items_sales"}></Language></span>
                                        <span className="info-box-number">{dashboardData.totalItemsSales}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-exchange"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Total_items_in_rent"}></Language></span>
                                        <span className="info-box-number">{dashboardData.itemRented}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-exchange"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Total_items_in_exchange"}></Language></span>
                                        <span className="info-box-number">{dashboardData.itemExchanged}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-shopping-cart"></i></span>

                                    <div className="info-box-content">
                                        <span className="info-box-text"><Language word={"Admin.Dashboard.Total_items_in_sales"}></Language></span>
                                        <span className="info-box-number">{dashboardData.itemSales}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* <div className="row">
            <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box">
                    <span className="info-box-icon bg-info elevation-1"><i className="fa fa-th"></i></span>

                    <div className="info-box-content">
                        <span className="info-box-text"><Language word={"Admin.Dashboard.Items"}></Language></span>
                        <span className="info-box-number">
                            {countItem}
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-danger elevation-1"><i className="fa fa-pencil-square"></i></span>

                    <div className="info-box-content">
                        <span className="info-box-text"><Language word={"Admin.Dashboard.Subscription"}></Language></span>
                        <span className="info-box-number">{activeSubscription}</span>
                    </div>
                </div>
            </div>
            <div className="clearfix hidden-md-up"></div>

            <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-success elevation-1"><i className="fas fa-shopping-cart"></i></span>

                    <div className="info-box-content">
                        <span className="info-box-text"><Language word={"Admin.Dashboard.Orders"}></Language></span>
                        <span className="info-box-number">{completedOrders}</span>
                    </div>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-warning elevation-1"><i className="fas fa-user"></i></span>

                    <div className="info-box-content">
                        <span className="info-box-text"><Language word={"Admin.Dashboard.New_Members"}></Language></span>
                        <span className="info-box-number">{newMembers}</span>
                    </div>
                </div>
            </div> */}

            {/* <div className="col-12 col-sm-6 col-md-4">
                <div className="info-box mb-3">
                    <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-pencil-square"></i></span>

                    <div className="info-box-content">
                        <span className="info-box-text"><Language word={"Admin.Dashboard.Total_Current_Free_Trial_Users"}></Language></span>
                        <span className="info-box-number">{newMembers}</span>
                    </div>
                </div>
            </div> 
            

        </div>*/}
        </>
    );
};

export default DescriptionComponent;