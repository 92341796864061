import React from 'react';
import HeaderPage from '../header/HeaderPage';
import Flight from './Flight';

const FlightPage = () => {
    document.body.style.backgroundColor = "#081217";
    return (
        <div className="mdg-body-ingame">
            <div className="mdg-header-ingame">
                <HeaderPage />
                <Flight />
            </div>
        </div>
    );
};

export default FlightPage;