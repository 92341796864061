import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import ItemService from "../../../../../../services/Item";
import ItemDetail from "../../../ingame/Details/ItemDetail";
import Deal from "./Deal";
import MyItemDetailNav from "./MyItemDetailNav";
import Swal from "sweetalert2";
import SellerModeService from "../../../../../../services/sellerMode";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ItemDetail2 from "../../../ingame/Details/ItemDetail2";
import Language from "../../../../../extra/language/language";
import translate from "../../../../../extra/language/translate";

const MyItemDetail = (props) => {
  const location = useLocation();
  const itemPassed = location.state?.item;
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const [item, setItem] = useState(null);
  const [relateds, setRelateds] = useState([]);
  const lang = useSelector((state) => state.language);

  let ariane = <Language word={"Seller.For_Sale"}></Language>;
  switch(props.action){
    case "exchange": ariane = <Language word={"Seller.Exchanged"}></Language>;break; 
    case "rent": ariane = <Language word={"Seller.Rented_out"}></Language>;break; 
    case "finished": ariane = <Language word={"Seller.Deal_Finished"}></Language>;break;
    default: break; 
  }

  const getRelateds = async () => {
    const result = await ItemService.getRelatedItems(
      props.item,
      props.type,
      user.id
    );
    if (!result.error) {
      setRelateds(result.data);
    }
  };

  const getItem = async () => {
    if (itemPassed) {
      setItem(itemPassed);
    } else {
      const result = await ItemService.getDetail(props.item);
      if (!result.error) {
        setItem(result.data);
      }
    }
  };

  const deleteMyStore = async (id) => {
    Swal.fire({
      title: translate('Seller.Confirmation',lang),
      text: translate('Seller.Are_you_sure_to_remove_this_item_from_the_store',lang),
      confirmButtonText: "Yes, i'm sure !",
      cancelButtonText: "Cancel",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await SellerModeService.deleteMyStore(id);
        if (res.error) {
          Swal.fire({
            title: res.response.data.message,
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            title: res.data.message,
            icon: res.data.error ? "warning" : "success",
            confirmButtonText: "Ok",
          }).then(() => {
            getRelateds();
          });
        }
      }
    });
  };

  useEffect(() => {
    getItem();
  }, [props.item]);

  useEffect(() => {
    getRelateds();
  }, [props.item, props.type]);

  return (
    <div className="container mdg-top-container">
      <div className="row justify-content-between mdg-title-ingame">
        <div className="col-lg-4">
          <NavLink to="/account/items" className={"link-style"}>
            <span className="mdg-h2"><Language word={"Seller.Manage_my_items"}></Language></span>
          </NavLink>
        </div>
        <div className="col-lg-8">          
          <div className="mdg-ariane-breadcrumb float-right">
            <NavLink to={"/account/items"} className=""><Language word={"Seller.Manage_my_items"}></Language></NavLink>
            <NavLink to={`/account/items/${props.item}/details/sell`} className="">{item?.name}</NavLink>
            <NavLink to={`/account/items/${props.item}/details/${props.action}`} className="">{ariane}</NavLink>
          </div>          
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="tab-content">
            <div className="mdg-width-card-item">
              <div className="mdg-width-card-item p-3">
                {/* <ItemDetail item={item} hide={true}></ItemDetail> */}
                <ItemDetail2 item={item} id={props.item} hide={true} action={props.action}></ItemDetail2>
                <MyItemDetailNav
                  relateds={relateds.length}
                  itemId={props.item}
                ></MyItemDetailNav>
                <div className="container-fluid">
                  {relateds &&
                    relateds.map((deal, i) => (
                      <Deal
                        deal={deal}
                        key={i}
                        index={i + 1}
                        deleteMyStore={deleteMyStore}
                        type={props.type}
                      ></Deal>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyItemDetail;
