import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SellerModeService from "../../../../../services/sellerMode";
import Language from "../../../../extra/language/language";

import "./style.css"

const BidProposal = ({ itemId }) => {
    const [proposals, setProposals] = useState([]);
    const [loading, setLoading] = useState(true);
    const getProposals = async () => {
        const result = await SellerModeService.getProposalByItemId(itemId);
        if (!result.error) {
            setProposals(result.data)
        }
        setLoading(false);
    }

    const refuseProposal = async (id) => {
        const data = new FormData();
        data.append("proposalStatus", "DENIED");
        const res = await SellerModeService.updateProposalStatus(id, data);
        if (res.error) {
            Swal.fire({
                title: res.response.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: res.data.message,
                icon: res.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
                getProposals();
            });
        }
    }

    const validProposal = async (id) => {
        const data = new FormData();
        data.append("proposalStatus", "ACCEPTED");
        const res = await SellerModeService.updateProposalStatus(id, data);
        if (res.error) {
            Swal.fire({
                title: res.response.data.message,
                icon: "warning",
                confirmButtonText: "Ok",
            });
        } else {
            Swal.fire({
                title: res.data.message,
                icon: res.data.error ? "warning" : "success",
                confirmButtonText: "Ok",
            }).then(() => {
                getProposals();
            });
        }
    }

    useEffect(() => {
        getProposals();
    }, []);


    return (
        <table >
            <thead>
                <tr>
                    <th ><Language word={"Seller.Name"}></Language></th>
                    <th ><Language word={"Seller.FC_Amount"}></Language></th>
                    <th ><Language word={"Seller.Action"}></Language></th>
                </tr>
            </thead>
            <tbody>
                {
                    loading && <tr ><td colSpan="3" ><Language word={"Seller.loading"}></Language></td></tr>
                }
                {
                    !loading && proposals.map((proposal, i) => (
                        <tr key={i}>
                            <td> {proposal.user.userName}</td>
                            <td> {proposal.fcAmount}  FC</td>
                            {proposal.status == "PENDING" ? (
                                <td>
                                    <button className="mdg-btn-deny" onClick={(e) => { refuseProposal(proposal.id) }}><Language word={"Seller.Refuse"}></Language></button>
                                    <button className="mdg-btn-accept" onClick={(e) => { validProposal(proposal.id) }}><Language word={"Seller.Valid"}></Language></button>
                                </td>
                            ):(<td>{proposal.status}</td>)}
                        </tr>
                    ))
                }
                {!loading && proposals.length == 0 && <tr ><td colSpan="3" ><Language word={"Seller.No_proposal_found"}></Language></td></tr>}
            </tbody>
        </table>);
}

export default BidProposal;