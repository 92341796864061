import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Select from "react-select";
import { findInList, getImageUrl, selectStyles } from "../../../Utils/Common";
import swal from "sweetalert";
import DescriptionComponent from "../description/DescriptionComponent";
import StoreCategoryService from "../../../services/store/catogory";
import StoreProductService from "../../../services/store/product";

const StoreProductForm = (props) => {
    const { id } = useParams();
    const actionName = props.action;
    const [productTitle, setProductTitle] = useState("");
    const [productDescripion, setProductDescripion] = useState("");
    const [fcPrice, setFcPrice] = useState(0);
    const [euroPrice, setEuroPrice] = useState(0);
    
    const [storeCategoryList, setStoreCategoryList] = useState([]);
    const [storeCategory, setStoreCategory] = useState("");

    const [productImage, setProductImage] = useState("");
    const [photoUrl, setPhotoUrl] = useState("/assets/img/upload-img.png");

    const getStoreCategoryList = async () => {
        let store_categories_list = await StoreCategoryService.getAvailableStoreCategoryListAsKeyValueService(id);
        setStoreCategoryList(store_categories_list);
    };

    const getStoreProduct = async () => {
        if (id) {
            const result = await StoreProductService.getStoreProductById(id); 
            if (!result.error) {
                const data = result.data.data;
                setProductTitle(data.title);
                setProductDescripion(data.description);
                setFcPrice(data.fcPrice                    );
                setEuroPrice(data.euroPrice);
                setStoreCategory(data.storeCategoryId);
                setPhotoUrl(getImageUrl(data.image));
            }
        }
    };

    useEffect(() => {
        getStoreCategoryList();
        getStoreProduct();
    }, []);

    const createStoreCategoryHandler = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("image", productImage);
        formData.append("title", productTitle);
        formData.append("description", productDescripion);
        formData.append("fcPrice", fcPrice);
        formData.append("euroPrice", euroPrice);
        formData.append("storeCategory", storeCategory);

        let result;

        if (id) {
            result = await StoreProductService.updateStoreProductService(id, formData);
        } else {
            result = await StoreProductService.createStoreProductService(formData);
        }


        if (result.error) {
            swal(result.response.data.message, {
                icon: "warning",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                event.preventDefault();
            });
        } else {
            swal(result.data.message, {
                icon: "success",
                buttons: {
                    confirm: "OK",
                },
            }).then((value) => {
                if (value) {
                    event.preventDefault();
                }
            });
        }
    };

    const handleChangeImage = () => {
        const pics = document.getElementById("id-product-pics");
        pics.click();
    };

    return (
        <div >

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{actionName} Store Product</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="#">Admin</a></li>
                                <li className="breadcrumb-item active">Store Product</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">{actionName} Store Product</h5>

                                    <div className="card-tools">
                                        <NavLink to="/admin/store-product/" type="button" className="btn btn-tool bg-info" >
                                            List
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <form onSubmit={(e) => createStoreCategoryHandler(e)}>
                                                <div className="mdg-card-body mdg-row">
                                                    <div className="mdg-col-sm-6 mdg-row">
                                                        <div className="mdg-col-sm-12 text-center">
                                                            <input
                                                                className="mdg-d-none"
                                                                type="file"
                                                                accept="image/png, image/gif, image/jpeg"
                                                                id="id-product-pics"
                                                                onChange={(event) => {
                                                                    setProductImage(event.target.files[0]);
                                                                    setPhotoUrl(URL.createObjectURL(event.target.files[0]));
                                                                }}
                                                            />

                                                            <img
                                                                onClick={handleChangeImage}
                                                                src={photoUrl}
                                                                className="mdg-upload-img"
                                                                alt=""
                                                            ></img>

                                                        </div>
                                                    </div>
                                                    <div className="mdg-col-sm-6 mdg-row">
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-product-title-id"
                                                                type="text"
                                                                placeholder=" "
                                                                value={productTitle}
                                                                style={{ color: "#fff" }}
                                                                onChange={(event) => {
                                                                    setProductTitle(event.target.value);
                                                                }}
                                                                required
                                                            />
                                                            <label htmlFor="input-product-title-id">
                                                                Title *
                                                            </label>
                                                        </div>
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-product-description"
                                                                type="text"
                                                                placeholder=" "
                                                                style={{ color: "#fff" }}
                                                                value={productDescripion}
                                                                onChange={(event) => {
                                                                    setProductDescripion(event.target.value);
                                                                }}
                                                                required
                                                            />
                                                            <label htmlFor="input-product-description">
                                                                Description *
                                                            </label>
                                                        </div>
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-product-fc-price"
                                                                type="number"
                                                                placeholder=" "
                                                                style={{ color: "#fff" }}
                                                                value={fcPrice}
                                                                onChange={(event) => {
                                                                    setFcPrice(event.target.value);
                                                                }}
                                                                required
                                                            />
                                                            <label htmlFor="input-product-fc-price">
                                                                FC Price *
                                                            </label>
                                                        </div>
                                                        <div className="mdg-textfield-out-lined">
                                                            <input
                                                                id="input-product-euro-price"
                                                                type="number"
                                                                placeholder=" "
                                                                style={{ color: "#fff" }}
                                                                value={euroPrice}
                                                                onChange={(event) => {
                                                                    setEuroPrice(event.target.value);
                                                                }}
                                                                required
                                                            />
                                                            <label htmlFor="input-product-euro-price">
                                                                Euro Price 
                                                            </label>
                                                        </div>
                                                        {storeCategoryList && (
                                                            <div className="mdg-textfield-out-lined">
                                                                <Select
                                                                    placeholder="Choose a Store Category *"
                                                                    options={storeCategoryList}
                                                                    styles={selectStyles}
                                                                    isClearable={true}
                                                                    style={{ color: "#fff" }}
                                                                    onChange={(newValue, actionMeta) => {
                                                                        newValue != null ? setStoreCategory(newValue.value) : setStoreCategory(null);
                                                                    }}
                                                                    value={findInList(storeCategoryList, storeCategory)}
                                                                    required
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-lg-12 text-right mt-2">
                                                        <button className="col-lg-2 mdg-btn btn-primary mdg-btn-round float-right">
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default StoreProductForm;
