import {
  verifyTokenStarted, verifyUserSuccess, verifyTokenEnd,
  userLoginStarted, userLoginFailure, userLogout,updateUserInformation,loggedWithSuccess
} from "../actions/authActions";
import { verifyTokenService, userLoginService, userLogoutService } from '../services/auth';
import { setAuthToken, setXsrfToken, setRefreshToken } from '../services/AxiosInstance';
import { refreshWalletAsync } from "./walletAsyncActions";
import Cookies from 'universal-cookie';
import UserService from "../services/user";

// handle verify token
export const verifyTokenAsync = (silentAuth = false) => async dispatch => {
  dispatch(verifyTokenStarted(silentAuth));

  const result = await verifyTokenService();

  if (result.error) {
    dispatch(verifyTokenEnd());
    if (result.response && [401, 403].includes(result.response.status))
      dispatch(userLogout());
    return;
  }

  if (result.status == 204) {
    dispatch(verifyTokenEnd());
  }
  else {
    // sessionStorage.setItem('token', result.data.token);
    // sessionStorage.setItem('xsrfToken', result.data.xsrfToken);
    // sessionStorage.setItem('refreshToken', result.data.refreshToken);

    // setAuthToken(result.data.token);
    // setXsrfToken(result.data.xsrfToken);
    // setRefreshToken(result.data.refreshToken);

    // dispatch(verifyUserSuccess(result.data));
  }
}

// handle user login
export const userLoginAsync = (username, password, remember_me) => async dispatch => {

  dispatch(userLoginStarted());

  const result = await userLoginService(username, password);

  if (result.error) {
    dispatch(userLoginFailure(result.response.data.message));
    return;
  }

  const cookiesObj = {
    username : username,
    password : password,
    rem_me : true
  }
  const cookies = new Cookies();
  if(remember_me){
    cookies.set('cookiesObj', cookiesObj , { path: '/' });
  }else cookies.remove('cookiesObj', cookiesObj , { path: '/' });
  
  // Pacman

  setAuthToken(result.data.token);
  setXsrfToken(result.data.xsrfToken);
  setRefreshToken(result.data.refreshToken);

  dispatch(loggedWithSuccess(result.data));
  dispatch(updateUserInfo());
 
}

// handle user logout
export const userLogoutAsync = () => dispatch => {
  dispatch(userLogout());
  userLogoutService();
}

// handle user logout
export const autoLogUser = (data) => dispatch => {
  const { token, xsrfToken, refreshToken } = data;

  setAuthToken(token);
  setXsrfToken(xsrfToken);
  setRefreshToken(refreshToken);

  dispatch(verifyUserSuccess(data));
}

export const updateUserInfo = () => async dispatch => {
  const result = await UserService.getMyInformation();

  if (result.error) {
    if (result.response && [401, 403].includes(result.response.status))
      dispatch(userLogout());
    return;
  }
  else{
    dispatch(updateUserInformation(result.data));
  } 
}
