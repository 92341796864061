import React, { useEffect, useState } from "react";
import HeaderBo from "./HeaderBo";
import SideBo from "./SideBo";
import FooterBO from "./FooterBO"
import AppInfoService from "../../../../services/appInfo";
import StoreOrderService from "../../../../services/store/order";


const BaseBO = (props) => {
    const [version, setVersion] = useState("0.0.1");
    const [pendigOrder, setPendingOrder] = useState(0)

    var pjson = require("../../../../../package.json");

    const appInfo = async () =>{
        const res = await AppInfoService.getAppInfo();
        setVersion(res.data.version);
    }
    const getPendingOrder = async() => {
        const result = await StoreOrderService.getPendingOrderService();
        if(!result.error){
            setPendingOrder(result.data.data.count);
        }
    }
    useEffect(()=>{
        appInfo();
        getPendingOrder()
    },[])

    return (
      <div className="dark-mode sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed">
        <div className="wrapper">
          <HeaderBo pendigOrder={pendigOrder}></HeaderBo>
          <SideBo></SideBo>
          <div className="content-wrapper">{props.page}</div>
          <FooterBO apiVersion={version} webVersion={pjson.version}></FooterBO>
        </div>
      </div>
    );
}

export default BaseBO;