import { REFRESH_USER_WALLET, REFRESH_USER_WALLET_END, REFRESH_USER_WALLET_START } from "../actions/actionTypes";

const initialState = {
  fc: null,
  miles: null,
  xp: null,
  refreshing: true
}

// update store based on type and payload and return the state
const wallet = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_USER_WALLET_START:
      return {
        ...state,
        refreshing: true
      }
    case REFRESH_USER_WALLET:
      const { fc, miles, xp } = action.payload;
      return {
        ...state,
        fc,
        miles,
        xp
      }
    case REFRESH_USER_WALLET_END:
      return {
        ...state,
        refreshing: false
      }

    default:
      return state
  }
}

export default wallet;