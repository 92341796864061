import axios  from "./AxiosInstance"

export const getItemsTypesListService = async () => {
  try {
    return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/type`);
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

export const createItemsTypesService = async (data) => {
    // try {
    //     return await axios.post(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/type`, {
    //         name,
    //         color,
    //         textColor,
    //     });
    // } catch (err) {
    //     return {
    //         error: true,
    //         response: err.response,
    //     };
    // }
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/type`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

export const getItemTypesListAsKeyValueService = async () => {
    const res = await getItemsTypesListService();
    if (res.error) {
        return [];
    } else {
        const itemTypes = [];
        for (let i = 0; i < res.data.length; ++i) {
        const elt = res.data[i];

            itemTypes.push({ label: elt.name + elt.color, value: elt.id });
        }
        return itemTypes;
    }
};

export const updateItemsTypesService = async (id, data) => {
    // try {
    //     return await axios.put(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/type/${id}`, {
    //         name,
    //         color,
    //         textColor,
    //     });
    // } catch (err) {
    //     return {
    //         error: true,
    //         response: err.response,
    //     };
    // }
    try {
        return await axios({
            method: "put",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/type/${id}`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

export const deleteItemsTypes = async (id) => {
    try {
        return await axios.delete(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/type/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

export const getItemsTypesById = async (id) => {
    try {
        return await axios.get(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/type/${id}`);
    } catch (err) {
        return {
            error: true,
            response: err.response,
        };
    }
};

const ItemTypesService = {
    getItemsTypesListService,
    createItemsTypesService,
    getItemTypesListAsKeyValueService,
    updateItemsTypesService,
    deleteItemsTypes,
    getItemsTypesById,
}

export default ItemTypesService;
