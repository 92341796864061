import React, { Component } from 'react';
import HeaderPage from '../header/HeaderPage';
import Seller from './Seller';

class SellerPage extends Component {
    render() {
    document.body.style.backgroundColor = "#081217";
        return (
            <div className="mdg-body-ingame">
                <div className="mdg-header-ingame">
                    <HeaderPage />
                    <Seller />
                </div>
            </div>
        );
    }
}

export default SellerPage;