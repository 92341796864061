import axios  from "../AxiosInstance"

const getAllScenario = async (id) => {
    try {
      return axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const getById = async (id) => {
    try {
      return await axios.get(
        `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario/${id}`
      );
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const create = async (data) => {
    try {
        return await axios({
            method: "post",
            url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario`,
            data: data
        }) ;
    } catch (err) {
      return {
        error: true,
        response: err.response,
      };
    }
};

const deleteById = async (id) => {
  try {
    return await axios.delete(
      `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario/${id}`,
    );
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const update = async (id,data) => {
  try {
      return await axios({
          method: "put",
          url: `${process.env.REACT_APP_REMOTE_API_BASE_URL}/scenario/${id}`,
          data: data
      }) ;
  } catch (err) {
    return {
      error: true,
      response: err.response,
    };
  }
};

const getAllAsKeyValue = async () => {
  const res = await getAllScenario();
  if (res.error) {
      return [];
  } else {
      const output = [];
      const  data  = res.data;
      
      data.forEach( (elt,i) => {
          output.push({ label: elt.title, value: elt.id });
      })

      return output;
  }
}

const ScenarioService = {
    create,
    getAllScenario,
    getById,
    deleteById,
    update,
    getAllAsKeyValue,
}

export default ScenarioService;