import React, { Component } from "react";
import HeaderPage from "../header/HeaderPage";
import MyStore from "./MyStore";

class MyStorePage extends Component {
  render() {
    document.body.style.backgroundColor = "#081217";
    return (
      <div className="mdg-body-ingame">
        <div className="mdg-header-ingame">
          <HeaderPage />
          <MyStore page={this.props.page} />
        </div>
      </div>
    );
  }
}

export default MyStorePage;
