import { userLogout } from "../actions/authActions";
import { refreshUserWallet, refreshUserWalletEnd, refreshUserWalletStart } from "../actions/walletAction";
import { getUserWalletService } from "../services/user";

export const refreshWalletAsync = () => async dispatch => {
    dispatch(refreshUserWalletStart());
   
    const result = await getUserWalletService();
   
    if (result.error) {
      dispatch(refreshUserWalletEnd());
      if (result.response && [401].includes(result.response.status))
        dispatch(userLogout());
      return;
    }
   
    if (result.status == 200){      
      dispatch(refreshUserWallet(result.data));
    }

    dispatch(refreshUserWalletEnd());
}