import axios from "axios";
const axiosApiInstance = axios.create({
    baseURL: process.env.REACT_APP_REMOTE_API_BASE_URL,
    headers: {
        'x-xsrf-token': sessionStorage.getItem('xsrfToken') !== null ? sessionStorage.getItem('xsrfToken') : null,
        'x-refresh-token': sessionStorage.getItem('refreshToken') !== null ? sessionStorage.getItem('refreshToken') : null,
        'Authorization': sessionStorage.getItem('token') !== null ? `Bearer ${sessionStorage.getItem('token')}` : null,
        'content-type': 'multipart/form-data'
    }
});

// set token to the axios
export const setAuthToken = (token) => {
    if (token) {
      sessionStorage.setItem('token', token);

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      sessionStorage.removeItem('token');
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  export const setXsrfToken = (xsrfToken) => {
    if (xsrfToken) {
        sessionStorage.setItem('xsrfToken', xsrfToken);
      axios.defaults.headers.common["x-xsrf-token"] = xsrfToken;
    } else {
      sessionStorage.removeItem('xsrfToken');
      delete axios.defaults.headers.common["x-xsrf-token"];
    }
  };

  export const setRefreshToken = (refreshToken) => {
    if (refreshToken) {
      sessionStorage.setItem('refreshToken', refreshToken);
      axios.defaults.headers.common["x-refresh-token"] = refreshToken;
    } else {
      sessionStorage.removeItem('refreshToken');
      delete axios.defaults.headers.common["x-refresh-token"];
    }
  };

let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })
    failedQueue = [];
}

axiosApiInstance.interceptors.request.use(
    async config => {
        const token = sessionStorage.getItem('token');
        const xsrfToken = sessionStorage.getItem('xsrfToken');
        const refreshToken = sessionStorage.getItem('refreshToken');

      config.headers = {
        'Authorization': `Bearer ${token}`,
        'x-refresh-token': `${refreshToken}`,
        'x-xsrf-token': `${xsrfToken}`,
        'Accept': 'application/json',
      }

      return config;
    },
    error => {
      Promise.reject(error)
  });

  // Response interceptor for API calls
  axiosApiInstance.interceptors.response.use((response) => {
    return response
  }, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry && (axios.defaults.headers.common["x-refresh-token"] !== undefined || sessionStorage.getItem('refreshToken') !== null)) {
        if (axios.defaults.headers.common["in-refresh-token"] === true) {
            // TODO Promise
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                return axiosApiInstance(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }
        axios.defaults.headers.common["in-refresh-token"] = true;
        originalRequest._retry = true;
        return new Promise((resolve, reject) => {
            axiosApiInstance.post(`${process.env.REACT_APP_REMOTE_API_BASE_URL}/refresh-token`)
            .then((result) => {
                setAuthToken(result.data.token);
                setXsrfToken(result.data.xsrfToken);
                processQueue(null, result.data.token);
                resolve(axiosApiInstance(originalRequest));
            }).catch(err => {
                setAuthToken(null);
                setXsrfToken(null);
                setRefreshToken(null);
                processQueue(err, null);
                reject(err);
            }).finally(() => {
                axios.defaults.headers.common["in-refresh-token"] = undefined;
            });
        });
    }
    return Promise.reject(error);
  });


export default axiosApiInstance;
