import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import Achat from './Achat';

const AchatPages = () => {
    return (
        <div>            
            <BaseBO page={<Achat />}></BaseBO>
        </div>
    );
};

export default AchatPages;