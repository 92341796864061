import React from 'react';
import BaseBO from '../../templates/bo/BaseBo';
import Commission from './Commission';

const CommissionPages = () => {
    return (
        <div>            
            <BaseBO page={<Commission />}></BaseBO>
        </div>
    );
};

export default CommissionPages;