import { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { IMaskInput } from "react-imask";
import { getFullDate, selectStylesFo, getFormatedDate, getLocalFormatedDate, } from "../../../../../Utils/Common";
import Swal from "sweetalert2";
import SellerModeService from "../../../../../services/sellerMode";
import { getUserListService } from "../../../../../services/user";
import { useSelector } from "react-redux";
import QuantityPickerInput from "../../../../extra/quantite_picker/QuantityPickerPages";
import DatePickerDarkInput from "../../../../extra/datepicker/DatePickerDarkInput";
import DatePicker from "react-datepicker";
import Language from "../../../../extra/language/language";

const Sell = ({ itemId, item }) => {
  const authObj = useSelector((state) => state.auth);

  const { user } = authObj;
  const [userList, setUserList] = useState([]);
  const [selectedSellType, setSelectedSellType] = useState({
    label: <Language word={"Seller.Sell"}></Language>,
    value: "SELL",
  });
  const [sellType, setSellType] = useState(0);
  const [price, setPrice] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dateLimit, setDateLimit] = useState(null);
  const selectOption = [
    { label: <Language word={"Seller.Sell"}></Language>, value: "SELL" },
    { label: <Language word={"Seller.Bid"}></Language>, value: "BID" },
    { label: <Language word={"Seller.Private"}></Language>, value: "PRIVATE" },
  ];
  const [selectedDate, setselectedDate] = useState(null);
  let inputValue = 1;

  const getUserList = async () => {
    await getUserListService().then((res) => {
      const users = [];
      if (res.statusText === "OK") {
        for (let i = 0; i < res.data.length; ++i) {
          let elt = res.data[i];
          let data = {
            name: elt.firstName + " " + elt.lastName,
            value: elt.id,
            label: elt.firstName + " " + elt.lastName,
          };
          if (user.id !== elt.id) {
            users.push(data);
          }
        }
      }
      setUserList(users);
    });
  };


  const sellItem = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("itemId", itemId);
    formData.append("fcAmount", price);
    formData.append("type", sellType);

    if (sellType === "BID") {
      formData.append("dateLimit", getFullDate(dateLimit));
    } else if (sellType === "PRIVATE") {
      formData.append("cible", selectedUser);
    }

    const res = await SellerModeService.sellMyItem(formData);

    if (res.error) {
      Swal.fire({
        title: res.response.data.message,
        icon: "warning",
        confirmButtonText: "Ok",
      });
    } else {
      Swal.fire({
        title: res.data.message,
        icon: res.data.error ? "warning" : "success",
        confirmButtonText: "Ok",
      }).then(() => { });
    }
  };

  const filterUsers = (inputValue) => {
    return userList.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadUserListOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterUsers(inputValue));
    }, 1000);
  };

  const handleUserInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');

    return inputValue;
  };

  useEffect(() => {
    getUserList();
    console.log(item)
  }, []);

  return (
    <div className="row">
      <div className="col-lg-12">
        <h1 className="mdg-text-white"><Language word={"Seller.Sell_my_Item"}></Language></h1>
      </div>
      <div className="col-lg-12">
        <form onSubmit={sellItem}>
          <div className="row">
            <div className="col-lg-12">
              <div className="mdg-form-seller mdg-textfield-out-lined">
                <Select
                  isClearable={false}
                  onChange={(newValue, actionMeta) => {
                    setSelectedSellType(newValue);
                    setSellType(newValue.value);
                  }}
                  styles={selectStylesFo}
                  isSearchable={false}
                  placeholder={<div><Language word={"Seller.Choose_a_type"}></Language></div>}
                  options={selectOption}
                  value={selectedSellType}
                  isDisabled={!item.canSell}
                  id="id-sell-type"
                  className="mdg-seller-type"
                  
                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="">
                {/* <input id="id-price" type="number" min="1" required className="mdg-text-white" name="price" placeholder=" " value={price} onChange={(e) => {   setPrice(e.target.value); }} /> */}
                {sellType === "BID" ? (
                  <label htmlFor="id-price" className="mdg-text-white"><Language word={"Seller.From_Price"}></Language></label>
                ) : (
                  <label htmlFor="id-price" className="mdg-text-white"><Language word={"Seller.Price"}></Language></label>
                )}
                <QuantityPickerInput className="mt-2" min={inputValue} inputValue={inputValue} onChange={(value) => { inputValue = value; setPrice(inputValue) }} isDisabled={!item.canSell}></QuantityPickerInput>
              </div>
            </div>
            {sellType === "PRIVATE" && (
              <div className="col-lg-12">
                <div className="mdg-form-seller mdg-textfield-out-lined">
                  <AsyncSelect
                    styles={selectStylesFo}
                    placeholder="Choose an user"
                    required
                    isClearable={true}
                    onChange={(newValue) => {
                      if (newValue) {
                        setSelectedUser(newValue.value);
                      }
                    }}
                    cacheOptions
                    loadOptions={loadUserListOptions}
                    defaultOptions
                    onInputChange={handleUserInputChange}
                    id="id-user"
                    className="mdg-seller-type"
                  />
                </div>
              </div>
            )}
            {sellType === "BID" && (
              <div className="col-lg-12">
                <div className="mdg-textfield-out-lined mdg-change-bg-datepicker mdg-form-seller">
                  <label htmlFor="id-date-limit" className="mdg-text-white"><Language word={"Seller.Date_Limit"}></Language></label>
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    id="id-bid-date-limit"
                    minDate={new Date()}
                    value={dateLimit?getLocalFormatedDate(dateLimit):getLocalFormatedDate(new Date())}
                    showDisabledMonthNavigation
                    placeholderText="Date Limit"
                    showYearDropdown
                    showMonthDropdown
                    autoComplete="false"
                    className="mdg-date-picker"
                    dropdownMode="select"
                    required={true}
                    onChange={(date) => {
                        setDateLimit(date);
                    }}
                  />
                </div>
                {/* <label htmlFor="id-date-limit" className="mdg-text-white">Date Limit</label> 
                <div className="mdg-top-7 mdg-form-seller">
                  {/* <IMaskInput
                    mask={Date}
                    value={dateLimit}
                    min={new Date()}
                    onAccept={(value, mask) => {setDateLimit(null);}}
                    onComplete={(value) => {setDateLimit(value);}}
                    id="id-date-limit"
                    style={{ color: "white" }}
                    required
                    minLength={10}
                    maxLength={10}
                  /> 
                   <DatePickerDarkInput
                    min={new Date(1950, 0, 1)}
                    max={new Date()}
                    id="id-date-limit"
                    required
                    minLength={10}
                    maxLength={10}
                    placeholder=""
                    value={dateLimit}
                    style={{ color: "white" }}
                    onChange={(value) => { setDateLimit(value); }}
                  /> 
                  
                </div>*/}
              </div>
            )}
            <div className="col align-self-end mt-3">
                {item.canSell ? 
                <button className="btn mdg-btn-common btn-sm float-right mdg-btn-12">
                    <span><Language word={"Seller.Sell_now"}></Language></span>
                </button>: <small className="mdg-text-red"><Language word={"Seller.This_item_can_not_be_resold"}></Language></small>
              }
            </div> 
          </div>
        </form>
      </div>
    </div>
  );
};

export default Sell;
